import React, { useContext, useEffect, useRef, useState } from "react";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import { alertErrorMessage, alertSuccessMessage } from "../../../../Services/CustomAlertModel";
import AuthService from "../../../../Services/ApiServices/AuthService";
import { ProfileContext } from "../../../../Services/ProfileProvider";
import { Link } from "react-router-dom";
import Webcam from 'react-webcam';
import SpinLoader from "../../../CoustamComponent/SpinLoader";
import moment from "moment";

const PaymentModules = () => {

    const { userDetails, handleUserProfile, userKycDetails, handleGetKycDetails, loading, setLoading } = useContext(ProfileContext);

    const [isOtpSent, setIsOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [isActiveAddBank, setIsActiveAddBank] = useState(false);
    const [isActiveAddUpi, setIsActiveAddUpi] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showImageModel, setShowImageModel] = useState("step1");
    const [isCameraAllowed, setIsCameraAllowed] = useState(false);
    const [imageStatus, setImageStatus] = useState(false);
    const [image, setImage] = useState(null);
    const [showSubmitButton, setShowSubmitButton] = useState(false);
    const [isActiveTab, setIsActiveTab] = useState("bank");
    const [showButton, setShowButton] = useState(false);
    const [ifscValid, setIfscValid] = useState(false);
    const [disableField, setDisableField] = useState(false);
    const [isActiveBank, setIsActiveBank] = useState("Active");
    const [isActiveUPI, setIsActiveUPI] = useState("Active");
    const [otpRequested, setOtpRequested] = useState(false);

    const webcamRef = useRef(null);

    const [formData, setFormData] = useState({
        emailId: "",
        emailOtp: '',
        bank_name: '',
        branch_name: '',
        accountHolderName: userKycDetails?.pandetails?.name || "",
        accountNumber: '',
        ifscCode: '',
        accountType: 'Saving',
        upiId: '',

    });

    useEffect(() => {
        setFormData({
            emailId: userDetails?.user?.email || "",
            accountHolderName: userKycDetails?.pandetails?.name || "",
            accountType: 'Saving',
        });
    }, [userKycDetails, userDetails]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        if (name === 'ifscCode') {
            formattedValue = formattedValue.toUpperCase().replace(/\s/g, '');
            formattedValue = formattedValue.replace(/(.{5})(.{4})(.{1})/, '$1$2$3');
            const panRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
            setIfscValid(panRegex.test(formattedValue));
        }
        setFormData(prevState => ({
            ...prevState,
            [name]: formattedValue
        }));
    };

    const base64ToBlob = (base64) => {
        const byteString = atob(base64.split(',')[1]);
        const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
        const buffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(buffer);
        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }
        return new Blob([buffer], { type: mimeString });
    };

    const handleSubmit = async () => {
        var formValue = new FormData();
        let formattedName = formData?.accountHolderName?.trim().replace(/\s+/g, ' ');
        formValue.append("email", formData.emailId);
        formValue.append("verification_code", formData.emailOtp);
        formValue.append("account_holder_name", formattedName);
        formValue.append("accountNumber", formData.accountNumber);
        formValue.append("ifsc", formData.ifscCode);
        formValue.append("account_type", formData.accountType);
        formValue.append("bank_name", formData.bank_name);
        formValue.append("branch_name", formData.branch_name);
        formValue.append("bankDocument", base64ToBlob(image), 'live-image.jpg');
        setIsLoading(true);
        try {
            const result = await AuthService.addBankAccount(formValue);
            if (result?.success) {
                handleUserProfile()
                setIsActiveAddBank(false);
                setIsOtpSent(false);
                setCountdown(0);
                setIsLoading(false);
                alertSuccessMessage(result.message);
                base64ToBlob();
            } else {
                alertErrorMessage(result || result.message);
                setIsLoading(false);
            }
        } catch (error) {
            alertErrorMessage(error);
            setIsLoading(false);
        }
    };

    const handleSubmitAddUpi = async () => {
        if (!formData?.upiId) {
            alertErrorMessage('Please Enter Upi ID..!!');
            return;
        }
        if (!formData?.emailOtp) {
            alertErrorMessage('Please Enter Email Otp..!!');
            return;
        }
        setLoading(true);
        try {
            const result = await AuthService.addUpiDetails(formData);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile()
                setIsActiveAddUpi(false);
                setIsOtpSent(false);
                setCountdown(0);
                setFormData({
                    emailId: "",
                    emailOtp: '',
                    upiId: '',
                    emailId: userDetails?.user?.email || "",
                    accountHolderName: userKycDetails?.pandetails?.name || "",
                });
                setLoading(false);
                handleUserProfile();
                setDisableField(false)
            } else {
                alertErrorMessage(result);
                setLoading(false);
                handleUserProfile();
                setDisableField(false)
            }
        } catch (error) {
            alertErrorMessage(error);
            setLoading(false);
            handleUserProfile();
            setDisableField(false)
        }

    };


    const handleAddDefaultBank = async (userId) => {
        try {
            const result = await AuthService.updateDefaultBank(userId);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile()
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    }

    const handleAddDefaultUpi = async (userId) => {
        try {
            const result = await AuthService.updateDefaultUpi(userId);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile()
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    }

    const handleDeleteUpi = async (userId) => {
        try {
            const result = await AuthService.deletetUpi(userId);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile()
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    }

    const deleteBankDetails = async (userId) => {
        try {
            const result = await AuthService.deletetBank(userId);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile()
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    }

    const handleGetOtp = async (emailId, type) => {
        try {
            const result = await AuthService.getOtp(emailId, type);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                setIsOtpSent(true);
                setShowButton(true);
                setCountdown(60);
                setDisableField(true)
                setOtpRequested(true)
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
            setDisableField(false)

        }
    };

    useEffect(() => {
        let timer;
        if (isOtpSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, countdown]);

    const ResetCount = () => {
        setIsOtpSent(false);
        setCountdown(0);

        setFormData({
            emailOtp: '',
            emailId: userDetails?.user?.email || "",
            accountHolderName: userKycDetails?.pandetails?.name || "",
            accountType: 'Saving',
        });
    }

    const checkCameraPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setIsCameraAllowed(true);
            stream.getTracks().forEach(track => track.stop());
        } catch (error) {
            setIsCameraAllowed(false);
            alertErrorMessage('Camera access is denied. Please enable camera access in your browser settings.');
        }
    };


    useEffect(() => {
        if (showImageModel === "step2") {
            checkCameraPermission();
        }
    }, []);


    const capture = () => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            setImage(imageSrc);
            setImageStatus(true);
        }
    };

    const handleSubmitDetails = () => {
        if (imageStatus) {
            setShowSubmitButton(true);
            setShowImageModel("step1");
        } else {
            setShowSubmitButton(false);
        }
    };



    useEffect(() => {
        handleUserProfile();
    }, [])

    const userBankDetails = userDetails?.bank_details?.map(item => item?.status);
    const userUpiDetails = userDetails?.upi_details?.map(item => item?.status);


    const userBankDetailsStatusNN = userDetails?.bank_details?.map(item => item?.isDeleted || item?.status);
    const userUpiDetailsStatusNN = userDetails?.upi_details?.map(item => item?.isDeleted || item?.status);


    return (

        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper" >
                    <section className="d_page history_page p_page m_account">
                        <div className="container" >
                            <div className="row justify-content-center" >
                                <div className="col-xxl-8 col-xl-10" >

                                    <div className="d_u_p align-items-center m-0" >
                                        <div className="dup_left" >
                                            <h6 className="mb-0" >  Payment Method   </h6>
                                        </div>
                                        <div className="up_right text-end" id="myTab" role="tablist" >
                                            <ul className="nav custom-tabs">
                                                {
                                                    userBankDetails?.some(status => status === "verified") ?
                                                        <>
                                                            <li role="presentation">
                                                                <button type="button" className={isActiveTab === "bank" ? 'show active' : ""} onClick={() => { setIsActiveTab("bank"); handleGetKycDetails(); handleUserProfile(); }}>
                                                                    Bank Account
                                                                </button>
                                                            </li>
                                                            <li role="presentation" className={userDetails?.bank_details?.length > 0 ? "" : "d-none"}>
                                                                <button type="button" className={isActiveTab === "upi" ? 'show active' : ""} onClick={() => { setIsActiveTab("upi"); handleGetKycDetails(); handleUserProfile(); }}>UPI ID </button>
                                                            </li>
                                                        </>
                                                        :
                                                        ""
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="tab-content" >
                                        <div className={`tab-pane ${isActiveTab === "bank" ? 'show active' : ""}`}>
                                            <div className="g_start  mt-4">
                                                <div className="d_u_p align-items-center m-0" >
                                                    <div className="dup_left" >
                                                        <h6 className="mb-0"> Bank Account </h6>
                                                    </div>
                                                    <div className="up_right" >
                                                        <div className="btn_row">
                                                            {/* {
                                                                userBankDetails?.filter(status => status === "verified")?.length < 2 && (
                                                                    <button
                                                                        className="btn btn-muted"
                                                                        onClick={() => {
                                                                            setIsActiveAddBank(true);
                                                                            setDisableField(false);
                                                                        }}
                                                                    >
                                                                        Add New
                                                                    </button>
                                                                )
                                                            } */}

                                                            {/*   {
                                                                userBankDetails?.some(
                                                                    (status) => status === "verified" 
                                                                )?.length < 2 && (
                                                                    <button
                                                                        className="btn btn-muted"
                                                                        onClick={() => {
                                                                            setIsActiveAddBank(true);
                                                                            setDisableField(false);
                                                                        }}
                                                                    >
                                                                        Add New
                                                                    </button>
                                                                )
                                                            } */}
                                                            {
                                                                userBankDetailsStatusNN?.some(status => status === "pending") ? false :
                                                                    userDetails?.bank_details?.filter(
                                                                        (bank) => bank?.status === "verified" && bank?.default
                                                                    )?.length < 2 && (
                                                                        <button
                                                                            className="btn btn-muted"
                                                                            onClick={() => {
                                                                                setIsActiveAddBank(true);
                                                                                setDisableField(false);
                                                                            }}
                                                                        >
                                                                            Add New
                                                                        </button>
                                                                    )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {userDetails?.bank_details?.some((item) => item?.isDeleted == true || item?.status === "rejected") ?
                                                    <div class="treade_card_header px-0 mt-2">

                                                        <div class={`card_header_title ${isActiveBank === "Active" ? "active" : ""}`} onClick={() => setIsActiveBank("Active")} > Active Accounts </div>

                                                        <div class={`card_header_title ${isActiveBank === "Rejected" ? "active" : ""}`} onClick={() => setIsActiveBank("Rejected")} >  Other Accounts </div>

                                                    </div>
                                                    :
                                                    ""
                                                }

                                                <div className={isActiveBank === "Active" ? "" : "d-none"}>
                                                    {userDetails?.bank_details?.length > 0 ?
                                                        userDetails?.bank_details
                                                            ?.filter((item) => item?.status === "verified" || item?.status === "pending" && item?.isDeleted == false)
                                                            ?.map((item, index) => {
                                                                return (
                                                                    <div className=" mt-4  p_card" key={index}>
                                                                        <div className="bb_head" >
                                                                            <div className="row align-items-center" >
                                                                                <div className="col-6" >
                                                                                    <p className="mb-0" >
                                                                                        {item?.userNameInBank || 'Bank Verification under process..'}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-md-6 text-end">
                                                                                    {item?.status === "pending" &&
                                                                                        <span className="badge text-dark">
                                                                                            <img
                                                                                                src="/assets/images/loadingNew.png"
                                                                                                alt=""
                                                                                                style={{ width: "20px" }}
                                                                                                className="d-inline-flex fw-semibold"
                                                                                            />
                                                                                        </span>
                                                                                    }

                                                                                    <span className={`badge mx-1 text-dark 
                                                                                    ${item?.status === "verified" ? 'badge-success' :
                                                                                            item?.status === "pending" ? 'bg-warning' :
                                                                                                item?.status === "rejected" ? 'badge-danger' : 'bg-secondary'}`}>


                                                                                        {item?.status?.toUpperCase()}
                                                                                    </span>
                                                                                    <span className={`badge mx-1 ${item?.default === true ? 'bg-primary' : ''}`}>
                                                                                        {item?.default === true ? 'Default' : ''}
                                                                                    </span>
                                                                                    {
                                                                                        item?.default === false && item?.status !== "rejected" &&
                                                                                        <span className="dropdown more_menu">
                                                                                            <button className="btn no-border py-0" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                <i className="ri-more-2-fill"></i>
                                                                                            </button>
                                                                                            <ul className="dropdown-menu p-2" aria-labelledby="dropdownMenuLink">
                                                                                                <li>
                                                                                                    <div className="dropdown-item cursor-pointer" onClick={() => handleAddDefaultBank(item?._id)}>
                                                                                                        Set As Default
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="dropdown-item  cursor-pointer text-danger" onClick={() => deleteBankDetails(item?._id)}>
                                                                                                        Delete Account
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row g-3" >
                                                                            <div className=" col-6 col-md-4" >
                                                                                <div className="ud" >
                                                                                    <span>Bank Name </span>
                                                                                    <h5> <small> {item?.bankName} </small>  </h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" col-6 col-md-6" >
                                                                                <div className="ud" >
                                                                                    <span> Account Number</span>
                                                                                    <h5> <small>{item?.accountNumber}</small>  </h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" col-6 col-md-4" >
                                                                                <div className="ud" >
                                                                                    <span> Branch Name </span>
                                                                                    <h5> <small> {item?.branchName} </small>  </h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" col-6 col-md-4" >
                                                                                <div className="ud" >
                                                                                    <span> IFSC Code </span>
                                                                                    <h5> <small> {item?.ifsc} </small>  </h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className=" col-6 col-md-4" >
                                                                                <div className="ud" >
                                                                                    <span> Account Holder Name </span>
                                                                                    <h5> <small> {item?.kycName} </small>  </h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );

                                                            })
                                                        :
                                                        <div className="nbf" >
                                                            <img src="assets/images/no_bank.svg" className="img-fluid" alt="" />
                                                        </div>
                                                    }
                                                </div>

                                                <div className={isActiveBank === "Rejected" ? "" : "d-none"}>
                                                    {userDetails?.bank_details
                                                        ?.filter((item) => item?.status === "rejected" || item?.isDeleted)
                                                        ?.map((item, index) => {
                                                            return (
                                                                <div className=" mt-4  p_card" key={index}>
                                                                    <div className="bb_head" >
                                                                        <div className="row align-items-start" >
                                                                            <div className="col-6" >
                                                                                <div className="brm" >
                                                                                    <p className="mb-0" >
                                                                                        <b className="text-danger" >  Bank account {item?.status === "rejected" ? "rejected" : item?.isDeleted ? "Deleted" : item?.status}</b>
                                                                                        {
                                                                                            item?.status === "rejected" &&

                                                                                            <span className="">
                                                                                                <b className=" d-inline-flex fw-semibold" >Reason:</b>  {item?.reject_reason?.remark}
                                                                                            </span>

                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d_u_p align-items-center col-lg-6" >
                                                                                {
                                                                                    item?.admin_rejected_date ? (
                                                                                        <div className="cn_title text-end" >
                                                                                            <p>
                                                                                                <span>   {item?.status} at:  </span> <br />
                                                                                                <small> {moment(item?.admin_rejected_date).format('MMMM Do YYYY, h:mm:ss a')} </small>
                                                                                            </p>
                                                                                        </div>
                                                                                    ) :
                                                                                        item?.deletedAt && (
                                                                                            <div className="cn_title text-end" >
                                                                                                <p>
                                                                                                    <span>   {item?.isDeleted && "Deleted"} at:  </span> <br />
                                                                                                    <small> {moment(item?.deletedAt).format('MMMM Do YYYY, h:mm:ss a')} </small>
                                                                                                </p>
                                                                                            </div>
                                                                                        )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row g-3" >
                                                                        <div className="col-md-4" >
                                                                            <div className="ud" >
                                                                                <span>Bank Name </span>
                                                                                <h5> <small> {item?.bankName} </small>  </h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6" >
                                                                            <div className="ud" >
                                                                                <span> Account Number</span>
                                                                                <h5> <small>{item?.accountNumber}</small>  </h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4" >
                                                                            <div className="ud" >
                                                                                <span> Branch Name </span>
                                                                                <h5> <small> {item?.branchName} </small>  </h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4" >
                                                                            <div className="ud" >
                                                                                <span> IFSC Code </span>
                                                                                <h5> <small> {item?.ifsc} </small>  </h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4" >
                                                                            <div className="ud" >
                                                                                <span> Account Holder Name </span>
                                                                                <h5> <small> {item?.kycName} </small>  </h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>


                                            </div>
                                        </div>

                                        <div className={`tab-pane  ${isActiveTab === "upi" ? 'show active' : ""}`}>
                                            <div className="g_start  mt-4    " >
                                                <div className="d_u_p align-items-center m-0" >
                                                    <div className="dup_left" >
                                                        <h6 className="mb-0" > UPI ID   </h6>
                                                    </div>
                                                    <div className="up_right">
                                                        <div className="btn_row">
                                                            {/* {
                                                                userUpiDetails?.filter(
                                                                    (upi) => upi === "verified" && upi?.isDeleted === false
                                                                )?.length < 2 && (
                                                                    <button
                                                                        className="btn btn-muted"
                                                                        onClick={() => setIsActiveAddUpi(true)}
                                                                    >
                                                                        Add New
                                                                    </button>
                                                                )
                                                            } */}



                                                            {
                                                                userUpiDetailsStatusNN?.some(status => status === "pending") ? false :
                                                                    userDetails?.upi_details?.filter(
                                                                        (upi) => upi?.status === "verified" && upi?.default
                                                                    )?.length < 2 && (
                                                                        <button
                                                                            className="btn btn-muted"
                                                                            onClick={() => setIsActiveAddUpi(true)}
                                                                        >
                                                                            Add New
                                                                        </button>
                                                                    )
                                                            }


                                                        </div>
                                                    </div>
                                                </div>


                                                {userDetails?.upi_details?.some((item) => item?.isDeleted == true || item?.status !== "verified") ?
                                                    <div class="treade_card_header px-0 mt-2">
                                                        <div class={`card_header_title ${isActiveUPI === "Active" ? "active" : ""}`} onClick={() => setIsActiveUPI("Active")} > Active UPI's </div>
                                                        <div class={`card_header_title ${isActiveUPI === "Rejected" ? "active" : ""}`} onClick={() => setIsActiveUPI("Rejected")} >  Other UPI's </div>
                                                    </div>
                                                    :
                                                    ""
                                                }



                                                <div className={isActiveUPI === "Active" ? "" : "d-none"}>
                                                    {userDetails?.upi_details?.length > 0 ?
                                                        userDetails?.upi_details
                                                            ?.filter((item) => item?.status === "verified" && item?.isDeleted == false)
                                                            ?.map((item, index) => {
                                                                return (
                                                                    <div className=" mt-4  p_card" key={index}>
                                                                        <div className="bb_head" >
                                                                            <div className="row px-0 align-items-center" >
                                                                                <div className="col-6" >
                                                                                    <h6 className="mb-0" > <small>{item?.vpaId}</small> </h6>
                                                                                </div>
                                                                                <div className="col-6 text-end">
                                                                                    <span className={`badge mx-1 ${item?.status === "verified" ? 'badge-success' : 'bg-danger'}`}
                                                                                    >  {item?.status?.toUpperCase()}</span>

                                                                                    <span className={`badge mx-1 ${item?.default === true ? 'bg-primary' : ''}`}>
                                                                                        {item?.default === true ? 'Default' : ''}
                                                                                    </span>
                                                                                    {
                                                                                        item?.default === false &&
                                                                                        <span className="dropdown more_menu">
                                                                                            <button className="btn no-border py-0" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                <i className="ri-more-2-fill"></i>
                                                                                            </button>
                                                                                            <ul className="dropdown-menu p-2" aria-labelledby="dropdownMenuLink">
                                                                                                <li>
                                                                                                    <div className="dropdown-item cursor-pointer" onClick={() => handleAddDefaultUpi(item?._id)}> Set As Default </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="dropdown-item  cursor-pointer text-danger" onClick={() => handleDeleteUpi(item?._id)}> Delete UPI </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row g-3" >
                                                                            <div className="col-md-4" >
                                                                                <div className="ud" >
                                                                                    <span> Name  </span>
                                                                                    <h5> <small>{item?.UPIHolderName}</small> </h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6" >
                                                                                <div className="ud" >
                                                                                    <span> UPI ID </span>
                                                                                    <h5> <small> {item?.vpaId} </small>  </h5>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            }) : <div className="nbf" >
                                                            <img src="assets/images/no_bank.svg" className="img-fluid" alt="" />
                                                        </div>}
                                                </div>

                                                <div className={isActiveUPI === "Rejected" ? "" : "d-none"}>
                                                    {userDetails?.upi_details?.length > 0 ?
                                                        userDetails?.upi_details
                                                            ?.filter((item) => item?.isDeleted || item?.status === "auto-rejected" || item?.status === "admin-rejected")
                                                            ?.map((item, index) => {
                                                                return (
                                                                    <div className=" mt-4  p_card" key={index}>
                                                                        <div className="bb_head" >
                                                                            <div className="row align-items-start" >
                                                                                <div className="col-6" >
                                                                                    <div className="brm" >
                                                                                        <p className="mb-0" >
                                                                                            <b className="text-danger" >  UPI account: {item?.isDeleted ? "Deleted" : item?.status}</b>
                                                                                            {
                                                                                                (item?.status === "auto-rejected" || item?.status === "admin-rejected") && (
                                                                                                    <span className="">
                                                                                                        <b className="d-inline-flex fw-semibold">Reason:</b> {item?.message}
                                                                                                    </span>
                                                                                                )
                                                                                            }

                                                                                        </p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d_u_p align-items-center col-lg-6" >
                                                                                    {
                                                                                        item?.rejected_date ? (
                                                                                            <div className="cn_title text-end" >
                                                                                                <p>
                                                                                                    <span>   {item?.status} at:  </span> <br />
                                                                                                    <small> {moment(item?.rejected_date).format('MMMM Do YYYY, h:mm:ss a')} </small>
                                                                                                </p>
                                                                                            </div>
                                                                                        ) :
                                                                                            item?.deletedAt && (
                                                                                                <div className="cn_title text-end" >
                                                                                                    <p>
                                                                                                        <span>   {item?.isDeleted && "Deleted"} at:  </span> <br />
                                                                                                        <small> {moment(item?.deletedAt).format('MMMM Do YYYY, h:mm:ss a')} </small>
                                                                                                    </p>
                                                                                                </div>
                                                                                            )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>



                                                                        <div className="row g-3" >
                                                                            <div className="col-md-4" >
                                                                                <div className="ud" >
                                                                                    <span>Name </span>
                                                                                    <h5> <small> {item?.UPIHolderName} </small>  </h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6" >
                                                                                <div className="ud" >
                                                                                    <span>  UPI ID </span>
                                                                                    <h5> <small>{item?.vpaId}</small>  </h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                )
                                                            }) : <div className="nbf" >
                                                            <img src="assets/images/no_bank.svg" className="img-fluid" alt="" />
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>



                {/* <!-- Add account modal --> */}
                {isActiveAddBank && (
                    <div className="modal-open-custom" >
                        <div className="modal show d-block" >
                            <div className="modal-dialog modal-md">
                                <div className="modal-content">
                                    <div className="modal-header  px-8">
                                        <h5 className="modal-title" id="modal_add_bank_Label"> Add New Bank Account </h5>
                                        <button type="button" className="btn-close"
                                            onClick={() => { setIsActiveAddBank(false); ResetCount(); setShowImageModel("step1"); setImageStatus(false) }}>
                                        </button>
                                    </div>
                                    <div className="modal-body modal_form">
                                        {
                                            userDetails?.user?.kycVerified != 2 ?

                                                <div className=" text-center" >
                                                    <img src="/assets/images/user_kyc.svg" className="img-fluid  m-auto" />
                                                    <span className={`m-auto mt-3 badge ${userDetails?.user?.kycVerified === 1 ? "badge-warning bg-warning" : userDetails?.user?.kycVerified === 0 ? "badge-warning bg-warning" : "badge-danger"}`} >
                                                        <Link className="text-dark" to={userDetails?.user?.kycVerified == 2 ? "#" : '/verify_kyc'}>
                                                            {userDetails?.user?.kycVerified == 1 ? "Kyc is Under Review" : userDetails?.user?.kycVerified == 0 ? "SUBMIT KYC" : "Verification Failed!"}
                                                        </Link>
                                                    </span>
                                                </div>
                                                :
                                                <form>
                                                    {/* step 1 */}
                                                    <div className={`  ${showImageModel === "step1" ? "" : "d-none"}`}>

                                                        {/* Step 1 A  */}
                                                        <div className="row " >

                                                            <div className="col-12 col-lg-6 form-group">
                                                                <label className="text-white mb-1" htmlFor="accountType">Account Type</label>
                                                                <select name="accountType" className="form-select form-control form-control-sm" value={formData.accountType} onChange={handleChange} disabled={disableField}>
                                                                    <option value="Saving">Saving Account</option>
                                                                    <option value="Current">Current Account</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-12 col-lg-6 form-group">
                                                                <label className="text-white mb-1" htmlFor="accountHolderName">Account Holder Name</label>
                                                                <input type="text" name="accountHolderName" id="accountHolderName" placeholder="Enter Ac. Holder Name.." className="form-control form-control-sm" value={formData.accountHolderName} disabled />
                                                            </div>
                                                            <div className="col-12 col-lg-6 form-group">
                                                                <label className="text-white mb-1" htmlFor="accountNumber">Account Number</label>
                                                                <input type="number" name="accountNumber" id="accountNumber" placeholder="Enter Ac. Number.." className="form-control form-control-sm" value={formData.accountNumber} onChange={handleChange} disabled={disableField}
                                                                    onWheel={(e) => e.target.blur()}
                                                                />
                                                            </div>
                                                            <div className="col-12 col-lg-6 form-group">
                                                                <label className="text-white mb-1" htmlFor="ifscCode">IFSC Code</label>
                                                                <input type="text" name="ifscCode" id="ifscCode" className="form-control form-control-sm" placeholder="Enter IFSC Code.." value={formData.ifscCode} onChange={handleChange} disabled={disableField} />
                                                                {formData.ifscCode && !ifscValid && <div className="invalid-feedback d-block">Enter a Valid IFSC Code</div>}
                                                            </div>
                                                            <div className="col-12 col-lg-6 form-group">
                                                                <label className="text-white mb-1" htmlFor="bank_name">Bank Name</label>
                                                                <input type="text" name="bank_name" placeholder="Enter Bank Name.." id="bank_name" className="form-control form-control-sm" value={formData.bank_name} onChange={handleChange} disabled={disableField} />
                                                            </div>
                                                            <div className="col-12 col-lg-6 form-group">
                                                                <label className="text-white mb-1" htmlFor="branch_name">Branch Name</label>
                                                                <input type="text" name="branch_name" id="branch_name" placeholder="Enter Branch Name.." className="form-control form-control-sm" value={formData.branch_name} onChange={handleChange} disabled={disableField} />
                                                            </div>


                                                        </div>
                                                        {/* Step 1 B */}
                                                        <div className="row">
                                                            <div className="col-md-12 up_cs_card form-group">
                                                                <label className="text-white mb-2" >Upload Selfie with Cheque</label>


                                                                {/* <div className="up_ac" > */}
                                                                {/* <label className="form_label mb-0" >
                                                                            {!imageStatus ?
                                                                                <div className="up_card" >
                                                                                    <div className="up_card" >
                                                                                        <img src="/assets/images/camera.svg" alt="Captured" />
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="up_card" ><img src={image} alt="Captured" /> </div>
                                                                            }
                                                                        </label> */}
                                                                {!imageStatus ?
                                                                    <div className="wcr_card cursor-pointer " >
                                                                        <div className="opld_row" onClick={() => setShowImageModel("step2")} >
                                                                            <img src="assets/images/upload_icon.svg" width="60" className="img-fluid" alt="" />
                                                                            <p className="mb-0" > Capture Selfie with Cheque <span className="text-underline cursoir-pointer" >Click here</span> </p>
                                                                        </div>
                                                                        {/* <button className="btn btn-dark btn-dotted px-5" type="button" onClick={() => setShowImageModel("step2")}> Click Selfie </button> */}

                                                                    </div>
                                                                    :
                                                                    <div className="wcr_card wcr_captured" >
                                                                        <div className="opld_row ">
                                                                            <span className="d-flex align-items-start gap-3" >
                                                                                <img src={image} width="130" className="img-fluid" alt="" />
                                                                                {/* <p> Selfie Captured </p> */}
                                                                            </span>
                                                                            <button className="btn  btn-dark px-4 " type="button" onClick={() => { setImageStatus(false); setShowImageModel("step2") }} disabled={disableField}><i class="ri-reset-left-line me-2 ri-lg fw-normal"></i>  Retake </button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {/* </div> */}

                                                                {/* <div className="col-lg-6" >
                                                                            <div className="up_details" >
                                                                                <h6 className="mb-2" > <b>Instructions </b> </h6>
                                                                                <p>  Make sure your face and <b className="text-dark">cancel cheque</b> with <b className="text-dark">signature</b> photo visible properly. </p>
                                                                                <img src="/assets/images/cheque_suggation.svg" alt="" className="img-fluid" />
                                                                            </div>
                                                                        </div> */}



                                                            </div>
                                                            {
                                                                (formData?.bank_name && formData?.branch_name && formData?.accountNumber && ifscValid && imageStatus) && (
                                                                    <div className="col-lg-12 " ><hr /></div>
                                                                )
                                                            }
                                                            {
                                                                (formData?.bank_name && formData?.branch_name && formData?.accountNumber && ifscValid && imageStatus) && (

                                                                    <div className="col-12 form-group">


                                                                        <label className="text-white mb-1" htmlFor="accountNumber"> Enter OTP </label>
                                                                        <div className="field-otp-box">
                                                                            <input type="text" name="emailOtp" id="email_otp" className="form-control" placeholder="Email verification Code" required value={formData.emailOtp}
                                                                                onChange={(e) => {
                                                                                    if (/^\d{0,6}$/.test(e.target.value)) {
                                                                                        handleChange(e);
                                                                                    }
                                                                                }}
                                                                                maxLength={6}
                                                                                disabled={!showButton} />

                                                                            <button type="button" className="btn btn-sm btn-warning" onClick={() => { handleGetOtp(formData?.emailId, 'addbank') }} disabled={isOtpSent}>
                                                                                <span>{isOtpSent ? `Resend in ${countdown}s` : 'GET OTP'}</span>
                                                                            </button>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                imageStatus && showSubmitButton && showButton && formData?.emailOtp?.length == 6 && ifscValid && (
                                                                    <div className="col-12 mt-2">
                                                                        <button
                                                                            onClick={handleSubmit}
                                                                            type="button"
                                                                            className="btn btn-warning btn-block w-100"
                                                                        >
                                                                            {
                                                                                isLoading ? <SpinLoader /> : <span>SUBMIT</span>
                                                                            }
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={`row justify-content-center ${showImageModel === "step2" ? "" : "d-none"}`}>
                                                        <div className="col-lg-12 col-xl-12" >
                                                            {/* <div className="fl_title" > */}
                                                            {/* <label htmlFor="Liveness" className="form-label text-center m-auto w-100 mb-3">
                                                                    <h5> Click selfie with cancel cheque. </h5>
                                                                    <p> Make sure your face and cheque photo visible properly. </p>
                                                                </label> */}

                                                            {isCameraAllowed === false &&
                                                                <>
                                                                    <div class="field-box text-center">
                                                                        <img alt="Liveness Check" class="img-fluid box-shadow" src="/assets/images/receipt_mockup.svg" />
                                                                        <div class="tip_ul">
                                                                            <h6>Tips</h6>
                                                                            <ul>
                                                                                <li>
                                                                                    <i class="ri-checkbox-circle-fill text-success"></i> Find a well lit place
                                                                                </li>
                                                                                <li>
                                                                                    <i class="ri-checkbox-circle-fill text-success"></i>Make sure your face and the cheque are within the frame
                                                                                </li>
                                                                                <li>
                                                                                    <i class="ri-close-circle-fill text-danger"></i>Don't wear hats, glasses &amp; masks
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <p className="text-center" >Checking camera permission...</p>
                                                                </>

                                                            }
                                                            {isCameraAllowed === false && (
                                                                <button onClick={checkCameraPermission} type="button" className=" w-100 btn-block btn btn-dark mt-2"> Continue </button>
                                                            )}
                                                            {/* </div> */}
                                                            {isCameraAllowed && !imageStatus ? (
                                                                <div className="wcr_card" >
                                                                    <div className="web_cam_row wcr_solo  "  >
                                                                        <div className="web_cam_box" >
                                                                            <Webcam
                                                                                audio={false}
                                                                                ref={webcamRef}
                                                                                screenshotFormat="image/jpeg"
                                                                            />
                                                                        </div>
                                                                        <button onClick={capture} type="button" className="btn btn-dark fw-semibold px-5 no-border">
                                                                            <i class="ri-camera-fill fw-normal me-2 ri-xl"></i>  Capture
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                imageStatus &&
                                                                <>
                                                                    <div className="wcr_card wcr_captured" >
                                                                        <div className="web_cam_row wcr_solo  " >
                                                                            <div className="web_cam_box" >
                                                                                <img src={image} alt="Captured" />
                                                                            </div>
                                                                            <div className="w-100 row align-items-center gx-3" >
                                                                                <div className="col-6">
                                                                                    <button onClick={() => { setImageStatus(false) }} type="button" className="btn btn-secondary btn-block w-100">
                                                                                        Re-take
                                                                                    </button>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <button onClick={handleSubmitDetails} type="button" className="btn  btn-warning btn-block w-100" disabled={isLoading}>
                                                                                        {
                                                                                            isLoading ? <SpinLoader /> : <span>Continue</span>
                                                                                        }
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                )}


                {isActiveAddUpi && (
                    <div className="modal-open-custom">
                        <div className="modal show d-block">
                            <div className="modal-dialog modal-md">
                                <div className="modal-content">
                                    <div className="modal-header flex-column px-8">
                                        <button type="button" className="btn-close" onClick={() => { setIsActiveAddUpi(false); ResetCount() }}></button>
                                        <h5 className="modal-title" id="modal_add_upi_Label">
                                            Add New UPI Id
                                        </h5>
                                    </div>
                                    <div className="modal-body modal_form">
                                        {
                                            userDetails?.user?.kycVerified != 2 ?

                                                <div className=" text-center" >
                                                    <img src="/assets/images/user_kyc.svg" className="img-fluid  m-auto" />
                                                    <span className={`m-auto mt-3 badge ${userDetails?.user?.kycVerified === 1 ? "badge-warning bg-warning" : userDetails?.user?.kycVerified === 0 ? "badge-warning bg-warning" : "badge-danger"}`} >
                                                        <Link className="text-dark" to={userDetails?.user?.kycVerified == 2 ? "#" : '/verify_kyc'}>
                                                            {userDetails?.user?.kycVerified == 1 ? "Kyc is Under Review" : userDetails?.user?.kycVerified == 0 ? "SUBMIT KYC" : "Verification Failed!"}
                                                        </Link>
                                                    </span>
                                                </div>
                                                :
                                                <form>
                                                    <div className="row">
                                                        <div className="col-12 form-group ">
                                                            <label className="text-white mb-1" htmlFor="upiHolder"> Name  </label>
                                                            <input type="text" name="upiHolder" id="upiHolder" className="form-control" value={formData.accountHolderName} disabled />
                                                        </div>
                                                        <div className="col-12 form-group">
                                                            <label className="text-white mb-1" htmlFor="upiId"> UPI VPA Id    </label>
                                                            <input type="text" name="upiId" id="upiId" className="form-control" value={formData.upiId} onChange={handleChange} disabled={disableField} />
                                                        </div>


                                                        {
                                                            formData.upiId &&
                                                            <div className="col-12 form-group">
                                                                <label className="text-white mb-1" htmlFor="email_otp">Email OTP</label>
                                                                <div className="field-otp-box">
                                                                    <input type="number" name="emailOtp" id="email_otp" className="form-control" placeholder="Email verification Code" required value={formData.emailOtp}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onChange={(e) => {
                                                                            if (/^\d{0,6}$/.test(e.target.value)) {
                                                                                handleChange(e);
                                                                            }
                                                                        }}
                                                                        maxLength={6}
                                                                        disabled={!otpRequested}
                                                                    />

                                                                    <button type="button" className="btn btn-sm btn-warning" onClick={() => {
                                                                        handleGetOtp(formData?.emailId, 'addbank')
                                                                    }} disabled={isOtpSent}>
                                                                        <span>{isOtpSent ? `Resend in ${countdown}s` : 'GET OTP'}</span>
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        }


                                                    </div>
                                                    <div className=" text-center ">
                                                        <button type="button" className="btn btn-dark w-50 mt-3" onClick={handleSubmitAddUpi}>
                                                            {loading ? <SpinLoader />
                                                                : <span> Verify UPI</span>}


                                                        </button>
                                                    </div>
                                                </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                )}
            </div>
        </main>

    )
}

export default PaymentModules