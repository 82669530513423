import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { alertErrorMessage, alertSuccessMessage } from "../../../Services/CustomAlertModel";
import AuthService from "../../../Services/ApiServices/AuthService";
import LoaderHelper from "../../../Services/Loading/LoaderHelper";
import TVChartContainer from "../../../Services/Libraries/TVChartContainer";
import './trade.css'
import { ProfileContext } from "../../../Services/ProfileProvider";
import { fetchCoinDetails } from "../../../Context/Utility/apiutils";
import { SocketContext } from "../../../Context/SocketContext";

const ExchangePage = () => {

    const params = useParams()
    let pathName = params?.pairName?.split('_');
    const navigate = useNavigate();
    const Authtoken = localStorage.getItem("token");
    const userId = localStorage.getItem('user_id');
    const { userDetails } = useContext(ProfileContext);
    const KycStatus = userDetails?.user?.kycVerified;

    const [search, setsearch] = useState('');
    const [CoinPairDetails, setCoinPairDetails] = useState();
    const [SelectedCoin, setSelectedCoin] = useState(null);
    const [baseCurId, setbaseCurId] = useState(null);
    const [quoteCurId, setquoteCurId] = useState(null);
    const [buyQuantity, setBuyQuantity] = useState(1);
    const [sellQuantity, setSellQuantity] = useState(1);
    const [pastOrders, setpastOrders] = useState([]);
    const [favCoins, setfavCoins] = useState([]);
    const [sellOrderPrice, setSellOrderPrice] = useState(0);
    const [buyOrderPrice, setbuyOrderPrice] = useState(0);
    const [coinDetails, setCoinDetails] = useState('');
    const [coinFilter, setcoinFilter] = useState('All');
    const [orderType, setorderType] = useState('All');
    const [infoPlaceOrder, setinfoPlaceOrder] = useState('LIMIT');
    const [socketData, setSocketData] = useState([]);
    const [activeTab, setActiveTab] = useState('chart');
    const [selectedPercentageBuy, setSelectedPercentageBuy] = useState(null);
    const [selectedPercentageSell, setSelectedPercentageSell] = useState(null);
    const [selectedCoinDetails, setSelectedCoinDetails] = useState(null);
    const [showCoinList, setShowCoinList] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loader, setloader] = useState(true);
    const { socket } = useContext(SocketContext);

    const [isBuyPriceModified, setIsBuyPriceModified] = useState(false);
    const [isSellPriceModified, setIsSellPriceModified] = useState(false);

    useEffect(() => {
        if (socket) {
            const payload = { message: 'exchange' };
            socket.emit('message', payload);

            const handleMessage = (data) => setSocketData(data);
            socket.on('message', handleMessage);

            return () => {
                socket.off('message', handleMessage);
            };
        }
    }, [socket]);

    useEffect(() => {
        let interval;
        if (baseCurId && quoteCurId && socket) {
            interval = setInterval(() => {
                let payload = {
                    'message': 'exchange',
                    'userId': userId,
                    'base_currency_id': baseCurId,
                    'quote_currency_id': quoteCurId,
                    name: "socket",
                }
                socket.emit('message', payload);
            }, 1000)
        }
        return (() => {
            clearInterval(interval)
        })
    }, [baseCurId, quoteCurId, socket]);

    useEffect(() => {
        if (!SelectedCoin && CoinPairDetails) {
            let Pair = CoinPairDetails[0];
            if (pathName?.length > 0) {
                const filteredData = CoinPairDetails.filter(item =>
                    pathName[0]?.includes(item?.base_currency) &&
                    pathName[1]?.includes(item?.quote_currency)
                );
                if (filteredData.length > 0) {
                    Pair = filteredData[0];
                }
            };
            navigate(`/exchange/${Pair?.base_currency}_${Pair?.quote_currency}`, { replace: true });
            setloader(false);
            setSellOrderPrice(Pair?.sell_price);
            setbuyOrderPrice(Pair?.buy_price);
            setSelectedCoin(Pair);
            setbaseCurId(Pair?.base_currency_id);
            setquoteCurId(Pair?.quote_currency_id);
            fetchCoinDetails(setCoinDetails, Pair?.base_currency_id);
            const payload = {
                message: 'exchange',
                userId: userId,
                base_currency_id: Pair?.base_currency_id,
                quote_currency_id: Pair?.quote_currency_id,
            };
            socket.emit('message', payload);
        }
    }, [CoinPairDetails]);

    useEffect(() => {
        if (socketData && SelectedCoin) {
            const filteredData = socketData?.pairs?.filter(
                (item) =>
                    item?.base_currency_id === SelectedCoin?.base_currency_id &&
                    item?.quote_currency_id === SelectedCoin?.quote_currency_id
            );
            if (filteredData?.length > 0) {
                const coinDetails = filteredData[0];
                if (!isSellPriceModified) {
                    setSellOrderPrice(coinDetails?.sell_price);
                }
                if (!isBuyPriceModified) {
                    setbuyOrderPrice(coinDetails?.buy_price);
                }
                setSelectedCoinDetails(coinDetails);
            }
        }
    }, [socketData, SelectedCoin, isSellPriceModified, isBuyPriceModified]);


    useEffect(() => {
        let filteredData = socketData?.pairs?.filter((item) => {
            const matchesSearch = item?.base_currency?.toLowerCase().includes(search?.toLowerCase()) || item?.quote_currency?.toLowerCase().includes(search?.toLowerCase());
            const isNotINR = item?.quote_currency?.toLowerCase() !== "inr";
            const isNotINRFirst = item?.base_currency?.toLowerCase() !== "inr";
            return matchesSearch && isNotINR && isNotINRFirst;
        });
        setCoinPairDetails(filteredData);
    }, [search, socketData]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        Authtoken && favoriteList();
        if (SelectedCoin?.base_currency_id) {
            fetchCoinDetails(setCoinDetails, SelectedCoin?.base_currency_id);
        }
    }, []);

    const handleSelectCoin = (data) => {
        navigate(`/exchange/${data?.base_currency}_${data?.quote_currency}`);
        setloader(false);
        setSellOrderPrice(data?.sell_price);
        setbuyOrderPrice(data?.buy_price);
        setSelectedCoin(data);
        setbaseCurId(data?.base_currency_id);
        setquoteCurId(data?.quote_currency_id);
        fetchCoinDetails(setCoinDetails, data?.base_currency_id);
        setShowCoinList(!showCoinList);
        setBuyQuantity(1)
        setSellQuantity(1)
        setIsBuyPriceModified(false);
        setIsSellPriceModified(false);
        let payload = {
            'message': 'exchange',
            'userId': userId,
            'base_currency_id': data?.base_currency_id,
            'quote_currency_id': data?.quote_currency_id,
        }
        socket.emit('message', payload);
    };

    const handleOrderType = (e) => {
        setinfoPlaceOrder(e.target.value);
        if (e.target.value === 'MARKET') {
            setSellOrderPrice(SelectedCoin?.sell_price);
            setbuyOrderPrice(SelectedCoin?.buy_price);
        }
    };

    const calculateBuyQuantity = (percentage) => {
        setSelectedPercentageBuy(percentage);
        if (buyOrderPrice) {
            setBuyQuantity(((socketData?.balance?.quote_currency_balance / 100) * percentage) / buyOrderPrice);
        }
    };

    const calculateSellQuantity = (percentage) => {
        setSelectedPercentageSell(percentage);
        setSellQuantity((socketData?.balance?.base_currency_balance / 100) * percentage);
    };


    const handleOrderPlace = async (infoPlaceOrder, price, quantity, base_currency_id, quote_currency_id, side) => {
        if (!userDetails?.permissions?.spot) {
            alertErrorMessage('Disable Buy Sell.');
            return;
        }
        setIsLoading(true);
        await AuthService.placeOrder(infoPlaceOrder, price, quantity, base_currency_id, quote_currency_id, side).then((result) => {
            if (result?.success) {
                setIsLoading(false);
                try {
                    alertSuccessMessage('Order Placed Successfully!!')
                    let payload = {
                        'message': 'exchange',
                        'userId': userId,
                        'base_currency_id': SelectedCoin?.base_currency_id,
                        'quote_currency_id': SelectedCoin?.quote_currency_id,
                    };
                    socket.emit('message', payload);
                    setSelectedPercentageBuy(null);
                    setSelectedPercentageSell(null);
                    setbuyOrderPrice(selectedCoinDetails?.buy_price)
                    setSellOrderPrice(selectedCoinDetails?.buy_price)
                    setBuyQuantity(1);
                    setSellQuantity(1);
                    setIsBuyPriceModified(false);
                    setIsSellPriceModified(false);
                } catch (error) {
                    setIsLoading(false);
                    alertErrorMessage(error?.message)
                }
            } else {
                setIsLoading(false);
                alertErrorMessage(result)
            }
        })
    };

    const handlePastOrder = async (base_currency_id, quote_currency_id) => {
        LoaderHelper.loaderStatus(false);
        await AuthService.pastOrder(base_currency_id, quote_currency_id).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setpastOrders(result?.data?.reverse())
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        })
    };

    const cancelOrder = async (orderId) => {
        await AuthService.cancelOrder(orderId).then((result) => {
            if (result?.success) {
                alertSuccessMessage('Order Cancelled Successfully');
            } else {
                alertErrorMessage(result?.message)
            }
        })
    };


    const handleAddFav = async (pairId) => {
        await AuthService.updateFavoriteCoin(pairId).then((result) => {
            if (result?.success) {
                favoriteList()
            } else {
                alertErrorMessage(result?.message)
            }
        })
    };


    const favoriteList = async () => {
        await AuthService.favoriteList().then((result) => {
            if (result?.success) {
                setfavCoins(result?.data?.pairs ? result?.data?.pairs : ['']);
            }
        });
    };

    const formatTotal = (value) => {
        const finalValue = value?.toFixed(8)?.replace(/\.?0+$/, '');
        let formattedNum = finalValue?.toString();
        let result = formattedNum?.replace(/^0\.0*/, '');
        const decimalPart = finalValue?.toString()?.split('.')[1];
        if (!decimalPart) return finalValue;
        let zeroCount = 0;
        for (let char of decimalPart) {
            if (char === '0') {
                zeroCount++;
            } else {
                break;
            }
        }
        if (zeroCount > 4) {
            return `0.0{${zeroCount}}${result}`;
        }
        if (value < 1e-7) {
            return `0.0{${zeroCount}}${result}`;
        } else {
            return finalValue;
        }
    };

    const handleCancelAllOrder = async () => {
        await AuthService.cancelAllOrder().then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage('Order Cancelled Successfully');
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message)
            }
        })
    };


    const handleBuyPriceChange = (e) => {
        const inputValue = e.target.value;
        setIsBuyPriceModified(true);
        if (inputValue.startsWith('.')) {
            setbuyOrderPrice('0' + inputValue);
            return;
        }
        if (/^\d{0,8}(\.\d{0,8})?$/.test(inputValue) || inputValue === '') {
            setbuyOrderPrice(inputValue);
        }
    };

    const handleSellPriceChange = (e) => {
        const inputValue = e.target.value;
        setIsSellPriceModified(true);
        if (inputValue.startsWith('.')) {
            setSellOrderPrice('0' + inputValue);
            return;
        }
        if (/^\d{0,8}(\.\d{0,8})?$/.test(inputValue) || inputValue === '') {
            setSellOrderPrice(inputValue);
        }
    };

    return (
        <div className="trade-wrapper spot pb-3">
            <div className=" container-fluid">
                <div className="row g-1 g-md-2">
                    <div className="col-12 col-lg-12 col-xl-7  col-xxl-7 ">
                        <div className={`spotLists ${showCoinList === true ? 'active' : ""}`}>
                            <div className=" trade_tabs buy_sell_cards">
                                <ul className="nav custom-tabs nav_order">
                                    {Authtoken &&
                                        <li className="favt-tab">
                                            <a data-bs-toggle="tab" href="#tab_fav" onClick={() => { setcoinFilter('FAV') }}>FAV.</a>
                                        </li>}
                                    <li className="all-tab">
                                        <a className="active" data-bs-toggle="tab" href="#tab_all" onClick={() => { setcoinFilter('All') }}> All </a>
                                    </li>
                                    <li className="usdt-tab">
                                        <a data-bs-toggle="tab" href="#tab_all" onClick={() => { setcoinFilter('USDT') }}>USDT</a>
                                    </li>
                                    <li className="btc-tab">
                                        <a data-bs-toggle="tab" href="#tab_all" onClick={() => { setcoinFilter('BTC') }}>BTC</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="spot-list-search">
                                <div className="ivu-input">
                                    <i className="ri-search-2-line"></i>
                                    <input autoComplete="off" spellCheck="false" type="search" placeholder="Search"
                                        onChange={(e) => setsearch(e.target.value)} value={search} />
                                </div>
                            </div>

                            <div className="price_card">
                                <div className="price_card_head">
                                    <div>Pair</div>
                                    <div>Price</div>
                                    <div>24H%</div>
                                </div>

                                <div className="price_card_body tab-content scroll_y" style={{ cursor: "pointer" }}>
                                    <div className="tab-pane px-0" id="tab_fav">
                                        {CoinPairDetails ? CoinPairDetails?.map((data, index) => {
                                            return (
                                                favCoins.includes(data?._id) && <div className="price_item_value active" key={index}>
                                                    <span className="d-flex align-items-center">
                                                        <i className={favCoins.includes(data?._id) ? "ri ri-star-fill text-warning me-1 ri-xl" : "ri ri-star-line me-1 ri-xl"} onClick={() => { handleAddFav(data?._id) }}>
                                                        </i> <dt onClick={() => handleSelectCoin(data)}>{`${data?.base_currency}/${data?.quote_currency}`}</dt></span><span onClick={() => handleSelectCoin(data)}>{data?.buy_price}</span><span className={data?.change_percentage >= 0 ? "text-success" : "text-danger"} onClick={() => handleSelectCoin(data)}>{parseFloat(data?.change_percentage?.toFixed(2))}%</span></div>
                                            )
                                        }) : null}
                                    </div>
                                    <div className="tab-pane px-0 active" id="tab_all">
                                        {CoinPairDetails ? CoinPairDetails?.map((data, index) => {
                                            return (
                                                (data?.quote_currency === coinFilter || coinFilter === 'All') &&
                                                <div className={`price_item_value ${SelectedCoin?.base_currency === data?.base_currency && SelectedCoin?.quote_currency === data?.quote_currency ? 'active' : ''}`} key={index}>
                                                    <span className="d-flex align-items-center ">
                                                        {Authtoken && <i className={favCoins.includes(data?._id) ? "ri ri-star-fill text-warning me-1 ri-xl" : "ri ri-star-line me-1 ri-xl"} onClick={() => { handleAddFav(data?._id) }}>
                                                        </i>} <dt onClick={() => handleSelectCoin(data)}>{`${data?.base_currency}/${data?.quote_currency}`}</dt> </span><span onClick={() => handleSelectCoin(data)}>{data?.buy_price}</span><span className={data?.change_percentage >= 0 ? "text-success" : "text-danger"} onClick={() => handleSelectCoin(data)}>{parseFloat(data?.change_percentage?.toFixed(2))}%</span></div>
                                            )
                                        }) : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="trade_card p-2  overflow_card mb-1">
                            <div className="headline_left__lBBPY">
                                <div className="headline_left__lBBPY_leftmain d-flex align-items-center">
                                    <div className="headline_symbolName__KfmIZ mt_tr_pr">
                                        <div className="headline_bigName__dspVW " onClick={() => setShowCoinList(!showCoinList)}>
                                            <i className="faaa ri-menu-add-line"></i>
                                        </div>
                                        <div>
                                            <div className="headline_bigName__dspVW ">
                                                <h1>{SelectedCoin ? `${SelectedCoin.base_currency}/${SelectedCoin.quote_currency}` : '---/---'}</h1>
                                            </div>
                                            <div className="headline_etfDisplay__P4Hdv">
                                                <span>{SelectedCoin?.base_currency_full_name || '----'}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="headline_leftItem__7BFYq headline_latestPrice__AYXu0">
                                        <div>
                                            <span className="headline_title__x1csO">{isNaN(selectedCoinDetails?.buy_price) ? '0' : selectedCoinDetails?.buy_price?.toFixed(3)}</span>
                                            <span className={`headline_change__tGyxo ms-2 ${selectedCoinDetails?.change_percentage >= 0 ? "text-success" : "text-danger"}`}>
                                                {isNaN(selectedCoinDetails?.change_percentage) ? '0' : selectedCoinDetails?.change_percentage?.toFixed(2)}%
                                            </span>
                                        </div>
                                        <div className="headline_etfDisplay__P4Hdv">
                                            <span>{isNaN(selectedCoinDetails?.buy_price) ? '$ 0' : `$ ${selectedCoinDetails?.buy_price?.toFixed(3)}`}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="scroll-subtabs_scrollSubInfo__T5nZF headline_left__lBBPY_rightmain">
                                    <div className="scroll-subtabs_tabs__Prom8">
                                        <div className="scroll-subtabs_subMarketWrap__XVmHp">
                                            <div className="headline_extendInfoWrapper__dooIS">
                                                <div className="headline_leftItem__7BFYq">
                                                    <div className="headline_withBorder__a6ZD2">24h High</div>
                                                    <div className={`headline_title__x1csO ${selectedCoinDetails?.high >= 0 ? "text-success" : "text-danger"}`}>
                                                        {selectedCoinDetails?.high >= 0 ? <i className="ri-arrow-up-s-fill"></i> : <i className="ri-arrow-down-s-fill"></i>}
                                                        {isNaN(selectedCoinDetails?.high) ? "0" : parseFloat(selectedCoinDetails?.high?.toFixed(2))}
                                                    </div>
                                                </div>
                                                <div className="headline_leftItem__7BFYq">
                                                    <div className="headline_withBorder__a6ZD2">24h Low</div>
                                                    <div className="headline_title__x1csO text-danger">
                                                        {isNaN(selectedCoinDetails?.low) ? "0" : parseFloat(selectedCoinDetails?.low?.toFixed(2))}
                                                    </div>
                                                </div>
                                                <div className="headline_leftItem__7BFYq">
                                                    <div className="headline_withBorder__a6ZD2">24h Volume ({SelectedCoin?.base_currency})</div>
                                                    <div className="headline_title__x1csO">
                                                        {isNaN(selectedCoinDetails?.volume) ? "0" : parseFloat(selectedCoinDetails?.volume?.toFixed(2))}
                                                    </div>
                                                </div>
                                                <div className="headline_leftItem__7BFYq">
                                                    <div className="headline_withBorder__a6ZD2">24h Amount ({SelectedCoin?.quote_currency})</div>
                                                    <div className="headline_title__x1csO">
                                                        {isNaN(SelectedCoin?.volume * selectedCoinDetails?.buy_price) ? "0" : parseFloat((SelectedCoin?.volume * selectedCoinDetails?.buy_price)?.toFixed(2))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="trade_card trade_chart  p-0">
                            <div className="treade_card_header">
                                <div className={`card_header_title ${activeTab === "chart" ? 'active' : ""}`} onClick={() => setActiveTab('chart')}>Chart  </div>
                                <div className={`card_header_title ${activeTab === "token_ifo" ? 'active' : ""}`} onClick={() => setActiveTab('token_ifo')}>  Info</div>
                            </div>
                            <div className="cstm_tabs">
                                <div className={`sctm_row ${activeTab === "chart" ? '' : "d-none"}`}>
                                    {SelectedCoin?.base_currency === undefined ? <div style={{ width: '100%', height: '465px' }}>
                                        <div className="loading-wave" style={{ width: '100%', height: '100%', alignItems: 'center' }}>
                                            <div className="loading-bar"></div>
                                            <div className="loading-bar"></div>
                                            <div className="loading-bar"></div>
                                            <div className="loading-bar"></div>
                                        </div>
                                    </div> :
                                        <TVChartContainer symbol={`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`} />
                                    }
                                </div>
                                <div className={`sctm_row ${activeTab === "token_ifo" ? '' : "d-none"}`}>
                                    <div className="exchange_chartContent__Ijukg ">
                                        <div className="information_visible__9A4Mp information_wrapper__AR6LL">
                                            <div className="information_title__TYI4l">
                                                <div className="information_icon__Lztnq">
                                                    <img src={SelectedCoin?.icon_path} onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} className="img-fluid" height="22" width="22" />
                                                </div>
                                                <div className="headline_etfDisplay__P4Hdv">
                                                    <span>{SelectedCoin?.base_currency_full_name || '----'}</span>
                                                </div>
                                                <div className="information_name__ygI29 information_bright__F_RTd">
                                                    {SelectedCoin?.base_currency}/{SelectedCoin?.quote_currency || '-------'}
                                                </div>
                                            </div>
                                            <div className="information_intro__ffLAj scroll-container scroll_y">
                                                <div className="information_bright__F_RTd">
                                                    {coinDetails?.description}
                                                </div>
                                            </div>
                                            <div className="information_title__TYI4l">
                                                <span className="information_bright__F_RTd information_name__ygI29">Overview</span></div>
                                            <div className="information_other__OQlyX">
                                                <div className="information_left__vgFSY">
                                                    <div className="information_infoItem__3_gv6">
                                                        <div>FullName</div>
                                                        <div className="information_bright__F_RTd">
                                                            {SelectedCoin?.base_currency_full_name}
                                                        </div>
                                                    </div>
                                                    <div className="information_infoItem__3_gv6">
                                                        <div>Issue Date</div>
                                                        <div className="information_bright__F_RTd">
                                                            {moment(coinDetails.createdAt).format("lll")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="information_right__kqyPI">
                                                    <div className="information_infoItem__3_gv6">
                                                        <div>Issue Price</div>
                                                        <div className="information_bright__F_RTd">
                                                            {selectedCoinDetails?.volume?.toFixed(2)}
                                                        </div>
                                                    </div>
                                                    <div className="information_infoItem__3_gv6">
                                                        <div>Total Supply</div>
                                                        <div className="information_bright__F_RTd">{coinDetails?.total_supply}</div>
                                                    </div>
                                                    <div className="information_infoItem__3_gv6">
                                                        <div>Circulating Supply</div>
                                                        <div className="information_bright__F_RTd">{coinDetails?.circulating_supply}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-12 col-xl-5 col-xxl-5" >
                        <div className="row g-1 g-md-2 px-1 px-md-0">
                            <div className="col-6">
                                <div className="trade_card">
                                    <div className="treade_card_header justify-content-between">
                                        <div className="card_header_title active">Order Book</div>
                                        <div className=" trade_tabs buy_sell_cards  buy_sell_row d-flex-between">
                                            <div className='d-flex'>
                                                <ul className="nav custom-tabs nav_order">
                                                    <li className="fav-tab">
                                                        <a className="active" data-bs-toggle="tab" href="#all_orders">
                                                            <img alt='' src="/assets/images/order_1.svg" width="22" height="11" />
                                                        </a>
                                                    </li>
                                                    <li className="usdt-tab"><a data-bs-toggle="tab" href="#buy_orders">
                                                        <img alt='' src="/assets/images/order_2.svg" width="22" height="11" />
                                                    </a>
                                                    </li>
                                                    <li className="btc-tab">
                                                        <a data-bs-toggle="tab" href="#sell_orders" className="me-0">
                                                            <img alt='' src="/assets/images/order_3.svg" width="22" height="11" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-content buy_sell_row_price">
                                        <div className="tab-pane fade px-0  active show" id="all_orders">
                                            <div className="price_card">
                                                <div className="price_card_head">
                                                    <div>Price({SelectedCoin?.quote_currency})</div>
                                                    <div>Quantity({SelectedCoin?.base_currency})</div>
                                                    <div>Total({SelectedCoin?.quote_currency})</div>
                                                </div>
                                                <div className="price_card_body scroll_y scroll_y_reverse" style={{ cursor: "pointer" }}>

                                                    {(socketData?.sell_order?.length > 0 && !loader) ? (
                                                        socketData.sell_order.slice(-7).reverse().map((data, index) => (
                                                            <div className="price_item_value" key={index}
                                                                onClick={() => {
                                                                    setBuyQuantity(data?.remaining?.toFixed(5));
                                                                    if (infoPlaceOrder !== 'MARKET') {
                                                                        setbuyOrderPrice(data?.price);
                                                                    }
                                                                }}
                                                            >
                                                                <span className="d-flex align-items-center text-danger">
                                                                    {(data?.price)?.toFixed(4)}
                                                                </span>
                                                                <span>{(data?.remaining)?.toFixed(5)}</span>
                                                                <span className="text-danger">
                                                                    {(data?.price * data?.remaining)?.toFixed(5)}
                                                                </span>
                                                            </div>
                                                        ))
                                                    ) : loader ? (
                                                        <div className="text-center no-data h-100 mb-0">
                                                            <div className="loading-wave">
                                                                <div className="loading-bar"></div>
                                                                <div className="loading-bar"></div>
                                                                <div className="loading-bar"></div>
                                                                <div className="loading-bar"></div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        null
                                                    )}
                                                </div>
                                                <div className="mrkt_trde_tab justify-content-center">
                                                    <b className={selectedCoinDetails?.change_percentage >= 0 ? "text-success" : "text-danger"}>
                                                        {isNaN(parseFloat(selectedCoinDetails?.buy_price)) ? "0" : parseFloat(selectedCoinDetails?.buy_price)?.toFixed(3)}
                                                    </b>
                                                    <i className={selectedCoinDetails?.change_percentage >= 0 ? 'ri-arrow-up-line ri-xl mx-3 text-success' : 'ri-arrow-down-line ri-xl mx-3 text-danger'}></i>
                                                    <span className={selectedCoinDetails?.change_percentage >= 0 ? "text-success" : "text-danger"}>
                                                        {isNaN(parseFloat(selectedCoinDetails?.change_percentage)) ? "0" : parseFloat(selectedCoinDetails?.change_percentage)?.toFixed(2)}%
                                                    </span>
                                                </div>

                                                <div className="price_card_body scroll_y" style={{ cursor: "pointer" }}>
                                                    {(socketData?.buy_order?.length > 0 && !loader) ?
                                                        socketData?.buy_order?.slice(0, 7)?.map((data, index) => {
                                                            return (
                                                                <div className="price_item_value" key={index} onClick={() => { setSellQuantity(data?.remaining?.toFixed(5)); infoPlaceOrder !== 'MARKET' && setSellOrderPrice(data?.price) }}>
                                                                    <span className={"text-success d-flex lign-items-center"}>{(data?.price)?.toFixed(4)}</span>
                                                                    <span >{(data?.remaining)?.toFixed(5)}</span>
                                                                    <span className="text-success">{(data?.price * data?.remaining)?.toFixed(5)}</span>
                                                                </div>
                                                            )
                                                        }) : loader ? <div className="text-center no-data h-100 mb-0" >
                                                            <div className="loading-wave">
                                                                <div className="loading-bar"></div>
                                                                <div className="loading-bar"></div>
                                                                <div className="loading-bar"></div>
                                                                <div className="loading-bar"></div>
                                                            </div>
                                                        </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade px-0" id="buy_orders">
                                            <div className="price_card">
                                                <div className="price_card_head">
                                                    <div>Price({SelectedCoin?.quote_currency})</div>
                                                    <div>Quantity({SelectedCoin?.base_currency})</div>
                                                    <div>Total({SelectedCoin?.quote_currency})</div>
                                                </div>
                                                <div className="price_card_body scroll_y_full" style={{ cursor: "pointer" }}>
                                                    {socketData?.buy_order?.length > 0 ?
                                                        socketData?.buy_order?.map((data, index) => {
                                                            return (
                                                                <div className="price_item_value" key={index} onClick={() => { setSellQuantity(data?.remaining); infoPlaceOrder !== 'MARKET' && setSellOrderPrice(data?.price) }}>
                                                                    <span className={"text-success d-flex lign-items-center"}>{(data?.price)?.toFixed(4)}</span>
                                                                    <span >{(data?.remaining)?.toFixed(2)}</span>
                                                                    <span className="text-success">{(data?.price * data?.remaining)?.toFixed(2)}</span>
                                                                </div>
                                                            )
                                                        }) :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade px-0" id="sell_orders">
                                            <div className="price_card">
                                                <div className="price_card_head">
                                                    <div>Price({SelectedCoin?.quote_currency})</div>
                                                    <div>Quantity({SelectedCoin?.base_currency})</div>
                                                    <div>Total({SelectedCoin?.quote_currency})
                                                    </div>
                                                </div>
                                                <div className="price_card_body scroll_y scroll_y_reverse scroll_y_full" style={{ cursor: "pointer" }}>
                                                    {socketData?.sell_order?.length > 0 ?
                                                        socketData?.sell_order?.slice()?.reverse()?.map((data, index) => {
                                                            return (
                                                                <div className="price_item_value" key={index} onClick={() => { setBuyQuantity(data?.remaining); infoPlaceOrder !== 'MARKET' && setbuyOrderPrice(data?.price) }}>
                                                                    <span className="d-flex align-items-center text-danger "> {(data?.price)?.toFixed(4)}</span>
                                                                    <span > {(data?.remaining)?.toFixed(2)}</span>
                                                                    <span className="text-danger"> {(data?.price * data?.remaining)?.toFixed(2)}</span>
                                                                </div>
                                                            )
                                                        }) :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="trade_card trade_chart buysell_card ">
                                    <div className="treade_card_header buysell_heder ">
                                        <ul className="nav custom-tabs" role="tablist">
                                            <li className="buysell-tab buy-tab"><a className="active" data-bs-toggle="tab" href="#buytab" aria-selected="true" role="tab"> Buy </a></li>
                                            <li className="  sell-tab"><a data-bs-toggle="tab" href="#selltab" aria-selected="false" tabIndex="-1" role="tab"> Sell </a></li>
                                        </ul>
                                    </div>

                                    <div className="p-2 p-md-3">
                                        <div className="spot_limit col-md-12 mb-3">
                                            <select className="input-select" name="infoPlaceOrder" onChange={handleOrderType} value={infoPlaceOrder}>
                                                <option value="LIMIT">Limit</option>
                                                <option value="MARKET">Market</option>
                                            </select>
                                        </div>

                                        <div className="tab-content">
                                            <div className="tab-pane px-0 active show" id="buytab" role="tabpanel">
                                                <form action="" className="buysellform data-buy">
                                                    {Authtoken &&
                                                        <div className="actions_balance__kTHO0">
                                                            <span className="actions_primaryText__ufKT0"> Available Balance: </span>
                                                            <span>
                                                                {isNaN(parseFloat(socketData?.balance?.quote_currency_balance)) ? "0" : parseFloat(socketData?.balance?.quote_currency_balance)?.toFixed(2)}
                                                            </span>

                                                            <span className="text-muted ms-1">{SelectedCoin?.quote_currency}</span>

                                                            <Link className="actions_deposit__Ydutk" to='/wallet'>
                                                                <i className="ri-add-circle-fill"></i>
                                                            </Link>
                                                        </div>
                                                    }

                                                    {
                                                        infoPlaceOrder === 'LIMIT' &&
                                                        <div className="form-group  mb-3">
                                                            <label>Price</label>
                                                            <div className="input-group mb-3">
                                                                <input type="text" id="buyOrderPrice" name="buyOrderPrice" className="form-control" value={buyOrderPrice} onChange={handleBuyPriceChange}
                                                                    disabled={infoPlaceOrder === 'MARKET'}
                                                                />
                                                                <span className="input-group-text text-start"><small>  {SelectedCoin?.quote_currency}</small></span>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="form-group  mb-3"><label>Quantity</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" value={buyQuantity}
                                                                onChange={(e) => {
                                                                    if (e.target.value.startsWith('.')) {
                                                                        e.target.value = '0' + e.target.value;
                                                                        return e.target.value;
                                                                    }
                                                                    if (/^\d{0,8}(\.\d{0,8})?$/.test(e.target.value) || e.target.value === '') {
                                                                        setBuyQuantity(e.target.value)
                                                                    }
                                                                }}
                                                            />
                                                            <span className="input-group-text text-start"><small> {SelectedCoin?.base_currency}</small></span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Total</label>
                                                        {
                                                            infoPlaceOrder === 'LIMIT' &&
                                                            <div className="input-group mb-3">
                                                                <input
                                                                    type="text"
                                                                    id="buy_order_total"
                                                                    name="buy_order_total"
                                                                    className="form-control"
                                                                    value={formatTotal(buyOrderPrice * buyQuantity)}
                                                                    readOnly
                                                                />
                                                                <span className="input-group-text text-start"><small>{SelectedCoin?.quote_currency}</small></span>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="form-group  mb-3" >
                                                        <div className="btn-group btn-group-mini" role="group" aria-label="Basic radio toggle button group">

                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio25" autoComplete="off" checked={selectedPercentageBuy === 25}
                                                                onChange={() => calculateBuyQuantity(25)} />

                                                            <label className="btn btn-outline-success" htmlFor="btnradio25">25%</label>

                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio50" autoComplete="off" checked={selectedPercentageBuy === 50}
                                                                onChange={() => calculateBuyQuantity(50)} />

                                                            <label className="btn btn-outline-success" htmlFor="btnradio50">50%</label>

                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio75" autoComplete="off" checked={selectedPercentageBuy === 75}
                                                                onChange={() => calculateBuyQuantity(75)} />

                                                            <label className="btn btn-outline-success" htmlFor="btnradio75">75%</label>

                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio100" autoComplete="off" checked={selectedPercentageBuy === 100}
                                                                onChange={() => calculateBuyQuantity(100)} />

                                                            <label className="btn btn-outline-success" htmlFor="btnradio100" >100%</label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">

                                                        {Authtoken ?
                                                            KycStatus === 0 || KycStatus == 1 || KycStatus == 3 ?
                                                                <div className="order-btns">
                                                                    <Link to='/verify_kyc' /* {KycStatus == 1 ? "" : '/verify_kyc'} */ className={` btn-mini btn custom-btn w-100 my-md-0 ${KycStatus === 1 ? "btn-warning" : KycStatus === 0 ? "btn-warning" : "btn-danger"}`}>
                                                                        {KycStatus == 1 ? "Verification Pending" : KycStatus == 0 ? "Submit Kyc" : "Kyc Rejected Verify Again"}
                                                                    </Link>
                                                                </div> :
                                                                <div className="order-btns">
                                                                    <button type='button' className="btn custom-btn btn-success  btn-mini w-100 my-3 my-md-0"
                                                                        onClick={() => handleOrderPlace(infoPlaceOrder, buyOrderPrice, buyQuantity, SelectedCoin?.base_currency_id, SelectedCoin?.quote_currency_id, 'BUY')} disabled={!buyQuantity || !Authtoken || buyQuantity === '0'}>

                                                                        {isLoading ? (
                                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                        ) : (
                                                                            <span>Buy {SelectedCoin?.base_currency}</span>
                                                                        )}

                                                                    </button>
                                                                </div>
                                                            :
                                                            <div className="order-btns">
                                                                <Link className="btn custom-btn btn-primary  btn-mini w-100" to="/login">Login</Link>
                                                                <Link className="btn custom-border-btn  custom-border-btn-white btn-mini w-100" to="/signup">Sign up</Link>
                                                            </div>
                                                        }
                                                    </div>
                                                </form>
                                            </div>

                                            <div className="tab-pane px-0" id="selltab" role="tabpanel">
                                                <form action="" className="buysellform data-sell">
                                                    {Authtoken &&
                                                        <div className="actions_balance__kTHO0">
                                                            <span className="actions_primaryText__ufKT0"> Available Balance: </span>
                                                            <div>
                                                                <span>
                                                                    {isNaN(parseFloat(socketData?.balance?.base_currency_balance)) ? "0" : parseFloat(socketData?.balance?.base_currency_balance).toFixed(2)}
                                                                </span>

                                                                <span className="text-muted ms-1">{SelectedCoin?.base_currency}</span>

                                                                <Link className="actions_deposit__Ydutk" to='/wallet'>
                                                                    <i className="ri-add-circle-fill"></i>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        infoPlaceOrder === 'LIMIT' &&
                                                        <div className="form-group mb-3">
                                                            <label>Price</label>
                                                            <div className="input-group mb-3">

                                                                <input type="text" id="sellOrderPrice" name="sellOrderPrice" className="form-control" value={sellOrderPrice} onChange={handleSellPriceChange}
                                                                    disabled={infoPlaceOrder === 'MARKET'}
                                                                />

                                                                <span className="input-group-text text-start"><small>{SelectedCoin?.quote_currency}</small></span>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="form-group  mb-3"><label>Quantity</label>
                                                        <div className="input-group mb-3">
                                                            <input type="text" aria-invalid="true" className="form-control" value={sellQuantity} onChange={(e) => {
                                                                if (e.target.value.startsWith('.')) {
                                                                    e.target.value = '0' + e.target.value;
                                                                    return e.target.value;
                                                                }
                                                                if (/^\d{0,8}(\.\d{0,8})?$/.test(e.target.value) || e.target.value === '') {
                                                                    setSellQuantity(e.target.value)
                                                                }
                                                            }} />
                                                            <span className="input-group-text text-start"><small>{SelectedCoin?.base_currency}</small></span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Total</label>
                                                        {
                                                            infoPlaceOrder === 'LIMIT' &&
                                                            <div className="input-group mb-3">
                                                                <input type="text" id="buy_order_total" name="buy_order_total" className="form-control" value={formatTotal(sellOrderPrice * sellQuantity)} readOnly />
                                                                <span className="input-group-text text-start"><small>{SelectedCoin?.quote_currency}</small></span>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="form-group  mb-3" >
                                                        <div className="btn-group btn-group-mini" role="group" aria-label="Basic radio toggle button group">

                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio125" autoComplete="off" checked={selectedPercentageSell === 25}
                                                                onChange={() => calculateSellQuantity(25)}
                                                            />

                                                            <label className="btn btn-outline-danger" htmlFor="btnradio125">25%</label>

                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio150" autoComplete="off" checked={selectedPercentageSell === 50}
                                                                onChange={() => calculateSellQuantity(50)} />

                                                            <label className="btn btn-outline-danger" htmlFor="btnradio150">50%</label>

                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio175" autoComplete="off" checked={selectedPercentageSell === 75}
                                                                onChange={() => calculateSellQuantity(75)} />

                                                            <label className="btn btn-outline-danger" htmlFor="btnradio175">75%</label>

                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio1100" autoComplete="off" checked={selectedPercentageSell === 100}
                                                                onChange={() => calculateSellQuantity(100)} />

                                                            <label className="btn btn-outline-danger" htmlFor="btnradio1100">100%</label>

                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        {Authtoken ?
                                                            (KycStatus === 0 || KycStatus == 1 || KycStatus == 3) ?
                                                                <div className="order-btns">
                                                                    <Link to="/verify_kyc" className={` btn-mini btn custom-btn w-100 my-md-0 ${KycStatus === 1 ? "btn-warning" : KycStatus === 0 ? "btn-warning" : "btn-danger"}`}>
                                                                        {KycStatus == 1 ? "Verification Pending" : KycStatus == 0 ? "Submit Kyc" : "Kyc Rejected Verify Again"}
                                                                    </Link>
                                                                </div> :
                                                                <div className="order-btns">
                                                                    <button type='button' className="btn custom-btn btn-danger  btn-mini w-100 my-md-0" onClick={() => handleOrderPlace(infoPlaceOrder, sellOrderPrice, sellQuantity, SelectedCoin?.base_currency_id, SelectedCoin?.quote_currency_id, 'SELL')} disabled={!sellQuantity || !Authtoken || sellQuantity === '0'}>
                                                                        {isLoading ? (
                                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                        ) : (
                                                                            <span>Sell {SelectedCoin?.base_currency}</span>
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            :
                                                            <div className="order-btns">
                                                                <Link className="btn custom-btn btn-primary btn-mini w-100" to="/login">Login</Link>
                                                                <Link className="btn custom-border-btn  custom-border-btn-white btn-mini w-100" to="/signup">Sign up</Link>
                                                            </div>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-6 col-xl-9 col-xxl-9">
                        <div className="trade_card mb-0">
                            <div className="treade_card_header d-flex-between justify-content-end trade_tabs w-100 align-items-center buy_sell_cards p-0">
                                <ul className="nav custom-tabs order_his_tabs ">
                                    <li className="buysell-tab"><a className="active" data-bs-toggle="tab" href="#open_orders">Open Orders</a></li>
                                    <li className="buysell-tab"><a data-bs-toggle="tab" href="#past_orders" onClick={() => { Authtoken && handlePastOrder(baseCurId, quoteCurId) }}>Order History </a></li>
                                    <li className="buysell-tab"><a className=" " data-bs-toggle="tab" href="#trade_orders"> Trade History  </a></li>
                                </ul>
                                <Link to="/spot_order" className="min-w-auto card_header_title"> View All </Link>
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane show active  px-0" id="open_orders">
                                    <div className="scroll_y" style={{ height: '300px' }}>
                                        {Authtoken && socketData?.open_orders?.length > 0 &&
                                            <div className="order_actions" >
                                                <button className="btn btn_theme btn-warning btn-sm" type="button" onClick={() => handleCancelAllOrder()}>
                                                    Cancel All Orders
                                                </button>
                                            </div>
                                        }
                                        <div className='table-responsive'>
                                            <table className="table table_home ">
                                                <thead>
                                                    <tr>
                                                        <th> Date</th>
                                                        <th>Trading Pair</th>
                                                        <th>
                                                            <div className="num-div justify-content-start">
                                                                <select className="form-select num-select p-0 input-select cursor-pointer" value={orderType} onChange={(e) => setorderType(e.target.value)}>
                                                                    <option value="All">All</option>
                                                                    <option value="BUY">Buy</option>
                                                                    <option value="SELL">Sell</option>
                                                                </select>
                                                            </div>

                                                        </th>
                                                        <th> Price</th>
                                                        <th>Quantity</th>
                                                        <th>Remaining</th>
                                                        <th>Filled</th>
                                                        <th>Total</th>
                                                        <th>Order Type</th>
                                                        <th>Status</th>
                                                        <th> Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {socketData?.open_orders?.length > 0 ? socketData?.open_orders?.reverse()?.map((item, index) =>
                                                        (orderType === item?.side || orderType === 'All') &&
                                                        <tr key={index}>
                                                            <td>{item.updatedAt && moment(item.updatedAt).format("DD-MM-YYYY : HH:MM")}</td>
                                                            <td>{`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`}</td>
                                                            <td className={item?.side === "BUY" ? "text-success" : "text-danger"}>{item?.side}</td>
                                                            <td>{item?.price?.toFixed(4)}</td>
                                                            <td>{item?.quantity?.toFixed(4)}</td>
                                                            <td>{item?.remaining?.toFixed(4)}</td>
                                                            <td>{item?.filled?.toFixed(4)}</td>
                                                            <td>{(item?.price * item?.quantity)?.toFixed(4)}</td>
                                                            <td>{item?.order_type}</td>
                                                            <td>{item?.status}</td>
                                                            <td>
                                                                <button type="button" className="btn text-danger btn-sm btn-icon no-border" onClick={() => cancelOrder(item?._id)}>
                                                                    <i className="ri-delete-bin-6-line pr-0"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ) :
                                                        null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                                <div className="tab-pane show px-0" id="past_orders">
                                    <div className="scroll_y" style={{ height: '301px' }} >
                                        <div className='table-responsive'>
                                            <table className="table table_home ">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Trading Pair</th>
                                                        <th>Type</th>
                                                        <th>Price</th>
                                                        <th>Quantity</th>
                                                        <th>Total</th>
                                                        <th>Order Type</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pastOrders?.length > 0 ? pastOrders?.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{item.updatedAt && moment(item.updatedAt).format("DD-MM-YYYY : HH:MM")}</td>
                                                            <td>{`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`}</td>
                                                            <td className={item?.side === "BUY" ? "text-success" : "text-danger"}>{item?.side}</td>
                                                            <td>{item?.price?.toFixed(4)}</td>
                                                            <td>{item?.quantity?.toFixed(4)}</td>
                                                            <td>{(item?.quantity * item?.price)?.toFixed(4)}</td>
                                                            <td>{item?.order_type}</td>
                                                            <td>{item?.status === "CANCELLED" ? "Cancel" : item?.status === "FILLED" ? "Completed" : item?.status}</td>
                                                        </tr>
                                                    ) :
                                                        null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane show px-0" id="trade_orders">
                                    <div className="scroll_y" style={{ height: '301px' }} >
                                        <div className='table-responsive'>
                                            <table className="table table_home ">
                                                <thead>
                                                    <tr>
                                                        <th>Trading Pair</th>
                                                        <th>Date</th>
                                                        <th>Type</th>
                                                        <th>Price</th>
                                                        <th>Executed</th>
                                                        <th>Total</th>
                                                        <th>Order Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* <tr rowSpan="5">
                                                        <td colSpan="12" className="no-border" >
                                                            <div className="favouriteData  py-5 my-1">
                                                                <img src="/assets/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                                                                <p>No Data Available</p>
                                                            </div>
                                                        </td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12  col-lg-6 col-xl-3 col-xxl-3">
                        <div className="trade_card ">
                            <div className="treade_card_header">
                                <div className="card_header_title active">Market Traders</div>
                            </div>
                            <div className="price_card_head">
                                <div>Price({SelectedCoin?.quote_currency})-</div>
                                <div>Quantity({SelectedCoin?.base_currency})</div>-
                                <div>Time
                                </div>
                            </div>
                            <div className="price_card_body scroll_y scroll_y_mt"  >
                                {(socketData?.recent_trades?.length > 0 && !loader) ?
                                    socketData?.recent_trades?.map((item, index) => {
                                        return (
                                            <div className="price_item_value" key={index}>
                                                <span className={item?.side === "BUY" ? "text-success d-flex align-items-center" : "text-danger d-flex align-items-center"}> {(item?.price)?.toFixed(4)}</span>
                                                <span>{parseFloat(item?.quantity?.toFixed(4))}</span>
                                                <span> {item.updatedAt && moment(item.updatedAt).format("HH:mm:ss")}</span>
                                            </div>
                                        )
                                    }) : loader ? <div className="text-center no-data h-100 mb-0" >
                                        <div className="loading-wave">
                                            <div className="loading-bar"></div>
                                            <div className="loading-bar"></div>
                                            <div className="loading-bar"></div>
                                            <div className="loading-bar"></div>
                                        </div>
                                    </div>
                                        : <p className="text-center no-data h-100 mb-0" >
                                            <img src="/assets/images/no-data.png" className='img-fluid mb-2' alt="no data" width="52" />
                                            No data Available
                                        </p>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default ExchangePage
