import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardSidebar from "../../CoustamComponent/DashboardSidebar";
import ProfileWalletSubPage from "../../CoustamComponent/ProfileWalletSubPage";
import AuthService from "../../../Services/ApiServices/AuthService";
import moment from "moment";
import { fetchCoinDetails, fetchWalletList } from "../../../Context/Utility/apiutils";
import { SocketContext } from "../../../Context/SocketContext";
import { ProfileContext } from "../../../Services/ProfileProvider";
import { alertErrorMessage, alertWarningMessage } from "../../../Services/CustomAlertModel";

const DashboardModule = () => {

    const [activeMarkets, setActiveMarkets] = useState("holdings");
    const [socketData, setSocketData] = useState([]);
    const [favCoins, setfavCoins] = useState([]);
    const [coinDetails, setCoinDetails] = useState('');
    const [socketCoinDetails, setSocketCoinDetails] = useState('');
    const [activeModel, setActiveModel] = useState(false);
    const [activeModelWallet, setActiveModelWallet] = useState(false);

    const [coinList, setCoinList] = useState([]);

    const { userDetails } = useContext(ProfileContext);
    const KycStatus = userDetails?.user?.kycVerified;
    const { socket } = useContext(SocketContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (socket) {
            let payload = {
                'message': 'market',
            };
            socket.emit('message', payload);
            socket.on('message', (data) => {
                setSocketData(data);
            });
        }
    }, [socket]);


    const favoriteList = async () => {
        await AuthService.favoriteList().then((result) => {
            if (result?.success) {
                setfavCoins(result?.data?.pairs ? result?.data?.pairs : [""]);
            } else {
                /* console.log(); */
            }
        });
    };

    const nextPage = (item) => {
        if (item?.quote_currency === "INR") {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            navigate('/buy_sell');
        } else {
            navigate(`/exchange/${item?.base_currency}_${item?.quote_currency}`, { replace: true });
        }
    };


    useEffect(() => {
        fetchWalletList(setCoinList);
        favoriteList();
    }, []);

    const handleCoinDetails = (data) => {
        setCoinDetails(data);
        setActiveModelWallet(true)
    };

    const nextPageWallet = (coinDetails) => {
        if (!userDetails?.permissions?.deposit) {
            alertErrorMessage('Disable Deposit..');
            return;
        } if (KycStatus == 0) {
            alertWarningMessage('Kyc Pending Submit Your Kyc..');
            return;
        } if (KycStatus == 1) {
            alertWarningMessage('Your Kyc is Under Review..');
            return;
        }
        navigate("/deposit_funds", { state: coinDetails });
    };

    const nextPageWalletWithdraw = (coinDetails) => {
        if (!userDetails?.permissions?.withdrawal) {
            alertErrorMessage('Disable withdrawal..');
            return;
        } if (KycStatus == 0) {
            alertWarningMessage('Kyc Pending Submit Your Kyc..');
            return;
        } if (KycStatus == 1) {
            alertWarningMessage('Your Kyc is Under Review..');
            return;
        }
        navigate("/withdraw_funds", { state: coinDetails });
    };

    return (

        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper" >
                    <section className="d_page">
                        <div className="container" >
                            <div className="row justify-content-center" >
                                <div className="col-xxl-10" >
                                    <ProfileWalletSubPage />
                                    <div className="g_start m_card" >
                                        <div className="Markets" >
                                            <h5>  Markets </h5>
                                            <Link to="/markets"> More <i className=" ri-arrow-right-s-line"></i> </Link>
                                        </div>
                                        <div className="m_tabs" >

                                            <div className={`tab_item ${activeMarkets === "holdings" ? 'active' : ''}`}
                                                onClick={() => setActiveMarkets('holdings')}> Holdings </div>

                                            <div className={`tab_item ${activeMarkets === "hot" ? 'active' : ''}`}
                                                onClick={() => setActiveMarkets('hot')}> Hot </div>

                                            <div className={`tab_item ${activeMarkets === "newListing" ? 'active' : ''}`}
                                                onClick={() => setActiveMarkets('newListing')}> New Listing </div>

                                            <div className={`tab_item ${activeMarkets === "favorite" ? 'active' : ''}`}
                                                onClick={() => setActiveMarkets('favorite')}> Favorite </div>

                                            <div className={`tab_item ${activeMarkets === "topGainers" ? 'active' : ''}`}
                                                onClick={() => setActiveMarkets('topGainers')}> Top Gainers </div>

                                            <div className={`tab_item ${activeMarkets === "24hVolume" ? 'active' : ''}`}
                                                onClick={() => setActiveMarkets('24hVolume')}> 24h Volume </div>
                                        </div>

                                        {/* hot */}

                                        <div className={`m_table ${activeMarkets != "holdings" ? 'd-none' : ''}`} >
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Coin Name</th>
                                                            <th scope="col" className="text-end">Available Balance</th>
                                                            <th scope="col" className="text-end">Locked Balance</th>
                                                            <th scope="col" className="text-end">Total Balance</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {coinList?.length > 0 ? (
                                                            coinList?.map((item, index) => (
                                                                <tr key={index} onClick={() => handleCoinDetails(item)}>
                                                                    <td>
                                                                        <div className="c_view">
                                                                            <img src={item.icon_path} className="img-fluid" alt=""
                                                                                onError={(e) => {
                                                                                    e.target.onerror = null;
                                                                                    e.target.src = "/assets/images/icon/nocoin.png";
                                                                                }} />
                                                                            <span>{item.short_name}<br /> <small>{item.currency}</small> </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <div className="c_view justify-content-end">
                                                                            <span>{item.balance?.toFixed(5)}<br /></span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <span> {item.locked_balance?.toFixed(5)} </span>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <span> {(item.balance + item.locked_balance)?.toFixed(2)} </span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="7">
                                                                    <div className="favouriteData">
                                                                        <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        {/* hot */}

                                        <div className={`m_table ${activeMarkets != "hot" ? 'd-none' : ''}`} >
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col" className="text-end" >Price</th>
                                                            <th scope="col" className="text-end" >24h Change</th>
                                                            <th scope="col" className="text-end" >Volume</th>
                                                            <th scope="col" className="text-end" >Available Balance</th>
                                                            <th scope="col" className="text-end" >Locked Balance</th>
                                                            <th scope="col" className="text-end" >Trade</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            socketData?.hot?.length > 0 ?
                                                                socketData?.hot?.slice(0, 10)?.map((item, index) => (
                                                                    <tr key={index} onClick={() => nextPage(item)}>
                                                                        <td>
                                                                            <div className="c_view" onClick={() => { setActiveModel(false); fetchCoinDetails(setCoinDetails, item?.base_currency_id); setSocketCoinDetails(item) }}>
                                                                                <img src={item?.icon_path} className="img-fluid" alt=""
                                                                                    onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} />
                                                                                <span>{item.base_currency + '/' + item.quote_currency} <br />
                                                                                    <small>{item.base_currency_full_name}</small>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            {item.quote_currency === "INR" ? '₹' : '$'}{item?.buy_price?.toFixed(2)}
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            <span className={item?.change >= 0 ? "text-success" : "text-danger"} >
                                                                                {item?.change?.toFixed(2)}%
                                                                            </span>
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            {item?.volume?.toFixed(2)}
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            0.000
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            0.000
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            <button className="btn-link text-dark" type="button" onClick={() => nextPage(item)}> Trade  </button>
                                                                        </td>
                                                                    </tr>

                                                                ))
                                                                :
                                                                <tr>
                                                                    <td colSpan="6">
                                                                        <div className="favouriteData">
                                                                            <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        {/* newListing */}

                                        <div className={`m_table ${activeMarkets != "newListing" ? 'd-none' : ''}`} >
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col" className="text-end" >Price</th>
                                                            <th scope="col" className="text-end" >24h Change</th>
                                                            <th scope="col" className="text-end" >Volume</th>
                                                            <th scope="col" className="text-end" >Available Balance</th>
                                                            <th scope="col" className="text-end" >Locked Balance</th>
                                                            <th scope="col" className="text-end" >Trade</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            socketData?.new_listed?.length > 0 ?
                                                                socketData?.new_listed?.slice(0, 10)?.map((item, index) => (
                                                                    <tr key={index} onClick={() => nextPage(item)}>
                                                                        <td>
                                                                            <div className="c_view" onClick={() => { setActiveModel(false); fetchCoinDetails(setCoinDetails, item?.base_currency_id); setSocketCoinDetails(item) }}>
                                                                                <img src={item?.icon_path} className="img-fluid" alt=""
                                                                                    onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} />
                                                                                <span>{item.base_currency + '/' + item.quote_currency}  <br />
                                                                                    <small>{item.base_currency_full_name}</small>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            {item.quote_currency === "INR" ? '₹' : '$'}{item?.buy_price?.toFixed(2)}
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            <span className={item?.change >= 0 ? "text-success" : "text-danger"} >
                                                                                {item?.change?.toFixed(2)}%
                                                                            </span>
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            {item?.volume?.toFixed(2)}
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            0.000
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            0.000
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            <button className="btn-link text-dark" type="button" onClick={() => nextPage(item)}> Trade  </button>
                                                                        </td>
                                                                    </tr>

                                                                ))
                                                                :
                                                                <tr>
                                                                    <td colSpan="6">
                                                                        <div className="favouriteData">
                                                                            <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        {/* favorite */}

                                        <div className={`m_table ${activeMarkets != "favorite" ? 'd-none' : ''}`} >
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col" className="text-end" >Price</th>
                                                            <th scope="col" className="text-end" >24h Change</th>
                                                            <th scope="col" className="text-end" >Volume</th>
                                                            <th scope="col" className="text-end" >Available Balance</th>
                                                            <th scope="col" className="text-end" >Locked Balance</th>
                                                            <th scope="col" className="text-end" >Trade</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {socketData?.pairs?.length > 0 && socketData?.pairs?.some(item => favCoins?.includes(item?._id)) ? (
                                                            socketData?.pairs?.filter(item => favCoins?.includes(item?._id))?.map((item, index) => (
                                                                <tr key={index} onClick={() => nextPage(item)}>
                                                                    <td>
                                                                        <div className="c_view" onClick={() => { setActiveModel(false); fetchCoinDetails(setCoinDetails, item?.base_currency_id); setSocketCoinDetails(item) }}>
                                                                            <img src={item?.icon_path} alt="" onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} />
                                                                            <span>{item?.base_currency + "/" + item?.quote_currency} <br />
                                                                                <small>{item.base_currency_full_name}</small>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <div className="c_view justify-content-end">
                                                                            <span>{item.quote_currency === "INR" ? '₹' : '$'}{item?.buy_price?.toFixed(4)}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <span className={item?.change >= 0 ? "text-success" : "text-danger"}>{item?.change.toFixed(4)}%</span>
                                                                    </td>
                                                                    <td className="text-end">{item?.volume?.toFixed(4)}</td>
                                                                    <td className="text-end" >
                                                                        0.000
                                                                    </td>
                                                                    <td className="text-end" >
                                                                        0.000
                                                                    </td>
                                                                    <td className="text-end"><button className="btn-link text-dark" type="button" onClick={() => nextPage(item)}>Trade</button></td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6">
                                                                    <div className="favouriteData">
                                                                        <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        {/* topGainers */}

                                        <div className={`m_table ${activeMarkets != "topGainers" ? 'd-none' : ''}`} >
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col" className="text-end" >Price</th>
                                                            <th scope="col" className="text-end" >24h Change</th>
                                                            <th scope="col" className="text-end" >Volume</th>
                                                            <th scope="col" className="text-end" >Available Balance</th>
                                                            <th scope="col" className="text-end" >Locked Balance</th>
                                                            <th scope="col" className="text-end" >Trade</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            socketData?.top_gainers?.length > 0 ?
                                                                socketData?.top_gainers?.slice(0, 10)?.map((item, index) => (
                                                                    <tr key={index} onClick={() => nextPage(item)}>
                                                                        <td>
                                                                            <div className="c_view" onClick={() => { setActiveModel(false); fetchCoinDetails(setCoinDetails, item?.base_currency_id); setSocketCoinDetails(item) }}>
                                                                                <img src={item?.icon_path} className="img-fluid" alt=""
                                                                                    onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} />
                                                                                <span>{item.base_currency + '/' + item.quote_currency}  <br />
                                                                                    <small>{item.base_currency_full_name}</small>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            {item.quote_currency === "INR" ? '₹' : '$'}{item?.buy_price?.toFixed(2)}
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            <span className={item?.change >= 0 ? "text-success" : "text-danger"} >
                                                                                {item?.change?.toFixed(2)}%
                                                                            </span>
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            {item?.volume?.toFixed(2)}
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            0.000
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            0.000
                                                                        </td>

                                                                        <td className="text-end" >
                                                                            <button className="btn-link text-dark" type="button" onClick={() => nextPage(item)}> Trade  </button>
                                                                        </td>
                                                                    </tr>

                                                                ))
                                                                :
                                                                <tr>
                                                                    <td colSpan="6">
                                                                        <div className="favouriteData">
                                                                            <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        {/* 24hVolume */}

                                        <div className={`m_table ${activeMarkets != "24hVolume" ? 'd-none' : ''}`} >
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col" className="text-end" >Price</th>
                                                            <th scope="col" className="text-end" >24h Change</th>
                                                            <th scope="col" className="text-end" >Volume</th>
                                                            <th scope="col" className="text-end" >Available Balance</th>
                                                            <th scope="col" className="text-end" >Locked Balance</th>
                                                            <th scope="col" className="text-end" >Trade</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            socketData?.pairs?.length > 0 ?
                                                                socketData?.pairs?.slice(0, 10)?.map((item, index) => (
                                                                    <tr key={index} onClick={() => nextPage(item)}>
                                                                        <td>
                                                                            <div className="c_view" onClick={() => { setActiveModel(false); fetchCoinDetails(setCoinDetails, item?.base_currency_id); setSocketCoinDetails(item) }}>
                                                                                <img src={item?.icon_path} className="img-fluid" alt=""
                                                                                    onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} />
                                                                                <span>{item.base_currency + '/' + item.quote_currency}  <br />
                                                                                    <small>{item.base_currency_full_name}</small>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            {item.quote_currency === "INR" ? '₹' : '$'}{item?.buy_price?.toFixed(2)}
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            <span className={item?.change >= 0 ? "text-success" : "text-danger"} >
                                                                                {item?.change?.toFixed(2)}%
                                                                            </span>
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            {item?.volume?.toFixed(2)}
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            0.000
                                                                        </td>
                                                                        <td className="text-end" >
                                                                            0.000
                                                                        </td>

                                                                        <td className="text-end" >
                                                                            <button className="btn-link text-dark" type="button" onClick={() => nextPage(item)}> Trade  </button>
                                                                        </td>
                                                                    </tr>

                                                                ))
                                                                :
                                                                <tr>
                                                                    <td colSpan="6">
                                                                        <div className="favouriteData">
                                                                            <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>


            {
                activeModel &&
                <div className="nc_modal-backdrop" >
                    <div className="nc_modal ng_popup"  >
                        <div className="popup_body" >
                            <div>
                                <div className="nc_header" >
                                    <div className="row" >
                                        <div className="col-8" >
                                            <span className="nc_icon justify-content-start">

                                                <div className="information_icon__Lztnq me-0">
                                                    <img src={coinDetails?.icon_path} onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} className="img-fluid" height="28" width="28" />
                                                </div>
                                                <div className="information_name__ygI29 information_bright__F_RTd">{coinDetails?.name}</div>
                                            </span>
                                        </div>
                                        <div className="col-4 text-end"  >
                                            <span className="btn-icon cursor_pointer" onClick={() => setActiveModel(false)} >
                                                <i className="ri-close-large-line "></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="pop_body" >
                                    <div className="exchange_chartContent__Ijukg h-auto ">
                                        <div className="information_visible__9A4Mp information_wrapper__AR6LL pt-0">
                                            <div className="information_intro__ffLAj scroll-container  h-auto mb-3">
                                                <div className="information_bright__F_RTd ms-0">{coinDetails?.description || '----------'}.</div>
                                            </div>
                                            <div className="information_title__TYI4l"><span className="information_bright__F_RTd information_name__ygI29">Overview</span></div>
                                            <div className="information_other__OQlyX">
                                                <div className="information_left__vgFSY">
                                                    <div className="information_infoItem__3_gv6">
                                                        <div>Name</div>
                                                        <div className="information_bright__F_RTd">{coinDetails?.name}</div>
                                                    </div>
                                                    <div className="information_infoItem__3_gv6">
                                                        <div>Issue Date</div>
                                                        <div className="information_bright__F_RTd">
                                                            {moment(coinDetails.createdAt).format("lll")}
                                                        </div>
                                                    </div>
                                                    <div className="information_infoItem__3_gv6">
                                                        <div>Deposit Status  </div>
                                                        <div className="information_bright__F_RTd">{coinDetails?.deposit_status}</div>
                                                    </div>
                                                    <div className="information_infoItem__3_gv6">
                                                        <div>Maker Fee</div>
                                                        <div className="information_bright__F_RTd">{coinDetails?.maker_fee}</div>
                                                    </div>
                                                    <div className="information_infoItem__3_gv6">
                                                        <div>Maker Fee</div>
                                                        <div className="information_bright__F_RTd">{coinDetails?.taker_fee}</div>
                                                    </div>
                                                </div>
                                                <div className="information_right__kqyPI">
                                                    <div className="information_infoItem__3_gv6">
                                                        <div>Issue Price</div>
                                                        <div className="information_bright__F_RTd">{socketCoinDetails?.buy_price?.toFixed(2)}</div>
                                                    </div>
                                                    <div className="information_infoItem__3_gv6">
                                                        <div>Total Supply</div>
                                                        <div className="information_bright__F_RTd">{coinDetails?.total_supply}</div>
                                                    </div>
                                                    <div className="information_infoItem__3_gv6">
                                                        <div>Circulating Supply</div>
                                                        <div className="information_bright__F_RTd">{coinDetails?.circulating_supply}</div>
                                                    </div>
                                                    <div className="information_infoItem__3_gv6">
                                                        <div>(24h) High  </div>
                                                        <div className="information_bright__F_RTd">+{socketCoinDetails?.high?.toFixed(2)}</div>
                                                    </div>
                                                    <div className="information_infoItem__3_gv6">
                                                        <div> (24h) Low  </div>
                                                        <div className="information_bright__F_RTd"> -{socketCoinDetails?.low?.toFixed(2)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-4 " >
                                                <button className="btn btn-block w-100 btn-warning" type="button" onClick={() => nextPage(socketCoinDetails)}> {socketCoinDetails?.quote_currency === "INR" ? 'Buy-Sell' : 'Trade Now'} </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                activeModelWallet &&
                <div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-md ">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title  " id="modal_otp_Label"> {coinDetails?.currency} </h5>
                                    <button type="button" className="btn-close" onClick={() => setActiveModelWallet(false)}></button>
                                </div>
                                <div className="modal-body p-md-4 ">
                                    <div className="tt_data ">
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Created At: </span>
                                            <span className="tt_normal">{moment(coinDetails?.createdAt).format('DD-MM-YYYY HH:mm:ss')}</span>
                                        </div>


                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Coin Name:</span>
                                            <span className="tt_normal">{coinDetails?.short_name}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted">Status: </span>
                                            <span className="tt_normal">{coinDetails?.status}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Locked Balance: </span>
                                            <span className="tt_normal">{coinDetails?.locked_balance?.toFixed(2)}</span>
                                        </div>
                                        <div className="tt_item no-border pt-2">
                                            <span className="tt_normal"> Available balance: </span>
                                            <span className="tt_normal">{coinDetails?.balance?.toFixed(2)} {coinDetails?.short_name}</span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="col-lg-12 mt-3" >
                                        <div className="btn_row" >
                                            <button type="button" className="btn btn-sm btn-warning" onClick={() => nextPageWallet(coinDetails)}>Deposit</button>
                                            <button type="button" className="btn btn-sm btn-warning" onClick={() => nextPageWalletWithdraw(coinDetails)}>Withdraw</button>

                                            <Link to="/coming_soon" className="btn btn-sm btn-dark">Transfer</Link>
                                            <Link to="/transaction_history" className="btn btn-sm btn-dark">History</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div>
            }

        </main>
    )
}

export default DashboardModule