import { CountdownCircleTimer } from "react-countdown-circle-timer";
import moment from "moment";
import { useState } from "react";
import AuthService from "../ApiServices/AuthService";

const CountdownTimer = ({ amountDepositedAt, id, reminder, handleTranactionList }) => {

  const [isTimerComplete, setIsTimerComplete] = useState(false);
  const [statusChange, setStatusChange] = useState("");

  if (!amountDepositedAt) return <div>00m 00s</div>;

  const fifteenMinutes = 900;
  const depositedTime = moment(amountDepositedAt).valueOf();
  const currentTime = moment().valueOf();
  const deadline = depositedTime + fifteenMinutes * 1000;
  const remainingTime = Math.max((deadline - currentTime) / 1000, 0);


  const handleremindAdmin = async () => {
    const result = await AuthService.remindQbsTimer(id);
    if (result?.success) {
      setStatusChange(result?.message);
      handleTranactionList();
    }
  };

  return (
    <>
      {isTimerComplete && (
        <img
          src="/assets/images/loadingNew.png"
          alt=""
          style={{ width: "80px", marginRight: "5px" }}
        />
      )}

      {!isTimerComplete &&
        <CountdownCircleTimer
          isPlaying
          size={100}
          strokeWidth={8}
          duration={fifteenMinutes}
          initialRemainingTime={remainingTime}
          colors={["#11c973", "#F0B90B", "#dc3545", "#ff0018"]}
          colorsTime={[900, 600, 300, 0]}
          onComplete={() => {
            setIsTimerComplete(true);
            return { shouldRepeat: false };
          }}
        >
          {({ remainingTime }) => {
            if (isNaN(remainingTime) || remainingTime <= 0)
              return <div>00m 00s</div>;

            const minutes = String(Math.floor(remainingTime / 60)).padStart(2, "0");
            const seconds = String(remainingTime % 60).padStart(2, "0");

            return (
              <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                {minutes}m {seconds}s
              </div>
            );
          }}
        </CountdownCircleTimer>
      }

      {isTimerComplete && (
        <button
          type="button"
          className="btn btn-md btn-warning btn-block"
          onClick={handleremindAdmin}
          disabled={reminder}
        >
          {reminder ? "Reminder Sent" : "Remind"}
        </button>
      )}

      {statusChange && <h5 className="text-success">{statusChange}</h5>}
    </>

  );
};

export default CountdownTimer;