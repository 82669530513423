import { useState, useEffect } from "react";
import moment from "moment";

const CountdownTimerOrder = ({ orderCreatedAt, orderStatus }) => {

  const fifteenMinutes = orderStatus ? 1800 : 900;
  const depositedTime = moment(orderCreatedAt).valueOf();
  const deadline = depositedTime + fifteenMinutes * 1000;

  const calculateRemainingTime = () => Math.max((deadline - moment().valueOf()) / 1000, 0);

  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

  useEffect(() => {
    if (!orderCreatedAt) return;

    const interval = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [orderCreatedAt, orderStatus]);

  if (!orderCreatedAt || isNaN(remainingTime) || remainingTime <= 0) {
    return null;
  }

  const minutes = String(Math.floor(remainingTime / 60)).padStart(2, "0");
  const seconds = String(Math.floor(remainingTime % 60)).padStart(2, "0");

  return (

    <span className="utr_timer">
      <i className="ri-timer-line"></i>
      {minutes}m {seconds}s
    </span>

  )
    ;
};

export default CountdownTimerOrder;
