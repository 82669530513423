import React, { useState } from "react";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import AuthService from "../../../../Services/ApiServices/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../../Services/CustomAlertModel";

const AccountStatement = () => {

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const handleFromDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const formattedDate = new Date(selectedDate.setUTCHours(0, 0, 0, 0)).toISOString();
        setFromDate(formattedDate);
    };

    const handleToDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const formattedDate = new Date(selectedDate.setUTCHours(23, 59, 59, 999)).toISOString();
        setToDate(formattedDate);
    };

    const handleRequestDownload = async () => {
        if (!fromDate || !toDate) {
            alertErrorMessage("Please Select Date")
        }
        try {
            const result = await AuthService.getAccountStatement(fromDate, toDate);
            if (result?.success && result?.data) {
                const csvData = convertToCSV(result.data);
                downloadCSV(csvData, 'accountStatement.csv');
                alertSuccessMessage("Account Statement Successfully downloaded")
                setFromDate("");
                setToDate("");
            } else {
                alertErrorMessage('No Data Found Account Statement');
            }
        } catch (error) {
            alertErrorMessage('No data found for Account Statement');
        }
    };

    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]).join(',');
        const rows = data.map(row => Object.values(row).join(','));
        return [headers, ...rows].join('\n');
    };


    const downloadCSV = (csvContent, fileName) => {
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };


    return (

        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper">
                    <section className="d_page report_sec">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xxl-8 col-xl-10">
                                    <div className="g_start">
                                        <h6 className="mb-3 mb-md-4">Account Statement</h6>
                                        <div className="tax_card">
                                            <div className="row g-3 g-md-4">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label>From Date</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            value={fromDate.substring(0, 10)}
                                                            onChange={handleFromDateChange}
                                                            max={new Date().toISOString().split("T")[0]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label>To Date</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            value={toDate.substring(0, 10)}
                                                            onChange={handleToDateChange}
                                                            max={new Date().toISOString().split("T")[0]}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <button type="button" className="form-control btn py-3 btn-warning btn-block w-100 mt-4" onClick={handleRequestDownload}>
                                                        Download
                                                    </button>
                                                </div>

                                                <div className="col-12">
                                                    <div className="settings-section-body-list&quot;">
                                                        <span className="dlrLME">Note:</span>
                                                        <ul className="settings-section-body-list Taxinvoice">
                                                            <li>You can now download your account statement, which includes your personal details and a summary of your holding assets.</li>
                                                            <li>Click the button below to download your statement.</li>
                                                            <li>Download Option:</li>
                                                            <li>CSV – Perfect for detailed spreadsheet analysis.
                                                            If you have any questions or need assistance, feel free to contact our support team.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>

    )
}

export default AccountStatement