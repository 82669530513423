import React, { useContext, useEffect, useState } from "react";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import AuthService from "../../../../Services/ApiServices/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../../Services/CustomAlertModel";
import { ProfileContext } from "../../../../Services/ProfileProvider";

const ManagingFeatures = () => {

    const { handleUserProfile, userDetails } = useContext(ProfileContext);

    const [featureStates, setFeatureStates] = useState({
        "INR Buy & Sell": true,
        "Spot Trading": true,
        "P2P Trading": true,
        "Quick Pay": true,
        "Deposit Funds": true,
        "withdraw Funds": true,
    });

    useEffect(() => {
        if (userDetails?.permissions) {
            setFeatureStates({
                "INR Buy & Sell": userDetails.permissions.buy_sell,
                "Spot Trading": userDetails.permissions.spot,
                "P2P Trading": userDetails.permissions.p2p,
                "Quick Pay": userDetails.permissions.quick_pay,
                "Deposit Funds": userDetails.permissions.deposit,
                "withdraw Funds": userDetails.permissions.withdrawal,
            });
        }
    }, [userDetails]);

    const handleSwitchChange = (e, featureName) => {
        const isChecked = e.target.checked;
        setFeatureStates({
            ...featureStates,
            [featureName]: isChecked
        });
        updateFeatureState(featureName, isChecked);
    };

    const updateFeatureState = async (featureName, isEnabled) => {
        try {
            const result = await AuthService.manageFeature(featureName, isEnabled);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile();
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };
    
    useEffect(() => {
        handleUserProfile();
    }, [])

    return (
        <main>
            <div className="page_wrapper">
                <div className="sidebar">
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper">
                    <section className="d_page m_account  history_page">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xxl-8 col-xl-10">
                                    <div className="g_start checkup_twofa ">
                                        <div className=" ">
                                            <h6 className="mb-1">Managing Features</h6>
                                            <p> <small> Manage features according to your requirements. </small> </p>
                                            <div className=" mt-4  p_card currency_card" >
                                                <div className="mf_col" >
                                                    {[
                                                        { name: "INR Buy & Sell" },
                                                        { name: "Spot Trading" },
                                                        { name: "P2P Trading" },
                                                        { name: "Quick Pay" },
                                                        { name: "Deposit Funds" },
                                                        { name: "withdraw Funds" }
                                                    ].map((feature, index) => (
                                                        <div className="per_col mb-3" key={index} >
                                                            <div className="d-flex align-items-center ">
                                                                <div className="pr_icon">
                                                                    <i className="ri-money-rupee-circle-fill"></i>
                                                                </div>
                                                                <div>
                                                                    <h5> {feature.name} </h5>
                                                                    <p> Disable Spot Trading freture on your account. </p>
                                                                </div>
                                                            </div>
                                                            <label className="switchbox" htmlFor={`switch-${index}`} title={featureStates[feature.name] ? "Enabled" : "Disabled"} >
                                                                <input
                                                                    className="form-check-input cursor-pointer"
                                                                    type="checkbox"
                                                                    name={feature.name}
                                                                    id={`switch-${index}`}
                                                                    onChange={(e) => handleSwitchChange(e, feature.name)}
                                                                    checked={featureStates[feature.name]}
                                                                />

                                                                <span className="slider round"></span>
                                                                {/* <label className="form-check-label ms-2" htmlFor={`switch-${index}`}>
                                                            {featureStates[feature.name] ? "Enabled" : "Disabled"}
                                                        </label> */}
                                                            </label>
                                                        </div>
                                                    ))}

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >
        </main >
    );
};

export default ManagingFeatures;
