import React, { useEffect, useState } from "react";
import Footer from "../../../Footer";
import AuthService from "../../../../../Services/ApiServices/AuthService";
import { Link } from "react-router-dom";


const FeeStructure = () => {

    const [qbsFeeStructureQbs, setQbsFeeStructureQbs] = useState('');
    const [spotFeeStructureQbs, setSpotFeeStructureQbs] = useState('');


    const handleQbsFeeStructure = async () => {
        const result = await AuthService.getQbsFeesStracture();
        if (result?.success) {
            setQbsFeeStructureQbs(result?.data);
        }
    };


    const handleSpotFeeStructure = async () => {
        const result = await AuthService.getSpotFeesStracture();
        if (result?.success) {
            setSpotFeeStructureQbs(result?.data);
        }
    };


    useEffect(() => {
        handleQbsFeeStructure();
        handleSpotFeeStructure();
    }, []);

    return (
        <>
            <main>
                <section className="tm_sec">
                    <div className="container">
                        <div className="row   mt-md-5 justify-content-center">
                            <div className="col-lg-10">
                                <div className="section-title text-center">
                                    <h2 className="text-gradient mb-0 "> Fee structure </h2>
                                    <nav className="mt-2">
                                        <ol className="breadcrumb justify-content-center">
                                            <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page"> Fee structure </li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="fees_wrapper" >
                                    {/* tab_1 */}
                                    <div className="trading_fee_tab" >
                                        <h6> Quick Buy Sell</h6>
                                        <div className="crypto-group ng-star-inserted" >
                                            <table className="mt-3 mb-5" >
                                                <thead >
                                                    <tr className="inactive-level">
                                                        <th className="token-head">Crypto/Fiat</th>
                                                        <th className="head-custom">  Trading Fee  </th>
                                                        <th className="head-custom">Minimum Deposit Limit</th>
                                                        <th className="head-custom">Minimum Withdrawal Limit</th>
                                                        <th className="head-custom">Deposit Fee</th>
                                                        <th className="head-custom">Withdrawal Fee</th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {qbsFeeStructureQbs?.length > 0 && (
                                                        qbsFeeStructureQbs.map((item, index) => (
                                                            <tr className="inactive-level ng-star-inserted" key={index}>
                                                                <td>{item.currency_name}</td>
                                                                <td>{item.trading_fee}</td>
                                                                <td>{item.min_deposit}</td>
                                                                <td>{item.min_withdrawal}</td>
                                                                <td>{item.deposit_fee}</td>
                                                                <td>{item.withdrawal_fee}</td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>

                                            <h6> Spot Trading </h6>

                                            <table className="mt-3" >
                                                <thead >
                                                    <tr className="inactive-level">
                                                        <th className="token-head">Crypto</th>
                                                        <th className="head"> Trading Fee </th>
                                                        <th className="head">Minimum Withdrawal Limit</th>
                                                        <th className="head">Withdrawal Fee</th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {spotFeeStructureQbs?.length > 0 && (
                                                        spotFeeStructureQbs.map((item, index) => (
                                                            <tr className="inactive-level ng-star-inserted" key={index}>
                                                                <td>{item.currency_name}</td>
                                                                <td>{item.trading_fee}</td>
                                                                <td>{item.min_withdrawal}</td>
                                                                <td>{item.withdrawal_fee}</td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="note" >
                                            <p>
                                                Please read the <a  href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/terms_and_conditions.pdf" target="_blank" >Terms of Use</a> and  Risk Disclosure as applicable to the website.
                                            </p>
                                            <p>
                                                <b>Disclaimer</b> Crypto products & NFTs are unregulated and can be highly risky. There may be no regulatory recourse for any loss from such transactions. The information and material contained herein are subject to change without prior notice including prices which may fluctuate based on market demand and supply. The material available on the site is proprietary to Crypto India, its parent, Licensor and/or its affiliates and is for informational purposes and informed investors only. This material is not: (i) an offer, or solicitation of an offer, to invest in, or to buy or sell, any interests or shares, or to participate in any investment or trading strategy, or (ii) intended to provide accounting, legal, or tax advice, or investment recommendations.
                                            </p>
                                            <h5 ><strong >Please Note: </strong></h5>
                                            <ul >
                                                <li >Certain pairs allow the fee to be calculated based on the trade's base currency, which can be specified when ordering using the fee currency option.</li>
                                                <li >
                                                    Fees on the Platform for INR trading pairs are updated daily.
                                                </li>
                                                <li >Taxes will be levied as per applicable law. </li><li >Fees are charged on a per-trade basis and calculated as a percentage of the trade's quoted currency volume by default.</li>
                                                <li >Fees are adjusted according to the blockchain conditions regularly.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>

    )
}

export default FeeStructure