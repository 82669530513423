import React, { useRef, useState } from "react";
import Footer from "../../../Footer";
import AuthService from "../../../../../Services/ApiServices/AuthService";
import Swal from "sweetalert2";

const MarketMakerProgram = () => {

    const [formData, setFormData] = useState({
        accountType: "",
        name: "",
        email: "",
        phone: "",
        registrationNumber: "",
        country: "",
        website: "",
        tradingPair: "",
        liquidityExperience: "",
        legalDisputes: "",
        agreed: false,
    });

    const [formErrors, setFormErrors] = useState("");
    const [responseResult, setResponseResult] = useState("");
    const [loading, setLoading] = useState(false);
    const fieldRefs = useRef({});
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^\d{10}$/;

    const getFirstInvalidField = () => {
        if (!formData.accountType) return "accountType";
        if (!formData.name) return "name";
        if (!formData?.email || !emailRegex.test(formData.email)) return 'email';
        if (!formData?.phone || !phoneRegex.test(formData.phone)) return 'phone';
        if (!formData.country) return "country";
        if (!formData.tradingPair) return "tradingPair";
        if (!formData.liquidityExperience) return "liquidityExperience";
        if (!formData.legalDisputes) return "legalDisputes";
        if (!formData.agreed) return "agreed";
        return null;
    };

    const validateField = (name, value) => {
        switch (name) {
            case "accountType":
                return value ? "" : "Account Type is required.";
            case "name":
                return value ? "" : "Name is required.";
            case "email":
                return emailRegex.test(value) ? "" : "Enter Valid Email Address.";
            case "phone":
                return phoneRegex.test(value) ? "" : "Enter Valid 10-digit Phone Number is required.";
            case "country":
                return value ? "" : "Country of Operation is required.";
            case "tradingPair":
                return value ? "" : "Preferred Trading Pair is required.";
            case "liquidityExperience":
                return value ? "" : "Liquidity experience field cannot be empty.";
            case "legalDisputes":
                return value ? "" : "Legal disputes information is required.";
            case "agreed":
                return value ? "" : "You must agree to the terms and conditions.";
            default:
                return "";
        }
    };


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === "checkbox" ? checked : value;
        if (name === "phone" && !/^\d*$/.test(value)) {
            return;
        }
        setFormData((prevData) => ({
            ...prevData,
            [name]: fieldValue,
        }));
        if (formErrors && name === getFirstInvalidField()) {
            setFormErrors("");
        }
        setResponseResult("");
    };

    const showAlert = (jobTitle) => {
        Swal.fire({
            title: `Thank You ${formData?.name}`,
            text: "Your Request Submited Successfully",
            icon: "success",
        })
    };

    const handleSubmit = async () => {
        const firstInvalidField = getFirstInvalidField();
        if (firstInvalidField) {
            const error = validateField(firstInvalidField, formData[firstInvalidField]);
            setFormErrors({ [firstInvalidField]: error });
            if (fieldRefs.current[firstInvalidField]) {
                fieldRefs.current[firstInvalidField].scrollIntoView({ behavior: "smooth", block: "center" });
                fieldRefs.current[firstInvalidField].focus();
            }
            return;
        }
        setLoading(true);
        try {
            const result = await AuthService.makerApplication(formData);
            if (result?.success) {
                showAlert(result?.message);
                setResponseResult("")
                setLoading(false);
                setFormData({
                    accountType: "",
                    name: "",
                    email: "",
                    phone: "",
                    registrationNumber: "",
                    country: "",
                    website: "",
                    tradingPair: "",
                    liquidityExperience: "",
                    legalDisputes: "",
                    agreed: false,
                });
            } else {
                setResponseResult(result || "Internal Server Error.");
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            setResponseResult(error?.message || "An unexpected error occurred.");
        }

    };


    return (
        <>
            <main>
                <section className="tm_sec d-block">
                    <div className="container">
                        <div className="row   mt-md-5 justify-content-center">
                            <div className="col-lg-12">
                                <div className="section-title  partner_title_sect text-start ">
                                    <div className="row align-items-center g-4" >
                                        <div className="col-lg-5" >
                                            <h2 className="text-dark  "> Market Maker Program <br /> Application Form   </h2>
                                            <div className="ddd text-start"  >
                                                <p>
                                                    Thank you for your interest in the Crypto India Market Maker Program for our OTC Desk. As a market maker, you play a crucial role in enhancing liquidity on our platform, ensuring seamless large-scale trades for all participants.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-7" >
                                            <div className="sec_img" >
                                                <img src="assets/images/mm_program.svg" className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="container">
                        <div className="g_start m_card overflow-hidden p-0">
                            <div className="p_card p-md-5">
                                <div className="why_card">
                                    <p>Please fill out the form below to apply for the program:</p>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-6 mb-4">
                                        <div className="field-box">
                                            <label className="form-label">Account Type</label>
                                            <select
                                                ref={(el) => (fieldRefs.current.accountType = el)}
                                                className={`form-control form-select ${formErrors.accountType ? "is-invalid" : ""}`}
                                                name="accountType"
                                                value={formData.accountType}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Type</option>
                                                <option value="Company Account">Company Account</option>
                                                <option value="Individual Account">Individual Account</option>
                                            </select>
                                            {formErrors.accountType && (
                                                <div className="invalid-feedback">{formErrors.accountType}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="field-box">
                                            <label className="form-label">Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                ref={(el) => (fieldRefs.current.name = el)}
                                                className={`form-control ${formErrors.name ? "is-invalid" : ""}`}
                                                value={formData.name}
                                                onChange={handleChange}
                                            />
                                            {formErrors.name && (
                                                <div className="invalid-feedback">{formErrors.name}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="field-box">
                                            <label className="form-label">Email Address</label>
                                            <input
                                                type="email"
                                                name="email"
                                                ref={(el) => (fieldRefs.current.email = el)}
                                                className={`form-control ${formErrors.email ? "is-invalid" : ""}`}
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                            {formErrors.email && (
                                                <div className="invalid-feedback">{formErrors.email}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="field-box">
                                            <label className="form-label">Phone Number</label>
                                            <input
                                                type="tel"
                                                name="phone"
                                                ref={(el) => (fieldRefs.current.phone = el)}
                                                className={`form-control ${formErrors.phone ? "is-invalid" : ""}`}
                                                value={formData.phone}
                                                onChange={handleChange}
                                                maxLength={10}
                                            />
                                            {formErrors.phone && (
                                                <div className="invalid-feedback">{formErrors.phone}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="field-box">
                                            <label className="form-label">Company Registration Number (Optional)</label>
                                            <input
                                                type="text"
                                                name="registrationNumber"
                                                className="form-control"
                                                value={formData.registrationNumber}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="field-box">
                                            <label className="form-label">Country of Operation</label>
                                            <input
                                                type="text"
                                                name="country"
                                                ref={(el) => (fieldRefs.current.country = el)}
                                                className={`form-control ${formErrors.country ? "is-invalid" : ""}`}
                                                value={formData.country}
                                                onChange={handleChange}
                                            />
                                            {formErrors.country && (
                                                <div className="invalid-feedback">{formErrors.country}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="field-box">
                                            <label className="form-label">Website (Optional)</label>
                                            <input
                                                type="text"
                                                name="website"
                                                className="form-control"
                                                value={formData.website}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="field-box">
                                            <label className="form-label">Preferred Trading Pairs</label>
                                            <select
                                                name="tradingPair"
                                                ref={(el) => (fieldRefs.current.tradingPair = el)}
                                                className={`form-control form-select ${formErrors.tradingPair ? "is-invalid" : ""}`}
                                                value={formData.tradingPair}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Pair</option>
                                                <option value="Bitcoin">Bitcoin</option>
                                                <option value="Ethereum">Ethereum</option>
                                                <option value="USDT">USDT</option>
                                            </select>
                                            {formErrors.tradingPair && (
                                                <div className="invalid-feedback">{formErrors.tradingPair}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <div className="field-box">
                                            <label className="form-label">
                                                Do you have experience providing liquidity on other exchanges?
                                                (Yes/No, if yes please provide name of exchange)
                                            </label>
                                            <input
                                                type="text"
                                                name="liquidityExperience"
                                                ref={(el) => (fieldRefs.current.liquidityExperience = el)}
                                                className={`form-control ${formErrors.liquidityExperience ? "is-invalid" : ""}`}
                                                value={formData.liquidityExperience}
                                                onChange={handleChange}
                                            />
                                            {formErrors.liquidityExperience && (
                                                <div className="invalid-feedback">{formErrors.liquidityExperience}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <div className="field-box">
                                            <label className="form-label">
                                                Have you been involved in any legal or regulatory disputes related to trading?
                                            </label>
                                            <select
                                                name="legalDisputes"
                                                ref={(el) => (fieldRefs.current.legalDisputes = el)}
                                                className={`form-control form-select ${formErrors.legalDisputes ? "is-invalid" : ""}`}
                                                value={formData.legalDisputes}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Option</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                            {formErrors.legalDisputes && (
                                                <div className="invalid-feedback">{formErrors.legalDisputes}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-check mb-4">
                                            <input
                                                ref={(el) => (fieldRefs.current.agreed = el)}
                                                className={`form-check-input cursor-pointer ${formErrors.agreed ? "is-invalid" : ""}`}
                                                type="checkbox"
                                                id="flexCheckDefault"
                                                name="agreed"
                                                checked={formData.agreed}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label " htmlFor="flexCheckDefault">
                                                <small>
                                                    By submitting this form, I agree to the terms and conditions of the Crypto India Market Maker
                                                    Program and will adhere to all regulatory and compliance requirements.
                                                </small>
                                            </label>

                                        </div>
                                    </div>
                                    {responseResult && (
                                        <div className="col-md-12">
                                            <div className="alert alert-danger">{responseResult}</div>
                                        </div>
                                    )}
                                    <div className="col-md-12 mt-2">
                                        <button className="btn w-auto px-5 btn-xl btn-warning" type="button" onClick={handleSubmit}>
                                            {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                : <span>Verify</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>

    )
}

export default MarketMakerProgram