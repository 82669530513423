import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import MenuItems from "../../../Context/MenuItems";
import { PieChart, Pie, Cell, Tooltip } from "recharts";

const BuysellSidebar = React.memo(({ qbsLimitsDetails }) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(location.pathname);
  const [activeMenu, setActiveMenu] = useState(false);
  const [menuName, setMenuName] = useState("Dashboard");
  const [menuIcon, setMenuIcon] = useState("ri-home-6-line");

  useEffect(() => {
    const currentMenu = MenuItems[location.pathname] || MenuItems["/"];
    setMenuName(currentMenu.name);
    setMenuIcon(currentMenu.icon);
  }, [qbsLimitsDetails]);


  const handleSetActive = path => {
    setIsActive(path);
  };

  const handleMenuToggle = () => {
    setActiveMenu(prevState => !prevState);
  };

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel =
    data =>
    ({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
      const total = data.reduce((sum, entry) => sum + entry.value, 0);
      const percent = total > 0 ? (value / total) * 100 : 0;

      if (percent < 1) return null;

      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
        >
          {`${percent.toFixed(1)}%`}
        </text>
      );
    };

  const limitsData = useMemo(
    () =>
      [
        {
          name: "Remaining Limit",
          value: qbsLimitsDetails?.remainingDailyBuyLimit,
        },

        {
          name: "Consumed",
          value:
            qbsLimitsDetails?.dailyBuyLimit -
            qbsLimitsDetails?.remainingDailyBuyLimit,
        },
        {
          name: "Remaining Cash Limit",
          value: qbsLimitsDetails?.remainingDailyCashLimit,
        },
      ].filter(item => item.value > 0),
    [qbsLimitsDetails]
  );

  const ordersData = useMemo(
    () =>
      [
        {
          name: "Remaining Orders",
          value: qbsLimitsDetails?.remainingDailyOrders,
        },
        {
          name: "Consumed Limit",
          value:
            qbsLimitsDetails?.dailyOrders -
            qbsLimitsDetails?.remainingDailyOrders,
        },
      ].filter(item => item.value > 0),
    [qbsLimitsDetails]
  );

  const COLORS = ["#1176d3", "#FFC107"];
  const ORDER_COLORS = ["#1176d3", "#FFC107"];

  return (
    <div className="sidebar bs_sidebar">
      <div className="sub_header d_sub_header">
        <div className="sub_header_row">
          <div className="sub_header_l">
            <span
              className="side_dropdown-item main_menu cursor-pointer"
              onClick={handleMenuToggle}
            >
              <span>
                <i className={menuIcon}></i> {menuName}
              </span>
            </span>

            <div className={`nsb_block ${activeMenu && "active"}`}>
              <div className="nsb_container">
                <div className="sdt_head d-lg-none">
                  <div className="ss_logo">
                    <img
                      className="img-fluid"
                      alt=""
                      src="/assets/images/logo.svg"
                    />
                  </div>
                  <span className="ss_close" onClick={handleMenuToggle}>
                    <i className="ri-close-large-fill fw-bold"></i>
                  </span>
                </div>
                <nav className="navbar-nav navbar side_nav" id="sub_nav">
                  <li className={`side_dropdown ${activeMenu && "active"}`}>
                    <Link
                      className={`side_dropdown-toggle nav-link ${
                        isActive === "/wallet" ? "active" : ""
                      }`}
                      to="/wallet"
                      onClick={() => handleSetActive("/wallet")}
                    >
                      <span>
                        <i className="ri-wallet-line"></i> Wallet
                      </span>
                    </Link>
                  </li>
                  <li className={`side_dropdown ${activeMenu && "active"}`}>
                    <Link
                      className={`side_dropdown-toggle nav-link ${
                        isActive === "/buy_sell_transactions" ? "active" : ""
                      }`}
                      to="/buy_sell_transactions"
                      onClick={() => handleSetActive("/buy_sell_transactions")}
                    >
                      <span>
                        <i className="ri-file-list-3-line"></i> History
                      </span>
                    </Link>
                  </li>
                  <li className={`side_dropdown ${activeMenu && "active"}`}>
                    <Link
                      className={`side_dropdown-toggle nav-link ${
                        isActive === "/payments" ? "active" : ""
                      }`}
                      to="/payments"
                      onClick={() => handleSetActive("/payments")}
                    >
                      <span>
                        <i className="ri-bank-card-line"></i> Payments
                      </span>
                    </Link>
                  </li>
                  <li className={`side_dropdown ${activeMenu && "active"}`}>
                    <Link
                      className={`side_dropdown-toggle nav-link ${
                        isActive === "/all_notificatios" ? "active" : ""
                      }`}
                      to="/all_notificatios"
                      onClick={() => handleSetActive("/all_notificatios")}
                    >
                      <span>
                        <i className="ri-notification-line"></i> Notifications
                      </span>
                    </Link>
                  </li>
                  <li className={`side_dropdown ${activeMenu && "active"}`}>
                    <Link
                      className={`side_dropdown-toggle nav-link ${
                        isActive === "/support" ? "active" : ""
                      }`}
                      to="/support"
                      onClick={() => handleSetActive("/support")}
                    >
                      <span>
                        <i className="ri-message-2-line"></i> Support
                      </span>
                    </Link>
                  </li>
                </nav>
                <div className="sd_other ">
                  <div className="ot_card">
                    <h6>Your Limits</h6>

                    <PieChart width={200} height={170}>
                      <Pie
                        data={limitsData}
                        cx="50%"
                        cy="50%"
                        innerRadius={40}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={0}
                        dataKey="value"
                        label={renderCustomizedLabel(limitsData)}
                        labelLine={false}
                      >
                        {limitsData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index]} />
                        ))}
                      </Pie>
                      {/*  <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fontWeight="bold">
                                            {qbsLimitsDetails?.dailyBuyLimit}
                                        </text> */}
                      <Tooltip />
                    </PieChart>
                    <div className="cc_data">
                      <div>
                        <em className="em_do bg-green"></em>
                        <span>
                          Total Buy Limit{" "}
                          <small>{qbsLimitsDetails?.dailyBuyLimit}</small>
                        </span>
                      </div>
                      <div>
                        <em className="em_do bg-primary"></em>
                        <span>
                          Remaining Limit{" "}
                          <small>
                            {qbsLimitsDetails?.remainingDailyBuyLimit}
                          </small>
                        </span>
                      </div>
                      <div>
                        <em className="em_do bg-warning"></em>
                        <span>
                          Consumed Limit{" "}
                          <small>
                            {qbsLimitsDetails?.dailyBuyLimit -
                              qbsLimitsDetails?.remainingDailyBuyLimit}
                          </small>
                        </span>
                      </div>
                      <div>
                        <em className="em_do bg-black"></em>
                        <span>
                          Remaining Cash Limit{" "}
                          <small>
                            {qbsLimitsDetails?.remainingDailyCashLimit}
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="ot_card">
                    <h6>Your Orders</h6>
                    <PieChart width={200} height={170}>
                      <Pie
                        data={ordersData}
                        cx="50%"
                        cy="50%"
                        innerRadius={40}
                        outerRadius={80}
                        fill="#82ca9d"
                        dataKey="value"
                        label={renderCustomizedLabel(ordersData)}
                        labelLine={false}
                        paddingAngle={0}
                      >
                        {ordersData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={ORDER_COLORS[index]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                    <div className="cc_data">
                      <div>
                        <em className="em_do bg-green"></em>
                        <span>
                          Total Orders{" "}
                          <small>{qbsLimitsDetails?.dailyOrders}</small>
                        </span>
                      </div>
                      <div>
                        <em className="em_do bg-primary"></em>
                        <span>
                          Remaining Orders{" "}
                          <small>
                            {qbsLimitsDetails?.remainingDailyOrders}
                          </small>
                        </span>
                      </div>
                      <div>
                        <em className="em_do bg-warning"></em>
                        <span>
                          Consumed Orders{" "}
                          <small>
                            {qbsLimitsDetails?.dailyOrders -
                              qbsLimitsDetails?.remainingDailyOrders}
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="ms-sidebar-backdrop d-lg-none"
                onClick={handleMenuToggle}
              >
                {" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default BuysellSidebar;
