import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Footer";
import AuthService from "../../../../../Services/ApiServices/AuthService";
import Swal from "sweetalert2";


const ProductFeedbackSuggestions = () => {


    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phoneNumber: "",
        description: "",
        attachment: null,
    });

    const [formErrors, setFormErrors] = useState({});
    const [responseResult, setResponseResult] = useState("");
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(false);
    const fieldRefs = useRef({});
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobileRegex = /^\d{10}$/;
    const [isActive, setIsActive] = useState(false);

    const handleInputChange = (e) => {
        const { name, type, files, value } = e.target;

        if (name === "phoneNumber" && !/^\d*$/.test(value)) {
            return;
        }

        setFormData({
            ...formData,
            [name]: type === "file" ? files[0] : value,
        });

        if (formErrors && name === getFirstInvalidField()) {
            setFormErrors("");
        }
        setFormErrors({});
    };

    const getFirstInvalidField = () => {
        if (!formData.fullName) return "fullName";
        if (!formData?.email || !emailRegex.test(formData.email)) return 'email';
        if (!formData?.phoneNumber || !mobileRegex.test(formData.phoneNumber)) return 'phoneNumber';
        if (!formData.description) return "description";
        if (!formData.attachment) return "attachment";
        return null;
    };


    const validateForm = (name, value) => {
        switch (name) {
            case "fullName":
                return value ? "" : "full Name is required.";
            case "email":
                return emailRegex.test(value) ? "" : "Enter Valid Email Address.";
            case "phoneNumber":
                return mobileRegex.test(value) ? "" : "Enter Valid 10-digit Phone Number is required.";
            case "description":
                return value ? "" : "description Type is required.";
            case "attachment":
                return value ? "" : "attachment is required.";
            default:
                return "";
        }
    };

    const handleSubmit = async () => {
        setResponseResult("")
        const firstInvalidField = getFirstInvalidField();
        if (firstInvalidField) {
            const error = validateForm(firstInvalidField, formData[firstInvalidField]);
            setFormErrors({ [firstInvalidField]: error });
            if (fieldRefs.current[firstInvalidField]) {
                fieldRefs.current[firstInvalidField].scrollIntoView({ behavior: "smooth", block: "center" });
                fieldRefs.current[firstInvalidField].focus();
            }
            return;
        }
        const formValue = new FormData();
        formValue.append("full_name", formData.fullName);
        formValue.append("email", formData.email);
        formValue.append("phone", formData.phoneNumber);
        formValue.append("description", formData.description);
        formValue.append("attachment", formData.attachment);
        setLoading(true);
        try {
            const result = await AuthService.productFeedback(formValue);
            if (result?.success) {
                setIsActive(true);
                setData(result?.data?.support_ticket_id);
                setLoading(false);
            } else {
                setLoading(false);
                setResponseResult(result || "An error occurred.");
            }
        } catch (error) {
            setLoading(false);
            setResponseResult(error?.message || "An unexpected error occurred.");
        }
    };

    const handleupper = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <>
            <main>
                <section className="tm_sec  features_sec feature_bg ">
                    <div className="container">
                        <div className="row   mt-md-5 justify-content-center">
                            <div className="col-lg-10">
                                <div className="section-title   text-center">
                                    <h2 className="text-warning  "> Product Feedback & Suggestions   </h2>

                                    <div className="ddd"  >
                                        <h4>
                                            We Value Your Feedback!
                                        </h4>
                                        <p>
                                            Thank you for choosing <b>Crypto India</b>. Your opinion matters to us, and we’d love to hear your feedback about our products and services. Please take a moment to let us know how we’re doing so we can continue to improve your experience.
                                        </p>
                                        <p>
                                            We take every piece of feedback seriously, and our team is committed to reviewing your suggestions. If your feedback highlights areas for improvement, we will strive to implement changes to enhance our products and services.
                                        </p>
                                        <p> Your input helps us continuously improve and provide you with the best experience possible at <b>Crypto India</b>.</p>
                                        <p><b className="text-warning" >Thank you for helping us grow!</b></p>
                                    </div>
                                </div>

                                <div className="g_start m_card overflow-hidden p-0 ">
                                    <div className={`p_card p-md-5 ${!isActive ? '' : "d-none"}`}>
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <div className="field-box">
                                                    <label for="text" className="form-label">Full Name</label>
                                                    <div className="field-otp-box">
                                                        <input
                                                            type="text"
                                                            ref={(el) => (fieldRefs.current.fullName = el)}
                                                            className={`form-control ${formErrors.fullName ? "is-invalid" : ""}`}
                                                            name="fullName"
                                                            value={formData.fullName}
                                                            onChange={handleInputChange}
                                                        />
                                                        {formErrors.fullName && (
                                                            <div className="invalid-feedback">{formErrors.fullName}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div className="field-box">
                                                    <label for="text" className="form-label">Email Address</label>
                                                    <div className="field-otp-box">
                                                        <input
                                                            type="email"
                                                            ref={(el) => (fieldRefs.current.email = el)}
                                                            className={`form-control ${formErrors.email ? "is-invalid" : ""}`}
                                                            name="email"
                                                            value={formData.email}
                                                            onChange={handleInputChange}
                                                        />
                                                        {formErrors.email && (
                                                            <div className="invalid-feedback">{formErrors.email}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div className="field-box">
                                                    <label for="text" className="form-label"> Mobile Number </label>
                                                    <div className="field-otp-box">
                                                        <input
                                                            type="tel"
                                                            ref={(el) => (fieldRefs.current.phoneNumber = el)}
                                                            className={`form-control ${formErrors.phoneNumber ? "is-invalid" : ""}`}
                                                            name="phoneNumber"
                                                            value={formData.phoneNumber}
                                                            onChange={handleInputChange}
                                                            maxLength={10}
                                                        />
                                                        {formErrors.phoneNumber && (
                                                            <div className="invalid-feedback">{formErrors.phoneNumber}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <label className="form-label mb-1">Description</label>
                                                <div className=" field-otp-box">
                                                    < input type="text" placeholder="Type message here" name="description"
                                                        ref={(el) => (fieldRefs.current.description = el)}
                                                        className={`form-control ${formErrors.description ? "is-invalid" : ""}`}
                                                        value={formData.description}
                                                        onChange={handleInputChange}
                                                    />
                                                    {formErrors.description && (
                                                        <div className="invalid-feedback">{formErrors.description}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div className="field-box">
                                                    <label for="text" className="form-label"> Attachments </label>
                                                    <div className="field-otp-box">
                                                        <input type="file" name="attachment"
                                                            ref={(el) => (fieldRefs.current.attachment = el)}
                                                            className={`form-control ${formErrors.attachment ? "is-invalid" : ""}`}
                                                            onChange={handleInputChange}
                                                        />
                                                        {formErrors.attachment && (
                                                            <div className="invalid-feedback">{formErrors.attachment}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {responseResult && (
                                                <div className="col-md-12">
                                                    <div className="alert alert-danger">
                                                        {responseResult}</div>
                                                </div>
                                            )}
                                            <div className="col-md-12 mt-2">
                                                <button className="btn w-auto btn-xl px-5 btn-dark" type="button" onClick={handleSubmit}>
                                                    {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        : <span>Submit</span>}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`container-fluid ${isActive ? '' : "d-none"}`}>
                                        <div className=" row gx-0 justify-content-center" >
                                            <div className="col-lg-8 col-xl-6" >
                                                <div className="thankyou_box">
                                                    <div className="ccc_form" >
                                                        <div className="  overflow-hidden p-0 text-center ">
                                                            <img src="assets/images/check.png" alt="" className="img-fluid mx-auto mb-4" />
                                                            <h3 className="text-success" > Thank You {formData?.fullName} </h3>
                                                            <p className="mb-2" > Your Feedback Submited Successfully. </p>
                                                            <Link className="btn btn-dark" to="/" onClick={handleupper}> Back to Home </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>

    )
}

export default ProductFeedbackSuggestions