import React, { useContext, useEffect, useState } from "react";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../../Services/ApiServices/AuthService";
import { ProfileContext } from "../../../../Services/ProfileProvider";
import SpinLoader from "../../../CoustamComponent/SpinLoader";

const AddNominee = () => {

    const navigate = useNavigate();
    const [isOpenModel, setIsOpenModel] = useState(false);
    const [responseResult, setResponseResult] = useState("");
    const [responseResultSuccess, setResponseResultSuccess] = useState("");
    const [fullNameError, setFullNameError] = useState("");
    const [relationError, setRelationError] = useState("");
    const [relationErrorInput, setRelationErrorInput] = useState("");
    const [aadhaarError, setAadhaarError] = useState("");
    const [dobError, setDobError] = useState("");
    const [otpError, setOtpError] = useState("");
    const { handleUserProfile } = useContext(ProfileContext);
    const [responseResultFinal, setResponseResultFinal] = useState("");
    const [loading, setLoading] = useState(false);


    const [formData, setFormData] = useState({
        fullName: '',
        relation: '',
        aadhar_number: '',
        relationName: '',
        date: '',
        otp: ''
    });


      useEffect(() => {
            handleUserProfile()      
        }, []);
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        if (name === 'aadhar_number') {
            formattedValue = formattedValue.replace(/\D/g, '').slice(0, 12);
        }

        setFormData(prevState => ({
            ...prevState,
            [name]: formattedValue
        }));

        setFullNameError("")
        setRelationError("")
        setRelationErrorInput("")
        setAadhaarError("")
        setDobError("")
        setOtpError("")
        setResponseResultFinal("")
    };


    const handleSubmit = async () => {
        const trimmedFormData = {
            fullName: formData.fullName,
            relation: formData.relation,
            relationName: formData.relation === "Other" ? formData.relationName : "",
            aadhar_number: formData.aadhar_number,
            date: formData.date,
        };

        const aadharNumberRegex = /^\d{12}$/;

        if (!trimmedFormData.fullName) {
            setFullNameError("Enter Full Name");
            return;
        }
        if (!trimmedFormData.relation) {
            setRelationError("Select Relation");
            return;
        }
        if (trimmedFormData.relation === "Other" && !trimmedFormData.relationName) {
            setRelationErrorInput("Enter Relation Name");
            return;
        }
        if (!trimmedFormData.aadhar_number) {
            setAadhaarError("Enter Aadhaar Number");
            return;
        }
        if (!aadharNumberRegex.test(trimmedFormData.aadhar_number)) {
            setAadhaarError("Invalid Aadhaar Number! It must be exactly 12 digits.");
            return;
        } if (!trimmedFormData.date) {
            setDobError("Enter Date of Birth");
            return;
        }
        setLoading(true);
        try {
            const result = await AuthService.getOtpNomini();
            if (result?.success) {
                setResponseResultSuccess(result.message);
                setIsOpenModel(true);
                setLoading(false);
            } else {
                setResponseResult(result || "Something went wrong");
                setLoading(false);
            }
        } catch (error) {
            setResponseResult("Error submitting form, please try again.");
            setLoading(false);
        }
    };



    const handleSubmitOtpForm = async () => {
        setResponseResult("");
        if (!formData.otp) {
            setOtpError("Enter Varification Code");
            return;
        } if (formData.otp?.length !== 6) {
            setOtpError("Enter Valid Varification Code");
            return;
        }
        try {
            const result = await AuthService.addNomini(formData);
            if (result?.success) {
                handleUserProfile();
                setIsOpenModel(false);
                navigate("/personal_information");
                setFormData({
                    fullName: '',
                    relation: '',
                    aadhar_number: '',
                    relationName: '',
                    date: '',
                    otp: ''
                });
            } else {
                setResponseResultFinal(result || "Something went wrong");
            }
        } catch (error) {
            setResponseResultFinal("Error submitting form, please try again.");
        }
    };

    const handleReset = () => {
        setIsOpenModel(false);
        setResponseResult("");
        setResponseResultFinal("");
        setFormData((prevData) => ({
            ...prevData,
            otp: "",
        }));
    }

    return (

        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper" >
                    <section className="d_page m_account  history_page">
                        <div className="container" >
                            <div className="row justify-content-center" >
                                <div className="col-xxl-8 col-xl-10" >
                                    <h6> Add Nominee   </h6>
                                    <div className="g_start " >
                                        <div className="row">
                                            <div className="col-md-12 mb-3 mb-md-4">
                                                <div className="field-box">
                                                    <label htmlFor="fullName" className="form-label">Full Name</label>
                                                    <input
                                                        type="text"
                                                        name="fullName"
                                                        className={`form-control ${fullNameError ? "is-invalid" : ""}`}
                                                        value={formData.fullName}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                {fullNameError && (
                                                    <div className="invalid-feedback">{fullNameError}</div>
                                                )}
                                            </div>
                                            <div className="col-md-12 mb-3 mb-md-4">
                                                <div className="field-box">
                                                    <label htmlFor="relation" className="form-label">Relation</label>
                                                    <select
                                                        name="relation"
                                                        className={`form-control ${relationError ? "is-invalid" : ""}`}
                                                        onChange={handleChange}
                                                        value={formData.relation}
                                                    >
                                                        <option value="" disabled>Select Relation</option>
                                                        <option value="Father">Father</option>
                                                        <option value="Mother">Mother</option>
                                                        <option value="Brother">Brother</option>
                                                        <option value="Sister">Sister</option>
                                                        <option value="Wife">Wife</option>
                                                        <option value="Son">Son</option>
                                                        <option value="Daughter">Daughter</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                {relationError && (
                                                    <div className="invalid-feedback">{relationError}</div>
                                                )}
                                            </div>

                                            {
                                                formData.relation === "Other" &&
                                                <div className="col-md-12 mb-3 mb-md-4">
                                                    <div className="field-box">
                                                        <label htmlFor="relationName" className="form-label">Relation Name</label>
                                                        <input
                                                            type="text"
                                                            name="relationName"
                                                            className={`form-control ${relationErrorInput ? "is-invalid" : ""}`}
                                                            value={formData.relationName}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    {relationErrorInput && (
                                                        <div className="invalid-feedback">{relationErrorInput}</div>
                                                    )}
                                                </div>
                                            }

                                            <div className="col-md-12 mb-3 mb-md-4">
                                                <div className="field-box">
                                                    <label htmlFor="aadhar_number" className="form-label">Aadhaar No. ID</label>
                                                    <input
                                                        type="text"
                                                        name="aadhar_number"
                                                        className={`form-control ${aadhaarError ? "is-invalid" : ""}`}
                                                        value={formData.aadhar_number}
                                                        onChange={handleChange}
                                                        maxLength="12"
                                                    />
                                                </div>
                                                {aadhaarError && (
                                                    <div className="invalid-feedback">{aadhaarError}</div>
                                                )}
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-12 mb-3 mb-md-4">
                                                <div className="field-box">
                                                    <label htmlFor="date" className="form-label">Date Of Birth</label>
                                                    <input
                                                        type="date"
                                                        name="date"
                                                        className={`form-control ${dobError ? "is-invalid" : ""}`}
                                                        value={formData.date}
                                                        onChange={handleChange}
                                                        max={new Date().toISOString().split("T")[0]}
                                                        onKeyDown={(e) => e.preventDefault()}
                                                    />
                                                </div>
                                                {dobError && (
                                                    <div className="invalid-feedback">{dobError}</div>
                                                )}

                                                {responseResult && (
                                                    <div className="invalid-feedback" >
                                                        {responseResult}
                                                    </div>
                                                )}

                                            </div>
                                            <div className="col-md-12 mb-3 mt-md-3 mt-2">
                                                <div className="field-box">
                                                    <button
                                                        className="btn custom-btn btn-block w-100 btn-xl"
                                                        type="button"
                                                        onClick={handleSubmit}
                                                    >
                                                        {loading ? <SpinLoader /> : <span>Submit</span>}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>


            {
                isOpenModel &&
                <div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog  modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title" id="modal_otp_Label"> Verification </h5>
                                    <button type="button" className="btn-close" onClick={handleReset}>
                                    </button>
                                </div>
                                <div className="modal-body p-md-4 ">
                                    <div className="row justify-content-center">

                                        {responseResultSuccess && (
                                            <div className="fl_card mb-4 mtm_error mt-0 fl_card_success" >
                                                <div className="fl_row gap-2" >
                                                    <i class="ri-error-warning-fill"></i>
                                                    <p className="mt-0">
                                                        {responseResultSuccess}
                                                    </p>
                                                </div>
                                            </div>
                                        )}

                                        <div className="col-lg-12  ">
                                            <label className="form-label" >Enter Verifaction Code</label>
                                            <div className=" field-otp-box">
                                                <input type="text" name="otp" id="otp" placeholder="Enter Verifaction Code...." value={formData.otp} onChange={handleChange} maxLength={6}
                                                    className={`form-control ${otpError ? "is-invalid" : ""}`}
                                                    onWheel={(e) => e.target.blur()}
                                                    onKeyDown={(e) => {
                                                        if (["+", "-", "e", "E"].includes(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/\D/g, "");
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {otpError && (
                                            <div className="invalid-feedback">{otpError}</div>
                                        )}
                                        {responseResultFinal && (
                                            <div className="invalid-feedback" >
                                                {responseResultFinal}
                                            </div>
                                        )}
                                        <div className="col-lg-12 text-center">
                                            <button type="button" className="btn custom-btn mt-3" onClick={handleSubmitOtpForm}>
                                                SUBMIT
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div>
            }
        </main>

    )
}

export default AddNominee