import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoaderHelper from "../../../Services/Loading/LoaderHelper";
import { alertErrorMessage, alertSuccessMessage } from "../../../Services/CustomAlertModel";
import AuthService from "../../../Services/ApiServices/AuthService";

const ForgotPassword = () => {

    const [showPass, setshowPass] = useState(false);
    const [showPass2, setshowPass2] = useState(false);
    const [forgotClicked, setForgotClicked] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [isOtpSentMob, setIsOtpSentMob] = useState(false);
    const [countdownMob, setCountdownMob] = useState(0);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isMobileValid, setIsMobileValid] = useState(false);

    const navigate = useNavigate();

    const [formData, setFormData] = useState(
        {
            emailId: "",
            email_otp: "",
            mobileNumber: "",
            mob_otp: "",
            password: "",
            confirmPassword: "",
            countryCode: "+91"
        }
    );

    const handleInput = (event) => {
        const { name, value } = event.target;
        if ((name === "email_otp" || name === "mob_otp") && value.length <= 6) {
            setFormData({ ...formData, [name]: value });
        }
        else if (name === "mobileNumber" && value.length <= 10) {
            setFormData({ ...formData, [name]: value });
            if (/^\d{10}$/.test(value)) {
                setIsMobileValid(true);
            } else {
                setIsMobileValid(false);
            }
        }
        else if (name === "emailId") {
            setFormData({ ...formData, [name]: value });
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            if (emailPattern.test(value)) {
                setIsEmailValid(true);
            } else {
                setIsEmailValid(false);
            }
        }
        else if (name !== "email_otp" && name !== "mob_otp" && name !== "mobileNumber" && name !== "emailId") {
            setFormData({ ...formData, [name]: value });
        }
    };


    const handleGetOtp = async (signid, type, inputType) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const mobileRegex = /^\d{10}$/;
        if (!signid && inputType === "email") {
            alertErrorMessage('Please Enter Email Address..!!');
            return;
        } if (!signid && inputType === "mobile") {
            alertErrorMessage('Please Enter Mobile Number..!!');
            return;
        } if (inputType === "email" && !emailRegex.test(signid)) {
            alertErrorMessage('Email is invalid');
            return;
        } if (inputType === "mobile" && !mobileRegex.test(signid)) {
            alertErrorMessage('Mobile number must be 10 digits');
            return;
        }
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.getOtp(signid, type);
            LoaderHelper.loaderStatus(false);
            result?.success ? alertSuccessMessage(result?.message) : alertErrorMessage(result);
            if (inputType === "email") {
                setIsOtpSent(true);
                setCountdown(60);
            } else {
                setIsOtpSentMob(true);
                setCountdownMob(60);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { emailId, email_otp, mobileNumber, mob_otp, password, confirmPassword } = formData;
        if (!emailId || !email_otp || !mobileNumber || !mob_otp || !password || !confirmPassword) {
            alertErrorMessage('All Fields are required.');
            return;
        } if (password !== confirmPassword) {
            setForgotClicked(true);
            return;
        }
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.forgotPassword(formData);
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(result.message);
                navigate("/login")
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };

    const handleCountryCodeChange = (selectedOption) => {
        if (selectedOption === null) {
            return;
        }
        const { value } = selectedOption;
        setFormData({ ...formData, countryCode: value });
    };



    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'black'
                : state.isFocused
                    ? 'gainsboro'
                    : 'white',
            color: state.isSelected
                ? 'white'
                : state.isFocused
                    ? 'dark'
                    : 'black',
            fontSize: '14px',
            borderRadius: '4px',
            margin: '0px',
        }),
    };

    useEffect(() => {
        let timer;
        if (isOtpSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, countdown]);

    useEffect(() => {
        let timer;
        if (isOtpSentMob && countdownMob > 0) {
            timer = setInterval(() => {
                setCountdownMob((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsOtpSentMob(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSentMob, countdownMob]);

    return (
        <main>
            <section className="section-padding login_bg login_sec">
                <div className="section-overlay"></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12 mx-auto">
                            <form className="custom-form ticket-form mb-5 mb-lg-0" onSubmit={handleSubmit}>
                                {/* box_shadow */}
                                <div border-radius="50% 200% 40% 80%" class="css-1ueqzyg">
                                    <div border-radius="50% 200% 40% 80%" class="css-1ueqzyg"></div>
                                </div>
                                <div border-radius="50% 200% 40% 80%" class="css-10gqqxt"></div>
                                {/* box shadow end */}
                                <div className="card-body">
                                    <div className="page_title">
                                        <h3 className="d-block text-center mb-5 text-warning letter_space"> Forgot Password ! </h3>
                                    </div>
                                    <div className="row gx-2 gy-4">
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <input type="email" name="emailId" id="emailId" className="form-control  mb-0" placeholder="Enter Email address" required autoComplete="emailId" value={formData.emailId} onChange={handleInput} />
                                        </div>
                                        {isEmailValid && (
                                            <div className="col-lg-12 col-md-12 col-12">
                                                <div className="field-otp-box">
                                                    <input type="number" name="email_otp" id="email_otp" className="form-control  mb-0" placeholder="Enter Email OTP" required value={formData.email_otp} onChange={handleInput}  onWheel={(e) => e.target.blur()}/>

                                                    <button type="button" className="btn btn-xs custom-btn" onClick={() => handleGetOtp(formData.emailId, 'forgot', 'email')} disabled={isOtpSent}>
                                                        <span>{isOtpSent ? `Resend in ${countdown}s` : 'GET OTP'}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        )}

                                        {/* <div className="col-lg-4 col-md-12 col-12"> */}
                                        {/* <input type="text" className="form-control mb-3" value="🇮🇳 India (+91)" /> */}

                                        {/* <Select
                                                name="countryCode"
                                                onChange={handleCountryCodeChange}
                                                options={countryOptions}
                                                value={countryOptions.find(option => option.value === formData.countryCode)}
                                                styles={customStyles}
                                            /> */}
                                        {/* </div> */}

                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="form-group  " >
                                                <div className="mnum_input" >
                                                    <span className="cn_code" >IN +91 <em>|</em> </span>
                                                    <input type="number" name="mobileNumber" id="mobileNumber" className="form-control mb-0" placeholder="Enter Mobile Number" required autoComplete="mobileNumber" value={formData.mobileNumber} onChange={handleInput}  onWheel={(e) => e.target.blur()}/>
                                                </div>
                                            </div>
                                        </div>

                                        {isMobileValid && (
                                            <div className="col-lg-12 col-md-12 col-12">
                                                <div className="field-otp-box">
                                                    <input type="number" name="mob_otp" id="mob_otp" className="form-control  mb-0" placeholder="Enter Mobile OTP" required value={formData.mob_otp} onChange={handleInput}  onWheel={(e) => e.target.blur()}/>
                                                    <button type="button" className="btn btn-xs custom-btn" onClick={() => handleGetOtp(formData.mobileNumber, 'forgot', 'mobile')} disabled={isOtpSentMob}>
                                                        <span>{isOtpSentMob ? `Resend in ${countdownMob}s` : 'GET OTP'}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        )}

                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className=" field-otp-box">
                                                <input type={showPass ? 'text' : "password"} name="password" id="password" className="form-control  mb-0" placeholder="Enter New Password" required value={formData.password} onChange={handleInput} />
                                                <button type="button" className="btn btn-icon btn_view_pass btn-white" onClick={() => { setshowPass(!showPass) }}>
                                                    <i className={`ri-eye${showPass ? "-line" : "-off-line"}`} />

                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="field-otp-box">
                                                <input type={showPass2 ? 'text' : "password"} name="confirmPassword" id="confirmPassword" className="form-control" placeholder="Corfirm password" required value={formData.confirmPassword} onChange={handleInput} />
                                                <button type="button" className="btn btn-icon btn_view_pass btn-white" onClick={() => { setshowPass2(!showPass2) }}>
                                                    <i className={`ri-eye${showPass2 ? "-line" : "-off-line"}`} />

                                                </button>
                                            </div>
                                        </div>

                                        {forgotClicked && formData.confirmPassword && formData.password !== formData.confirmPassword && <span className="text-danger mb-0 mt-0">Passwords does not match.</span>}
                                    </div>
                                    <div className="col-12 mx-auto  mt-1">
                                        <button type="submit" className="form-control">Submit</button>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-12 text-center mt-4">
                                        <p className="mb-0" >
                                            Back to Login <Link className="fw-bold" to="/login" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}> Login</Link>
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {/* <button
                type="button"
                className="btn btn-xs custom-btn"
                onClick={() => handleGetOtp(formData.emailId, 'forgot', 'email')}
                disabled={isDisabled}
            >
                {isDisabled ? `Wait ${countdown}s` : <span>GET OTP</span>}
            </button> */}
        </main>
    );
};

export default ForgotPassword;
