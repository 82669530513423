import AuthService from '../../Services/ApiServices/AuthService';
import { alertErrorMessage } from '../../Services/CustomAlertModel';

export const fetchWalletList = async (setCoinList, setSelectedPairs, walletType) => { 
  let filters = {};

  if (walletType) {
      filters.wallet_type = walletType;
  }
  const queryString = new URLSearchParams(filters).toString();
  try {
    const result = await AuthService.getWalletList(queryString);
    if (result?.success) {
      setCoinList(result?.data?.wallets);
      setSelectedPairs(result?.data?.wallets?.[0])
    } else {
      alertErrorMessage(result);
    }
  } catch (error) {
    alertErrorMessage(error);
  }
};





export const fetchCoinDetails = async (setCoinDetails, currencyId, setSelectedChain, setSelectedChainFees) => {
  try {
    const result = await AuthService.getCoinDetails(currencyId);
    if (result?.success) {
      setCoinDetails(result?.data);
      setSelectedChain(result?.data?.chain[0])
      setSelectedChainFees(result?.data?.quick_buy_sell)
    } else {
      alertErrorMessage(result);
    }
  } catch (error) {
    alertErrorMessage(error);
  }
};

export const fetchVerifyDeposit = async () => {
  await AuthService.verifyDeposit();
};


export const handlePayLimit = async (setPaylimitDetails) => {
  const result = await AuthService.getPaylimitDetails();
  if (result?.success) {
      setPaylimitDetails(result?.data);
  }
};
