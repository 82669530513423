import moment from "moment";
import { ApiConfig } from "../../ApiServices/AuthAction/ApiConfig";
import { ApiCallDelete, ApiCallGET, ApiCallPost, ApiCallPut } from "../../ApiServices/AuthAction/Apicall";

const AuthService = {

  register: async (formData) => {
    const { baseUser, register } = ApiConfig;
    const url = baseUser + register;
    const params = {
      email: formData.emailId?.toLowerCase(),
      eotp: formData.emailOtp,
      phone: formData.mobileNumber,
      potp: formData.mobileOtp,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
      countryCode: formData.countryCode,
      referral_code: formData.referral_code,

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  checkEmailPhone: async (signId, type) => {
    const { baseUser, check_attempt } = ApiConfig;
    const url = baseUser + check_attempt;
    const params = {
      email_or_phone: signId,
      type: type,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  checkEmailPhoneLogin: async (signId) => {
    const { baseUser, valid_type } = ApiConfig;
    const url = baseUser + valid_type;
    const params = {
      email_or_phone: signId,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  antityRegister: async (formData) => {
    const { baseUser, register } = ApiConfig;
    const url = baseUser + register;
    const params = {
      entity_name: formData.entityName,
      entity_type: formData.entityType,
      email: formData.emailId?.toLowerCase(),
      eotp: formData.emailOtp,
      phone: formData.mobileNumber,
      potp: formData.mobileOtp,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
      countryCode: formData.countryCode,
      referral_code: formData.referral_code,
      user_type: "Entity",

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getOtp: async (signId, type) => {
    const { baseUser, getOtp } = ApiConfig;
    const url = baseUser + getOtp;
    const params = {
      email_or_phone: signId?.toLowerCase(),
      type: type,
      "resend": true
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  login: async (formData) => {
    const { baseUser, signIn } = ApiConfig;
    const url = baseUser + signIn;
    const params = {
      email_or_phone: formData.signId?.toLowerCase(),
      password: formData.password,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  codeVerification: async (signId, verificationcode) => {
    const { baseUser, verify_otp } = ApiConfig;
    const url = baseUser + verify_otp;
    const params = {
      email_or_phone: signId,
      verification_code: +verificationcode
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  forgotPassword: async (formData) => {
    const { baseUser, forgotPassword } = ApiConfig;
    const url = baseUser + forgotPassword;
    const params = {
      email: formData.emailId,
      eotp: +formData.email_otp,
      phone: +formData.mobileNumber,
      potp: +formData.mob_otp,
      newPassword: formData.password,
      confirmPassword: formData.confirmPassword,
      countryCode: formData.countryCode,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  getDetails: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, userDetails } = ApiConfig;
    const url = baseUser + userDetails;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  addNomini: async (formData) => {
    console.log(formData, 'formData');

    const Authtoken = localStorage.getItem("token");
    const formattedDate = moment(formData.date).format("DD-MM-YYYY");
    const { baseUser, add_nominee } = ApiConfig;
    const url = baseUser + add_nominee;
    const params = {
      full_name: formData.fullName,
      relation: formData.relation === "Other" ? formData.relationName : formData.relation,
      adhaar_number: formData.aadhar_number,
      dob: formattedDate,
      otp: formData?.otp
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  getOtpNomini: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, sent_nominee_otp } = ApiConfig;
    const url = baseUser + sent_nominee_otp;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  addBankAccount: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, add_bank } = ApiConfig;
    const url = baseUser + add_bank;
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: Authtoken,
    };
    return ApiCallPost(url, formData, headers);
  },

  uploadBankDocument: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseUser, upload_bank_document } = ApiConfig;
    const url = baseUser + upload_bank_document;
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': token
    };
    return ApiCallPost(url, formData, headers);
  },

  // editBankAccount: async (formData, isDefault) => {
  //   const Authtoken = localStorage.getItem("token");
  //   const { baseUser, edit_bank } = ApiConfig;
  //   const url = `${baseUser}${edit_bank}/${formData?.userbankId}`;
  //   const params = {
  //     email: formData.emailId,
  //     verification_code: formData.emailOtp,
  //     account_holder_name: formData.accountHolderName,
  //     account_number: formData.reEnterAccountNumber,
  //     ifsc: formData.ifscCode,
  //     account_type: formData.accountType,
  //     bank_name: formData.bank_name,
  //     branch_name: formData.branch_name,
  //     is_default: isDefault,
  //   };
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: Authtoken,
  //   };
  //   return ApiCallPut(url, params, headers);
  // },

  updateDefaultBank: async (userId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser } = ApiConfig;
    const url = baseUser + `set-default/${userId}`;
    const params = {};
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  addUpiDetails: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    let formattedName = formData?.accountHolderName?.trim().replace(/\s+/g, ' ');
    const { baseUser, verify_Upi } = ApiConfig;
    const url = baseUser + verify_Upi;
    const params = {
      email: formData.emailId,
      otp: formData.emailOtp,
      feName: formattedName,
      vpaId: formData.upiId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  // editUpiDetails: async (formData) => {
  //   const Authtoken = localStorage.getItem("token");
  //   const { baseUser, edit_upi } = ApiConfig;
  //   const url = `${baseUser}${edit_upi}/${formData?.userUpiId}`;
  //   const params = {
  //     email: formData.emailId,
  //     verification_code: formData.emailOtp,
  //     upi_name: formData.userName,
  //     upi_vpa_id: formData.upiId,
  //   };
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: Authtoken,
  //   };
  //   return ApiCallPut(url, params, headers);
  // },

  updateDefaultUpi: async (userId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, default_upi } = ApiConfig;
    const url = baseUser + default_upi;
    const params = {
      id: userId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPut(url, params, headers);
  },

  deletetUpi: async (userId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, delete_upi } = ApiConfig;
    const url = `${baseUser}${delete_upi}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallDelete(url, headers);
  },

  deletetBank: async (userId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, delete_bank } = ApiConfig;
    const url = `${baseUser}${delete_bank}/${userId}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallDelete(url, headers);
  },

  updateProfile: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseUser, editProfile } = ApiConfig;
    const url = baseUser + editProfile;
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': token
    };
    return ApiCallPut(url, formData, headers);
  },

  changePassword: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseUser, change_password } = ApiConfig;
    const url = baseUser + change_password;
    const params = {
      email_or_phone: formData.signId,
      verification_code: formData.verificationCode,
      newPassword: formData.newPassword,
      confirmPassword: formData.confirmPassword,
      currentPassword: formData.currentPassword,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,

    };
    return ApiCallPost(url, params, headers);
  },

  updateCurrency: async (currencyType) => {
    const token = localStorage.getItem("token");
    const { baseUser, edit_currency } = ApiConfig;
    const url = baseUser + edit_currency;
    const params = {
      currency: currencyType,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  genrateGoogleQr: async () => {
    const token = localStorage.getItem("token");
    const { baseUser, genrate_google_qr } = ApiConfig;
    const url = baseUser + genrate_google_qr;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  buy_sell: async (payAmount, side, base_currency, quote_currency, base_currency_id, quote_currency_id, Id, checkButton) => {
    const Authtoken = localStorage.getItem("token");
    const { baseqbs, buySell } = ApiConfig;
    const url = baseqbs + buySell;
    const params = {
      pay_amount: +payAmount,
      side: side,
      base_currency: base_currency,
      quote_currency: quote_currency,
      base_currency_id: base_currency_id,
      quote_currency_id: quote_currency_id,
      ad_id: Id,
      use_available: checkButton,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  cancelBuySellOrder: async (id) => {
    const Authtoken = localStorage.getItem("token");
    const { baseqbs, cancel_qbs_order } = ApiConfig;
    const url = baseqbs + cancel_qbs_order + `/${id}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },


  remindQbsTimer: async (id) => {
    const Authtoken = localStorage.getItem("token");
    const { basePayment, remind_admin } = ApiConfig;
    const url = basePayment + remind_admin + `/${id}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },


  walletBalance: async (currencyName, walletType) => {
    const Authtoken = localStorage.getItem("token");

    const { baseWallet, walletby_coinName } = ApiConfig;
    const url = baseWallet + walletby_coinName + `?short_name=${currencyName}&wallet_type=${walletType}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  getWalletList: async (queryString) => {
    const Authtoken = localStorage.getItem("token");

    const { baseWallet, getWallet } = ApiConfig;
    const url = `${baseWallet}${getWallet}?${queryString}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },


  getCurrencyList: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseCoin, currency_list } = ApiConfig;
    const url = baseCoin + currency_list;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },


  submitKyc: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, identityKyc } = ApiConfig;
    const url = baseUser + identityKyc;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, formData, headers);
  },


  getCoinDetails: async (coinId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseCoin, coin_details } = ApiConfig;
    const url = baseCoin + coin_details + `?_id=${coinId}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': Authtoken
    };
    return ApiCallGET(url, headers);
  },

  favoriteList: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, favoriteList } = ApiConfig;
    const url = baseUser + favoriteList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  getPairs: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseCoin, getpairs } = ApiConfig;
    const url = baseCoin + getpairs;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  placeOrder: async (infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side) => {
    const Authtoken = localStorage.getItem("token");
    const { baseExchange, placeOrder } = ApiConfig;
    const url = baseExchange + placeOrder;
    const params = {
      "order_type": infoPlaceOrder,
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
      "side": side,
      "price": +buyprice,
      "quantity": +buyamount
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },


  pastOrder: async (base_currency_id, quote_currency_id) => {
    const Authtoken = localStorage.getItem("token");
    const { baseExchange, pastOrder } = ApiConfig;
    const url = baseExchange + pastOrder;
    const params = {
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  cancelOrder: async (orderId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseExchange, cancelOrder } = ApiConfig;
    const url = baseExchange + cancelOrder;
    const params = {
      "order_id": orderId
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  cryptoCompareApi: async (base, quote, to, resolution) => {
    const Authtoken = localStorage.getItem("token");
    const url = `https://min-api.cryptocompare.com/data/v2/${resolution}?fsym=${base}&tsym=${quote}&toTs=${to}&limit=2000`

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  getHistoricalData: async (base, quote, from, to) => {
    const Authtoken = localStorage.getItem("token");
    const { baseExchange, getHistoricalData } = ApiConfig;
    const url = baseExchange + getHistoricalData;
    const params = {
      base_currency: base,
      quote_currency: quote,
      from: from,
      to: to,
      limit: 2000,
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  updateFavoriteCoin: async (pairId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, favoriteCoin } = ApiConfig;
    const url = baseUser + favoriteCoin;
    const params = {
      "pair_id": pairId
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  generateDepositAddress: async (chain) => {
    const Authtoken = localStorage.getItem("token");
    const { baseAddress, depositAddress } = ApiConfig;
    const url = baseAddress + depositAddress;
    const params = {
      "chain": chain
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPut(url, params, headers);
  },

  buySellTranactionList: async (queryString) => {
    const Authtoken = localStorage.getItem("token");
    const { baseQbsUser, qbs_history } = ApiConfig;
    const url = `${baseQbsUser}${qbs_history}?${queryString}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },


  buySellTranactionListss: async (skip, limit, selectedTab, side, startDate, endDate, searchKey) => {
    const validStartDate = startDate || "";
    const validEndDate = endDate || "";
    const validSide = side || "";
    const valiStatus = selectedTab === "Pending" ? selectedTab + "," + 'Refund Initiated' : selectedTab || "";
    const searchKeyd = searchKey || "";
    const Authtoken = localStorage.getItem("token");
    const { baseqbs } = ApiConfig;
    const url = baseqbs + `user/qbs-history?skip=${skip}&limit=${limit}&status=${valiStatus}&side=${validSide}&start_date=${validStartDate}&end_date=${validEndDate}&search=${searchKeyd}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  spotList: async (skip, limit, startDate, endDate) => {
    const token = localStorage.getItem("token");
    const { baseExchange } = ApiConfig;
    const url = baseExchange + `trade-history?skip=${skip}&limit=${limit}&start_date=${startDate}&end_date=${endDate}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  openOrderList: async (skip, limit, startDate, endDate) => {
    const token = localStorage.getItem("token");
    const { baseExchange } = ApiConfig;
    const url = baseExchange + `open-orders?skip=${skip}&limit=${limit}&start_date=${startDate}&end_date=${endDate}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  depositDatawithdrawalData: async (skip, limit, startDate, endDate, type) => {
    const Authtoken = localStorage.getItem("token");
    const { baseWallet } = ApiConfig;
    const url = baseWallet + `user-deposit-withdrawal-history?type=${type}&skip=${skip}&limit=${limit}&start_date=${startDate}&end_date=${endDate}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  exportDataSpot: async (startDate, endDate) => {
    const token = localStorage.getItem("token");
    const { baseExchange } = ApiConfig;
    const url = baseExchange + `export-trade-history?start_date=${startDate}&end_date=${endDate}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  exportDataBuySell: async (startDate, endDate) => {
    const token = localStorage.getItem("token");
    const { baseqbs } = ApiConfig;
    const url = baseqbs + `export-quick-buy-sell-history?start_date=${startDate}&end_date=${endDate}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  exportDataDepositWithdrawal: async (startDate, endDate, status) => {
    const token = localStorage.getItem("token");
    const { baseWallet } = ApiConfig;
    const url = baseWallet + `export-user-deposit-withdrawal-history?start_date=${startDate}&end_date=${endDate}&status=${status}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  verifyDeposit: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseAddress, verify_deposit } = ApiConfig;
    const url = baseAddress + verify_deposit;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  cryptoWithdrawal: async (walletAddress, amountValue, otp, chainName, email, currency_id) => {
    const token = localStorage.getItem("token");
    const { baseWallet, crypto_ithdrawal } = ApiConfig;
    const url = baseWallet + crypto_ithdrawal;
    const params = {
      address: walletAddress,
      quantity: amountValue,
      verification_code: otp,
      chain: chainName,
      email_or_phone: email,
      currency_id: currency_id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,

    };
    return ApiCallPost(url, params, headers);
  },

  changeTwofact: async (sselectedOption, verificationCode, selectedAuth) => {
    const token = localStorage.getItem("token");
    const { baseUser, update2fa } = ApiConfig;
    const url = baseUser + update2fa;
    const params = {
      type: sselectedOption,
      verification_code: verificationCode,
      email_or_phone: selectedAuth
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  supportTicketList: async (skip, limit) => {
    const token = localStorage.getItem("token");
    const { baseUser } = ApiConfig;
    const url = baseUser + `user-ticket-list?skip=${skip}&limit=${limit}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGET(url, headers);
  },

  submitTicket: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseUser, update_ticket } = ApiConfig;
    const url = baseUser + update_ticket;
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': token
    };

    return ApiCallPost(url, formData, headers);
  },

  cancelAllOrder: async () => {
    const token = localStorage.getItem("token");
    const { baseExchange, cancel_all_order } = ApiConfig;
    const url = baseExchange + cancel_all_order;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallDelete(url, headers);
  },


  manageFeature: async (featureName, isEnabled) => {
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user_id");
    const { baseUser, update_permission } = ApiConfig;
    const url = `${baseUser}${update_permission}/${user_id}`;
    const params = {
      [featureName]: isEnabled
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallPut(url, params, headers);
  },

  deleteTicket: async (ticketId) => {
    const token = localStorage.getItem("token");
    const { baseUser, delete_ticket } = ApiConfig;
    const url = `${baseUser}${delete_ticket}/${ticketId}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallDelete(url, headers);
  },

  notificationList: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, notifications } = ApiConfig;
    const url = baseUser + notifications;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  accountActivities: async (skip, limit) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser } = ApiConfig;
    const url = baseUser + `activity-logs?skip=${skip}&limit=${limit}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  referList: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, user_referral_list } = ApiConfig;
    const url = baseUser + user_referral_list;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': Authtoken
    };
    return ApiCallGET(url, headers);
  },

  sendUserMessage: async (ticket_id, sendMessage) => {
    const token = localStorage.getItem("token");
    const { baseUser, ticket_user_reply } = ApiConfig;
    const url = baseUser + ticket_user_reply;
    const params = {
      ticket_id: ticket_id,
      message: sendMessage,
      message_by: 1,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,

    };
    return ApiCallPost(url, params, headers);
  },

  getMessageById: async (ticketId) => {
    const token = localStorage.getItem("token");
    const { baseUser, single_ticket } = ApiConfig;
    const url = `${baseUser}${single_ticket}/${ticketId}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };

    return ApiCallGET(url, headers);
  },


  getAdminBankDetails: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, admin_bank_details } = ApiConfig;
    const url = baseUser + admin_bank_details;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  getAdminUpiDetails: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, admin_upi_details } = ApiConfig;
    const url = baseUser + admin_upi_details;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  getPaymentOptions: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, payment_options } = ApiConfig;
    const url = baseUser + payment_options;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallGET(url, headers);
  },


  getQbsLimits: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseqbs, get_limits } = ApiConfig;
    const url = baseqbs + get_limits;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallGET(url, headers);
  },



  coinListDetails: async (bulkTrader) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser } = ApiConfig;
    const url = baseUser + `admin-qbs-list-details?bulk_trader=${bulkTrader}`;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallGET(url, headers);
  },


  addInr: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseqbs, utr_trans } = ApiConfig;
    const url = baseqbs + utr_trans;
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: Authtoken,
    };
    return ApiCallPost(url, formData, headers);
  },

  /* /******Persnol Details/ */


  getuserKycStep: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, fetch_kyc_step } = ApiConfig;
    const url = baseUser + fetch_kyc_step;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  updateKycStepStatus: async (step) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, update_kyc_step } = ApiConfig;
    const url = baseUser + update_kyc_step;
    const params = {
      step: step,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPut(url, params, headers);
  },







  verifyPancard: async (itemName) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, verify_pan } = ApiConfig;
    const url = baseUser + verify_pan;
    const params = {
      pan: itemName,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  getAmlCheckStatus: async (pan, name, dateOfBirth) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, user_aml_check } = ApiConfig;
    const url = baseUser + user_aml_check;
    const params = {
      idNumber: pan,
      name: name,
      dob: dateOfBirth,
      idType: "PAN",
      user_type: "user"
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },



  verifyAadharDocument: async (aadharNumber, referenceId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, generate_adhaar_otp } = ApiConfig;
    const url = baseUser + generate_adhaar_otp;
    const params = {
      source: aadharNumber,
      reference_id: String(referenceId)
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  getAadharDetails: async (aadharOtp, transaction_id, reference_id, panNumber, directorId, userType) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, adhaar_details } = ApiConfig;
    const url = baseUser + adhaar_details;
    const params = {
      otp: aadharOtp,
      reference_id: String(reference_id),
      transaction_id: String(transaction_id),
      pan: panNumber,
      directorId: directorId,
      user_type: userType
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  // verifyDrivingLicance: async (drivingNumber, dob) => {
  //   const Authtoken = localStorage.getItem("token");
  //   const { baseUser, dl_details } = ApiConfig;
  //   const url = baseUser + dl_details;
  //   const params = {
  //     dlNumber: drivingNumber,
  //     dob: dob
  //   };
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: Authtoken,
  //   };
  //   return ApiCallPost(url, params, headers);
  // },

  /*   verifyPassport: async (passportFileNumber, passportDob, passportIssueDate, passportNumber, passportUserName) => {
      const Authtoken = localStorage.getItem("token");
      const { baseUser, passport_details } = ApiConfig;
      const url = baseUser + passport_details;
      const params = {
        fileNo: passportFileNumber,
        dob: passportDob,
        doi: passportIssueDate,
        passportNo: passportNumber,
        name: passportUserName,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: Authtoken,
      };
      return ApiCallPost(url, params, headers);
    }, */

  livenessPicture: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, check_user_liveness } = ApiConfig;
    const url = baseUser + check_user_liveness;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, formData, headers);
  },

  getFaceMatchData: async (liveface_image, adhaar_image, livenessId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, user_face_match } = ApiConfig;
    const url = baseUser + user_face_match;
    const params = {
      imageUrl1: adhaar_image,
      imageUrl2: liveface_image,
      livenessId: livenessId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  submitEducationOccupation: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, other_kyc_details } = ApiConfig;
    const url = baseUser + other_kyc_details;
    const params = {
      education: formData?.edituaction,
      marital_status: formData?.maritalStatus,
      occupation: formData?.occupation,
      annual_income: +formData?.annualIncome,
      annual_turnover: +formData?.tradingTurnover,
      pep: formData?.politically,
      reason: formData?.remarkPep,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  submitOtherImages: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, upload_documents } = ApiConfig;
    const url = baseUser + upload_documents;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, formData, headers);
  },

  declaration: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, kyc_declaration } = ApiConfig;
    const url = baseUser + kyc_declaration;
    const params = {
      declaration_submitted: true
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  getKycDetails: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, kyc_details } = ApiConfig;
    const url = baseUser + kyc_details;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  /* /******Entity Details/ */

  getKycDetailsEntity: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseEntity, kyc_details } = ApiConfig;
    const url = baseEntity + kyc_details;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  getEntityPanDetails: async (itemName, name) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, entity_pan_details } = ApiConfig;
    const url = baseUser + entity_pan_details;
    const params = {
      user_type: "entity",
      pan: itemName,
      directorName: name
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  getAmlCheckStatusEntity: async (panNumber, name) => {
    const Authtoken = localStorage.getItem("token");
    const { baseEntity, entityAmlData } = ApiConfig;
    const url = baseEntity + entityAmlData;
    const params = {
      idNumber: panNumber,
      idType: panNumber,
      name: name,
      user_type: "entity",

    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  getEntityCinDetails: async (itemName) => {
    const Authtoken = localStorage.getItem("token");
    const { baseEntity, cinDetails } = ApiConfig;
    const url = baseEntity + cinDetails;
    const params = {
      cin: itemName,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  submitEntityDetails: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseEntity, entity_details } = ApiConfig;
    const url = baseEntity + entity_details;
    const params = {

      entity_name: formData?.entityName,
      entity_type: formData?.entityType,
      registered_country: formData?.countryName,
      registration_number: formData?.registrationNo,
      date: formData?.IncorporationDate,
      website: formData?.website,
      registered_address: formData?.registerdAddress,
      registered_address_city: formData?.registerdCity,
      registered_address_state: formData?.registerdState,
      registered_address_pincode: formData?.registerdPinCode,
      operational_address: formData?.opertationalAddress,
      operational_address_city: formData?.opertationalCity,
      operational_address_state: formData?.opertationalState,
      operational_address_pincode: formData?.opertationalPinCode,
      email_id: formData?.emailId,
      mobile_number: formData?.mobileNumber,
      purpose: formData?.purposeApplication,
      annual_turnover: formData?.annualTrunover,
      source_of_capital: formData?.sourceCapital,
      total_assets: +formData?.totalAssets,
      gst_number: formData?.gstNumber,
      fiu_number: formData?.fiuNumber,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  verifyDirectorPancard: async (itemName, directorId, Entity) => {
    const Authtoken = localStorage.getItem("token");
    const { baseEntity, verify_director_pan } = ApiConfig;
    const url = baseEntity + verify_director_pan;
    const params = {
      pan: itemName,
      director_id: directorId,
      user_type: Entity
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  verifyUboMore: async (ubos) => {
    const Authtoken = localStorage.getItem("token");
    const { baseEntity, saveUboData } = ApiConfig;
    const url = baseEntity + saveUboData;
    const params = { ubos };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  verifyMainTrader: async (director_id, panNumber) => {
    const Authtoken = localStorage.getItem("token");
    const { baseEntity, main_trader } = ApiConfig;
    const url = baseEntity + main_trader;
    const params = { director_id, panNumber };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  getEntityFaceMatchData: async (liveface_image, adhaar_image) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, user_face_match } = ApiConfig;
    const url = baseUser + user_face_match;
    const params = {
      imageUrl1: adhaar_image,
      imageUrl2: liveface_image,
      user_type: "Entity"
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },


  submitEntityDocument: async (formData, directorLength) => {
    const Authtoken = localStorage.getItem("token");
    const { baseEntity, verificationdocuments } = ApiConfig;
    const url = `${baseEntity}${verificationdocuments}?total_Directors=${directorLength}`;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, formData, headers);
  },

  getDownloadReport: async (fromDate, toDate) => {
    const Authtoken = localStorage.getItem("token");
    const { baseqbs, download_report } = ApiConfig;
    const url = baseqbs + download_report;
    const params = {
      fromDate: fromDate,
      toDate: toDate
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  getDownloadReportSpot: async (fromDate, toDate) => {
    const Authtoken = localStorage.getItem("token");
    const { baseExchange, download_report } = ApiConfig;
    const url = baseExchange + download_report;
    const params = {
      fromDate: fromDate,
      toDate: toDate
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },


  getAccountStatement: async (fromDate, toDate, walletType) => {
    const Authtoken = localStorage.getItem("token");
    const { baseWallet, getWalletDetails } = ApiConfig;
    const url = baseWallet + getWalletDetails;
    const params = {
      fromDate: fromDate,
      toDate: toDate,
      wallet_type: walletType,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  authDataSdk: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, hyperverge_sdklogin } = ApiConfig;
    const url = `${baseUser}${hyperverge_sdklogin}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': Authtoken
    };
    return ApiCallGET(url, headers);
  },


  getLinkKycResult: async () => {
    const transactionId = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");
    const { baseUser, hyperverge_liveness_result } = ApiConfig;
    const url = `${baseUser}${hyperverge_liveness_result}/${transactionId}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    return ApiCallGET(url, headers);
  },



  applyforJob: async (formData) => {
    const { baseUser, jobApplication } = ApiConfig;
    const url = baseUser + jobApplication;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);
  },


  getJobOpening: async () => {
    const { baseUser, job_openings } = ApiConfig;
    const url = baseUser + job_openings;
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallGET(url, headers);
  },

  getQbsFeesStracture: async () => {
    const { baseUser, qbs_fees } = ApiConfig;
    const url = baseUser + qbs_fees;
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallGET(url, headers);
  },

  getSpotFeesStracture: async () => {
    const { baseUser, spot_fees } = ApiConfig;
    const url = baseUser + spot_fees;
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallGET(url, headers);
  },

  makerApplication: async (formData) => {
    const { baseUser, market_maker } = ApiConfig;
    const url = baseUser + market_maker;
    const params = {
      account_type: formData.accountType,
      full_name: formData.name,
      email: formData.email,
      phone: formData.phone,
      company_reg_number: formData.registrationNumber,
      country_of_operation: formData.country,
      website: formData.website,
      currency_name: formData.tradingPair,
      previous_liquidity_exp: formData.liquidityExperience,
      involved_in_legal_disputes: formData.legalDisputes,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  coinListing: async (formData) => {
    const { baseUser, coin_list } = ApiConfig;
    const url = baseUser + coin_list;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);
  },

  partnershipProgram: async (formData) => {
    const { baseUser, partnership } = ApiConfig;
    const url = baseUser + partnership;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);
  },

  contactConnectTeam: async (formData) => {
    const { baseUser, support_ticket } = ApiConfig;
    const url = baseUser + support_ticket;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);
  },

  productFeedback: async (formData) => {
    const { baseUser, feedback } = ApiConfig;
    const url = baseUser + feedback;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);
  },

  raiseConcernDetails: async (ticket_id) => {
    const { baseUser, support_ticket_details } = ApiConfig;
    const url = `${baseUser}${support_ticket_details}/${ticket_id}`;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGET(url, headers);
  },

  raiseConcern: async (formData, ticket_id) => {
    const { baseUser, user_update_ticket } = ApiConfig;
    const url = `${baseUser}${user_update_ticket}/${ticket_id}`;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPut(url, formData, headers);
  },

  checkValidTicketId: async (ticket_id) => {
    const { baseUser, valid_ticket } = ApiConfig;
    const url = `${baseUser}${valid_ticket}/${ticket_id}`;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGET(url, headers);
  },

  getToeknDetails: async (tokenDetails) => {
    const { baseUser } = ApiConfig;
    const url = baseUser + `get-token-details?token=${tokenDetails}`;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGET(url, headers);
  },

  sendChangeEmailPhoneOtp: async (tokenDetails) => {
    const { baseUser } = ApiConfig;
    const url = baseUser + `email-change/send-otp?token=${tokenDetails}`;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGET(url, headers);
  },

  addEmailAddress: async (otp, tokenDetails) => {
    const { baseUser } = ApiConfig;
    const url = baseUser + `add-new-email?token=${tokenDetails}`;
    const params = { otp };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  addNewMobileNumber: async (otp, tokenDetails) => {
    const { baseUser } = ApiConfig;
    const url = baseUser + `add-new-phone?token=${tokenDetails}`;
    const params = { otp };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  getStepRejectedRemark: async (kycFormStep) => {
    const token = localStorage.getItem("token");
    const { baseUser, rejected_kyc_remarks } = ApiConfig;
    const url = `${baseUser}${rejected_kyc_remarks}/${kycFormStep}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    return ApiCallGET(url, headers);
  },

  getlatestPan: async () => {
    const token = localStorage.getItem("token");
    const { baseUser, latest_pan } = ApiConfig;
    const url = baseUser + latest_pan;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    return ApiCallGET(url, headers);
  },


  getCurrentKycStatus: async () => {
    const token = localStorage.getItem("token");
    const { baseUser, progress_bar } = ApiConfig;
    const url = baseUser + progress_bar;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    return ApiCallGET(url, headers);
  },

  refferResult: async (referCode) => {
    const token = localStorage.getItem("token");
    const { baseUser } = ApiConfig;
    const url = baseUser + `check-refer-code?referCode=${referCode}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    return ApiCallGET(url, headers);
  },

  getUpgradeuserStep: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, user_upgrade_step } = ApiConfig;
    const url = baseUser + user_upgrade_step;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  upgradeStepStatus: async (step) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser } = ApiConfig;
    const url = baseUser + `user-upgrade/update-progress/${step}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  submitUpgradeDocument: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, upload_upgrade_documents } = ApiConfig;
    const url = baseUser + upload_upgrade_documents;

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, formData, headers);
  },

  getUpgradeUserLimit: async (formData) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, user_upgrade_limit } = ApiConfig;
    const url = baseUser + user_upgrade_limit;
    const params = {
      total_account_withdrawal_limit: formData?.totalLimit,
      daily_desired_limit: formData?.dailyLimit,
      daily_qb_limit: formData?.qbLimit,
      reason: formData?.reason
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },


  upgradeDeclaration: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, user_upgrade_declaration } = ApiConfig;
    const url = baseUser + user_upgrade_declaration;
    const params = {
      declaration_status: true
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallPost(url, params, headers);
  },

  getUpgradeuserDetails: async (step) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser } = ApiConfig;
    const url = baseUser + `user-upgrade-details/${step}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },


  getRejectedRemarkUpgrade: async (step) => {
    const token = localStorage.getItem("token");
    const { baseUser } = ApiConfig;
    const url = baseUser + `user-upgrade-remark/${step}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token
    };
    return ApiCallGET(url, headers);
  },



  getRecieverDetails: async (signId) => {
    const token = localStorage.getItem("token");

    const { baseWallet, reciever_details } = ApiConfig;
    const url = baseWallet + reciever_details;
    const params = { email_or_phone: signId };
    const headers = {
      "Content-Type": "application/json",
      'Authorization': token

    };
    return ApiCallPost(url, params, headers);
  },

  quickyTranactionHistory: async (skip, limit, activeTab, startDate, endDate, searchKey) => {
    const validStatus = activeTab || "";
    const validStartDate = startDate || "";
    const validEndDate = endDate || "";
    const searchParams = searchKey || "";
    const Authtoken = localStorage.getItem("token");
    const { baseWallet } = ApiConfig;
    const url = baseWallet + `quick-pay-transactions?skip=${skip}&limit=${limit}&query=${validStatus}&start_date=${validStartDate}&end_date=${validEndDate}&searchParams=${searchParams}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  quickPayFeesDetails: async (amount, currency) => {
    const token = localStorage.getItem("token");
    const { baseWallet, quick_pay_fee } = ApiConfig;
    const url = baseWallet + quick_pay_fee;
    const params = {
      amount: +amount,
      currency: currency?.label,
      id: currency?.id,
    };
    const headers = {
      "Content-Type": "application/json",
      'Authorization': token

    };
    return ApiCallPost(url, params, headers);
  },

  getPaylimitDetails: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseWallet, quick_pay_limit } = ApiConfig;
    const url = baseWallet + quick_pay_limit;

    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  quickPayLimit: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseWallet, quick_pay } = ApiConfig;
    const url = baseWallet + quick_pay;
    const params = { remark: formData?.message, otp: formData?.otp };
    const headers = {
      "Content-Type": "application/json",
      'Authorization': token

    };
    return ApiCallPost(url, params, headers);
  },

  getLimitRangeDetails: async (currencyName) => {
    const token = localStorage.getItem("token");
    const { baseWallet, quick_pay_limit_range } = ApiConfig;
    const url = baseWallet + quick_pay_limit_range;
    const params = { currency: currencyName };
    const headers = {
      "Content-Type": "application/json",
      'Authorization': token

    };
    return ApiCallPost(url, params, headers);
  },

  sendquickpayotp: async () => {
    const Authtoken = localStorage.getItem("token");
    const { baseWallet, quick_pay_otp } = ApiConfig;
    const url = baseWallet + quick_pay_otp;
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },


  getlivenessHistory: async (userId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, liveness_history_user } = ApiConfig;
    const url = baseUser + liveness_history_user + `/${userId}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

  cash_eligibility: async (id, order_id, ad_id, ref_no, pay_amount, inr_deposit_amount, cashId) => {
    const token = localStorage.getItem("token");
    const { basePayment, cash_eligibility } = ApiConfig;
    const url = basePayment + cash_eligibility;
    const params = {
      qbsId: id,
      orderId: order_id,
      adId: ad_id,
      refNumber: ref_no,
      orderAmount: pay_amount,
      orderDepositAmount: inr_deposit_amount,
      paymentOptionId: cashId,
      cashSelected: true,
    };
    const headers = {
      "Content-Type": "application/json",
      'Authorization': token

    };
    return ApiCallPost(url, params, headers);
  },


  userFundTransfer: async (selectedFromWallet, selectedToWallet, amount, selectedWalletId) => {
    const token = localStorage.getItem("token");
    const { baseWallet, internal_fund_transfer } = ApiConfig;
    const url = baseWallet + internal_fund_transfer;
    const params = {
      from_wallet_type: selectedFromWallet,
      to_wallet_type: selectedToWallet,
      amount: amount,
      currency_id: selectedWalletId,
    };
    const headers = {
      "Content-Type": "application/json",
      'Authorization': token

    };
    return ApiCallPost(url, params, headers);
  },

  getWithdrawalLimit: async (userId) => {
    const Authtoken = localStorage.getItem("token");
    const { baseUser, get_user_withdrawal_limit } = ApiConfig;
    const url = baseUser + get_user_withdrawal_limit + `/${userId}`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: Authtoken,
    };
    return ApiCallGET(url, headers);
  },

};

export default AuthService;
