import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import { alertErrorMessage, alertSuccessMessage } from "../../../../Services/CustomAlertModel";
import AuthService from "../../../../Services/ApiServices/AuthService";
import { fetchCoinDetails, fetchWalletList } from "../../../../Context/Utility/apiutils";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import { ProfileContext } from "../../../../Services/ProfileProvider";
const WithdrawFunds = () => {


    const location = useLocation();
    const { state } = location;
    const [stateData, setStateData] = useState(state || null);


    const [isActive, setIsActive] = useState('step1')
    const [coinList, setCoinList] = useState([]);
    const [search, setsearch] = useState("");
    const [fiterPairData, setFiterPairData] = useState([]);
    const [coinDetails, setCoinDetails] = useState([]);
    const [coinFee, setCoinFee] = useState([]);
    const [adjustedAmount, setAdjustedAmount] = useState(null);
    const [amountValue, setAmountValue] = useState("")
    const [walletAddress, setWalletAddress] = useState("")
    const [otp, setOtp] = useState("");
    const [chainName, setChainName] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const { userDetails } = useContext(ProfileContext);
    const [activeModel, setActiveModel] = useState(false);
    const [withdrawAmount, setWithdrawAmount] = useState("")
    const [isActiveInr, setIsActiveInr] = useState('step1');
    const KycStatus = userDetails?.user?.kycVerified;

    const handleInputChange = (e) => {
        const value = parseFloat(e.target.value);
        if (isNaN(value)) {
            setAmountValue("");
            setAdjustedAmount(null);
        } else {
            setAmountValue(value);
            if (value >= coinFee?.min_withdrawal) {
                setAdjustedAmount(value - coinFee?.withdrawal_fee);
            } else {
                setAdjustedAmount(null);
            }
        }
    };
   

    const handleGetOtp = async (signId, type) => {
        try {
            const result = await AuthService.getOtp(signId, type);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                setIsOtpSent(true);
                setCountdown(60);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const handleWithdrawalDetails = (item, step) => {
        if (!userDetails?.permissions?.withdrawal) {
            alertErrorMessage('Disable withdrawal..');
            return;
        }
        if (item?.short_name === 'INR') {
            setIsActive('step3')
            setCoinDetails(item)
            fetchCoinDetails(setCoinFee, item?.currency_id)
        } else {
            setIsActive(step)
            setCoinDetails(item)
            fetchCoinDetails(setCoinFee, item?.currency_id)
        }
    }

    useEffect(() => {
        fetchWalletList(setCoinList);
    }, []);

    useEffect(() => {
        let filteredData = coinList?.filter((item) => {
            return (
                item?.short_name?.toLowerCase().includes(search?.toLowerCase())
            );
        });
        setFiterPairData(filteredData);
    }, [search, coinList]);


    const steponeWithdraw = () => {

        if (!chainName) {
            alertErrorMessage("please select a chain");
            return;
        } if (!walletAddress) {
            alertErrorMessage("please enter withdraw address");
            return;
        } if (!amountValue) {
            alertErrorMessage("please enter withdraw amount");
            return;
        } if (!isOtpSent) {
            alertErrorMessage("please send otp first");
            return;
        } if (!otp) {
            alertErrorMessage("please enter otp");
            return;
        }
        else {
            setActiveModel(true);
        }
    }

    useEffect(() => {
        let timer;
        if (isOtpSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, countdown]);

    const showAlert = (walletAddress, amountValue, otp) => {
        Swal.fire({
            title: "Are you sure?",
            text: `You are going to withdraw ${amountValue} ${coinFee?.short_name}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, withdraw it!"
        }).then((result) => {
            if (result.isConfirmed) {
                handleWithdrawal(walletAddress, amountValue, otp)
            }
        });
    };

    const handleWithdrawal = async (walletAddress, amountValue, otp) => {
        try {
            const result = await AuthService.cryptoWithdrawal(walletAddress, amountValue, otp, chainName, userDetails?.user?.email, coinDetails?.currency_id);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                setIsActive('step1')
                handleReset();
                setActiveModel(false);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setChainName(selectedValue);
    }

    const handleReset = () => {
        setWalletAddress("");
        setAmountValue("");
        setOtp("");
        setChainName("");
        setCountdown(0);
        setWithdrawAmount("");
    }


    const withdrawalInrProcess = (withdrawAmount, otp) => {
        if (!withdrawAmount || withdrawAmount < 1000 || withdrawAmount > coinDetails?.balance) {
            alertErrorMessage("Invalid withdrawal amount. Please enter an amount between 1000 and your available balance.");
            return;
        }
        if (!withdrawAmount) {
            alertErrorMessage("please enter withdraw amount");
            return;
        } if (!isOtpSent) {
            alertErrorMessage("please send otp first");
            return;
        } if (!otp) {
            alertErrorMessage("please enter otp");
            return;
        }
        setIsActiveInr('step2')
    }

    const withdrawalInr = (withdrawAmount, otp) => {
        alertErrorMessage('Something went wrong');
    }


    const handleOtpChange = (event) => {
        const value = event.target.value;
        if (value.length <= 6) {
            setOtp(value);
        }
    };


    const handleWithdrawalDetailsAuto = (item, step) => {
        if (userDetails?.permissions?.withdrawal) {
            alertErrorMessage('Disable withdrawal..');
            return;
        }
        if (item?.short_name === 'INR') {
            setIsActive('step3')
            setCoinDetails(item)
            fetchCoinDetails(setCoinFee, item?.currency_id)
        } else {
            setIsActive(step)
            setCoinDetails(item)
            fetchCoinDetails(setCoinFee, item?.currency_id)
        }
    }

    const handleBack = () => {
        setStateData(null);
        setIsActive('step1');
    };

    useEffect(() => {
        if (stateData) {
            handleWithdrawalDetailsAuto(stateData, "step2");
        }
    }, [stateData]);

    useEffect(() => {
        fetchWalletList(setCoinList);
    }, []);


    return (
        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper">
                    <section className="d_page history_page p_page deposit_wrapper">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xxl-10 col-xl-12">
                                    <div className="d_u_p align-items-center m-0">
                                        <div className="dup_left">
                                            <h6 className="mb-0"> Withdraw Funds </h6>
                                        </div>
                                        <div className="up_right text-end">
                                            <Link to="/transaction_history" className="btn btn-muted"> Recent Transactions </Link>
                                        </div>
                                    </div>
                                    <div className="g_start d_card mt-3">

                                        {/* Step 1  */}
                                        <div className={`step-1 ${isActive === 'step1' ? 'active' : 'd-none'}`}>
                                            <div className="nc_header">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6  col-lg-7">
                                                        <span className="nc_icon justify-content-start">
                                                            Select Coin
                                                        </span>
                                                    </div>
                                                    {coinList?.length > 0 ?
                                                        <div className="col-md-6 col-lg-5">
                                                            <div className="searchBar custom-tabs">
                                                                <i className="ri-search-2-line"></i>
                                                                <input type="search" className="custom_search w-100" placeholder="Search Crypto Coin" value={search} onChange={(e) => setsearch(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </div>
                                            <div className="nc_body scroll_y">
                                                <div className="ng_table">
                                                    <div className="container">
                                                        <div className="row  t_head_chain">
                                                            <div className="col-3 text-start">
                                                                <p>Coin Name</p>
                                                            </div>
                                                            <div className="col-3 text-center">
                                                                <p>Available Balance</p>
                                                            </div>
                                                            <div className="col-3 text-center">
                                                                <p>Locked Balance</p>
                                                            </div>
                                                            <div className="col-3 text-end">
                                                                <p>Total Balance</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="t_body_row">
                                                        {fiterPairData?.length > 0 ? (
                                                            fiterPairData?.map((item, index) => (
                                                                <span className="row align-items-center t_body_chain cursor_pointer" key={index} onClick={() => handleWithdrawalDetails(item, 'step2')}>
                                                                    <div className="col-3 text-start">
                                                                        <div className="td_div">
                                                                            <img src={item.icon_path} width="25" className="img-fluid icon_img coinimg_max me-2 " alt=""
                                                                                onError={(e) => {
                                                                                    e.target.onerror = null;
                                                                                    e.target.src = "/assets/images/icon/nocoin.png";
                                                                                }} />
                                                                            <b>{item.short_name}</b>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3 text-center">
                                                                        <p>{item.balance?.toFixed(5)}</p>
                                                                    </div>
                                                                    <div className="col-3 text-center">
                                                                        <p>{item.locked_balance?.toFixed(5)}</p>
                                                                    </div>
                                                                    <div className="col-3 text-end">
                                                                        <p>{(item.balance + item.locked_balance)?.toFixed(5)}</p>
                                                                    </div>
                                                                </span>
                                                            ))
                                                        ) : (
                                                            <div className="favouriteData">
                                                                <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Step 2 */}
                                        <div className={`step-3 ${isActive === 'step2' ? 'active' : 'd-none'}`}>
                                            <div className="nc_header">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6  col-lg-7">
                                                        <span className="nc_icon justify-content-start ">

                                                            <span className="btn-icon cursor_pointer" onClick={() => { setIsActive('step1'); handleReset(); handleBack() }}><i className="ri-arrow-left-s-line"></i></span>

                                                            Withdraw {coinDetails.short_name}
                                                            <img src={coinDetails.icon_path} width="25" className="img-fluid icon_img coinimg_max me-2 " alt=""
                                                                onError={(e) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = "/assets/images/icon/nocoin.png";
                                                                }} />
                                                        </span>
                                                    </div>
                                                    <div className="col-md-6 col-lg-5 text-end">
                                                        <p className="text_ifo">
                                                            <small className="text-muted d-block">Balance </small>
                                                            <small><b className="text-dark">{coinDetails?.balance?.toFixed(5)} {coinDetails?.short_name}</b></small>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="nc_body nc_qr">
                                                <div className="qr_body d-block">
                                                    <div className="form-group mb-4" >
                                                        <label>Select Chain</label>
                                                        {coinDetails?.chain?.length > 0 ? (
                                                            <select
                                                                className="form-select form-control width-50"
                                                                value={chainName}
                                                                onChange={handleSelectChange}
                                                            >
                                                                <option>
                                                                    Select a network
                                                                </option>
                                                                {coinDetails.chain.map((item, index) => (
                                                                    <option key={index} value={item} className="form-control">
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            null
                                                        )}
                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <label> Address</label>
                                                        <input className="form-control" type="text" name="wallet_Add" value={walletAddress} placeholder="Wallet Address" onChange={(e) => setWalletAddress(e.target.value)} />
                                                    </div>
                                                    <div className="form-group  col-lg-12 col-md-12 col-12 mb-4">
                                                        <label> Enter Amount </label>
                                                        <div className=" field-otp-box">
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                onWheelCapture={e => e.target.blur()}
                                                                name="amount_val"
                                                                value={amountValue}
                                                                placeholder="Amount"
                                                                onChange={handleInputChange}
                                                                onBlur={(e) => {
                                                                    const value = parseFloat(e.target.value);
                                                                    if (value > parseFloat(coinDetails?.balance)) {
                                                                        setAmountValue(coinDetails?.balance);
                                                                        if (value >= coinFee?.min_withdrawal) {
                                                                            setAdjustedAmount(coinDetails?.balance - coinFee?.withdrawal_fee);
                                                                        } else {
                                                                            setAdjustedAmount(null);
                                                                        }
                                                                    }
                                                                }}
                                                                max={coinDetails?.balance}
                                                            />
                                                            <button type="button" className="btn btn-xs btn-dark" onClick={() => setAmountValue(coinDetails?.balance)}>MAX</button>
                                                        </div>
                                                    </div>
                                                    {KycStatus === 2 &&
                                                        <div className=" form-group  col-lg-12 col-md-12 col-12 mb-4">
                                                            <label> Verification Code </label>
                                                            <div className=" field-otp-box">
                                                                <input type="number" name="form-otp" id="form-otp" className="form-control" placeholder="Enter Verification Code" value={otp} onChange={handleOtpChange} />

                                                                <button type="button" className="btn btn-xs custom-btn" onClick={() => handleGetOtp(userDetails?.user?.email, 'withdrawal')} disabled={isOtpSent || !chainName || !walletAddress || !amountValue}>
                                                                    <span>{isOtpSent ? `Resend OTP in ${countdown}s` : 'GET OTP'}</span>
                                                                </button>

                                                            </div>
                                                        </div>
                                                    }

                                                    {(amountValue || amountValue === 0) &&
                                                        (amountValue < coinFee?.min_withdrawal ? (
                                                            <span className="mb-2 d-block text-center text-danger">
                                                                <small>Minimum withdrawal amount should be {coinFee?.min_withdrawal}</small>
                                                            </span>
                                                        ) : (
                                                            amountValue > coinDetails?.balance && (
                                                                <span className="mb-2 d-block text-center text-danger">
                                                                    <small>Insufficient Available balance</small>
                                                                </span>
                                                            )
                                                        ))
                                                    }
                                                    <div className="form-group">
                                                        <button
                                                            type="button"
                                                            className="btn custom-btn btn-block w-100 btn-xl"
                                                            onClick={KycStatus === 2 ? steponeWithdraw : undefined}
                                                            disabled={KycStatus !== 2 || amountValue < coinFee?.min_withdrawal}
                                                        >
                                                            <span>{KycStatus === 2 ? "Withdraw" : "KYC Pending"}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/* Step INR */}
                                        <div className={`step-4 ${isActive === 'step3' ? 'active' : 'd-none'}`}>
                                            <div className="nc_header">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6  col-lg-7">
                                                        <span className="nc_icon justify-content-start ">
                                                            <span className="btn-icon cursor_pointer" onClick={() => { setIsActive('step1'); handleReset(); setIsActiveInr('step1'); handleBack() }}><i className="ri-arrow-left-s-line"></i></span>
                                                            INR
                                                        </span>
                                                    </div>
                                                    <div className="col-md-6 col-lg-5 text-end">
                                                        <p className="text_ifo">
                                                            <small className="text-muted d-block">Balance </small>
                                                            <small><b className="text-dark">
                                                                {coinDetails?.balance?.toFixed(5)} {coinDetails?.short_name}
                                                            </b></small>
                                                        </p>
                                                    </div>

                                                    <div className="ng_table">

                                                        {/* step 2 */}
                                                        <div className={`t_body_row modal_form p_card mt-3 ${isActiveInr === 'step1' ? '' : 'd-none'}`}>
                                                            <div className="row">
                                                                <div className="col-12 text-center" >
                                                                    <h6> Withdraw Funds </h6>
                                                                </div>
                                                                <div className="col-12 form-group ">
                                                                    <label className="text-white mb-1"> Amount </label>
                                                                    <input type="number" className="form-control" placeholder="Enter Amount" value={withdrawAmount} onChange={(e) => setWithdrawAmount(e.target.value)} />

                                                                </div>
                                                                <div className="balance-libra card-success mb-3">
                                                                    <div className="token-img-bg_right" id="lc-data"> Min. Withdraw:- <b> 1,000 INR </b></div>
                                                                </div>
                                                                {
                                                                    withdrawAmount && withdrawAmount >= 1000 &&

                                                                    <div className=" form-group  col-12 col-md-12 col-12 mb-4">
                                                                        <label> Verification Code </label>
                                                                        <div className=" field-otp-box">
                                                                            <input type="number" name="form-otp" id="form-otp" className="form-control" placeholder="Enter Verification Code" value={otp} onChange={handleOtpChange} />

                                                                            <button type="button" className="btn btn-sm btn-dark" onClick={() => handleGetOtp(userDetails?.user?.email, 'withdrawal')} disabled={isOtpSent}>
                                                                                <span>{isOtpSent ? `Resend OTP in ${countdown}s` : 'GET OTP'}</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="col-12 form-group">
                                                                    {/* bank account */}
                                                                    {userDetails?.bank_details ? userDetails?.bank_details
                                                                        ?.filter(item => item.is_default === true)?.map((item, index) => {
                                                                            return (
                                                                                <div className="b_card" key={index}>
                                                                                    <div className="b_row" >
                                                                                        <div className="b_icon" >
                                                                                            <i className="ri-bank-line"></i>
                                                                                        </div>
                                                                                        <div className="b-content" >
                                                                                            <h6> {item?.bank_name} </h6>
                                                                                            <p className="mb-0" >{item?.account_number}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Link to="/payments" className="btn btn-sm btn-dark" > Change  </Link>
                                                                                </div>
                                                                            )
                                                                        }) : null}

                                                                    {/* UPI Id   */}

                                                                    {userDetails?.upi_details ? userDetails?.upi_details
                                                                        ?.filter(item => item.is_default === true)?.map((item, index) => {
                                                                            return (
                                                                                <div className="b_card" key={index}>
                                                                                    <div className="b_row" >
                                                                                        <div className="b_icon" >
                                                                                            <i className="ri-qr-code-line"></i>
                                                                                        </div>
                                                                                        <div className="b-content" >
                                                                                            <h6>{item?.upi_name}</h6>
                                                                                            <p className="mb-0" > {item?.upi_vpa_id} </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Link to="/payments" className="btn btn-sm btn-dark" > Change  </Link>
                                                                                </div>
                                                                            )
                                                                        }) : null}
                                                                </div>
                                                                <div className="col-md-12" >

                                                                </div>
                                                                {
                                                                    withdrawAmount && withdrawAmount < 1000 &&
                                                                    <span className="mt-3" style={{ color: 'red' }}>Min. Withdraw 1,000 INR</span>
                                                                }
                                                                {
                                                                    withdrawAmount && withdrawAmount > coinDetails?.balance &&
                                                                    <span className="mt-3" style={{ color: 'red' }}>Insufficient Balance</span>
                                                                }
                                                                <div className="col-12" >
                                                                    <button className="btn custom-btn mt-3 w-100 btn-block p-3" onClick={() => withdrawalInrProcess(withdrawAmount, otp)} > Process </button>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* step 3 */}
                                                        <div className={`t_body_row modal_form p_card mt-3 ${isActiveInr === 'step2' ? '' : 'd-none'}`}>
                                                            <div className="row">
                                                                <div className="col-12 text-center" >
                                                                    <h6> Order Details </h6>
                                                                </div>
                                                                <div className="col-12 ">
                                                                    <div className="tt_data ">
                                                                        <div className="tt_card">
                                                                            <div className="tt_item no-border">
                                                                                <span className="text-muted"> Order No : </span>
                                                                                <span className="tt_normal">5265123156</span>
                                                                            </div>
                                                                            <div className="tt_item">
                                                                                <span className="text-muted"> Amount </span>
                                                                                <span className="tt_normal">{withdrawAmount} ₹</span>
                                                                            </div>
                                                                            <div className="tt_item">
                                                                                <span className="text-muted"> Fee </span>
                                                                                <span className="tt_normal">10 ₹</span>
                                                                            </div>
                                                                            <div className="tt_item no-border">
                                                                                <span className="text-muted"> Total :</span>
                                                                                <span className="tt_normal">{withdrawAmount - 10} ₹</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12" >
                                                                    <button className="btn custom-btn mt-3 w-100 btn-block p-3" onClick={() => withdrawalInr(withdrawAmount, otp)}> Withdraw INR </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            {/* withdraw funds details */}
            {
                activeModel &&
                <div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-md ">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title  " id="modal_otp_Label"> Withdraw Bitcoin </h5>
                                    <button type="button" className="btn-close" onClick={() => setActiveModel(false)}></button>
                                </div>
                                <div className="modal-body p-md-4 ">
                                    <div className="tt_data ">
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Withdrawal Address: </span>
                                            <span className="tt_normal">{walletAddress}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Withdrawal fee: </span>
                                            <span className="tt_normal">{coinFee?.withdrawal_fee} {coinFee?.short_name}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Contact fee:</span>
                                            <span className="tt_normal"> 0.00 {coinFee?.short_name}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted">Min. withdrawal amount: </span>
                                            <span className="tt_normal">{coinFee?.min_withdrawal} {coinFee?.short_name}</span>
                                        </div>
                                        <div className="tt_item">
                                            <span className="text-muted pb-2"> You will get: </span>
                                            <span className="tt_normal h6"> <b>{adjustedAmount || 0} {coinFee?.short_name}</b> </span>
                                        </div>
                                        <div className="tt_item no-border pt-2">
                                            <span className="tt_normal"> Available balance: </span>
                                            <span className="tt_normal">{coinDetails?.balance?.toFixed(5)} {coinDetails?.short_name}</span>
                                        </div>
                                        <button className="btn custom-btn mt-3 w-100 btn-block" onClick={() => showAlert(walletAddress, amountValue, otp)}> Withdraw </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div>
            }
        </main>

    )
}

export default WithdrawFunds