import React, { useContext, useEffect, useRef, useState } from "react";
import Select from 'react-select';
import DashboardSidebar from "../../../../CoustamComponent/DashboardSidebar";
import { ProfileContext } from "../../../../../Services/ProfileProvider";
import { alertErrorMessage, alertSuccessMessage } from "../../../../../Services/CustomAlertModel";
import { countries } from "countries-list";
import AuthService from "../../../../../Services/ApiServices/AuthService";
import Swal from "sweetalert2";
import Webcam from "react-webcam";
import { genratePdfLoa } from "../../../../../Context/Utility/genratePdfLoa";
import { SanctionsPdf } from "../../../../../Context/Utility/SanctionsPdf";
import { cryptAssetsPdf } from "../../../../../Context/Utility/cryptAssetsPdf";

const EntityKyc = () => {

    const { userDetails, handleUserProfile } = useContext(ProfileContext);
    const [sameAddress, setSameAddress] = useState(false);
    const [isPanValid, setIsPanValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [entityKycDetail, setEntityKycDetail] = useState([]);
    const [isAadharValid, setIsAadharValid] = useState(false);
    const [isLoadingPan, setIsLoadingPan] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [kycFormStep, setKycFormStep] = useState('');
    const [directors, setDirectors] = useState([{ panNumber: '', aadharNumber: '', aadharOtp: '' }]);
    const [uboPercentages, setUboPercentages] = useState([]);
    const [allFieldsFilled, setAllFieldsFilled] = useState(false);
    const [coiFile, setCoiFile] = useState(null);
    const [moaFile, setMoaFile] = useState(null);
    const [aoaFile, setAoaFile] = useState(null);
    const [bankStatementFile, setBankStatementFile] = useState(null);
    const [surveyCapitalFile, setSurveyCapitalFile] = useState(null);
    const [amlCftFile, setAmlCftFile] = useState(null);
    const [loaFile, setLoaFile] = useState(null);
    const [sanctionsFile, setSanctionsFile] = useState(null);
    const [inrAssetsFile, setInrAssetsFile] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [aadharOtpDetails, setAadharOtpDetails] = useState("");

    const [checkButton, setCheckButton] = useState(false);
    const [userKycStatus, setUserKycStatus] = useState("");
    const [isActivePrevieModel, setIsActivePrevieModel] = useState(false);
    const [activeSection, setActiveSection] = useState('pan');
    const [selectedImages, setSelectedImages] = useState([]);
    const [responseResult, setResponseResult] = useState("");
    const [isActiveAadharOtpField, setIsActiveAadharOtpField] = useState(false);
    const [livenessResult, setLivenessResult] = useState('');
    const [loadingLiveness, setLoadingLiveness] = useState(false);
    const [loading, setLoading] = useState(false);

    const transactionId = localStorage.getItem("user_id");

    const [directorImages, setDirectorImages] = useState(
        entityKycDetail?.aadharDetails?.map(() => ({
            aadharFront: null,
            aadharBack: null,
            panCard: null,
        })) || []
    );


    const formatDate = (isoDate) => {
        if (!isoDate) return '';
        const date = new Date(isoDate);
        return date.toISOString().split('T')[0];
    };

    const [formData, setFormData] = useState({
        panNumber: entityKycDetail?.entityPan?.pan || '',
        entityName: entityKycDetail?.entityPan?.name || '',
        entityType: userDetails?.user?.entity_type || '',
        countryName: entityKycDetail?.entityRecord?.registered_country || 'India',
        registrationNo: entityKycDetail?.entityRecord?.registration_number || '',
        IncorporationDate: entityKycDetail?.entityPan?.dateOfBirth || '',
        website: entityKycDetail?.entityRecord?.website || '',
        registerdAddress: entityKycDetail?.entityRecord?.registered_address || '',
        registerdCity: entityKycDetail?.entityRecord?.registered_address_city || entityKycDetail?.entityPan?.address?.city || '',
        registerdState: entityKycDetail?.entityRecord?.registered_address_state || '',
        registerdPinCode: entityKycDetail?.entityRecord?.registered_address_pincode || '',
        opertationalAddress: entityKycDetail?.entityRecord?.operational_address || '',
        opertationalCity: entityKycDetail?.entityRecord?.operational_address_city || '',
        opertationalState: entityKycDetail?.entityRecord?.operational_address_state || '',
        opertationalPinCode: entityKycDetail?.entityRecord?.operational_address_pincode || '',
        emailId: userDetails?.user?.email || '',
        mobileNumber: userDetails?.user?.phone || '',
        purposeApplication: entityKycDetail?.entityRecord?.purpose || '',
        annualTrunover: entityKycDetail?.entityRecord?.annual_turnover || '',
        sourceCapital: entityKycDetail?.entityRecord?.source_of_capital || '',
        totalAssets: entityKycDetail?.entityRecord?.total_assets || '',
        gstNumber: entityKycDetail?.entityRecord?.gst_number || '',
        fiuNumber: entityKycDetail?.entityRecord?.fiu_number || '',
        uboPercentage: "",
        directors: [],
        selectedDirector: null,
    });

    useEffect(() => {
        if (userDetails) {
            setFormData(prevState => ({
                ...prevState,
                panNumber: entityKycDetail?.entityPan?.pan || '',
                entityName: entityKycDetail?.entityPan?.name || '',
                entityType: userDetails?.user?.entity_type || '',
                countryName: entityKycDetail?.entityRecord?.registered_country || 'India',
                registrationNo: entityKycDetail?.entityRecord?.registration_number || '',
                IncorporationDate: formatDate(entityKycDetail?.entityPan?.dateOfBirth) || '',
                website: entityKycDetail?.entityRecord?.website || '',
                registerdAddress: entityKycDetail?.entityRecord?.registered_address || '',
                registerdCity: entityKycDetail?.entityRecord?.registered_address_city || entityKycDetail?.entityPan?.address?.city || '',
                registerdState: entityKycDetail?.entityRecord?.registered_address_state || '',
                registerdPinCode: entityKycDetail?.entityRecord?.registered_address_pincode || '',
                opertationalAddress: entityKycDetail?.entityRecord?.operational_address || '',
                opertationalCity: entityKycDetail?.entityRecord?.operational_address_city || '',
                opertationalState: entityKycDetail?.entityRecord?.operational_address_state || '',
                opertationalPinCode: entityKycDetail?.entityRecord?.operational_address_pincode || '',
                purposeApplication: entityKycDetail?.entityRecord?.purpose || '',
                sourceCapital: entityKycDetail?.entityRecord?.source_of_capital || '',
                annualTrunover: entityKycDetail?.entityRecord?.annual_turnover || '',
                totalAssets: entityKycDetail?.entityRecord?.total_assets || '',
                gstNumber: entityKycDetail?.entityRecord?.gst_number || '',
                fiuNumber: entityKycDetail?.entityRecord?.fiu_number || '',
                emailId: userDetails?.user?.email || '',
                mobileNumber: userDetails?.user?.phone || '',
            }));
            setUserKycStatus(userDetails?.user?.kycVerified);
        }
    }, [userDetails, entityKycDetail]);

    useEffect(() => {
        let timer;
        if (isOtpSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, countdown]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;
        if (name === 'panNumber') {
            formattedValue = formattedValue.toUpperCase().replace(/\s/g, '');
            formattedValue = formattedValue.replace(/(.{5})(.{4})(.{1})/, '$1$2$3');
            const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
            setIsPanValid(panRegex.test(formattedValue));
        }
        setFormData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
            ...(sameAddress && name.startsWith('registerd') && {
                [`opertational${name.substring(9)}`]: formattedValue,
            }),
        }));
        setResponseResult("");
    };

    const handleSameAddressChange = () => {
        setSameAddress((prev) => {
            const newState = !prev;
            if (newState) {
                setFormData((prevState) => ({
                    ...prevState,
                    opertationalAddress: prevState.registerdAddress,
                    opertationalCity: prevState.registerdCity,
                    opertationalState: prevState.registerdState,
                    opertationalPinCode: prevState.registerdPinCode,
                }));
            } else {
                setFormData((prevState) => ({
                    ...prevState,
                    opertationalAddress: '',
                    opertationalCity: '',
                    opertationalState: '',
                    opertationalPinCode: '',
                }));
            }
            return newState;
        });
    };

    const optionsEntityType = [
        { value: 'Partnership', label: 'Partnership' },
        { value: 'LLP', label: 'LLP' },
        { value: 'Private Limited', label: 'Private Limited' },
        { value: 'Public Limited', label: 'Public Limited' },
        { value: 'Govt Sector', label: 'Govt Sector' },
        { value: 'Govt Agency', label: 'Govt Agency' },
    ];

    const optionPurposeApplication = [
        { value: 'Spot Trading', label: 'Spot Trading' },
        { value: 'Quick Buy Sell', label: 'Quick Buy Sell' },
        { value: 'P2P Trading', label: 'P2P Trading' },
        { value: 'Crypto Exchange', label: 'Crypto Exchange' },
        { value: 'OTC Trades', label: 'OTC Trades' },
    ];

    const handleChangeEntity = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'entityType',
                value: selectedOption.value,
            },
        });
    };

    const changeApplicatonPurpose = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'purposeApplication',
                value: selectedOption.value,
            },
        });
    };

    const handleCountryName = (selectedOption) => {
        if (selectedOption === null) {
            return;
        }
        const { value } = selectedOption;
        setFormData({ ...formData, country: value });
    };

    const countryNames = Object.values(countries).map(country => ({
        label: country.name,
        value: country.name,
    }));

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'black'
                : state.isFocused
                    ? 'gainsboro'
                    : 'white',
            color: state.isSelected
                ? 'white'
                : state.isFocused
                    ? 'dark'
                    : 'black',
            fontSize: '14px',
            borderRadius: '4px',
            margin: '0px',
        }),
    };

    const annualTrunover = [
        { value: '', label: 'Please select one' },
        { value: '0-1 Crore', label: '0-1 Crore' },
        { value: '1 Crore - 10 Crore', label: '1 Crore - 10 Crore' },
        { value: '10 Crore - 50 Crore', label: '10 Crore - 50 Crore' },
        { value: '50 Crore - 100 Crore', label: '50 Crore - 100 Crore' },
        { value: '100 Crore - 500 Crore', label: '100 Crore - 500 Crore' },
        { value: 'Above 500 Crore', label: 'Above 500 Crore' },
    ];


    const handleAnnualTurnOver = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'annualTrunover',
                value: selectedOption.value,
            },
        });
    };

    const handleAddDirector = () => {
        setDirectors([...directors, { panNumber: '', aadharNumber: '', aadharOtp: '' }]);
    };


    const isMissingDetails = directors?.some((_, index) =>
        !entityKycDetail?.directorsPan?.[index] || !entityKycDetail?.aadharDetails?.[index]
    );

    useEffect(() => {
        if (entityKycDetail?.directorsPan?.length > 0) {
            const panVerifiedDirectors = entityKycDetail.directorsPan.map((director) => ({
                panNumber: director.pan || '',
            }));
            setDirectors(panVerifiedDirectors);
        }
    }, [entityKycDetail]);


    const handleRemoveDirector = (index) => {
        if (index === 0) return;
        const updatedDirectors = directors.filter((_, i) => i !== index);
        setDirectors(updatedDirectors);

    };

    const handleDirectorChange = (e, index, field) => {
        let { value } = e.target;

        if (field === 'panNumber') {
            value = value.toUpperCase().replace(/\s/g, '');
            value = value.replace(/(.{5})(.{4})(.{1})/, '$1$2$3');
            const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
            setIsPanValid(panRegex.test(value));
        }

        if (field === 'aadharNumber') {
            let input = e.target.value.replace(/\D/g, '');
            const regex = /^[2-9]{1}[0-9]{7}$/;
            const isValid = regex.test(input);
            setIsAadharValid(isValid);
            setIsActiveAadharOtpField(false);
            setIsOtpSent(false);
            setCountdown(0);
        }
        const updatedDirectors = directors.map((director, i) =>
            i === index ? { ...director, [field]: value } : director
        );
        setDirectors(updatedDirectors);
    };

    const handleSelectDirector = (director) => {
        setFormData(prevState => ({
            ...prevState,
            selectedDirector: director
        }));
    };

    const maskName = (name) => {
        if (!name) return '';
        const words = name.trim().split(' ');
        const maskedWords = words.map((word) => {
            if (word.length <= 2) return word;
            const firstChar = word[0];
            const lastChar = word[word.length - 1];
            const maskedPart = '*'.repeat(word.length - 2);
            return `${firstChar}${maskedPart}${lastChar}`;
        });
        return maskedWords.join(' ');
    };

    const handleUpdateKycStep = async (step) => {
        setIsLoading(true);
        try {
            const result = await AuthService.updateKycStepStatus(step);
            if (result?.success) {
                setIsLoading(false);
                handleEntityKycDetails();
                handleuserStep();
            } else {
                setIsLoading(false);
                alertErrorMessage(result);
            }
        } catch (error) {
            setIsLoading(false);
            alertErrorMessage(error);
        }
    };

    const handleEntityPanDetails = async (item, name) => {
        setIsLoadingPan(true);
        try {
            const result = await AuthService.getEntityPanDetails(item, name);
            if (result?.success) {
                handleAmlCheck(result?.data?.pan, result?.data?.name);
            } else {
                setIsLoadingPan(false);
                setResponseResult(result);
            }
        } catch (error) {
            setIsLoadingPan(false);
            setResponseResult(error);
        }
    };

    const handleAmlCheck = async (panNumber, name) => {
        try {
            await AuthService.getAmlCheckStatusEntity(panNumber, name).then(async (result) => {
                if (result?.success) {
                    setIsPanValid(false);
                    setIsLoadingPan(false);
                    handleEntityKycDetails();
                } else {
                    setIsLoadingPan(false);
                }
            });
        } catch (error) {
            setIsLoadingPan(false);
        }
    };

    const handleEntityDetails = async () => {
        setIsLoading(true);
        try {
            await AuthService.submitEntityDetails(formData).then(async (result) => {
                if (result?.success) {
                    setIsLoading(false);
                    alertSuccessMessage(result?.message);
                    handleEntityKycDetails();
                } else {
                    alertErrorMessage(result);
                    setIsLoading(false);
                }
            });
        } catch (error) {
            setIsLoading(false);
            alertErrorMessage(error);
        }
    };

    const handleuserStep = async () => {
        const result = await AuthService.getuserKycStep();
        if (result?.success) {
            setKycFormStep(result.data?.step || "step1");
        }
    };

    const handleEntityKycDetails = async () => {
        try {
            const result = await AuthService.getKycDetailsEntity();
            if (result?.success) {
                setEntityKycDetail(result.data);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };


    useEffect(() => {
        handleEntityKycDetails();
        handleuserStep();
    }, [userDetails]);

    const handlegetOtpAAdhar = async (aadharNumber) => {
        setIsLoadingPan(true);
        try {
            const referenceId = Math.floor(100000 + Math.random() * 900000);
            const result = await AuthService.verifyAadharDocument(aadharNumber, referenceId);
            if (result?.success) {
                alertSuccessMessage(result.message);
                setIsOtpSent(true);
                setCountdown(60);
                setAadharOtpDetails(result.data);
                setIsLoadingPan(false);
                setIsActiveAadharOtpField(true);
            } else {
                alertErrorMessage(result);
                setIsOtpSent(false);
                setCountdown(0);
                setIsLoadingPan(false);
            }
        } catch (error) {
            alertErrorMessage(error);
            setIsLoadingPan(false);
        }
    };

    const handleVerifyAadhar = async (aadharOtp, panNumber, directorId) => {
        setIsLoading(true);
        try {
            const result = await AuthService.getAadharDetails(aadharOtp, aadharOtpDetails?.transaction_id, aadharOtpDetails?.reference_id, panNumber, directorId, "director");
            if (result?.success) {
                setIsLoading(false);
                handleEntityKycDetails();
                setIsOtpSent(false);
                setCountdown(0);
            } else {
                setIsLoading(false);
                alertErrorMessage(result?.message);
            }
        } catch (error) {
            setIsLoading(false);
            alertErrorMessage(error);
        }
    };

    const showAlert = (item) => {
        Swal.fire({
            title: `${item}`,
            text: `You want to verify your PAN number.${""} ${""} ${""} You cannot re-verify it again.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#43e019",
            cancelButtonColor: "#e64942",
            confirmButtonText: "Proceed"
        }).then((result) => {
            if (result.isConfirmed) {
                handleVerifyPan(item)
            }
        });
    };


    const handleVerifyPan = async (item, directorId) => {
        setIsLoadingPan(true);
        try {
            const result = await AuthService.verifyDirectorPancard(item, directorId, "director");
            if (result?.success) {
                setIsLoadingPan(false);
                setIsPanValid(false);
                handleAmlCheck(result?.data?.pan, result?.data?.name, result?.data?.dateOfBirth);
            } else {
                setIsLoadingPan(false);
                alertErrorMessage(result);
            }
        } catch (error) {
            setIsLoadingPan(false);
            alertErrorMessage(error?.message);
        }
    };

    const handleInputChangeUbo = (index, e) => {
        const value = e.target.value;
        if (value >= 100) {
            alertErrorMessage("Percentage must be less than 100")
            return;
        }

        const updatedPercentages = [...uboPercentages];
        updatedPercentages[index] = value;
        setAllFieldsFilled(updatedPercentages.every(p => p !== ""));
        setUboPercentages(updatedPercentages);
    };

    const handleSaveUboMore = async () => {
        const dataToSubmit = entityKycDetail?.directorsPan.map((item, index) => ({
            panNumber: item.pan,
            directorId: item.director_id,
            sharePercentage: parseFloat(uboPercentages[index]) || 0
        }));

        try {
            const result = await AuthService.verifyUboMore(dataToSubmit);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleEntityKycDetails();
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error?.message);
        }
    };


    useEffect(() => {
        if (entityKycDetail?.ubodetails?.length > 0) {
            const updatedPercentages = entityKycDetail.ubodetails?.map(item => item.sharePercentage || '') || [];
            setUboPercentages(updatedPercentages);
        } else {
            setUboPercentages(Array(entityKycDetail?.directorsPan?.length).fill(""));
        }
    }, [entityKycDetail]);

    const handleAddMainTrader = async () => {
        const data = formData?.selectedDirector;
        try {
            const result = await AuthService.verifyMainTrader(data?.director_id, data?.pan,);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleEntityKycDetails();
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error?.message);
        }
    };

    const handleImageChange = async (event, setImage) => {
        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            const maxSize = 10 * 1024 * 1024;
            const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
            if (!allowedTypes.includes(file.type)) {
                alertErrorMessage("Only PNG, JPEG, and JPG file types are allowed.");
            } else if (file.size > maxSize) {
                alertErrorMessage("Max image size is 10 MB.");
            } else {
                setImage(file)
            }
        }
    };

    const handleChangeVideo = async (event, setImage) => {
        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            const maxSize = 50 * 1024 * 1024;
            const allowedTypes = ["application/pdf"];
           /*  if (!allowedTypes.includes(file.type)) {
                alertErrorMessage("Video are allowed.");
            } else  */if (file.size > maxSize) {
                alertErrorMessage("Max Video size is 50 MB.");
            } else {
                setImage(file)
            }
        }
    };

    const handleImageChangeDirector = async (event, setImages, index, type) => {
        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            const maxSize = 10 * 1024 * 1024;
            const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
            if (!allowedTypes.includes(file.type)) {
                alertErrorMessage("Only PNG, JPEG, and JPG file types are allowed.");
            } else if (file.size > maxSize) {
                alertErrorMessage("Max image size is 10 MB.");
            } else {
                setImages((prevImages) => {
                    const updatedImages = [...prevImages];
                    updatedImages[index] = {
                        ...updatedImages[index],
                        [type]: file,
                    };
                    return updatedImages;
                });
            }
        } else {
            alertErrorMessage("No file selected.");
        }
    };

    const [activeIndex, setActiveIndex] = useState(0);
    const [uploadedFiles, setUploadedFiles] = useState(
        Array(entityKycDetail?.aadharDetails?.length).fill(0)
    );
    const totalFilesPerUser = 3;

    const handleFileChange = (event, index, type) => {
        handleImageChangeDirector(event, setDirectorImages, index, type);
        const newUploadedFiles = [...uploadedFiles];
        newUploadedFiles[index] += 1;
        setUploadedFiles(newUploadedFiles);
        if (newUploadedFiles[index] >= totalFilesPerUser) {
            setActiveIndex(prevIndex => prevIndex + 1);
        }
    };

    const changeDocumentCoi = (event) => handleImageChange(event, setCoiFile);
    const changeDocumentMoa = (event) => handleImageChange(event, setMoaFile);
    const changeDocumentAoa = (event) => handleImageChange(event, setAoaFile);
    const changeDocumentBank = (event) => handleImageChange(event, setBankStatementFile);
    const changeDocumentSurveyCapital = (event) => handleImageChange(event, setSurveyCapitalFile);
    const changeDocumentAmlCft = (event) => handleImageChange(event, setAmlCftFile);
    const changeDocumentLoa = (event) => handleImageChange(event, setLoaFile);
    const changeDocumentSanctions = (event) => handleImageChange(event, setSanctionsFile);
    const changeDocumentAssets = (event) => handleImageChange(event, setInrAssetsFile);
    const changeDocumentVideo = (event) => handleChangeVideo(event, setVideoFile);

    const validationMessagesFile = {
        coiFileError: 'Please Upload Certificate of InCorporation (COI)',
        moaFileError: 'Please Upload Memorandum of Association (MOA)',
        aoaFileError: 'Please Upload Articles of association (AOA)',
        bankStatementFileError: 'Please Upload Bank Statement',
        surveyCapitalFileError: 'Please Upload Source Of Capital',
        amlCftFileError: 'Please Upload AML / CFT Policy',
        loaFileError: 'Please Upload Letter of Authorization (LOA)',
    }

    const handleSubmitEntityDocument = async () => {
        if (!coiFile) {
            alertErrorMessage(validationMessagesFile.coiFileError);
            return;
        }
        if (!moaFile) {
            alertErrorMessage(validationMessagesFile.moaFileError);
            return;
        }
        if (!aoaFile) {
            alertErrorMessage(validationMessagesFile.aoaFileError);
            return;
        }
        if (!bankStatementFile) {
            alertErrorMessage(validationMessagesFile.bankStatementFileError);
            return;
        }
        if (!surveyCapitalFile) {
            alertErrorMessage(validationMessagesFile.surveyCapitalFileError);
            return;
        }
        if (!amlCftFile) {
            alertErrorMessage(validationMessagesFile.amlCftFileError);
            return;
        }
        if (!loaFile) {
            alertErrorMessage(validationMessagesFile.loaFileError);
            return;
        }

        // Validate director images
        let allImagesUploaded = true;
        entityKycDetail?.aadharDetails?.forEach((item, index) => {
            if (directorImages && directorImages[index]) {
                const { aadharFront, aadharBack, panCard } = directorImages[index];
                if (!aadharFront) {
                    alertErrorMessage(`Please upload ${item?.name}'s Aadhaar Front Image.`);
                    allImagesUploaded = false;
                }
                if (!aadharBack) {
                    alertErrorMessage(`Please upload ${item?.name}'s Aadhaar Back Image.`);
                    allImagesUploaded = false;
                }
                if (!panCard) {
                    alertErrorMessage(`Please upload ${item?.name}'s PAN Card Image.`);
                    allImagesUploaded = false;
                }
            } else {
                alertErrorMessage(`Please upload images for Director ${index + 1}.`);
                allImagesUploaded = false;
            }
        });

        if (!allImagesUploaded) {
            return;
        }

        const formValue = new FormData();
        formValue.append("coi", coiFile);
        formValue.append("moa", moaFile);
        formValue.append("aoa", aoaFile);
        formValue.append("bankStatement", bankStatementFile);
        formValue.append("capitalSurvey", surveyCapitalFile);
        formValue.append("amlcft", amlCftFile);
        formValue.append("loa", loaFile);
        formValue.append("sanctionsFile", sanctionsFile);
        formValue.append("inrAssetsFile", inrAssetsFile);
        formValue.append("videoFile", videoFile);
        selectedImages.map((item, index) => {
            formValue.append(`otherImages${++index}`, item);
        })
        directorImages.forEach((images, index) => {
            if (images.aadharFront) {
                formValue.append(`director${index + 1}AadharfrontImage`, images.aadharFront);
            }
            if (images.aadharBack) {
                formValue.append(`director${index + 1}AadharbackImage`, images.aadharBack);
            }
            if (images.panCard) {
                formValue.append(`director${index + 1}panImage`, images.panCard);
            }
        });
        setIsLoading(true);
        await AuthService.submitEntityDocument(formValue, entityKycDetail?.aadharDetails?.length).then(async (result) => {
            setIsLoading(false);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleEntityKycDetails();
            } else {
                alertErrorMessage(result);
                setIsLoading(false);
            }
        });
    };

    const handleDeclarationSubmission = async () => {
        if (!checkButton) {
            alertErrorMessage('Please check Declaration and Submission');
            return;
        }
        try {
            const result = await AuthService.declaration();
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleSubmitKyc(1);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    }

    const handleSubmitKyc = async (status) => {
        setIsActivePrevieModel(false)
        const result = await AuthService.updateKycStatus(status);
        if (result?.success) {
            handleUserProfile();
            showAlertFinelStep();
        } else {
            alertErrorMessage(result);
        }
    }

    const showAlertFinelStep = () => {
        Swal.fire({
            title: "Kyc Submitted Successfully",
            text: "Your Kyc is Under Review",
            icon: "success",
            confirmButtonColor: "#F0B90B",
            confirmButtonText: "Kyc In Review",
        });
    };

    const handleImageChanges = (e) => {
        const files = Array.from(e.target.files);
        setSelectedImages((prevImages) => [...prevImages, ...files]);
    };

    const handleRemoveImage = (indexToRemove) => {
        setSelectedImages((prevImages) =>
            prevImages.filter((_, index) => index !== indexToRemove)
        );
    };

    const matchingDirector = entityKycDetail?.directorsPan?.find(director => director?.pan == entityKycDetail?.mainTraderDetails?.panNumber);
    const matchingDirectorAAdhhar = entityKycDetail?.aadharDetails?.find(director => director?.director_id == entityKycDetail?.mainTraderDetails?.directorId);


    const handleLivenessResult = async () => {
        setLoading(true);
        try {
            const resultData = await AuthService.getLinkKycResult();
            if (resultData?.success) {
                setLivenessResult(resultData?.data);
                handleFaceMatch(resultData?.data?.results?.[0]?.imageUrl);
            }
        } catch (error) {
            alertErrorMessage(error);
        } finally {
            setLoading(false);
        }
    };

    const handleLivenessResultAgain = async () => {
        setLoading(true);
        try {
            const resultData = await AuthService.getLinkKycResult();
            if (resultData?.data?.results?.[0]?.imageUrl) {
                setLivenessResult(resultData?.data);
                handleFaceMatch(resultData?.data?.results?.[0]?.imageUrl);
            } else {
                handleLivenessResult();
            }
        } catch (error) {
            alertErrorMessage(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (kycFormStep === "step5") {
            handleLivenessResult();
        }
    }, [kycFormStep]);


    const handleAuthLivenessSdk = async () => {
        setLoading(true);
        try {
            const resultData = await AuthService.authDataSdk();
            if (resultData?.success) {
                startOnboarding(resultData?.data?.result?.token);
            } else {
                alertErrorMessage("Failed to authenticate liveness SDK.");
            }
        } catch (error) {
            alertErrorMessage(error);
        } finally {
            setLoading(false);
        }
    };

    const workflowId = "liveness";

    const startOnboarding = (sdkToken) => {
        if (window.HyperKycConfig && window.HyperKYCModule) {
            const hyperKycConfig = new window.HyperKycConfig(
                sdkToken,
                workflowId,
                transactionId,
            );
            window.HyperKYCModule.launch(hyperKycConfig, (sdkResult) => {
                handleLivenessResultAgain(sdkResult);
                handleLivenessResult();
            });
        } else {
            console.error("HyperKYCModule or HyperKycConfig is not loaded.");
        }
    };


    const handleFaceMatch = async (liveface_image) => {
        try {
            await AuthService.getEntityFaceMatchData(liveface_image, matchingDirectorAAdhhar?.adhaar_image).then(async (result) => {
                if (result?.success) {
                    handleEntityKycDetails();
                }
            });
        } catch (error) {
            handleLivenessResult();
        }
    };

    return (
        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper" >
                    <section className="dd_page">
                        <div className="container" >
                            <div className="dd_title text-center flex-column" >
                                <h2 className="text-warning" > KYC Verification </h2>
                                <p>{userKycStatus == 0 ? 'Complete your KYC in just 7 steps' : ""}</p>
                            </div>
                            {
                                userKycStatus == 0 ?
                                    <div className="mt_form" >
                                        <ul id="progressbar">
                                            <li className={entityKycDetail?.entityPan ? 'active' : ''} id="step1">
                                                <strong>Step 1</strong>
                                            </li>
                                            <li className={entityKycDetail?.entityRecord ? 'active' : ''} id="step2">
                                                <strong>Step 2</strong>
                                            </li>
                                            <li className={entityKycDetail?.aadharDetails && entityKycDetail?.directorsPan ? 'active' : ''} id="step3" >
                                                <strong>Step 3</strong>
                                            </li>
                                            <li className={entityKycDetail?.ubodetails ? 'active' : ''} id="step4">
                                                <strong>Step 4</strong>
                                            </li>
                                            <li className={entityKycDetail?.mainTraderDetails ? 'active' : ''} id="step5" ><strong>Step 5</strong>
                                            </li>
                                            <li className={entityKycDetail?.documentDetails ? 'active' : ''} id="step6" ><strong>Step 6</strong>
                                            </li>
                                            <li id="step7" ><strong>Step 7</strong>
                                            </li>


                                        </ul>

                                        <form className="multistep_form row justify-content-center" >
                                            <div className="col-lg-10" >

                                                {/* step 1 */}
                                                {
                                                    kycFormStep === "step1" &&
                                                    <fieldset className="current">
                                                        <div className="form-card">
                                                            <div className="row justify-content-center ">

                                                                <div className="col-xl-6 mb-4">
                                                                    <div className="field-box mb-3">
                                                                        <label htmlFor="panNumber" className="form-label">Pan Number</label>
                                                                        <div className="field-otp-box">
                                                                            <div className="form-control p-0 overflow-hidden">
                                                                                <input
                                                                                    id="panNumber"
                                                                                    name="panNumber"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    value={formData.panNumber}
                                                                                    onChange={handleInputChange}
                                                                                    placeholder="Enter Your Pan Number"
                                                                                    maxLength={10}
                                                                                    disabled={entityKycDetail?.entityPan?.verified}
                                                                                />
                                                                            </div>
                                                                            {isPanValid && entityKycDetail?.entityPan?.pan != formData.panNumber ? (
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-xs custom-btn"
                                                                                    onClick={() => handleEntityPanDetails(formData.panNumber)}
                                                                                    disabled={isLoadingPan}
                                                                                >
                                                                                    {isLoadingPan ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Verify</span>}
                                                                                </button>
                                                                            )
                                                                                :
                                                                                entityKycDetail?.entityPan?.name ?
                                                                                    <button type="button" className="btn btn-xs success-btn">
                                                                                        {maskName(entityKycDetail?.entityPan?.name)} &nbsp;
                                                                                        <i className="ri-verified-badge-fill text-success"></i>
                                                                                    </button>
                                                                                    :
                                                                                    ""
                                                                            }

                                                                        </div>

                                                                        {
                                                                            entityKycDetail?.entityPan?.message === "Valid PAN." && entityKycDetail?.entityPan?.pan === formData.panNumber &&
                                                                            <div className="row" >
                                                                                <div className="col-md-12" >
                                                                                    <div className="form-group mt-4 " >
                                                                                        <button className="form-control btn-warning  btn-block w-100" type="button" onClick={() => handleUpdateKycStep("step2")}>
                                                                                            {
                                                                                                isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Continue to the Next Step </span>
                                                                                            }
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        }
                                                                    </div>
                                                                    {responseResult && (
                                                                        <div className="text-danger">
                                                                            {responseResult}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                }

                                                {/* step 2 */}
                                                {
                                                    kycFormStep === "step2" &&
                                                    <fieldset className="current" >
                                                        <div className="form-card">
                                                            <h6 className="mb-4" >Entity Details</h6>
                                                            <div className="row">
                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="entityName" className="form-label">Entity Name</label>
                                                                        <span className="text-danger">*</span>
                                                                        <input id="entityName" name="entityName" type="text" className="form-control" value={formData.entityName} onChange={handleInputChange} disabled={entityKycDetail?.entityPan?.name} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4" >
                                                                    <div className="form-group mb-4" >
                                                                        <label htmlFor="entityType"> Entity Type <span className="text-danger">*</span> </label>
                                                                        <Select
                                                                            name="entityType"
                                                                            id="entityType"
                                                                            value={optionsEntityType.find(option => option.value === formData.entityType)}
                                                                            onChange={handleChangeEntity}
                                                                            options={optionsEntityType}
                                                                            styles={customStyles}
                                                                            isDisabled={entityKycDetail?.entityRecord?.entity_type}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="countryName" className="form-label">Registerd Country</label><span className="text-danger">*</span>
                                                                        <Select
                                                                            name="countryName"
                                                                            onChange={handleCountryName}
                                                                            options={countryNames}
                                                                            value={countryNames.find(option => option.value === formData.countryName)}
                                                                            styles={customStyles}
                                                                            isDisabled
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="registrationNo" className="form-label">Registration No</label><span className="text-danger">*</span>
                                                                        <input id="registrationNo" name="registrationNo" type="text" className="form-control" value={formData.registrationNo} onChange={handleInputChange}
                                                                            disabled={entityKycDetail?.entityRecord?.registration_number} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="IncorporationDate" className="form-label">Date Of Incorporation</label><span className="text-danger">*</span>
                                                                        <input id="IncorporationDate" name="IncorporationDate" type="date" className="form-control" value={formData.IncorporationDate} onChange={handleInputChange} disabled={entityKycDetail?.entityPan?.dateOfBirth} max={new Date().toISOString().split("T")[0]} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="website" className="form-label">Website (Optional)</label>
                                                                        <input id="website" name="website" type="text" className="form-control" value={formData.website} onChange={handleInputChange} disabled={entityKycDetail?.entityRecord?.website} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-3 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="registerdAddress" className="form-label">Registerd Address</label>
                                                                        <span className="text-danger">*</span>
                                                                        <input id="registerdAddress" name="registerdAddress" type="text" className="form-control" value={formData.registerdAddress} onChange={handleInputChange} disabled={entityKycDetail?.entityRecord?.registered_address} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="registerdCity" className="form-label"></label>
                                                                        <input id="registerdCity" name="registerdCity" placeholder="City" type="text" className="form-control" value={formData.registerdCity} onChange={handleInputChange} disabled={entityKycDetail?.entityRecord?.registered_address_city} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="registerdState" className="form-label"></label>
                                                                        <input id="registerdState" name="registerdState" placeholder="State" type="text" className="form-control" value={formData.registerdState} onChange={handleInputChange} disabled={entityKycDetail?.entityRecord?.registered_address_state} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-3 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="registerdPinCode" className="form-label"></label>
                                                                        <input id="registerdPinCode" name="registerdPinCode" placeholder="Pin Code" type="number" className="form-control" value={formData.registerdPinCode} onChange={handleInputChange} disabled={entityKycDetail?.entityRecord?.registered_address_pincode} />
                                                                    </div>
                                                                </div>
                                                                {
                                                                    !entityKycDetail?.entityRecord &&
                                                                    <div className="avil_bal " >
                                                                        <div className="form-check cursor-pointer  d-block">
                                                                            <input className="form-check-input" type="checkbox" id="sameAddress" defaultChecked={sameAddress} value={sameAddress} onChange={handleSameAddressChange}
                                                                            />
                                                                            <label className="form-check-label" label="sameAddress">
                                                                                <b>Address Same as Above  </b>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                <div className="col-md-3 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="opertationalAddress" className="form-label">Opertational Address <span className="text-danger">*</span></label>
                                                                        <input id="opertationalAddress" name="opertationalAddress" type="text" className="form-control" value={formData.opertationalAddress} onChange={handleInputChange} disabled={sameAddress || entityKycDetail?.entityRecord?.operational_address} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-3 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="opertationalCity" className="form-label"></label>
                                                                        <input id="opertationalCity" name="opertationalCity" placeholder="City" type="text" className="form-control" value={formData.opertationalCity} onChange={handleInputChange} disabled={sameAddress || entityKycDetail?.entityRecord?.operational_address_city} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="opertationalState" className="form-label"></label>
                                                                        <input id="opertationalState" name="opertationalState" placeholder="State" type="text" className="form-control" value={formData.opertationalState} onChange={handleInputChange} disabled={sameAddress || entityKycDetail?.entityRecord?.operational_address_city} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-3 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="opertationalPinCode" className="form-label"></label>
                                                                        <input id="opertationalPinCode" name="opertationalPinCode" placeholder="Pin Code" type="number" className="form-control" value={formData.opertationalPinCode} onChange={handleInputChange} disabled={sameAddress || entityKycDetail?.entityRecord?.operational_address_pincode} />
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="emailId" className="form-label">Email Id <span className="text-danger">*</span></label>
                                                                        <input id="emailId" name="emailId" type="email" className="form-control" value={formData.emailId} onChange={handleInputChange} disabled />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="mobileNumber" className="form-label">Mobile Number <span className="text-danger">*</span></label>
                                                                        <input id="mobileNumber" name="mobileNumber" type="number" className="form-control" value={formData.mobileNumber} onChange={handleInputChange} disabled />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="purposeApplication" className="form-label">Purpose of Application</label>
                                                                        <span className="text-danger">*</span>
                                                                        <Select
                                                                            name="purposeApplication"
                                                                            id="purposeApplication"
                                                                            value={optionPurposeApplication.find(option => option.value === formData.purposeApplication)}
                                                                            onChange={changeApplicatonPurpose}
                                                                            options={optionPurposeApplication}
                                                                            styles={customStyles}
                                                                            isDisabled={entityKycDetail?.entityRecord?.purpose}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="annualTrunover" className="form-label">Annual Trunover (INR) <span className="text-danger">*</span></label>
                                                                        <Select
                                                                            name="annualTrunover"
                                                                            id="annualTrunover"
                                                                            value={annualTrunover.find(option => option.value == formData.annualTrunover)}
                                                                            onChange={handleAnnualTurnOver}
                                                                            options={annualTrunover}
                                                                            styles={customStyles}
                                                                            isDisabled={entityKycDetail?.entityRecord?.annual_turnover}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="sourceCapital" className="form-label">Source of Capital <span className="text-danger">*</span></label>
                                                                        <input id="sourceCapital" name="sourceCapital" type="text" className="form-control" value={formData.sourceCapital} onChange={handleInputChange} disabled={entityKycDetail?.entityRecord?.source_of_capital} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="totalAssets" className="form-label">Total Assets Value (INR) <span className="text-danger">*</span></label>
                                                                        <input id="totalAssets" name="totalAssets" type="number" className="form-control" value={formData.totalAssets} onChange={handleInputChange} disabled={entityKycDetail?.entityRecord?.total_assets} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="gstNumber" className="form-label">GST Number (Optional)</label>
                                                                        <input id="gstNumber" name="gstNumber" type="text" className="form-control" value={formData.gstNumber} onChange={handleInputChange} disabled={entityKycDetail?.entityRecord?.gst_number || entityKycDetail?.entityRecord} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="fiuNumber" className="form-label">FIU RE. No (Optional)</label>
                                                                        <input id="fiuNumber" name="fiuNumber" type="text" className="form-control" value={formData.fiuNumber} onChange={handleInputChange} disabled={entityKycDetail?.entityRecord?.fiu_number || entityKycDetail?.entityRecord} />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            {
                                                                !entityKycDetail?.entityRecord ?

                                                                    <div className="row" >
                                                                        <div className="col-md-12" >
                                                                            <div className="form-group mt-4 " >
                                                                                <button className="form-control btn-warning  btn-block w-100" type="button" onClick={handleEntityDetails}>
                                                                                    {
                                                                                        isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Verify</span>
                                                                                    }
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="row" >
                                                                        <div className="col-md-12" >
                                                                            <div className="form-group mt-4 " >
                                                                                <button className="form-control btn-warning  btn-block w-100" type="button" onClick={() => handleUpdateKycStep("step3")}> Continue to the Next Step </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </fieldset>
                                                }

                                                {/* step 3 */}
                                                {
                                                    kycFormStep === "step3" &&
                                                    <fieldset className="current">
                                                        <div className="form-card">
                                                            <div className="d-flex mb-4 align-items-center justify-content-between">
                                                                <h6 className="mb-0"> {entityKycDetail?.entityRecord?.entity_type === "Partnership" ? "Partners" : "Directors"}  </h6>
                                                                <small>(Minimum 2 {entityKycDetail?.entityRecord?.entity_type === "Partnership" ? "Partners" : "Directors"}) </small>
                                                            </div>

                                                            {directors.map((director, index) => {
                                                                const panData = entityKycDetail?.directorsPan?.[index];
                                                                const aadharData = entityKycDetail?.aadharDetails?.[index];
                                                                return (
                                                                    <div className="dd_row" key={index}>
                                                                        <div className="row">
                                                                            <div className="dd_title">
                                                                                <p>{entityKycDetail?.entityRecord?.entity_type === "Partnership" ? "Partner" : "Director"} {index + 1}</p>
                                                                                {index !== 0 && !panData && (
                                                                                    <button
                                                                                        className="btn btn-sm btn-danger"
                                                                                        type="button"
                                                                                        onClick={() => handleRemoveDirector(index)}
                                                                                    >
                                                                                        Remove
                                                                                    </button>
                                                                                )}
                                                                            </div>

                                                                            <div className="col-md-6 mb-3">
                                                                                <label htmlFor={`panNumber-${index}`} className="form-label mt-2">
                                                                                    Enter PAN Card No.
                                                                                </label>
                                                                                <div className="field-otp-box">
                                                                                    <input
                                                                                        id={`panNumber-${index}`}
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value={director.panNumber}
                                                                                        onChange={(e) => handleDirectorChange(e, index, 'panNumber')}
                                                                                        disabled={aadharData}
                                                                                    />
                                                                                    {panData?.pan != director.panNumber && isPanValid && (
                                                                                        <button
                                                                                            className="btn btn-xs custom-btn"
                                                                                            type="button"
                                                                                            onClick={() => handleVerifyPan(director.panNumber, panData?.director_id)}
                                                                                            disabled={isLoadingPan}
                                                                                        >
                                                                                            {isLoadingPan ? (
                                                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                                            ) : (
                                                                                                <span>Verify</span>
                                                                                            )}
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-6 mb-3 mt-4">
                                                                                {panData && (
                                                                                    <div className="vn_card">
                                                                                        <h6>{maskName(panData.name)}</h6>
                                                                                        <i className="ri-verified-badge-fill"></i>
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                            {/* Aadhar Details */}
                                                                            {!aadharData && panData ? (
                                                                                <>

                                                                                    <div className="col-md-6 mb-3">
                                                                                        <label htmlFor={`aadharNumber-${index}`} className="form-label">Enter Aadhar Number (Enter First 8 Digit)</label>
                                                                                        <div className="field-box mb-3">
                                                                                            <div className="combined-input form-control p-0 overflow-hidden field-otp-box">
                                                                                                <input
                                                                                                    id={`aadharNumber-${index}`}
                                                                                                    name="aadharNumber"
                                                                                                    type="text"
                                                                                                    className="form-control no-border pe-0"
                                                                                                    maxLength="8"
                                                                                                    value={director.aadharNumber}
                                                                                                    onChange={(e) => handleDirectorChange(e, index, 'aadharNumber')}
                                                                                                    autoComplete="off"
                                                                                                    style={{ width: '95px' }}
                                                                                                    placeholder="________"
                                                                                                />
                                                                                                {
                                                                                                    director.aadharNumber?.length === 8 &&
                                                                                                    <span
                                                                                                        className="form-control no-border ps-0 last-four-input text-muted"
                                                                                                    >
                                                                                                        {panData?.maskedAadhaarNumber?.slice(-4)}
                                                                                                    </span>
                                                                                                }


                                                                                                {isAadharValid && (
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-xs custom-btn"
                                                                                                        onClick={() => handlegetOtpAAdhar(director.aadharNumber + panData?.maskedAadhaarNumber?.slice(-4))
                                                                                                        }
                                                                                                        disabled={isOtpSent}
                                                                                                    >

                                                                                                        {
                                                                                                            isLoadingPan ? <span className="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span> :
                                                                                                                <span>
                                                                                                                    {isOtpSent ? `Resend in ${countdown}s` : 'GET OTP'}
                                                                                                                </span>
                                                                                                        }
                                                                                                    </button>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {isActiveAadharOtpField && (
                                                                                        <div className="col-md-6 mb-3">
                                                                                            <div className="field-box mb-3">
                                                                                                <label htmlFor="aadharOtp" className="form-label">Enter Aadhar Otp</label>
                                                                                                <div className="field-otp-box">
                                                                                                    <div className=" overflow-hidden">
                                                                                                        <input
                                                                                                            id={`aadharOtp-${index}`}
                                                                                                            name="aadharOtp"
                                                                                                            type="number"
                                                                                                            className="form-control"
                                                                                                            value={director.aadharOtp}
                                                                                                            onChange={(e) => handleDirectorChange(e, index, 'aadharOtp')}
                                                                                                        />
                                                                                                    </div>

                                                                                                    {
                                                                                                        director?.aadharOtp?.length === 6 && (

                                                                                                            <button
                                                                                                                className="btn btn-xs custom-btn"
                                                                                                                type="button"
                                                                                                                onClick={() => handleVerifyAadhar(director.aadharOtp, panData?.pan, panData.director_id)}
                                                                                                            >
                                                                                                                {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Verify Aadhar</span>}
                                                                                                            </button>
                                                                                                        )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                aadharData &&
                                                                                <>
                                                                                    <label className="form-label mb-0">Aadhar Card Details</label>
                                                                                    <div className="col-md-6 mb-3">
                                                                                        <div className="vn_card">
                                                                                            <h6>{aadharData.full_name}</h6>
                                                                                            <i className="ri-verified-badge-fill"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}

                                                            <div className="row mt-4">

                                                                {
                                                                    !isMissingDetails &&
                                                                    <div className="col-md-6">
                                                                        <button
                                                                            className="form-control btn-warning btn-block w-100"
                                                                            type="button"
                                                                            onClick={handleAddDirector}
                                                                        >
                                                                            {entityKycDetail?.entityRecord?.entity_type === "Partnership" ? "Add New Partner" : "Add New  Director"}
                                                                        </button>
                                                                    </div>
                                                                }



                                                                {
                                                                    !isMissingDetails && entityKycDetail?.directorsPan?.length >= 2 &&

                                                                    <div className="col-md-6">
                                                                        <button
                                                                            className="form-control btn-warning btn-block w-100"
                                                                            type="button"
                                                                            onClick={() => handleUpdateKycStep("step4")}
                                                                            disabled={directors.some((_, index) =>
                                                                                !entityKycDetail?.directorsPan?.[index] ||
                                                                                !entityKycDetail?.aadharDetails?.[index]
                                                                            )}
                                                                        >
                                                                            Continue to the Next Step
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                }

                                                {/* step 4 */}
                                                {
                                                    kycFormStep === "step4" &&
                                                    <fieldset className="current">
                                                        <div className="form-card">
                                                            <div className="d-flex mb-4 align-items-center justify-content-between">
                                                                <h6 className="mb-0">UBO More Then 10%</h6>
                                                            </div>

                                                            {entityKycDetail?.directorsPan && entityKycDetail?.directorsPan.map((item, index) => (
                                                                <div className="dd_row" key={item?.directorId}>
                                                                    <div className="row">
                                                                        <div className="col-lg-6">
                                                                            <p className="mb-0"><small>{entityKycDetail?.entityRecord?.entity_type === "Partnership" ? "Partner" : "Director"} {item?.directorId}</small></p>
                                                                            <h6 className="mb-lg-0"><small>{item?.name}</small></h6>
                                                                        </div>
                                                                        <div className="col-lg-6 form-group mb-0">
                                                                            <input
                                                                                type="number"
                                                                                className="form-control"
                                                                                placeholder={entityKycDetail?.entityRecord?.entity_type === "Partnership" ? "Partner (%)" : "Director(%)"}
                                                                                name="uboPercentage"
                                                                                value={uboPercentages?.[index] || ""}
                                                                                onChange={(e) => handleInputChangeUbo(index, e)}
                                                                                disabled={entityKycDetail?.ubodetails}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}

                                                            {/* Conditionally show submit button when all fields are filled */}
                                                            {allFieldsFilled && !entityKycDetail?.ubodetails &&
                                                                <div className="col-md-12" >
                                                                    <div className="form-group mt-4 " >
                                                                        <button className="form-control btn-warning  btn-block w-100" type="button" onClick={handleSaveUboMore}>
                                                                            Submit
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {
                                                                entityKycDetail?.ubodetails &&
                                                                <div className="col-md-12" >
                                                                    <div className="form-group mt-4 " >
                                                                        <button className="form-control btn-warning  btn-block w-100" type="button" onClick={() => handleUpdateKycStep("step5")}>
                                                                            {
                                                                                isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Continue to the Next Step </span>
                                                                            }
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>
                                                    </fieldset>
                                                }
                                                {/* step 5 */}
                                                {
                                                    kycFormStep === "step5" &&
                                                    <fieldset className="current">
                                                        <div className="form-card">
                                                            <div className={`step_1 ${!entityKycDetail?.mainTraderDetails ? "" : "d-none"}`} >
                                                                <h6 className="mb-4" > Who is main Trader? </h6>
                                                                <div className="dctr_row row g-2 g-md-3 mt-md-3">
                                                                    {
                                                                        entityKycDetail?.directorsPan && entityKycDetail?.directorsPan?.map((director, index) => (
                                                                            <div className="col-lg-4" key={index}>
                                                                                <label className="card">
                                                                                    <input
                                                                                        name="selectedDirector"
                                                                                        id={`selectedDirector-${director._id}`}
                                                                                        className="radio"
                                                                                        type="radio"
                                                                                        onChange={() => handleSelectDirector(director)}
                                                                                        disabled={entityKycDetail?.mainTraderDetails}
                                                                                    />
                                                                                    <span className="plan-details">
                                                                                        <span className="plan-type">{entityKycDetail?.entityRecord?.entity_type === "Partnership" ? "Partner" : "Director"} {index + 1}</span>
                                                                                        <span className="plan-cost">{director?.name}</span>
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                            {
                                                                !entityKycDetail?.mainTraderDetails && formData?.selectedDirector &&
                                                                <div className="col-md-12 mt-2" >
                                                                    <div className="form-group mt-4 " >
                                                                        <button className="form-control btn-warning  btn-block w-100" type="button" onClick={handleAddMainTrader}>
                                                                            Submit
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            }


                                                            <div className={`step_2 ${entityKycDetail?.mainTraderDetails ? "" : "d-none"}`}>
                                                                <div className="row justify-content-center" >
                                                                    <div className="col-md-6 mb-4">

                                                                        <div className="field-box">
                                                                            <div className="fl_title" >
                                                                                <label htmlFor="Liveness" className="form-label text-center m-auto w-100 mb-3">
                                                                                    <h5> Verify Liveness.({matchingDirector?.name || "Main Trader"})</h5>
                                                                                </label>
                                                                            </div>

                                                                            <div className="col-xl-6 mb-4">
                                                                                {loading ? (
                                                                                    <div className="loader-overlay card_loader py-5 mt-4">
                                                                                        <div className="spinner"></div>
                                                                                        <p>Processing... Please wait.</p>
                                                                                    </div>
                                                                                ) : (
                                                                                    livenessResult?.applicationStatus === "auto_approved" || livenessResult?.applicationStatus === "started" ? (
                                                                                        entityKycDetail?.faceMatch?.facematch_value === "yes" && entityKycDetail?.faceMatch?.facematch_confidence === "high" ? (
                                                                                            <>
                                                                                                <div className="web_cam_row">
                                                                                                    <div className="web_cam_box success">
                                                                                                        <img
                                                                                                            src={livenessResult?.results?.[0]?.imageUrl}
                                                                                                            alt="Liveness Check"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <button
                                                                                                    className="form-control btn-warning btn-block w-100 mt-3"
                                                                                                    type="button"
                                                                                                    onClick={() => handleUpdateKycStep("step6")}
                                                                                                >
                                                                                                    Continue to the Next Step
                                                                                                </button>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <div className="web_cam_row">
                                                                                                    <div className="web_cam_box failed">
                                                                                                        <img
                                                                                                            src={livenessResult?.results?.[0]?.imageUrl}
                                                                                                            alt="Liveness Check"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <button
                                                                                                    className="form-control btn-warning btn-block w-100 mt-3"
                                                                                                    type="button"
                                                                                                    onClick={handleAuthLivenessSdk}
                                                                                                >
                                                                                                    Verify Again
                                                                                                </button>
                                                                                            </>
                                                                                        )
                                                                                    ) : livenessResult?.applicationStatus === "auto_declined" || livenessResult?.applicationStatus === "user_cancelled" ? (
                                                                                        <>
                                                                                            <div className="web_cam_row">
                                                                                                <div className="web_cam_box failed">
                                                                                                    <img
                                                                                                        src={livenessResult?.results?.[0]?.imageUrl || "/assets/images/liveness_thumb.svg"}
                                                                                                        alt="Liveness Check"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <button
                                                                                                className="form-control btn-warning btn-block w-100 mt-3"
                                                                                                type="button"
                                                                                                onClick={handleAuthLivenessSdk}
                                                                                            >
                                                                                                Verify Again
                                                                                            </button>
                                                                                        </>
                                                                                    ) : (
                                                                                        <div className="field-box">

                                                                                            <img src="/assets/images/liveness_thumb.svg" alt="Liveness Check" />

                                                                                            <button
                                                                                                className="form-control btn-dark btn-block w-100"
                                                                                                type="button"
                                                                                                onClick={handleAuthLivenessSdk}
                                                                                            >
                                                                                                Start Liveness
                                                                                            </button>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                }

                                                {/* step 6 */}
                                                {
                                                    kycFormStep === "step6" &&
                                                    <fieldset className="current">

                                                        {
                                                            !entityKycDetail?.documentDetails ?
                                                                <div className="form-card">
                                                                    <h6 className="mb-4" > Upload Required Documents </h6>

                                                                    <div className="accordion form_accordian" id="accordionPanelsStayOpenExample">

                                                                        {/* entity form */}
                                                                        <div className="accordion-item">
                                                                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                                                    Entity Documents
                                                                                </button>
                                                                            </h2>
                                                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne" data-bs-parent="#accordionPanelsStayOpenExample" >
                                                                                <div className="accordion-body">
                                                                                    <div className="dov_card" >
                                                                                        <div className="row g-3">
                                                                                            <div className="col-lg-6" >
                                                                                                <div className="doc_row" >
                                                                                                    <label htmlFor="coiFile">Certificate of InCorporation (COI) </label>
                                                                                                    <p> Format: doc, pdf </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-6" >
                                                                                                <input type="file" name="coiFile" className="form-control" id="coiFile" onChange={changeDocumentCoi} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="dov_card" >
                                                                                        <div className="row g-3">
                                                                                            <div className="col-lg-6" >
                                                                                                <div className="doc_row" >
                                                                                                    <label htmlFor="moaFile">Memorandum of Association (MOA) </label>
                                                                                                    <p> Format: doc, pdf </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-6" >
                                                                                                <input type="file" className="form-control" id="moaFile" onChange={changeDocumentMoa} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="dov_card" >
                                                                                        <div className="row g-3">
                                                                                            <div className="col-lg-6" >
                                                                                                <div className="doc_row" >
                                                                                                    <label htmlFor="aoaFile">Articles of association (AOA) </label>
                                                                                                    <p> Format: doc, pdf </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-6" >
                                                                                                <input type="file" className="form-control" id="aoaFile" onChange={changeDocumentAoa} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="dov_card" >
                                                                                        <div className="row g-3">
                                                                                            <div className="col-lg-6" >
                                                                                                <div className="doc_row" >
                                                                                                    <label htmlFor="bankStatementFile"> Bank Statement </label>
                                                                                                    <p> Format: doc, pdf </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-6" >
                                                                                                <input type="file" className="form-control" id="bankStatementFile" onChange={changeDocumentBank} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="dov_card" >
                                                                                        <div className="row g-3">
                                                                                            <div className="col-lg-6" >
                                                                                                <div className="doc_row" >
                                                                                                    <label htmlFor="surveyCapitalFile"> Source Of Capital </label>
                                                                                                    <p> Format: doc, pdf </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-6" >
                                                                                                <input type="file" className="form-control" id="surveyCapitalFile" onChange={changeDocumentSurveyCapital} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="dov_card" >
                                                                                        <div className="row g-3">
                                                                                            <div className="col-lg-6" >
                                                                                                <div className="doc_row" >
                                                                                                    <label htmlFor="amlCftFile">  AML / CFT Policy </label>
                                                                                                    <p> Format: doc, pdf </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-6" >
                                                                                                <input type="file" className="form-control" id="amlCftFile" onChange={changeDocumentAmlCft} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="dov_card" >
                                                                                        <div className="row g-3">
                                                                                            <div className="col-lg-6" >
                                                                                                <div className="doc_row" >
                                                                                                    <label htmlFor="loaFile">  Letter of Authorization (LOA) </label>
                                                                                                    <p> Format: doc, pdf </p>
                                                                                                    <p className="text-underline cursor-pointer" onClick={genratePdfLoa}> Download Sample <b>
                                                                                                        <i className="ri-file-download-line text-success" style={{ fontSize: "18px" }}></i>
                                                                                                    </b> </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-6" >
                                                                                                <input type="file" className="form-control" id="loaFile" onChange={changeDocumentLoa} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="dov_card" >
                                                                                        <div className="row g-3">
                                                                                            <div className="col-lg-6" >
                                                                                                <div className="doc_row" >
                                                                                                    <label htmlFor="SanctionsFile">  Sanctions Questionnaires </label>
                                                                                                    <p> Format: doc, pdf </p>
                                                                                                    <p className="text-underline cursor-pointer" onClick={SanctionsPdf}> Download Sample <b>
                                                                                                        <i className="ri-file-download-line text-success" style={{ fontSize: "18px" }}></i>
                                                                                                    </b> </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-6" >
                                                                                                <input type="file" className="form-control" id="SanctionsFile" onChange={changeDocumentSanctions} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="dov_card" >
                                                                                        <div className="row g-3">
                                                                                            <div className="col-lg-6" >
                                                                                                <div className="doc_row" >
                                                                                                    <label htmlFor="assetsFile">  INR / Crypto Assets Declaration </label>
                                                                                                    <p> Format: doc, pdf </p>
                                                                                                    <p className="text-underline cursor-pointer" onClick={cryptAssetsPdf}> Download Sample <b>
                                                                                                        <i className="ri-file-download-line text-success" style={{ fontSize: "18px" }}></i>
                                                                                                    </b> </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-6" >
                                                                                                <input type="file" className="form-control" id="assetsFile" onChange={changeDocumentAssets} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="dov_card" >
                                                                                        <div className="row g-3">
                                                                                            <div className="col-lg-6" >
                                                                                                <div className="doc_row" >
                                                                                                    <label htmlFor="video">  Video Declaration </label>
                                                                                                    <p> Format: doc, pdf </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-6" >
                                                                                                <input type="file" className="form-control" id="video" onChange={changeDocumentVideo} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>

                                                                        {/* director form */}
                                                                        <div className="accordion-item">
                                                                            <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                                                    Directors Documents
                                                                                </button>
                                                                            </h2>
                                                                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo" data-bs-parent="#accordionPanelsStayOpenExample" >

                                                                                <div className="accordion-body">
                                                                                    {entityKycDetail?.directorsPan?.map((item, index) => {
                                                                                        return (
                                                                                            index <= activeIndex && (
                                                                                                <div className="new_gap pt-3" key={index} >

                                                                                                    <p className="mb-2" > <b>Director {index + 1} ({item?.name})  </b></p>

                                                                                                    <div className="dov_card mt-2">
                                                                                                        <div className="row g-3">
                                                                                                            <div className="col-lg-8">
                                                                                                                <div className="row g-3" >
                                                                                                                    <div className="col-lg-6" >
                                                                                                                        <label className="form-label" > Aadhar Card (Front) </label>
                                                                                                                        <input
                                                                                                                            type="file"
                                                                                                                            className="form-control"
                                                                                                                            id={`aadharFront-${index}`}
                                                                                                                            onChange={(e) => handleFileChange(e, index, 'aadharFront')}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <div className="col-lg-6" >
                                                                                                                        <label className="form-label" > Aadhar Card (Back) </label>
                                                                                                                        <input
                                                                                                                            type="file"
                                                                                                                            className="form-control"
                                                                                                                            id={`aadharBack-${index}`}
                                                                                                                            onChange={(e) => handleFileChange(e, index, 'aadharBack')}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                            <div className="col-lg-4">
                                                                                                                <label className="form-label" > Pan Card  </label>
                                                                                                                <input
                                                                                                                    type="file"
                                                                                                                    className="form-control"
                                                                                                                    id={`panCard-${index}`}
                                                                                                                    onChange={(e) => handleFileChange(e, index, 'panCard')}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        );
                                                                                    })}

                                                                                </div>


                                                                            </div>
                                                                        </div>

                                                                        <div className="accordion-item">
                                                                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                                                    Other Documents
                                                                                </button>
                                                                            </h2>
                                                                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree" data-bs-parent="#accordionPanelsStayOpenExample" >
                                                                                <div className="accordion-body">
                                                                                    <div className="dov_card mt-2" >
                                                                                        <label className="form-label" > Upload Assets </label>
                                                                                        <input
                                                                                            className="form-control"
                                                                                            type="file"
                                                                                            multiple
                                                                                            onChange={handleImageChanges}
                                                                                            accept="image/*"
                                                                                        />
                                                                                        <div className="upd_images" >
                                                                                            {
                                                                                                selectedImages.map((image, index) => (
                                                                                                    <div className="upd_box">
                                                                                                        <div className="upd_card" >
                                                                                                            <img src={URL.createObjectURL(image)} alt="uploaded" style={{ width: "100px", height: "100px", objectFit: "cover" }} />
                                                                                                            {/* <p>{image.name}</p> */}
                                                                                                            <button className="btn btn-sm  btn-outline-danger" onClick={() => handleRemoveImage(index)} type="button"> Remove </button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>





                                                                    <div className="row" >
                                                                        <div className="col-md-12" >
                                                                            <div className="form-group mt-4 " >
                                                                                <button className="form-control btn-warning  btn-block w-100" type="button" onClick={() => handleSubmitEntityDocument()} >
                                                                                    {
                                                                                        isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>SUBMIT</span>
                                                                                    }
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :

                                                                <>
                                                                    <div className="row justify-content-center" >
                                                                        <div className="col-xl-6 col-xxl-4 text-center mt-5 ">
                                                                            <h5>Document Uploaded</h5>
                                                                            <img src="/assets/images/verified_documents.svg" className="img-fluid" />
                                                                            <div className="form-group mt-4 " >
                                                                                <button className="form-control btn-warning btn-block w-100" type="button" onClick={() => handleUpdateKycStep("step7")}>
                                                                                    {
                                                                                        isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Continue to the Next Step </span>
                                                                                    }
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                        }
                                                    </fieldset>
                                                }

                                                {/* step 7 */}
                                                {
                                                    kycFormStep === "step7" &&
                                                    <fieldset className="current">
                                                        <div className="form-card">
                                                            <div className="row">
                                                                <div className="p_card mt-2 p-3">
                                                                    <h5>Declaration and Submission</h5>
                                                                    <ul className="desc mb-0">
                                                                        <p>In submitting and returning the above information/documents,
                                                                            I fully understand and confirm for and on behalf of the Company that:</p>
                                                                        <li className="mb-2">
                                                                            I have full power and authority to open the Binance Entity Account and to enter
                                                                            into the associated terms and conditions and representations for and on behalf of the applicant company.
                                                                        </li>
                                                                        <li className="mb-2">
                                                                            I have read, understood, and agreed to be bound by the applicable terms and conditions as published on Binance,
                                                                            including but not limited to the terms and conditions and the Privacy Policy.
                                                                        </li>
                                                                        <li className="mb-2">
                                                                            The information provided in this Entity Account Application is to the best of my/our knowledge true, accurate, and complete.
                                                                        </li>
                                                                        <li>
                                                                            I will notify Binance, or where applicable its affiliates, of any changes to the information provided to Binance promptly,
                                                                            but no later than 7 business days following the occurrence of such changes.
                                                                        </li>
                                                                    </ul>
                                                                    <div className="avil_bal my-2 mb-1 " >
                                                                        <div className="form-check cursor-pointer  d-block">
                                                                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" defaultChecked={checkButton} value={checkButton} onClick={() => setCheckButton((prev) => !prev)} />
                                                                            <label className="form-check-label" label="flexCheckDefault">
                                                                                <b>  I Understand and<a href="/policies" className="text-black text-underline" target="_blank">  &nbsp; agree </a>
                                                                                    &nbsp; to the declaration </b>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row" >
                                                                <div className="col-md-12" >
                                                                    <div className="form-group mt-4 " >
                                                                        <button className="btn form-control btn-dark  btn-block w-100 " type="button" onClick={() => {
                                                                            if (!checkButton) {
                                                                                alertErrorMessage('Please check Declaration and Submission');
                                                                                return;
                                                                            }
                                                                            setIsActivePrevieModel(true)
                                                                        }}> Preview </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                }

                                            </div>
                                        </form>
                                    </div>
                                    :
                                    <fieldset className="current">
                                        <div className="form-card">
                                            <div className="kyc_stst" >
                                                <div className="kyc_row" >
                                                    <h5> Pan Card </h5>
                                                    <span className="ky_ badge badge-success"><i className="ri-check-double-line"></i>Verification Success</span>
                                                </div>
                                                <div className="kyc_row" >
                                                    <h5> Entity Details </h5>
                                                    <span className={`ky_ badge ${userKycStatus === 2 ? "badge-success" : userKycStatus === 1 ? "badge-warning bg-warning" : "badge-danger"}`}>

                                                        <i className={userKycStatus === 2 ? "ri-check-double-line" : userKycStatus === 1 ? "ri-error-warning-line" : "ri-close-circle-line"} ></i>

                                                        {userKycStatus == 2 ? "Verification Success" : userKycStatus == 1 ? "Verification Pending" : "Verification Failed!"}
                                                    </span>
                                                </div>
                                                <div className="kyc_row" >
                                                    <h5> Directors </h5>
                                                    {/*   <span className={`ky_ badge ${userKycStatus === 2 ? "badge-success" : userKycStatus === 1 ? "badge-warning bg-warning" : "badge-danger"}`} >

                                                        <i className={userKycStatus === 2 ? "ri-check-double-line" : userKycStatus === 1 ? "ri-error-warning-line" : "ri-close-circle-line"} ></i>

                                                        {userKycStatus == 2 ? "Verification Success" : userKycStatus == 1 ? "Verification Pending" : "Verification Failed!"}
                                                    </span> */}
                                                    <span className="ky_ badge badge-success"><i className="ri-check-double-line"></i>Verification Success</span>
                                                </div>
                                                <div className="kyc_row" >
                                                    <h5> UBO Details </h5>
                                                    {/*   <span className={`ky_ badge ${userKycStatus === 2 ? "badge-success" : userKycStatus === 1 ? "badge-warning bg-warning" : "badge-danger"}`} >

                                                        <i className={userKycStatus === 2 ? "ri-check-double-line" : userKycStatus === 1 ? "ri-error-warning-line" : "ri-close-circle-line"} ></i>

                                                        {userKycStatus == 2 ? "Verification Success" : userKycStatus == 1 ? "Verification Pending" : "Verification Failed!"}
                                                    </span> */}
                                                    <span className="ky_ badge badge-success"><i className="ri-check-double-line"></i>Verification Success</span>
                                                </div>
                                                <div className="kyc_row" >
                                                    <h5>Main Traders </h5>
                                                    {/*  <span className={`ky_ badge ${userKycStatus === 2 ? "badge-success" : userKycStatus === 1 ? "badge-warning bg-warning" : "badge-danger"}`} >

                                                        <i className={userKycStatus === 2 ? "ri-check-double-line" : userKycStatus === 1 ? "ri-error-warning-line" : "ri-close-circle-line"} ></i>

                                                        {userKycStatus == 2 ? "Verification Success" : userKycStatus == 1 ? "Verification Pending" : "Verification Failed!"}
                                                    </span> */}
                                                    <span className="ky_ badge badge-success"><i className="ri-check-double-line"></i>Verification Success</span>
                                                </div>
                                                <div className="kyc_row" >
                                                    <h5>Documents Details </h5>
                                                    <span className={`ky_ badge ${userKycStatus === 2 ? "badge-success" : userKycStatus === 1 ? "badge-warning bg-warning" : "badge-danger"}`} >

                                                        <i className={userKycStatus === 2 ? "ri-check-double-line" : userKycStatus === 1 ? "ri-error-warning-line" : "ri-close-circle-line"} ></i>

                                                        {userKycStatus == 2 ? "Verification Success" : userKycStatus == 1 ? "Verification Pending" : "Verification Failed!"}
                                                    </span>
                                                </div>
                                                <div className="kyc_row" >
                                                    <h5> Declaration and Submission </h5>
                                                    <span className={`ky_ badge ${userKycStatus === 2 ? "badge-success" : userKycStatus === 1 ? "badge-warning bg-warning" : "badge-danger"}`} >

                                                        <i className={userKycStatus === 2 ? "ri-check-double-line" : userKycStatus === 1 ? "ri-error-warning-line" : "ri-close-circle-line"} ></i>

                                                        {userKycStatus == 2 ? "Verification Success" : userKycStatus == 1 ? "Verification Pending" : "Verification Failed!"}
                                                    </span>
                                                </div>
                                                {userKycStatus == 3 ?
                                                    <div className="kyc_row justify-content-center" >
                                                        <div className="text-center mt-4" >
                                                            <p> Account verification request rejected...
                                                                (Check submitted information for accuracy and follow guidelines for resubmission or appeal.)<br />
                                                                <span className="text-danger">({userDetails?.user?.kyc_reject_reason}) </span>
                                                            </p>
                                                            <button className="btn btn-warning custom-btn" type="button" onClick={() => setUserKycStatus(0)}> Verify KYC Again </button>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                {userKycStatus == 1 ?
                                                    <div className="kyc_row justify-content-center" >
                                                        <div className="text-center mt-1" >
                                                            <i className="ri-error-warning-line me-1 text-warning" style={{ fontSize: "30px" }}></i>
                                                            <p className="text-warning mb-0">
                                                                Your Kyc is Under Review </p>
                                                            <p >You will receive an email once the verification is complete.</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </fieldset>
                            }
                        </div>
                    </section>
                </div >

                {isActivePrevieModel && (
                    <div div className="modal-open-custom" >
                        <div className="modal show d-block" >
                            <div className="modal-dialog modal-md">
                                <div className="modal-content">
                                    <div className="modal-header d-flex justify-content-between  px-md-4">
                                        <h5 className="modal-title" id="modal_otp_Label">{entityKycDetail?.entityPan?.name} Details</h5>
                                        <button type="button" className="btn-close" onClick={() => setIsActivePrevieModel(false)}></button>
                                    </div>
                                    <div className="modal-body p-md-4 ">
                                        <div className="btn-group btn_radio_group p-0">
                                            <button className={`btn btn-outline-primary  ${activeSection === 'pan' ? 'active' : ''}`}
                                                onClick={() => setActiveSection('pan')}>Pan Details</button>
                                            <button className={`btn btn-outline-primary  ${activeSection === 'entity' ? 'active' : ''}`}
                                                onClick={() => setActiveSection('entity')}>Entity Details</button>
                                            <button className={`btn btn-outline-primary  ${activeSection === 'directors' ? 'active' : ''}`}
                                                onClick={() => setActiveSection('directors')}>Directors & UBO Details</button>
                                        </div>
                                        <div className={`tt_data ${activeSection === 'pan' ? '' : 'd-none'}`}>
                                            <span className="my-2 d-block"><b>Pan Details</b></span>
                                            <div className="tt_card outline-success">
                                                <div className="tt_item no-border">
                                                    <span className="text-muted"> User Name: </span>
                                                    <span className="tt_normal">
                                                        {entityKycDetail?.entityPan?.name} &nbsp;
                                                        <i className="ri-verified-badge-fill text-success"></i>
                                                    </span>
                                                </div>
                                                <div className="tt_item no-border">
                                                    <span className="text-muted"> Pan Number: </span>

                                                    <span className="tt_normal">
                                                        {entityKycDetail?.entityPan?.pan} &nbsp;
                                                        <i className="ri-verified-badge-fill text-success"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`tt_data mt-2 ${activeSection === 'entity' ? '' : 'd-none'}`}>
                                            <span className="my-2 d-block"><b>Entity Details</b></span>
                                            <div className="tt_card outline-success">
                                                <div className="tt_item no-border">
                                                    <span className="text-muted"> Entity Name: </span>
                                                    <span className="tt_normal">
                                                        {entityKycDetail?.entityRecord?.entity_name} &nbsp;
                                                        <i className="ri-verified-badge-fill text-success"></i>
                                                    </span>
                                                </div>
                                                <div className="tt_item no-border">
                                                    <span className="text-muted"> Entity Type: </span>
                                                    <span className="tt_normal">
                                                        {entityKycDetail?.entityRecord?.entity_type} &nbsp;
                                                        <i className="ri-verified-badge-fill text-success"></i>
                                                    </span>
                                                </div>
                                                <div className="tt_item no-border">
                                                    <span className="text-muted"> Registration Number: </span>
                                                    <span className="tt_normal">
                                                        {entityKycDetail?.entityRecord?.registration_number} &nbsp;
                                                        <i className="ri-verified-badge-fill text-success"></i>
                                                    </span>
                                                </div>
                                                <div className="tt_item no-border">
                                                    <span className="text-muted"> Email ID: </span>
                                                    <span className="tt_normal">
                                                        {entityKycDetail?.entityRecord?.email_id} &nbsp;
                                                        <i className="ri-verified-badge-fill text-success"></i>
                                                    </span>
                                                </div>
                                                <div className="tt_item no-border">
                                                    <span className="text-muted"> Mobile Number: </span>
                                                    <span className="tt_normal">
                                                        {entityKycDetail?.entityRecord?.mobile_number} &nbsp;
                                                        <i className="ri-verified-badge-fill text-success"></i>
                                                    </span>
                                                </div>
                                                <div className="tt_item no-border">
                                                    <span className="text-muted"> Registerd Address: </span>
                                                    <span className="tt_normal">
                                                        {entityKycDetail?.entityRecord?.registered_address} &nbsp;
                                                        <i className="ri-verified-badge-fill text-success"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`tt_data mt-2 ${activeSection === 'directors' ? '' : 'd-none'}`}>
                                            <span className="my-2 d-block"><b>{entityKycDetail?.entityRecord?.entity_type === "Partnership" ? "Partner" : "Director"} & UBO Details</b></span>
                                            <div className="tt_card outline-success">
                                                {entityKycDetail && entityKycDetail?.directorsPan?.map((item, index) => (
                                                    <>
                                                        <div className="d-flex justify-content-between my-1">
                                                            <small> <b> {entityKycDetail?.entityRecord?.entity_type === "Partnership" ? "Partner" : "Director"} {item?.directorId}</b></small>
                                                            <span className="badge bg-success text-dark">
                                                                {matchingDirector?.name?.toUpperCase() === item?.name?.toUpperCase() ? "Main Trader" : ""}
                                                            </span>
                                                        </div>
                                                        <div className="tt_item no-border">
                                                            <span className="text-muted"> {entityKycDetail?.entityRecord?.entity_type === "Partnership" ? "Partner" : "Director"} Name: </span>
                                                            <span className="tt_normal">
                                                                {item?.name} &nbsp;
                                                                <i className="ri-verified-badge-fill text-success"></i>
                                                            </span>
                                                        </div>
                                                        <div className="tt_item no-border">
                                                            <span className="text-muted"> Aadhar Number: </span>
                                                            <span className="tt_normal">
                                                                {item?.maskedAadhaarNumber} &nbsp;
                                                                <i className="ri-verified-badge-fill text-success"></i>
                                                            </span>
                                                        </div>
                                                        <div className="tt_item no-border">
                                                            <span className="text-muted"> Pan Number: </span>
                                                            <span className="tt_normal">
                                                                {item?.pan} &nbsp;
                                                                <i className="ri-verified-badge-fill text-success"></i>
                                                            </span>
                                                        </div>
                                                        <div className="tt_item no-border">
                                                            <span className="text-muted"> Percentage: </span>
                                                            <span className="tt_normal">
                                                                {entityKycDetail?.ubodetails?.find(ubo => ubo.panNumber === item.pan)?.sharePercentage} &nbsp;
                                                                <i className="ri-verified-badge-fill text-success"></i>
                                                            </span>
                                                        </div>
                                                        <hr className="my-2" />
                                                    </>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="row" >
                                            <div className="col-md-12" >
                                                <div className="form-group mt-4 " >
                                                    <button className="btn form-control btn-warning  btn-block w-100 " type="button" onClick={() => handleDeclarationSubmission()}> Submit </button>
                                                </div>
                                            </div>
                                            {/* <small className="text-center mt-4"><b>If You Have Fill Wrong Details Submit Now, and wait for support</b></small> */}

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="modal-backdrop fade show"> </div>
                    </div >
                )}

            </div >
        </main >
    )
}

export default EntityKyc