import React, { useContext, useEffect, useState } from "react";
import DashboardSidebar from "../../../../CoustamComponent/DashboardSidebar";
import { ProfileContext } from "../../../../../Services/ProfileProvider";
import Select from 'react-select';
import { alertErrorMessage, alertSuccessMessage } from "../../../../../Services/CustomAlertModel";
import AuthService from "../../../../../Services/ApiServices/AuthService";
import { format } from 'date-fns';
import Swal from "sweetalert2";
import moment from "moment";

const PersonalKyc = () => {

    const { userDetails, handleUserProfile } = useContext(ProfileContext);
    const [kycStatus, setKycStatus] = useState("");
    const [isPanValid, setIsPanValid] = useState(false);
    const [isAadharValid, setIsAadharValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPan, setIsLoadingPan] = useState(false);
    const [userKycDetails, setUserKycDetails] = useState([]);
    const [aadharOtpDetails, setAadharOtpDetails] = useState("");
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [checkButton, setCheckButton] = useState(false);
    const [kycFormStep, setKycFormStep] = useState('');
    const [isActivePanAadhar, setIsActivePanAadhar] = useState(false);
    const [isActiveLiveness, setIsActiveLiveness] = useState(false);
    const [isActiveEducation, setIsActiveEducation] = useState(false);
    const [isActiveAadharOtpField, setIsActiveAadharOtpField] = useState(false);
    const [livenessResult, setLivenessResult] = useState([]);
    const transactionId = localStorage.getItem("user_id");

    const [loading, setLoading] = useState(false);

    const maskName = (name) => {
        if (!name) return '';
        const words = name.trim().split(' ');
        const maskedWords = words.map((word) => {
            if (word.length <= 2) return word;
            const firstChar = word[0];
            const lastChar = word[word.length - 1];
            const maskedPart = '*'.repeat(word.length - 2);
            return `${firstChar}${maskedPart}${lastChar}`;
        });
        return maskedWords.join(' ');
    };

    const [formData, setFormData] = useState({
        panNumber: '',
        aadharNumber: '',
        aadharOtp: '',
        edituaction: '',
        maritalStatus: '',
        occupation: '',
        annualIncome: '',
        tradingTurnover: '',
        politically: false,
    });

    useEffect(() => {
        if (userDetails) {
            setFormData(prevState => ({
                ...prevState,
                panNumber: userKycDetails?.pandetails?.pan || '',
                edituaction: userKycDetails?.otherDetails?.education || '',
                maritalStatus: userKycDetails?.otherDetails?.marital_status || '',
                occupation: userKycDetails?.otherDetails?.occupation || '',
                annualIncome: userKycDetails?.otherDetails?.annual_income || '',
                tradingTurnover: userKycDetails?.otherDetails?.annual_turnover || '',
                politically: userKycDetails?.otherDetails?.pep || false,
            }));
        }
        setKycStatus(userDetails?.user?.kycVerified);
    }, [userDetails, userKycDetails]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        if (name === "politically") {
            formattedValue = value === "true";
        }
        if (name === 'panNumber') {
            formattedValue = formattedValue.toUpperCase().replace(/\s/g, '');
            formattedValue = formattedValue.replace(/(.{5})(.{4})(.{1})/, '$1$2$3');
            const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
            setIsPanValid(panRegex.test(formattedValue));
        }

        setFormData(prevState => ({
            ...prevState,
            [name]: formattedValue
        }));
    };

    const handleAadharChange = (e) => {
        let input = e.target.value.replace(/\D/g, '');
        const regex = /^[2-9]{1}[0-9]{7}$/;
        const isValid = regex.test(input);
        setFormData({
            ...formData,
            aadharNumber: input,
        });
        setIsAadharValid(isValid);
        setIsOtpSent(false);
        setCountdown(0);
        setIsActiveAadharOtpField(false);
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return format(date, 'dd-MM-yyyy');
    };

    useEffect(() => {
        let timer;
        if (isOtpSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, countdown]);


    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'black'
                : state.isFocused
                    ? 'gainsboro'
                    : 'white',
            color: state.isSelected
                ? 'white'
                : state.isFocused
                    ? 'dark'
                    : 'black',
            fontSize: '14px',
            borderRadius: '4px',
            margin: '0px',
        }),
    };


    const is18OrAbove = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);
        let age = today.getFullYear() - birth.getFullYear();
        const monthDifference = today.getMonth() - birth.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
            age--;
        }
        return age >= 18;
    };

    const handleuserStep = async () => {
        const result = await AuthService.getuserKycStep();
        if (result?.success) {
            setKycFormStep(result.data?.step || "step1");
            handleGetKycDetails();
        }
    };

    const handleUpdateKycStep = async (step) => {
        if (!userKycDetails?.pandetails?.aadhaarLinked) {
            alertErrorMessage("Your PAN is not linked with Aadhaar")
            return;
        } if (!is18OrAbove(userKycDetails?.pandetails?.dateOfBirth)) {
            alertErrorMessage("your Age is below 18 Year")
            return;
        }
        setIsLoading(true);
        try {
            const result = await AuthService.updateKycStepStatus(step);
            if (result?.success) {
                setIsLoading(false);
                handleGetKycDetails();
                handleuserStep();
            } else {
                setIsLoading(false);
                alertErrorMessage(result);
            }
        } catch (error) {
            setIsLoading(false);
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        handleuserStep();
    }, [])

    const handleSubmitData = async (item) => {
        setIsLoadingPan(true);
        try {
            const result = await AuthService.verifyPancard(item);
            if (result?.success) {
                setIsLoadingPan(false);
                setIsPanValid(false);
                handleAmlCheck(result?.data?.pan, result?.data?.name, result?.data?.dateOfBirth);
            } else {
                setIsLoadingPan(false);
                alertErrorMessage(result);

            }
        } catch (error) {
            setIsLoadingPan(false);
        }
    };

    const handleAmlCheck = async (pan, name, dateOfBirth) => {
        try {
            await AuthService.getAmlCheckStatus(pan, name, formatDate(dateOfBirth)).then(async (result) => {
                if (result?.success) {
                    alertSuccessMessage("Pan Check Successfully");
                    handleGetKycDetails();
                } else {
                    alertErrorMessage(result);
                }
            });
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const handlegetOtpAAdhar = async (aadharNumber) => {
        setIsLoadingPan(true);
        try {
            const referenceId = Math.floor(100000 + Math.random() * 900000);
            const result = await AuthService.verifyAadharDocument(aadharNumber, referenceId);
            if (result?.success) {
                alertSuccessMessage(result.message);
                setAadharOtpDetails(result.data);
                setIsActiveAadharOtpField(true);
                setIsOtpSent(true);
                setCountdown(60);
                setIsLoadingPan(false);
            } else {
                alertErrorMessage(result);
                setIsOtpSent(false);
                setCountdown(0);
                setIsLoadingPan(false);
            }
        } catch (error) {
            alertErrorMessage(error);
            setIsLoadingPan(false);
        }
    };

    const handleVerifyAadhar = async (aadharOtp) => {
        setIsLoading(true);
        try {
            const result = await AuthService.getAadharDetails(aadharOtp, aadharOtpDetails?.transaction_id, aadharOtpDetails?.reference_id, formData?.panNumber);
            if (result?.success) {
                setIsLoading(false);
                handleGetKycDetails();
            } else {
                setIsLoading(false);
                alertErrorMessage(result?.message);
            }
        } catch (error) {
            setIsLoading(false);
            alertErrorMessage(error);
        }
    };




    const options = [
        { value: '', label: 'Please select one' },
        { value: 'Self Employe', label: 'Self Employed' },
        { value: 'Salaried Employe', label: 'Salaried Employee' },
        { value: 'House Wife', label: 'House Wife' },
        { value: 'Retired Person', label: 'Retired Person' },
        { value: 'unemployed', label: 'Unemployed' },
    ];

    const optionsEducation = [
        { value: '', label: 'Please select one' },
        { value: 'Matriculation', label: 'Matriculation' },
        { value: 'Higher Secondary', label: 'Higher Secondary' },
        { value: 'Gradutaion', label: 'Gradutaion' },
        { value: 'Post Gradutaion', label: 'Post Gradutaion' },
        { value: 'professional', label: 'Professional' },
    ];

    const optionsMaritalStatus = [
        { value: '', label: 'Please select one' },
        { value: 'Single', label: 'Single' },
        { value: 'Married', label: 'Married' },
    ];

    const optionsAnnualIncome = [
        { value: '', label: 'Please select one' },
        { value: '300000', label: '0-300000' },
        { value: '700000', label: '300001 - 700000' },
        { value: '1000000', label: '700001 - 1000000' },
        { value: '1200000', label: '1000001 - 1200000' },
        { value: '1500000', label: '1200001 - 1500000' },
        { value: '1500001', label: 'Above 15 Lakh' },
    ];

    const tradingTrunOver = [
        { value: '', label: 'Please select one' },
        { value: '10000000', label: '0-10000000' },
        { value: '50000000', label: '10000000 - 50000000' },
        { value: '500000000', label: '50000000 - 500000000' },
        { value: '500000001', label: 'Above 50 Crore' },
    ];

    const handleChange = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'occupation',
                value: selectedOption.value,
            },
        });
    };

    const handleChangeEducation = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'edituaction',
                value: selectedOption.value,
            },
        });

    };

    const handleChangeMarital = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'maritalStatus',
                value: selectedOption.value,
            },
        });
    };

    const handleChangeAnualIncome = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'annualIncome',
                value: selectedOption.value,
            },
        });
    };

    const handleChangeTradingTurnover = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'tradingTurnover',
                value: selectedOption.value,
            },
        });
    };

    const handleEducationOccupation = async () => {
        try {
            if (!formData?.edituaction) {
                alertErrorMessage('Education is required.');
                return;
            }
            if (!formData?.maritalStatus) {
                alertErrorMessage('Marital status is required.');
                return;
            }
            if (!formData?.occupation) {
                alertErrorMessage('Occupation is required.');
                return;
            }
            if (!formData?.annualIncome || formData?.annualIncome <= 0) {
                alertErrorMessage('Enter valid annual income.');
                return;
            }
            if (!formData?.tradingTurnover || formData?.tradingTurnover <= 0) {
                alertErrorMessage('Enter valid trading turnover.');
                return;
            }

            await AuthService.submitEducationOccupation(formData).then(async (result) => {
                if (result?.success) {
                    handleGetKycDetails();
                    alertSuccessMessage("Education & Occupation Submited Successfully");
                } else {
                    alertErrorMessage(result);
                }
            });
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const handleGetKycDetails = async () => {
        try {
            const result = await AuthService.getKycDetails();
            if (result?.success) {
                setUserKycDetails(result.data);
            } else {
                // alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };


    useEffect(() => {
        handleGetKycDetails();
    }, []);


    const showAlert = () => {
        Swal.fire({
            title: userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" && !userKycDetails?.otherDetails?.pep ? "Successful" : "Kyc Pending",
            text: userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" && !userKycDetails?.otherDetails?.pep ? "Your Account has been verified successfully" : "Your Kyc is Under Review",
            icon: userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" && !userKycDetails?.otherDetails?.pep ? "success" : "warning",
            confirmButtonColor: userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" && !userKycDetails?.otherDetails?.pep ? "#40ad15" : "#F0B90B",
            confirmButtonText: userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" && !userKycDetails?.otherDetails?.pep ? "Start Trading" : "Kyc In Review",
            preConfirm: () => {
                if (userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" && !userKycDetails?.otherDetails?.pep) {
                    window.location.href = '/exchange/:pairName';
                }
            }
        });
    };

    const handleDeclarationSubmission = async () => {
        if (!checkButton) {
            alertErrorMessage('Please check Declaration and Submission');
            return;
        }
        try {
            const result = await AuthService.declaration();
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleSubmitKyc(userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" && userKycDetails?.otherDetails?.pep === false ? 2 : 1);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    }

    const handleSubmitKyc = async (status) => {
        const result = await AuthService.updateKycStatus(status);
        if (result?.success) {
            handleUserProfile();
            showAlert();
        } else {
            alertErrorMessage(result);
        }
    }

    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const formattedDateTime = currentDateTime.toLocaleString();



    // Step2 Functions Start

    const handleLivenessResult = async () => {
        setLoading(true);
        try {
            const resultData = await AuthService.getLinkKycResult();
            if (resultData?.success) {
                setLivenessResult(resultData?.data);
                handleFaceMatch(resultData?.data?.results?.[0]?.imageUrl);
            }
        } catch (error) {
            alertErrorMessage(error);
        } finally {
            setLoading(false);
        }
    };

    const handleLivenessResultAgain = async () => {
        setLoading(true);
        try {
            const resultData = await AuthService.getLinkKycResult();
            if (resultData?.data?.results?.[0]?.imageUrl) {
                setLivenessResult(resultData?.data);
                handleFaceMatch(resultData?.data?.results?.[0]?.imageUrl);
            } else {
                handleLivenessResult();
            }
        } catch (error) {
            alertErrorMessage(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (kycFormStep === "step2") {
            handleLivenessResult();
        }
    }, [kycFormStep]);

    const handleAuthLivenessSdk = async () => {
        setLoading(true);
        try {
            const resultData = await AuthService.authDataSdk();
            if (resultData?.success) {
                startOnboarding(resultData?.data?.result?.token);
            } else {
                alertErrorMessage("Failed to authenticate liveness SDK.");
            }
        } catch (error) {
            alertErrorMessage(error);
        } finally {
            setLoading(false);
        }
    };

    const workflowId = "liveness";
    const startOnboarding = (sdkToken) => {
        if (window.HyperKycConfig && window.HyperKYCModule) {
            const hyperKycConfig = new window.HyperKycConfig(
                sdkToken,
                workflowId,
                transactionId,
            );
            window.HyperKYCModule.launch(hyperKycConfig, (sdkResult) => {
                handleLivenessResultAgain(sdkResult);
                handleLivenessResult();
            });
        } else {
            console.error("HyperKYCModule or HyperKycConfig is not loaded.");
        }
    };

    /*   const handleFaceMatch = async (imageLive) => {
          try {
              setLoading(true);
              const result = await AuthService.getFaceMatchData(
                  imageLive,
                  userKycDetails?.adhaardetails?.adhaar_image
              );
  
              if (result?.success) {
                  handleGetKycDetails();
              } else {
                  handleLivenessResult();
                  throw new Error("Face match failed. Please retry.");
              }
          } catch (error) {
              alertErrorMessage(error.message || error);
              handleLivenessResult();
          } finally {
              setLoading(false);
          }
      }; */


    const handleFaceMatch = async (imageLive) => {
        try {
            await AuthService.getFaceMatchData(imageLive, userKycDetails?.adhaardetails?.adhaar_image).then(async (result) => {
                if (result?.success) {
                    handleGetKycDetails();
                }
            });
        } catch (error) {
            handleLivenessResult();
        }
    };


    // useEffect(() => {
    //     const scriptId = "dotlottie-player-script";
    //     if (!document.getElementById(scriptId) && !customElements.get("dotlottie-player")) {
    //         const script = document.createElement("script");
    //         script.src = "https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/hyperverge-web-sdk@8.10.1/src/sdk.min.js";
    //         script.async = true;
    //         script.id = scriptId;           
    //         document.body.appendChild(script);
    //         return () => {              
    //             document.body.removeChild(script);
    //         };
    //     }
    // }, []);

    // Step2 Functions End

    return (
        <main>
            <div className="page_wrapper">
                <div className="sidebar">
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper">
                    <section className="dd_page">
                        <div className="container">
                            <div className="dd_title text-center">
                                <h2 className="text-warning">KYC Verification</h2>
                                <p>{kycStatus == 0 ? 'Complete your KYC in just 5 steps' : ""}</p>
                            </div>
                            {
                                kycStatus == 0 ?
                                    <div className="mt_form">
                                        <ul id="progressbar">
                                            <ul id="progressbar">
                                                <li className={`cursor-pointer ${userKycDetails?.pandetails && 'active'}`} id="step1"
                                                    onClick={() => setIsActivePanAadhar(true)}>
                                                    <strong>Proof of Identification</strong>
                                                </li>
                                                <li className={`cursor-pointer ${userKycDetails?.facematchdetails && 'active'}`} id="step2"
                                                    onClick={() => setIsActiveLiveness(true)}>
                                                    <strong>Check Liveness</strong>
                                                </li>
                                                <li className={`cursor-pointer ${userKycDetails?.otherDetails && 'active'}`} id="step3"
                                                    onClick={() => setIsActiveEducation(true)}>
                                                    <strong>Education & Occupation</strong>
                                                </li>
                                                <li id="step4"><strong>Declaration and Submission</strong>
                                                </li>
                                            </ul>
                                        </ul>

                                        <form className="multistep_form row justify-content-center">
                                            <div className="col-lg-10">

                                                {/* Step 1 */}
                                                {
                                                    kycFormStep === "step1" &&
                                                    <fieldset  className="current">
                                                        <div className="form-card">
                                                            <div className="row justify-content-center ">
                                                                <div className="col-xl-6 mb-4">
                                                                    <div className="field-box mb-3">
                                                                        <label htmlFor="panNumber" className="form-label">Pan Number</label>
                                                                        <div className="field-otp-box">
                                                                            <div className="form-control p-0 overflow-hidden">
                                                                                <input
                                                                                    id="panNumber"
                                                                                    name="panNumber"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    value={formData.panNumber}
                                                                                    onChange={handleInputChange}
                                                                                    placeholder="Enter Your Pan Number"
                                                                                    maxLength={10}
                                                                                    disabled={userKycDetails?.pandetails?.verified}
                                                                                />
                                                                            </div>
                                                                            {isPanValid && formData?.panNumber !== userKycDetails?.pandetails?.pan ? (
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-xs custom-btn"
                                                                                    onClick={() => handleSubmitData(formData.panNumber)}
                                                                                    disabled={isLoadingPan}
                                                                                >
                                                                                    {isLoadingPan ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Verify</span>}
                                                                                </button>
                                                                            )
                                                                                :
                                                                                userKycDetails?.pandetails?.name && formData?.panNumber === userKycDetails?.pandetails?.pan ?
                                                                                    <button type="button" className="btn btn-xs success-btn">
                                                                                        {maskName(userKycDetails?.pandetails?.name)} &nbsp;
                                                                                        <i className="ri-verified-badge-fill text-success"></i>
                                                                                    </button>
                                                                                    :
                                                                                    ""
                                                                            }
                                                                        </div>
                                                                    </div>


                                                                    <>
                                                                        {!userKycDetails?.adhaardetails && userKycDetails?.pandetails && formData?.panNumber === userKycDetails?.pandetails?.pan ? (
                                                                            <>
                                                                                <div className="field-box mb-3">
                                                                                    <label htmlFor="aadharNumber" className="form-label">Aadhar Number</label>
                                                                                    <div className="field-otp-box" >
                                                                                        <div className="combined-input combined-input form-control p-0 verflow-hidden">
                                                                                            <input
                                                                                                id="aadharNumber"
                                                                                                name="aadharNumber"
                                                                                                type="text"
                                                                                                className="form-control no-border pe-0"
                                                                                                maxLength="8"
                                                                                                value={formData.aadharNumber}
                                                                                                onChange={handleAadharChange}
                                                                                                autoComplete="off"
                                                                                                style={{ width: '95px' }}
                                                                                                placeholder="-----------"
                                                                                            />
                                                                                            <span
                                                                                                style={{ borderLeft: "0px" }}
                                                                                                className="form-control  no-border ps-0 last-four-input text-muted"
                                                                                                value={userKycDetails?.pandetails?.maskedAadhaarNumber?.slice(-4)}
                                                                                            >{userKycDetails?.pandetails?.maskedAadhaarNumber?.slice(-4)}</span>
                                                                                        </div>
                                                                                        {isAadharValid && (
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-xs custom-btn"
                                                                                                onClick={() => handlegetOtpAAdhar(formData.aadharNumber + userKycDetails?.pandetails?.maskedAadhaarNumber?.slice(-4))}
                                                                                                disabled={isOtpSent}
                                                                                            >
                                                                                                {
                                                                                                    isLoadingPan ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                                                                                                        <span>
                                                                                                            {isOtpSent ? `Resend in ${countdown}s` : 'GET OTP'}
                                                                                                        </span>
                                                                                                }

                                                                                            </button>
                                                                                        )}
                                                                                    </div>
                                                                                </div>

                                                                                {isActiveAadharOtpField && formData.aadharNumber.length == 8 && (

                                                                                    <div className="field-box mb-3">
                                                                                        <label htmlFor="aadharOtp" className="form-label">Enter Aadhar Otp</label>
                                                                                        <div className="field-otp-box">
                                                                                            <div className="form-control p-0 overflow-hidden">
                                                                                                <input
                                                                                                    id="aadharOtp"
                                                                                                    name="aadharOtp"
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    value={formData.aadharOtp}
                                                                                                    onChange={(e) => {
                                                                                                        if (/^\d{0,6}$/.test(e.target.value)) {
                                                                                                            handleInputChange(e);
                                                                                                        }
                                                                                                    }}
                                                                                                    required
                                                                                                    maxLength={6}
                                                                                                />
                                                                                            </div>

                                                                                            {formData.aadharOtp.length === 6 && (
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-xs custom-btn"
                                                                                                    onClick={() => handleVerifyAadhar(formData.aadharOtp)}
                                                                                                    disabled={isLoading}
                                                                                                >
                                                                                                    {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Verify</span>}
                                                                                                </button>
                                                                                            )}

                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            userKycDetails?.pandetails?.verified ?
                                                                                <>
                                                                                    <label className="form-label">Aadhar Name</label>
                                                                                    <div className="vn_card mt-0">
                                                                                        {userKycDetails?.adhaardetails?.full_name}
                                                                                        <i className="ri-verified-badge-fill"></i>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                ""
                                                                        )}

                                                                        {userKycDetails?.adhaardetails && userKycDetails?.pandetails?.verified && (
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <div className="form-group mt-4">
                                                                                        <button className="form-control btn-warning btn-block w-100" type="button" onClick={() => handleUpdateKycStep("step2")}>
                                                                                            Continue to the Next Step
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                }


                                                {/* Step 2 */}
                                                {
                                                    kycFormStep === "step2" &&
                                                    <fieldset className="current">
                                                        <div className="form-card">
                                                            <div className="row justify-content-center ">
                                                                <div className="col-xl-6 mb-4">
                                                                    {loading ? (
                                                                        <div className="loader-overlay card_loader py-5 mt-4">
                                                                            <div className="spinner"></div>
                                                                            <p>Processing... Please wait.</p>
                                                                        </div>
                                                                    ) : (
                                                                        livenessResult?.applicationStatus === "auto_approved" || livenessResult?.applicationStatus === "started" ? (
                                                                            userKycDetails?.facematchdetails?.facematch_value === "yes" &&
                                                                                userKycDetails?.facematchdetails?.facematch_confidence === "high" ? (
                                                                                <>
                                                                                    <div className="web_cam_row">
                                                                                        <div className="web_cam_box success">
                                                                                            <img
                                                                                                src={livenessResult?.results?.[0]?.imageUrl}
                                                                                                alt="Liveness Check"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <button
                                                                                        className="form-control btn-warning btn-block w-100  mt-3"
                                                                                        type="button"
                                                                                        onClick={() => handleUpdateKycStep("step3")}
                                                                                    >
                                                                                        Continue to the Next Step
                                                                                    </button>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <div className="web_cam_row">
                                                                                        <div className="web_cam_box failed">
                                                                                            <img
                                                                                                src={livenessResult?.results?.[0]?.imageUrl}
                                                                                                alt="Liveness Check"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <button
                                                                                        className="form-control btn-warning btn-block w-100 mt-3"
                                                                                        type="button"
                                                                                        onClick={handleAuthLivenessSdk}
                                                                                    >
                                                                                        Verify Again
                                                                                    </button>
                                                                                </>
                                                                            )
                                                                        ) : livenessResult?.applicationStatus === "auto_declined" || livenessResult?.applicationStatus === "user_cancelled" ? (
                                                                            <>
                                                                                <div className="web_cam_row">
                                                                                    <div className="web_cam_box failed">
                                                                                        <img
                                                                                            src={livenessResult?.results?.[0]?.imageUrl || "/assets/images/liveness_thumb.svg"}
                                                                                            alt="Liveness Check"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <button
                                                                                    className="form-control btn-warning btn-block w-100  mt-3"
                                                                                    type="button"
                                                                                    onClick={handleAuthLivenessSdk}
                                                                                >
                                                                                    Verify Again
                                                                                </button>
                                                                            </>
                                                                        ) : (
                                                                            <div className="field-box">

                                                                                <img src="/assets/images/liveness_thumb.svg" alt="Liveness Check" />

                                                                                <button
                                                                                    className="form-control btn-dark btn-block w-100"
                                                                                    type="button"
                                                                                    onClick={handleAuthLivenessSdk}
                                                                                >
                                                                                    Start Liveness
                                                                                </button>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                }

                                                {/* Step 3 */}
                                                {
                                                    kycFormStep === "step3" &&
                                                    <fieldset  className="current">
                                                        <div className="form-card">
                                                            <h6 className="mb-4" >Education & Occupation </h6>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <div className="form-group  mb-4" >
                                                                        <label htmlFor="edituaction"> Education </label>
                                                                        <Select
                                                                            name="edituaction"
                                                                            id="edituaction"
                                                                            value={optionsEducation.find(option => option.value === formData.edituaction)}
                                                                            onChange={handleChangeEducation}
                                                                            options={optionsEducation}
                                                                            styles={customStyles}
                                                                            isDisabled={userKycDetails?.otherDetails}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6" >
                                                                    <div className="form-group  mb-4" >
                                                                        <label htmlFor="maritalStatus"> Marital Status </label>
                                                                        <Select
                                                                            name="maritalStatus"
                                                                            id="maritalStatus"
                                                                            value={optionsMaritalStatus.find(option => option.value === formData.maritalStatus)}
                                                                            onChange={handleChangeMarital}
                                                                            options={optionsMaritalStatus}
                                                                            styles={customStyles}
                                                                            isDisabled={userKycDetails?.otherDetails}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4" >
                                                                    <div className="form-group  mb-4" >
                                                                        <label htmlFor="occupation"> Occupation </label>
                                                                        <Select
                                                                            name="occupation"
                                                                            id="occupation"
                                                                            value={options.find(option => option.value === formData.occupation)}
                                                                            onChange={handleChange}
                                                                            options={options}
                                                                            styles={customStyles}
                                                                            isDisabled={userKycDetails?.otherDetails}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="annualIncome" className="form-label">Annual Income In Rupees</label>

                                                                        <Select
                                                                            name="annualIncome"
                                                                            id="annualIncome"
                                                                            value={optionsAnnualIncome.find(option => option.value == formData.annualIncome)}
                                                                            onChange={handleChangeAnualIncome}
                                                                            options={optionsAnnualIncome}
                                                                            styles={customStyles}
                                                                            isDisabled={userKycDetails?.otherDetails}
                                                                        />


                                                                        {/*   <input id="annualIncome" type="number" className="form-control" name="annualIncome" value={formData.annualIncome} onChange={handleInputChange} disabled={userKycDetails?.otherDetails} /> */}
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4 mb-4">
                                                                    <div className="field-box">
                                                                        <label htmlFor="tradingTurnover" className="form-label">Estimated Annual Trading Turnover</label>
                                                                        {/*    <input id="tradingTurnover" type="number" className="form-control" name="tradingTurnover" value={formData.tradingTurnover} onChange={handleInputChange} disabled={userKycDetails?.otherDetails} /> */}
                                                                        <Select
                                                                            name="tradingTurnover"
                                                                            id="tradingTurnover"
                                                                            value={tradingTrunOver.find(option => option.value == formData.tradingTurnover)}
                                                                            onChange={handleChangeTradingTurnover}
                                                                            options={tradingTrunOver}
                                                                            styles={customStyles}
                                                                            isDisabled={userKycDetails?.otherDetails}
                                                                        />
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-12 mb-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="politically">Are you a Politically Exposed person (PEP)?</label>
                                                                    </div>
                                                                    <div className="d-flex align-items-center gap-3 gap-md-5 mt-2">
                                                                        <div className="form-check cursor-pointer">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="politically"
                                                                                id="flexRadioDefault1"
                                                                                value="true"
                                                                                defaultChecked={formData.politically === true}
                                                                                onChange={handleInputChange} disabled={userKycDetails?.otherDetails}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                Yes
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check cursor-pointer">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="politically"
                                                                                id="flexRadioDefault2"
                                                                                value="false"
                                                                                defaultChecked={formData.politically === false}
                                                                                onChange={handleInputChange} disabled={userKycDetails?.otherDetails}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                                No
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                !userKycDetails?.otherDetails ?
                                                                    <div className="col-12">
                                                                        <button onClick={handleEducationOccupation} type="button" className="btn-block w-100 form-control btn-dark mt-2" disabled={isLoading}>
                                                                            {
                                                                                isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Submit</span>
                                                                            }
                                                                        </button>
                                                                    </div>
                                                                    :
                                                                    <div className="row" >
                                                                        <div className="col-md-12" >
                                                                            <div className="form-group mt-4 " >
                                                                                <button className="form-control btn-warning  btn-block w-100" type="button" onClick={() => handleUpdateKycStep("step4")}> Continue to the Next Step </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </fieldset>
                                                }

                                                {/* Step 4 */}

                                                {
                                                    kycFormStep === "step4" &&
                                                    <fieldset  className="current">
                                                        <div className="form-card">
                                                            <div className="row">
                                                                <div className="p_card mt-2 p-3">
                                                                    <h5>DECLARATION OF SOURCE OF FUNDS</h5>
                                                                    <ul className="desc mb-0">
                                                                        <p>Date: &nbsp; <b> {formattedDateTime} </b></p>
                                                                        <li className="mb-2 d-block">
                                                                            <b>[{userKycDetails?.pandetails?.name}]</b>, a citizen of India, holding Aadhar Number <b>[{userKycDetails?.pandetails?.maskedAadhaarNumber}]</b> and PAN Card number <b>
                                                                                [{userKycDetails?.pandetails?.pan}]</b>, hereby declare and affirm that all funds used in my financial transactions, with INCRYP BLOCKCHAIN PRIVATE LIMITED in INR (Indian Currency) and any other crypto currency, are obtained from legitimate and legal sources. The funds are fully compliant with all applicable laws, regulations, and guidelines, including Anti-Money Laundering (AML) and Counter-Terrorist Financing (CTF) regulations.
                                                                        </li>
                                                                        <li className="mb-2">We further declare that:</li>

                                                                        <li className="mb-2 d-block">
                                                                            1. Legitimate Source: All funds used in our company’s financial operations, including the acquisition of crypto assets, are free from any association with illegal activities such as money laundering, tax evasion, or terrorist financing.
                                                                        </li>

                                                                        <li className="mb-2 d-block">
                                                                            2. Compliance with AML Regulations: Our company follows stringent internal controls and compliance measures, ensuring full adherence to AML, CTF, and Know Your Customer (KYC) guidelines as stipulated by regulatory authorities. We have systems in place to detect and prevent suspicious or unlawful financial activities
                                                                        </li>

                                                                        <li className="mb-2 d-block">
                                                                            3. Due Diligence: Our company undertakes regular due diligence on all counterparties and customers to verify the legitimacy of funds, consistent with legal requirements, before entering any transaction involving crypto assets or traditional financial services.

                                                                        </li>

                                                                        <li className="mb-2 d-block">
                                                                            4. Ethical Standards: Our company upholds the highest standards of ethical conduct in all financial dealings, ensuring that funds used in all transactions are derived from legitimate business activities and are compliant with domestic and international regulations.
                                                                        </li>

                                                                        <li className="mb-2 d-block">
                                                                            5. Cooperation with Authorities: In the event of any suspicion, inquiry, or investigation into the source of our company’s funds, we commit to full cooperation with law enforcement agencies, financial institutions, and regulatory authorities. We are prepared to provide all necessary documentation and information to verify the source of funds when required.
                                                                        </li>
                                                                    </ul>
                                                                    <div className="avil_bal my-2 mb-1 " >
                                                                        <div className="form-check cursor-pointer  d-block">
                                                                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" defaultChecked={checkButton} value={checkButton} onClick={() => setCheckButton((prev) => !prev)} />
                                                                            <label className="form-check-label" label="flexCheckDefault">
                                                                                <b>  I Understand and agree to the above declaration and <a href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/terms_and_conditions.pdf" className="text-warning text-underline" target="_blank">  &nbsp; agree </a>
                                                                                    &nbsp; Terms of use Policy and<a href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/refund_policy.pdf" className="text-warning text-underline" target="_blank">  &nbsp; agree </a>
                                                                                    &nbsp; Refund Policy </b>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row" >
                                                                <div className="col-md-6" >
                                                                    <div className="form-group mt-4 " >
                                                                        <button className="btn form-control btn-warning  btn-block w-100 " type="button" onClick={() => handleDeclarationSubmission()}> Submit </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                }


                                            </div>
                                        </form>
                                    </div>
                                    : kycStatus == 1 || kycStatus == 2 || kycStatus == 3 || kycStatus == 4 ?
                                        <fieldset className="current">
                                            <div className="form-card">
                                                <div className="kyc_stst" >
                                                    <div className="kyc_row" >
                                                        <h5> Pan Card </h5>
                                                        <span className="ky_ badge badge-success"><i className="ri-check-double-line"></i>Verification Success</span>
                                                    </div>
                                                    <div className="kyc_row" >
                                                        <h5> Proof of Address ({userKycDetails?.dldetails ? "Driving Licance" : userKycDetails?.adhaardetails ? "Aadhar Card" : userKycDetails?.passportdetails ? "Passport" : ""} )  </h5>
                                                        <span className={`ky_ badge ${kycStatus === 2 ? "badge-success" : userKycDetails?.adhaardetails ? "badge-success" : kycStatus === 1 ? "badge-warning bg-warning" : kycStatus === 3 ? "badge-danger" : ""}`}>

                                                            <i className={kycStatus === 2 ? "ri-check-double-line" : userKycDetails?.adhaardetails ? "ri-check-double-line" : kycStatus === 1 ? "ri-error-warning-line" : kycStatus === 3 ? "ri-close-circle-line" : ""} ></i>

                                                            {kycStatus == 2 ? "Verification Success" : userKycDetails?.adhaardetails ? "Verification Success" : kycStatus == 1 ? "Verification Pending" : kycStatus === 3 ? "Verification Failed!" : ""}
                                                        </span>
                                                    </div>
                                                    <div className="kyc_row" >
                                                        <h5> Check Liveness / Face Match </h5>
                                                        <span className={`ky_ badge ${kycStatus === 2 ? "badge-success" : userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "badge-success" : kycStatus === 1 ? "badge-warning bg-warning" : kycStatus === 3 ? "badge-danger" : ""}`} >

                                                            <i className={kycStatus === 2 ? "ri-check-double-line" : userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "ri-check-double-line" : kycStatus === 1 ? "ri-error-warning-line" : kycStatus === 3 ? "ri-close-circle-line" : ""} ></i>

                                                            {kycStatus == 2 ? "Verification Success" : userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "Verification Success" : kycStatus == 1 ? "Verification Pending" : kycStatus === 3 ? "Verification Failed!" : ""}
                                                        </span>
                                                    </div>
                                                    <div className="kyc_row" >
                                                        <h5> Education & Occupation </h5>
                                                        <span className={`ky_ badge ${userKycDetails?.otherDetails?.pep === false ? "badge-success" : userKycDetails?.otherDetails?.pep === true ? "badge-warning bg-warning" : kycStatus === 3 ? "badge-danger" : ""}`} >

                                                            <i className={userKycDetails?.otherDetails?.pep === false ? "ri-check-double-line" : userKycDetails?.otherDetails?.pep === true ? "ri-error-warning-line" : kycStatus === 3 ? "ri-close-circle-line" : ""} ></i>

                                                            {userKycDetails?.otherDetails?.pep === false ? "Verification Success" : userKycDetails?.otherDetails?.pep === true ? "Verification Pending" : kycStatus === 3 ? "Verification Failed!" : ""}
                                                        </span>
                                                    </div>
                                                    <div className="kyc_row" >
                                                        <h5> Declaration and Submission </h5>
                                                        <span className={`ky_ badge ${kycStatus === 2 ? "badge-success" : kycStatus === 1 ? "badge-warning bg-warning" : kycStatus === 3 ? "badge-danger" : ""}`} >

                                                            <i className={kycStatus === 2 ? "ri-check-double-line" : kycStatus === 1 ? "ri-error-warning-line" : kycStatus === 3 ? "ri-close-circle-line" : ""} ></i>

                                                            {kycStatus == 2 ? "Verification Success" : kycStatus == 1 ? "Verification Pending" : kycStatus === 3 ? "Verification Failed!" : ""}
                                                        </span>
                                                    </div>
                                                    {kycStatus == 3 ?
                                                        <div className="kyc_row justify-content-center" >
                                                            <div className="text-center mt-4" >
                                                                <p> Account verification request rejected...
                                                                    (Check submitted information for accuracy and follow guidelines for resubmission or appeal.)<br />
                                                                    <span className="text-danger">({userDetails?.user?.kyc_reject_reason}) </span>
                                                                </p>
                                                                <button className="btn btn-warning custom-btn" type="button" onClick={() => setKycStatus(0)}> Verify KYC Again </button>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {kycStatus == 1 &&
                                                        <div className="kyc_row justify-content-center" >
                                                            <div className="text-center mt-1" >
                                                                <i className="ri-error-warning-line me-1 text-warning" style={{ fontSize: "30px" }}></i>
                                                                <p className="mb-0">
                                                                    Kyc Submited : {moment(userKycDetails?.kycdeclaration?.updatedAt).format('Do MMMM YYYY, h:mm:ss a')} </p>
                                                                <p className="text-warning mb-0">
                                                                    Your Kyc is Under Review </p>
                                                                <p >You will receive an email once the verification is complete.</p>
                                                            </div>
                                                        </div>
                                                    }

                                                    {kycStatus == 2 &&
                                                        <div className="kyc_row justify-content-center" >
                                                            <div className="text-center mt-1" >
                                                                <i className="ri-verified-badge-fill me-1 text-success" style={{ fontSize: "30px" }}></i>
                                                                <p className="mb-0">
                                                                    Kyc Approved Date : {moment(userKycDetails?.kycdeclaration?.updatedAt).format('Do MMMM YYYY, h:mm:ss a')} </p>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </fieldset>
                                        :
                                        <div style={{ width: '92%', height: '500px' }}>
                                            <div className="loading-wave" style={{ width: '100%', height: '100%', alignItems: 'center' }}>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                            </div>
                                        </div>
                            }
                        </div>
                    </section>

                    {isActivePanAadhar && userKycDetails?.pandetails && (
                        <div div className="modal-open-custom" >
                            <div className="modal show d-block" >
                                <div className="modal-dialog modal-md">
                                    <div className="modal-content">
                                        <div className="modal-header d-flex justify-content-between  px-md-4">
                                            <h5 className="modal-title" id="modal_otp_Label">Pan Card & Aadhar Details</h5>
                                            <button type="button" className="btn-close" onClick={() => setIsActivePanAadhar(false)}></button>
                                        </div>
                                        <div className="modal-body p-md-4">
                                            <div className="tt_data  ">
                                                <div className="tt_card outline-success">
                                                    <div className="tt_item no-border">
                                                        <span className="text-muted"> User Name: </span>
                                                        <span className="tt_normal">
                                                            {maskName(userKycDetails?.pandetails?.firstName)} {maskName(userKycDetails?.pandetails?.middleName)} {maskName(userKycDetails?.pandetails?.lastName)} &nbsp;
                                                            <i className="ri-verified-badge-fill text-success"></i>
                                                        </span>
                                                    </div>
                                                    <div className="tt_item no-border">
                                                        <span className="text-muted"> Pan Number: </span>
                                                        <span className="tt_normal">
                                                            {userKycDetails?.pandetails?.pan} &nbsp;
                                                            <i className="ri-verified-badge-fill text-success"></i>
                                                        </span>
                                                    </div>

                                                    {
                                                        userKycDetails?.pandetails?.verified &&
                                                        <>
                                                            <div className="tt_item no-border">
                                                                <span className="text-muted"> Aadhar Name: </span>
                                                                <span className="tt_normal">
                                                                    {userKycDetails?.adhaardetails?.full_name} &nbsp;
                                                                    <i className="ri-verified-badge-fill text-success"></i>
                                                                </span>
                                                            </div>
                                                            <div className="tt_item no-border">
                                                                <span className="text-muted"> Aadhar Number: </span>
                                                                <span className="tt_normal">
                                                                    {userKycDetails?.pandetails?.maskedAadhaarNumber} &nbsp;
                                                                    <i className="ri-verified-badge-fill text-success"></i>
                                                                </span>
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop fade show"> </div>
                        </div >
                    )}


                    {isActiveLiveness && userKycDetails?.facematchdetails && (
                        <div div className="modal-open-custom" >
                            <div className="modal show d-block" >
                                <div className="modal-dialog modal-md">
                                    <div className="modal-content">
                                        <div className="modal-header d-flex justify-content-between  px-md-4">
                                            <h5 className="modal-title" id="modal_otp_Label">Liveness Image</h5>
                                            <button type="button" className="btn-close" onClick={() => setIsActiveLiveness(false)}></button>
                                        </div>
                                        <div className="modal-body p-md-4">
                                            <div className="tt_data  ">
                                                <div className="tt_card outline-success">
                                                    <div className="tt_item no-border">
                                                        <span className="text-muted"></span>
                                                        <span className="tt_normal">
                                                            {userKycDetails?.pandetails?.firstName} {userKycDetails?.pandetails?.middleName} {userKycDetails?.pandetails?.lastName} &nbsp;
                                                            <i className="ri-verified-badge-fill text-success"></i>
                                                        </span>
                                                    </div>
                                                    <div className="web_cam_row"  >
                                                        <div className={`web_cam_box ${userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "success" : userKycDetails?.facematchdetails?.action === "manualReview" ? "failed" : "failed"}`} >
                                                            <img src={livenessResult?.results?.[0]?.imageUrl} alt="Liveness Check" className="img-fliud" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop fade show"> </div>
                        </div >
                    )}

                    {isActiveEducation && userKycDetails?.otherDetails && (
                        <div div className="modal-open-custom" >
                            <div className="modal show d-block" >
                                <div className="modal-dialog modal-md">
                                    <div className="modal-content">
                                        <div className="modal-header d-flex justify-content-between  px-md-4">
                                            <h5 className="modal-title" id="modal_otp_Label">Education & Occupation Details</h5>
                                            <button type="button" className="btn-close" onClick={() => setIsActiveEducation(false)}></button>
                                        </div>
                                        <div className="modal-body p-md-4">
                                            <div className="tt_data  ">
                                                <div className="tt_card outline-success">
                                                    <div className="tt_item no-border">
                                                        <span className="text-muted"> Education: </span>
                                                        <span className="tt_normal">
                                                            {userKycDetails?.otherDetails?.education}
                                                        </span>
                                                    </div>
                                                    <div className="tt_item no-border">
                                                        <span className="text-muted"> Occupation: </span>
                                                        <span className="tt_normal">
                                                            {userKycDetails?.otherDetails?.occupation}
                                                        </span>
                                                    </div>
                                                    <div className="tt_item no-border">
                                                        <span className="text-muted"> Marital Status: </span>
                                                        <span className="tt_normal">
                                                            {userKycDetails?.otherDetails?.marital_status}
                                                        </span>
                                                    </div>
                                                    <div className="tt_item no-border">
                                                        <span className="text-muted"> Annual Income: </span>
                                                        <span className="tt_normal">
                                                            {userKycDetails?.otherDetails?.annual_income}
                                                        </span>
                                                    </div>
                                                    <div className="tt_item no-border">
                                                        <span className="text-muted"> Annual Turnover: </span>
                                                        <span className="tt_normal">
                                                            {userKycDetails?.otherDetails?.annual_turnover}
                                                        </span>
                                                    </div>
                                                    <div className="tt_item no-border">
                                                        <span className="text-muted"> Politically Exposed person: </span>
                                                        <span className="tt_normal">
                                                            {userKycDetails?.otherDetails?.pep === false ? "NO" : "YES"}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop fade show"> </div>
                        </div >
                    )}

                </div >
            </div >
        </main >
    );
};

export default PersonalKyc;
