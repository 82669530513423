import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { alertErrorMessage } from "../../../../Services/CustomAlertModel";
import AuthService from "../../../../Services/ApiServices/AuthService";
import LoaderHelper from "../../../../Services/Loading/LoaderHelper";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import { ProfileContext } from "../../../../Services/ProfileProvider";
import { saveAs } from 'file-saver';
import { generatePdfBuySell } from "../../../../Context/Utility/generatePdfBuySell";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const BuySellTranstions = () => {

    const { userDetails, userKycDetails } = useContext(ProfileContext);

    const [activeOrders, setActiveOrders] = useState("all");
    const [transactionList, setTransactionList] = useState([]);
    const [orderSide, setOrderSide] = useState('');
    const [orderStatus, setOrderStatus] = useState('');
    const [totalDataLength, setTotalDataLength] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [activeExport, setActiveExport] = useState(false);
    const [isOpenOrderdetailsModal, setIsOpenOrderdetailsModal] = useState(false);
    const [transactionDetail, setTransactionDetail] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [fileUrl, setFileUrl] = useState("");
    const [searchKey, setSearchKey] = useState("");
    const [isOpenChatModel, setIsOpenChatModel] = useState(false);
    const [isActiveMobileFilter, setIsActiveMobileFilter] = useState(false);
    const [skip, setSkip] = useState(0);
    const limit = 20;


    const userUpiDetails = userDetails?.upi_details?.find(item => item?.default);
    const userBankDetail = userDetails?.bank_details?.find(item => item?.default);


    const formatDate = (dateString) => {
        if (!dateString) return "";
        const parts = dateString.split("-");
        if (parts.length !== 3) return "";
        const [year, month, day] = parts;
        return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
    };

    const handleDateFilterData = (startDate, endDate) => {
        if (!startDate) {
            alertErrorMessage("Please select Start date")
            return;
        }
        if (!endDate) {
            alertErrorMessage("Please select End date")
            return;
        }
        handleTranactionList();
    };

    const handleTranactionList = async () => {

        const formattedSearchKey = searchKey || "";
        const formattedStartDate = startDate ? formatDate(startDate) : "";
        const formattedEndDate = endDate ? formatDate(endDate) : "";
        let filters = {};

        if (skip) {
            filters.skip = skip;
        }

        if (limit) {
            filters.limit = limit;
        }

        if (formattedSearchKey) {
            filters.search = formattedSearchKey;
        }

        if (formattedStartDate) {
            filters.start_date = formattedStartDate;
        }
        if (formattedEndDate) {
            filters.end_date = formattedEndDate;
        }
        if (orderStatus) {
            filters.status = orderStatus;
        }
        if (orderSide) {
            filters.side = orderSide;
        }

        const queryString = new URLSearchParams(filters).toString();
        try {
            const result = await AuthService.buySellTranactionList(queryString);
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setTransactionList(result.data);
                setTotalDataLength(result?.count);
                setIsActiveMobileFilter(false);
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result);
                setTransactionList([])
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        handleTranactionList();
    }, [skip, limit, orderStatus, orderSide, searchKey]);

    const columns = [
        {
            name: "Date",
            cell: (row) => <div className="justify-content-start" > <span>{moment(row.createdAt).format("DD/MM/YYYY  ")} <br /> <small>{moment(row.createdAt).format("HH:mm:ss")}</small> </span> </div>,
            sortable: false,
        },
        {
            name: 'Order ID.',
            selector: row => row.order_id,
            sortable: true,
            wrap: true,

            format: row =>
                <div className="c_view text-underline" onClick={() => handleRowClick(row)}>{row.order_id} </div>,
        },



        {
            name: 'Currency',
            selector: row => row,
            sortable: true,
            format: row => <span>{row.base_currency + "/" + row.quote_currency}</span>,
        },
        {

            name: 'Side',
            selector: row => row.side,
            wrap: true,
            sortable: true,
            format: row =>
                <span className={row.side === 'BUY' ? " text-success" : "text-danger"}>
                    {row?.side}
                </span>,
        },

        {
            name: 'Total Paid',
            selector: row => row.pay_amount,
            wrap: true,
            sortable: true,
            format: row => <div className="text-end">{row.pay_amount}
                &nbsp;{row?.side === "BUY" ? row.quote_currency : row.base_currency}</div>,

        },

        {
            name: 'Total Recived',
            selector: row => row.final_get_amount,
            wrap: true,
            sortable: true,
            format: row => <div className="text-end">{row.final_get_amount}
                &nbsp;{row?.side === "BUY" ? row.base_currency : row.quote_currency}</div>,

        },

        {

            name: 'Status',
            selector: row => row.status,
            wrap: true,
            sortable: true,
            format: row =>
                <span className={row.status === 'Completed' ? " text-success" : row.status === 'Cancelled By User' ? "text-danger" : row.status === 'Rejected' ? "text-danger" : "text-dark"}>
                    {row?.status === "Cancelled By User" ? "Cancelled" : row?.status === "Refund Processed" ? "Refunded" : row?.status}
                </span>,
        },

        {
            name: 'Invoice',
            selector: row => row.status === 'Completed' || row?.status === "Refund Processed" && <div className="ac_row cursor-pointer" onClick={() => generatePdfBuySell(row, userKycDetails, userBankDetail, userUpiDetails)}>
                <i className="ri-download-2-line"></i>
            </div>,
            wrap: true,
        }
    ];


    const handlePagination = (action) => {
        if (action === 'prev') {
            if (skip - limit >= 0) {
                setSkip(skip - limit);
            }
        } else if (action === 'next') {
            if (skip + limit < totalDataLength) {
                setSkip(skip + limit);
            }
        } else if (action === 'first') {
            setSkip(0);
        } else if (action === 'last' && totalDataLength >= 10) {
            const lastPageSkip = Math.floor(totalDataLength / limit) * limit;
            setSkip(lastPageSkip - limit);
        }
    };





    const convertToCSV = (data) => {
        const headers = Object.keys(data[0])?.filter(key => !['_id', 'user_id', 'order_id', 'updatedAt', '_v'].includes(key));
        let csv = headers.join(',') + '\n';
        data.forEach(item => {
            const row = headers.map(header => {
                if (header === 'createdAt') {
                    return moment(item[header]).format('DD-MM-YYYY HH:mm:ss');
                }
                return item[header];
            });
            csv += row.join(',') + '\n';
        });

        return csv;
    };


    const handleExportData = async (startDate, endDate) => {
        try {
            const result = await AuthService.exportDataBuySell(startDate, endDate);
            if (result?.success) {
                if (result.data && result.data.length > 0) {
                    const csvData = convertToCSV(result.data);
                    saveAs(new Blob([csvData], { type: 'text/csv' }), `Buy_sell ${userDetails?.user?.email}.csv`);
                } else {
                    alertErrorMessage('No Data Available...!')
                }
                LoaderHelper.loaderStatus(false);
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    };

    const handleRowClick = (rowData) => {
        setTransactionDetail(rowData);
        setIsOpenOrderdetailsModal(true);
    };


    const handleOpen = (url) => {
        if (!url) return;
        setFileUrl(url);
        setIsOpen(true);
    };

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchKey(searchTerm);
    };


    const handleFilterReset = () => {
        setSearchKey('');
        setOrderSide('');
        setStartDate('');
        setOrderStatus('');
        setEndDate('');
        setIsActiveMobileFilter(false)
        handleTranactionList();
    };

    return (
        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper" >
                    <section className="d_page history_page">
                        <div className="container" >
                            <div className="row justify-content-center">
                                <div className="col-lg-12" >
                                    <div className="pg_header" >
                                        <div className="hd_innre" >
                                            <h6 className="mb-0" >Buy/Sell Orders</h6>
                                        </div>
                                    </div>
                                    <div className="spot_tabs my-2 my-md-3" >
                                        <ul className="nav custom-tabs">
                                            <li className={`${activeOrders === "Pending" ? 'active' : ''}`}
                                                onClick={() => { setActiveOrders('Pending'); setOrderStatus('Pending'); setSkip(0) }}> <span> Ongoing Orders </span></li>
                                            <li className={`tab_item ${activeOrders === "all" ? 'active' : ''}`}
                                                onClick={() => { setActiveOrders('all'); setOrderStatus(''); setSkip(0) }}><span>All Orders</span></li>
                                        </ul>

                                    </div>

                                    <div className="filter-card mb-3 mb-md-4">
                                        <div className="row gx-2 align-items-end" >
                                            <div className="col-10 col-xl-11" >
                                                <div className="row gx-2 align-items-end" >
                                                    <div className="col-lg-8  col-xl-6 col-xxl-2" >
                                                        <div className="flt_search">
                                                            <div className="form-group">
                                                                <label className=" d-none d-xxl-block"> Search </label>
                                                                <div className="searchBar custom-tabs mt-0">
                                                                    <input type="search" className="custom_search" placeholder="Search Crypto" value={searchKey} onChange={handleSearch} />
                                                                    <i className="ri-search-2-line"></i>
                                                                </div>
                                                            </div>
                                                            <span className="filt_icon d-xxl-none" onClick={() => setIsActiveMobileFilter(true)}> <i class="ri-filter-fill"></i> Filter </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-xl-6 col-xxl-10" >
                                                        <div className={`filter_more ${isActiveMobileFilter && 'active'}`}>
                                                            <div className="more_card" >
                                                                <div className="modal-header p-0 d-xxl-none" >
                                                                    <h5 className="mb-0" >
                                                                        Filter
                                                                    </h5>
                                                                    <span onClick={() => setIsActiveMobileFilter(false)}><i class="ri-close-large-line"></i></span>
                                                                </div>
                                                                <div className="row g-2 align-items-end" >
                                                                    <div className="col-12 col-xxl-2 ">
                                                                        <div className="form-group">
                                                                            <label> Order Side </label>
                                                                            <select className="form-control form-select cursor-pointer" onChange={(e) => { setOrderSide(e.target.value); setSkip(0) }}>
                                                                                <option value="">All Orders</option>
                                                                                <option value="BUY">Buy Orders</option>
                                                                                <option value="SELL">Sell Orders</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    {activeOrders === "all" &&
                                                                        <div className="col-12 col-xxl-2 ">
                                                                            <div className="form-group">
                                                                                <label> Order Status </label>
                                                                                <select className="form-control form-select cursor-pointer" onChange={(e) => { setOrderStatus(e.target.value); setSkip(0) }}>
                                                                                    <option value="">All Status</option>
                                                                                    <option value="Completed">Completed </option>
                                                                                    <option value="Pending">Pending</option>
                                                                                    <option value="Rejected">Rejected</option>
                                                                                    <option value="Cancelled By User">Cancelled</option>
                                                                                    <option value="Refund Initiated">Refund Initiated</option>
                                                                                    <option value="Refund Processed">Refund Processed</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    <div className="col-6  col-xxl-2">
                                                                        <div className="form-group">
                                                                            <label>Start Date</label>
                                                                            <input
                                                                                type="date"
                                                                                className="form-control cursor-pointer"
                                                                                value={startDate}
                                                                                onChange={(e) => setStartDate(e.target.value)}
                                                                                max={new Date().toISOString().split("T")[0]}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6  col-xxl-2">
                                                                        <div className="form-group">
                                                                            <label>End Date</label>
                                                                            <input
                                                                                type="date"
                                                                                className="form-control cursor-pointer"
                                                                                value={endDate}
                                                                                onChange={(e) => setEndDate(e.target.value)}
                                                                                max={new Date().toISOString().split("T")[0]}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xxl-3">
                                                                        <div className="d-flex gap-2 mt-2  mt-xxl-0">
                                                                            <button className="btn btn-muted w-50" onClick={handleFilterReset}>Clear</button>
                                                                            <button className="btn btn-dark w-50" onClick={() => handleDateFilterData(startDate, endDate)}>Apply</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-2 col-xl-1" >
                                                <div className="ph_right" >
                                                    <button className="btn btn-dark px-4 export_btn" type="button" onClick={() => handleExportData(startDate, endDate)} disabled={!startDate || !endDate || !activeExport}>
                                                        <span className="d-none d-lg-flex me-2" >Export</span> <i className=" ri-download-2-line"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="g_start m_card overflow-hidden p-0">
                                        <div className="m_table" >
                                            <div className="table-responsive h-auto cursor-pointer">
                                                <DataTable
                                                    columns={columns}
                                                    data={transactionList}
                                                    onRowClicked={handleRowClick}
                                                    noDataComponent={
                                                        <p> <img src="/assets/images/not_found.svg" width="300" className='img-fluid mt-5' alt='' /> </p>
                                                    }
                                                />

                                                {
                                                    transactionList?.length > 0 &&
                                                    <div className="hVPalX gap-2" >
                                                        <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                                        <div className="sc-eAKtBH gVtWSU">
                                                            <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                                </svg>
                                                            </button>
                                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                </svg>
                                                            </button>

                                                            <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                </svg>
                                                            </button>
                                                            <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')} >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>


            {
                isOpenOrderdetailsModal && (
                    <div className="d-block" >
                        <div className="isOpenOrderdetailsModal modal chat-modal ccc_modal show d-block" >
                            <div className="modal-dialog modal-dialog-centered ">
                                <div className="modal-content p-0  ">
                                    <div className="Attachment">
                                        <button type="button" className="btn-sm btn-icon btn-dark modal-close"
                                            onClick={() => setIsOpenOrderdetailsModal(false)}>
                                            <i className="ri-close-large-line"></i>
                                        </button>
                                    </div>
                                    <div className="modal-body p-0">
                                        <div className="row g-0" >
                                            <div className="col-lg-12" >
                                                <div className="oh_left" >

                                                    <div className={`oh_top ${transactionDetail?.side === "SELL" ? "bg-danger" : transactionDetail?.status === "Completed" ? "bg-green" :
                                                        transactionDetail?.status === "Cancelled By User" ? "bg-danger" : transactionDetail?.status === "Pending" ? "bg-warning" : "bg-primary"}`} >
                                                        <div className="ot_top" >
                                                            <span>Order No: {transactionDetail?.order_id} </span>
                                                            <span>
                                                                {transactionDetail?.status === "Cancelled By User" ? "Cancelled" : transactionDetail?.status === "Refund Processed" ? "Refunded" : transactionDetail?.status}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="Attachment">
                                                        <div className="ot_body" >
                                                            <div>
                                                                <span className={transactionDetail?.side === "SELL" ? "text-danger" : "text-success"} >{transactionDetail?.side}</span> | <span> Total amount </span>
                                                                <h6 className="mb-0"> {transactionDetail?.pay_amount} <sub> {transactionDetail?.side === "BUY" ? transactionDetail?.quote_currency : transactionDetail?.base_currency}</sub> </h6>
                                                            </div>

                                                            {
                                                                transactionDetail?.status === 'Refund Initiated' && transactionDetail?.status === 'Refund Processed' &&
                                                                <div className="d-flex align-items-center gap-1 w-auto justify-content-end ms-auto">

                                                                    <div className="dropdown w-auto d-inline-block">
                                                                        <button type="button" className="btn btn-blank btn-sm p-0" data-bs-toggle="dropdown" fdprocessedid="1s52ls" aria-expanded="false">
                                                                            <i className="ri-more-2-fill ri-xl"></i>
                                                                        </button>
                                                                        <div className="dropdown-menu">

                                                                            <span className="dropdown-item cursor-pointer" onClick={() => { setIsOpenChatModel(true); setIsOpenOrderdetailsModal(false) }}> Chat History </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>


                                                    </div>
                                                    <div className="oh_body">
                                                        <img src="assets/img/logo.svg" className="wt_mark" alt="" />
                                                        <div className="d-flex align-items-center justify-content-between" >
                                                            <h6> Order Details </h6>
                                                            <div className="oh_data text-end">
                                                                <p className="mb-0">  Final Amonut:<br /> {transactionDetail?.final_pay_amount} <small> {transactionDetail?.side === "BUY" ? transactionDetail?.quote_currency : transactionDetail?.base_currency}</small> </p>
                                                            </div>
                                                        </div>
                                                        <div className="row g-1">
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" > Reff. No   </span>
                                                                    <p className="mb-0"> {transactionDetail?.ref_no || "---"} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted"  > Order Currency </span>
                                                                    <p className="mb-0"> {transactionDetail?.base_currency} / {transactionDetail?.quote_currency} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" > Rate  </span>
                                                                    <p className="mb-0"> {transactionDetail?.price} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" >    Quantity </span>
                                                                    <p className="mb-0"> {transactionDetail?.get_amount} {transactionDetail?.side === "SELL" ? transactionDetail?.quote_currency : transactionDetail?.base_currency} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" > Order Type   </span>
                                                                    <p className="mb-0"> {transactionDetail?.bulk_order || "Normal"} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" > Fees ({transactionDetail?.qbs_fees}%) </span>
                                                                    <p className="mb-0"> {transactionDetail?.qbs_fees_amount} {transactionDetail?.quote_currency}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" > TDS  ({transactionDetail?.tds}%)  </span>
                                                                    <p className="mb-0"> {transactionDetail?.tds_amount} {transactionDetail?.quote_currency} </p>
                                                                </div>
                                                            </div>



                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" >Order Created Time   </span>
                                                                    <p className="mb-0"> {moment(transactionDetail?.order_created_at).format('Do MMM YYYY')} <br />
                                                                        <small>{moment(transactionDetail?.order_created_at).format('h:mm:ss a')}</small>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {
                                                                transactionDetail?.use_available && transactionDetail?.inr_deposit_amount == 0 ? null :

                                                                    <>
                                                                        <div className="col-4" >
                                                                            <div className="oh_data" >
                                                                                <span className="text-muted" >Order Paid Time   </span>
                                                                                <p className="mb-0">
                                                                                    {
                                                                                        transactionDetail?.amount_deposited_at ? (
                                                                                            <>
                                                                                                {moment(transactionDetail.amount_deposited_at).format("Do MMM YYYY")}
                                                                                                <br />
                                                                                                <small>{moment(transactionDetail.amount_deposited_at).format("h:mm:ss a")}</small>
                                                                                            </>
                                                                                        ) : (
                                                                                            null
                                                                                        )
                                                                                    }

                                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-4" >
                                                                            <div className="oh_data" >
                                                                                <span className="text-muted" >Order Remind Time   </span>


                                                                                {
                                                                                    transactionDetail?.remindAdminAfter ? (
                                                                                        <>
                                                                                            {moment(transactionDetail.remindAdminAfter).format("Do MMM YYYY")}
                                                                                            <br />
                                                                                            <small>{moment(transactionDetail.remindAdminAfter).format("h:mm:ss a")}</small>
                                                                                        </>
                                                                                    ) : (
                                                                                        null
                                                                                    )
                                                                                }


                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4" >
                                                                            <div className="oh_data" >
                                                                                <span className="text-muted" >Order Updated Time   </span>
                                                                                {
                                                                                    transactionDetail?.status_updated_at ? (
                                                                                        <p className="mb-0"> {moment(transactionDetail.status_updated_at).format("Do MMM YYYY h:mm:ss a")}</p>
                                                                                    ) : (
                                                                                        null

                                                                                    )
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                            }
                                                        </div>

                                                        {
                                                            transactionDetail?.use_available && transactionDetail?.inr_deposit_amount > 0 &&
                                                            <>
                                                                <hr />
                                                                <h6> Deduct From Wallet </h6>
                                                                <div className="row g-1">

                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted"> Payment Type </span>
                                                                            <p className="mb-0">From Wallet</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted"> Paid Amount </span>
                                                                            <p className="mb-0">{transactionDetail?.pay_amount - transactionDetail?.inr_deposit_amount} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted"> Remaining Amount </span>
                                                                            <p className="mb-0">{transactionDetail?.inr_deposit_amount} </p>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </>
                                                        }


                                                        <hr />
                                                        <h6> Payment Details </h6>
                                                        <div className="row g-1">
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted"  > Payment Type </span>
                                                                    <p className="mb-0">  {transactionDetail?.use_available && transactionDetail?.inr_deposit_amount == 0 ? "From Wallet" : transactionDetail?.payment_details?.[0]?.paymentType} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" > Payment Mode </span>
                                                                    <p className="mb-0"> {transactionDetail?.use_available && transactionDetail?.inr_deposit_amount == 0 ? "Debit From Wallet" : transactionDetail?.payment_details?.[0]?.paymentMode} </p>
                                                                </div>
                                                            </div>

                                                            {
                                                                !transactionDetail?.use_available && !transactionDetail?.utrNumber &&
                                                                <>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > Payment Details </span>
                                                                            {
                                                                                transactionDetail?.payment_details?.[0]?.paymentMode ?
                                                                                    <p className="mb-0">
                                                                                        {transactionDetail?.payment_details?.[0]?.paymentMode === "UPI"
                                                                                            ? `UPI ID: ${userUpiDetails?.vpaId || "---"}`
                                                                                            : `Ac. No: ${userBankDetail?.accountNumber || "---"} IFSC: ${userBankDetail?.ifsc || "---"}`
                                                                                        }
                                                                                    </p>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > UTR No.   </span>
                                                                            <p className="mb-0">  {transactionDetail?.payment_details?.[0]?.utrNumber || "---"}  </p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                transactionDetail?.use_available && transactionDetail?.inr_deposit_amount > 0 &&

                                                                <>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > Payment Details </span>
                                                                            {
                                                                                transactionDetail?.payment_details?.[0]?.paymentMode ?
                                                                                    <p className="mb-0">
                                                                                        {transactionDetail?.payment_details?.[0]?.paymentMode === "UPI"
                                                                                            ? `UPI ID: ${userUpiDetails?.vpaId || "---"}`
                                                                                            : `Ac. No: ${userBankDetail?.accountNumber || "---"} IFSC: ${userBankDetail?.ifsc || "---"}`
                                                                                        }
                                                                                    </p>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-8" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > UTR No.   </span>
                                                                            <p className="mb-0">  {transactionDetail?.payment_details?.[0]?.utrNumber || "---"}  </p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }


                                                            {
                                                                !transactionDetail?.use_available && !transactionDetail?.utrNumber &&
                                                                <>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > UTR Added At </span>
                                                                            {
                                                                                transactionDetail?.amount_deposited_at ? (
                                                                                    <p className="mb-0">
                                                                                        {moment(transactionDetail.amount_deposited_at).format("Do MMM YYYY,h:mm:ss a")}
                                                                                    </p>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > User Remark </span>
                                                                            <p className="mb-0">{transactionDetail?.payment_details?.[0]?.remark || "---"}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > UTR Added By  </span>
                                                                            <p className="mb-0">{transactionDetail?.payment_details?.[0]?.utrAddedBy || "---"} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > Admin Remark   </span>
                                                                            <p className="mb-0"> ---  </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-4 Attachment">
                                                                        <div className="oh_data">
                                                                            <span className="text-muted">Attachment</span>
                                                                            <p className="mb-0">
                                                                                {
                                                                                    transactionDetail?.payment_details?.[0]?.attachment ?
                                                                                        <button
                                                                                            className="btn btn-outline-dark btn-xs"
                                                                                            type="button"
                                                                                            onClick={() => handleOpen(transactionDetail?.payment_details?.[0]?.attachment)}
                                                                                        >
                                                                                            View
                                                                                        </button>
                                                                                        : "---"
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        {isOpen && (
                                                                            <Lightbox
                                                                                open={isOpen}
                                                                                close={() => setIsOpen(false)}
                                                                                slides={[
                                                                                    {
                                                                                        src: fileUrl.endsWith(".pdf")
                                                                                            ? `https://docs.google.com/gview?url=${fileUrl}&embedded=true`
                                                                                            : fileUrl,
                                                                                    },
                                                                                ]}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </>
                                                            }




                                                            {
                                                                transactionDetail?.use_available && transactionDetail?.inr_deposit_amount > 0 &&
                                                                <>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > UTR Added At </span>
                                                                            {
                                                                                transactionDetail?.amount_deposited_at ? (
                                                                                    <p className="mb-0">
                                                                                        {moment(transactionDetail.amount_deposited_at).format("Do MMM YYYY h:mm")}

                                                                                    </p>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-8" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > User Remark </span>
                                                                            <p className="mb-0">{transactionDetail?.payment_details?.[0]?.remark || "---"}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > UTR Added By  </span>
                                                                            <p className="mb-0">{transactionDetail?.payment_details?.[0]?.utrAddedBy || "---"} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-8" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > Admin Remark   </span>
                                                                            <p className="mb-0"> ---  </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-4 Attachment">
                                                                        <div className="oh_data">
                                                                            <span className="text-muted">Attachment</span>
                                                                            <p className="mb-0">
                                                                                {
                                                                                    transactionDetail?.payment_details?.[0]?.attachment ?
                                                                                        <button
                                                                                            className="btn btn-outline-dark btn-xs"
                                                                                            type="button"
                                                                                            onClick={() => handleOpen(transactionDetail?.payment_details?.[0]?.attachment)}
                                                                                        >
                                                                                            View
                                                                                        </button>
                                                                                        : "---"
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        {isOpen && (
                                                                            <Lightbox
                                                                                open={isOpen}
                                                                                close={() => setIsOpen(false)}
                                                                                slides={[
                                                                                    {
                                                                                        src: fileUrl.endsWith(".pdf")
                                                                                            ? `https://docs.google.com/gview?url=${fileUrl}&embedded=true`
                                                                                            : fileUrl,
                                                                                    },
                                                                                ]}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"> </div>
                    </div>
                )
            }

            {
                isOpenChatModel &&
                <div className="d-block" >
                    <div className="modal chat-modal ccc_modal show d-block" >
                        <div className="modal-dialog modal-dialog-centered ">
                            <div className="modal-content p-0  ">
                                <button type="button" className="btn-sm btn-icon btn-dark modal-close" onClick={() => setIsOpenChatModel(false)}>
                                    <i className="ri-close-large-line"></i>
                                </button>
                                <div className="modal-body p-0">
                                    <div className="row g-0" >
                                        <div className="col-lg-12" >
                                            <div className="oh_right" >
                                                <div className="or_top" >
                                                    <div className="oh_user" >
                                                        <img src="/assets/images/user_thumb.png" className="u_img img-fluid" alt="" />
                                                        <span className=""> {transactionDetail?.user_name} <small> {transactionDetail?.order_id} </small> </span>
                                                    </div>
                                                    <div className="or_action" >
                                                        <button className="badge no-border  btn-secondary" onClick={() => { setIsOpenOrderdetailsModal(true); setIsOpenChatModel(false) }}> Order Details </button>
                                                    </div>
                                                </div>

                                                {/* modal chat */}
                                                <div className="chat_box g_start p-0 chat-sm">
                                                    <div className="chat_body scroll_y">
                                                        <div className="msg_history scroll_bar">
                                                            <div className="ag_status">
                                                                <p>You are currently chating with - Unknown Agent  </p>
                                                            </div>
                                                            <div className="incoming_msg" >
                                                                <div className="received_msg important_msg">
                                                                    <div className="received_withd_msg">
                                                                        <p>
                                                                            <b>Importane Note: </b>
                                                                            <br />
                                                                            sadasd asd asd asdasd
                                                                            asd asd asdasd asd asd asdas asd asda asd
                                                                            asdasdasdasd asd as
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="outgoing_msg"  >
                                                                <div className="sent_msg">
                                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.  </p>
                                                                </div>
                                                                <div className="msg_time">
                                                                    <small> 15 min </small>
                                                                </div>
                                                            </div>
                                                            <div className="incoming_msg" >
                                                                <div className="received_msg">
                                                                    <div className="received_withd_msg">
                                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.  </p>
                                                                    </div>
                                                                    <div className="msg_time">
                                                                        <small> 18 min </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {transactionDetail?.status === "Refund Initiated" &&
                                                        <div className="chat-footer send-message cup">
                                                            <form>
                                                                <input type="text" cols="10" rows="1" className="form-control" placeholder="Type your message here ..." />
                                                                <span className="chat_action">
                                                                    <button className="btn_attach  btn-icon">
                                                                        <input type="file" />
                                                                        <i className="ri-link"></i>
                                                                    </button>
                                                                    <button type="button" className="btn btn-primary send " fdprocessedid="fi0lzb">
                                                                        <i className="ri-send-plane-fill  "></i>  </button>
                                                                </span>
                                                            </form>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div>
            }
        </main>

    )
}

export default BuySellTranstions