import React from 'react';
import { Link } from 'react-router-dom';

const DecentralizedDis = () => {

    return (

        <section className="dd_sec dd_img">
            <div className="container">
                <div className="row gx-5 align-items-center">
                    <div className="col-lg-6">
                        <img src="/assets/images/dd_dashboard.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-6">
                        <div className="section-title mb-0 text-start"> 
                            <h2 className="text-gradient "> India's Trusted <br/>  <small>Crypto Trading Platform</small> </h2>
                            <p> Crypto India is India's most trusted and reliable cryptocurrency trading platform, dedicated to empowering millions of Indians with seamless and secure access to the world of digital assets. Our mission is to offer a user-friendly and transparent platform where both beginners and experienced traders can buy, sell, and trade a wide range of cryptocurrencies with confidence.
                            </p>
                            <p>  Join us at Crypto India to be part of the future of finance and unlock the full potential of cryptocurrency in India. We are committed to helping you navigate the exciting world of digital currencies with trust and innovation!
                            </p>
                            <Link to="/company_management" className="btn btn-dark px-5 "> Know More </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default DecentralizedDis