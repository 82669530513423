import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ProfileContext } from "../../../Services/ProfileProvider";
import { ApiConfig } from "../../../Services/ApiServices/AuthAction/ApiConfig";
import { alertErrorMessage, alertWarningMessage } from "../../../Services/CustomAlertModel";
import AuthService from "../../../Services/ApiServices/AuthService";

const ProfileWalletSubPage = () => {

    const { maskedEmail, userDetails } = useContext(ProfileContext);
    const [wlletBalance, setWalletBalance] = useState('');
    const [currencyName, setCurrencyName] = useState(userDetails?.user?.currency_prefrence || 'INR');
    const [userKycDetails, setUserKycDetails] = useState([]);

    const userDataDetails = userDetails?.user;
    const KycStatus = userDetails?.user?.kycVerified;
    const navigate = useNavigate();

    const handleWalletBalance = async (currency) => {
        try {
            const result = await AuthService.walletBalance(currency,"SPOT");
            if (result?.success) {
                setWalletBalance(result?.data);
            } else {
                alertErrorMessage(result);
                setWalletBalance("")
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        handleWalletBalance(currencyName);
    }, []);

    const nextPageWallet = () => {
        if (!userDetails?.permissions?.deposit) {
            alertErrorMessage('Disable Deposit..');
            return;
        } if (KycStatus == 0) {
            alertWarningMessage('Kyc Pending Submit Your Kyc..');
            return;
        } if (KycStatus == 1) {
            alertWarningMessage('Your Kyc is Under Review..');
            return;
        }
        navigate("/deposit_funds");
    };

    const nextPageWalletWithdraw = () => {
        if (!userDetails?.permissions?.withdrawal) {
            alertErrorMessage('Disable withdrawal..');
            return;
        } if (KycStatus == 0) {
            alertWarningMessage('Kyc Pending Submit Your Kyc..');
            return;
        } if (KycStatus == 1) {
            alertWarningMessage('Your Kyc is Under Review..');
            return;
        }
        navigate("/withdraw_funds");
    };




    const handleGetKycDetails = async () => {
        const result = await AuthService.getKycDetails();
        if (result?.success) {
            setUserKycDetails(result.data);
        }
    };

    useEffect(() => {
        handleGetKycDetails();
    }, [userDetails]);


    return (
        <>
            <div className="d_u_p" >
                <div className="dup_left" >
                    <div className="up" >
                        <img className="img-fluid" alt="user"
                            src={userKycDetails?.facematchdetails?.facematch_image2 || "/assets/images/user_thumb.png"}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/assets/images/user_thumb.png";
                            }}
                        />

                    </div>
                    <div className="ud" >
                        <h5>{userKycDetails?.pandetails?.name || "------"}</h5>
                        <span> {maskedEmail}</span>
                    </div>
                </div>
                <div className="up_right text-end" >
                    <span className="ur_data">
                        <small>User ID</small>
                        {userDataDetails?.uin}
                    </span>
                    <span className="ur_data">
                        <small>User Type  </small>
                        {userDataDetails?.user_type || '------'}
                    </span>
                </div>
            </div>

            {/* wallet */}
            <div className="g_start" >
                <div className="g_row row" >
                    <div className="col-lg-6" >
                        <div className="g_card">
                            <h6>Estimated Balance</h6>
                            <div className="d-flex align-items-center">
                                <h5>{wlletBalance.balance || 0}</h5>
                                <div className="dropdown">
                                    <div className="btn-link dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                        {currencyName}
                                    </div>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink" onClick={(e) => {
                                        const newCurrency = e.target.getAttribute('value');
                                        setCurrencyName(newCurrency);
                                        handleWalletBalance(newCurrency);
                                    }}>
                                        <li><div className="dropdown-item cursor-pointer" value="USDT">USDT</div></li>
                                        <li><div className="dropdown-item cursor-pointer" value="BTC">BTC</div></li>
                                        <li><div className="dropdown-item cursor-pointer" value="BNB">BNB</div></li>
                                        <li><div className="dropdown-item cursor-pointer" value="INR">INR</div></li>
                                    </ul>
                                </div>
                            </div>
                            <p className="mb-0">Locked Balance ≈ {wlletBalance.locked_balance}</p>
                        </div>
                    </div>
                    <div className="col-lg-6" >
                        <div className="g_right" >
                            <div className="btn_row" >
                                <button type="button" className="btn btn-warning" onClick={nextPageWallet}>Deposit</button>
                                <button type="button" className="btn btn-warning" onClick={nextPageWalletWithdraw}>Withdraw</button>
                                <Link to="/coming_soon" className="btn btn-muted">Transfer</Link>
                                <Link to="/transaction_history" className="btn btn-muted">History</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}

export default ProfileWalletSubPage