import React from "react";
import Footer from "../../../Footer";

const LeaLegal = () => {

    return (
        <>
            <main>
                <section className="tm_sec">
                    <div className="container">
                        <div className="row   mt-md-5 justify-content-center">
                            <div className="col-lg-10">

                                <div className="g_start m_card overflow-hidden p-0 ">
                                    <div className="p_card p-md-5">
                                        {/* step1 */}
                                        <div className="step_1" >

                                            <h3>
                                                Government Law Enforcement Request System
                                            </h3>

                                            <p>For Government and Law Enforcement Agencies only</p>

                                            <p>Welcome to the Government Law Enforcement Request System (LERS). Government and law enforcement agencies can use this system to submit information requests. Crypto India will review each case and cooperate on a case-by-case basis to disclose information as legally required, in accordance with our Terms of Use and applicable laws.</p>


                                            <p>Please submit your inquiry on our Law Enforcement Portal. you will need to register on the platform. After registration, you will be able to submit your requests, keep track of your cases, and access all relevant information.</p>

                                            <p> Additionally, be aware that agency verifications submitted with the use of VPNs will not be completed. </p>
                                        </div>
                                        <br />
                                        <br />
                                        {/* step 2*/}
                                        <div className="step_2 " >
                                            <div className="row">
                                                <div className="col-md-12 mb-4">

                                                    <h6 className="mb-3" >
                                                        1. Agency/Department Verification
                                                    </h6>

                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label">Emai Address  </label>
                                                        <div className="field-otp-box mb-2">
                                                            <input type="text" className="form-control" name="subject" />
                                                        </div>
                                                        <p className="mb-0" > <small>Please enter your agnecy email address.</small></p>
                                                        <p className="mb-0" > <small>By Clicking an account, you acknowladge and accept out Terms of Services and Privacy Policy.</small></p>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="col-md-12 mb-4">

                                                    <h6 className="mb-3 mt-4" >
                                                        2. Agency/Department Details
                                                    </h6>

                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Agency/Department Name    </label>
                                                        <div className="field-otp-box ">
                                                            <input type="text" className="form-control" name="subject" />
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Branch Name </label>
                                                        <div className="field-otp-box ">
                                                            <input type="text" className="form-control" name="subject" />
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Location Branch Address</label>
                                                        <div className="field-otp-box">
                                                            <input type="text" className="form-control" name="subject" />
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Location/Branch Country </label>
                                                        <div className="field-otp-box ">
                                                            <input type="text" className="form-control" name="subject" />
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Postel/Zip Code </label>
                                                        <div className="field-otp-box ">
                                                            <input type="text" className="form-control" name="subject" />
                                                        </div>
                                                    </div>

                                                </div>
                                                <br />
                                                <div className="col-md-12 mb-4">

                                                    <h6 className="mb-3 mt-4" >
                                                        3 . Add  Agent Identification Documents
                                                    </h6>
                                                    <div className="field-box mb-3">
                                                        <p className="mb-0" > <small>If you would like to expedite your verification, upload three photos of your badge/ID: </small> </p>
                                                        <ul className="lea_ol mb-3" >
                                                            <li> A close up picture of the front of your badge/ID</li>
                                                            <li> A close up picture of the back of your badge/ID</li>
                                                            <li> A selfie of you holding your badge/ID, ensuring your ID picture is clearly visible.</li>
                                                        </ul>
                                                        <p><small>Please use .jpg, .jpeg, or .png formats. No scans or altered images will be accepted. Documents are stored
                                                            encrypted.</small></p>
                                                        <p className="mb-0" > <small>By Clicking an account, you acknowladge and accept out Terms of Services and Privacy Policy.</small></p>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Documents </label>
                                                        <div className="field-otp-box">
                                                            <input type="file" className="form-control" name="subject" multipl />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-12 mb-4">
                                                        <label className="form-label mb-1">Please provide details about your inquiry
                                                        </label>
                                                        <div className=" field-otp-box">
                                                            <textarea className="form-control" rows="4" placeholder="Type message here" name="description"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="col-md-12 mb-4">

                                                    <h6 className="mb-3 mt-4" >
                                                        4. Personal Information
                                                    </h6>

                                                    <div className="row" >
                                                        <div className="col-lg-12" >
                                                            <div className="field-box mb-3">
                                                                <label for="text" className="form-label">  Designation/Post    </label>
                                                                <div className="field-otp-box ">
                                                                    <input type="text" className="form-control" name="subject" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4" >
                                                            <div className="field-box mb-3">
                                                                <label for="text" className="form-label"> First Name    </label>
                                                                <div className="field-otp-box ">
                                                                    <input type="text" className="form-control" name="subject" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="field-box mb-3">
                                                                <label for="text" className="form-label">  Middle Name </label>
                                                                <div className="field-otp-box ">
                                                                    <input type="text" className="form-control" name="subject" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="field-box mb-3">
                                                                <label for="text" className="form-label">  Last Name </label>
                                                                <div className="field-otp-box ">
                                                                    <input type="text" className="form-control" name="subject" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Email Address </label>
                                                        <div className="field-otp-box ">
                                                            <input type="text" className="form-control" name="subject" />
                                                        </div>
                                                    </div>
                                                    <div className="field-box mb-3">
                                                        <label for="text" className="form-label"> Phone Number </label>
                                                        <div className="field-otp-box ">
                                                            <input type="text" className="form-control" name="subject" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="col-md-12 mb-4">

                                                    <h6 className="mb-3 mt-4" >
                                                        5 . Summary
                                                    </h6>
                                                    <div className="field-box ">
                                                        <p className=" " > <small>If you are a law enforcement agent, member of a carrier partner, a law firm, litigator, or emergency
                                                            responder who is authorized to gather evidence in connection with an official investigation or in
                                                            order to investigate an emergency involving the danger of serious physical injury or death, you may
                                                            request records through this system.</small> </p>

                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" for="flexCheckDefault">
                                                                <small>I am an authorized law enforcement agent, carrier partner, a law firm, litigator, or
                                                                    government employee conducting an official investigation, and this is an official request</small>
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault2" />
                                                            <label className="form-check-label" for="flexCheckDefault2">
                                                                <small>I have read and agree to the Crypto India  Terms of Service and Privacy Policy..</small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-2">
                                                    <button className="btn w-auto px-5 btn-lg btn-dark" type="button">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>




                        </div>








                    </div>
                </section>
            </main>

            <Footer />
        </>

    )
}

export default LeaLegal