import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import AuthService from "../../../../Services/ApiServices/AuthService";
import SpinLoader from "../../../CoustamComponent/SpinLoader";
import moment from "moment";
import PayLimitModel from "./PayLimitModel";
import { handlePayLimit } from "../../../../Context/Utility/apiutils";
import { Html5Qrcode } from "html5-qrcode";
import { alertWarningMessage } from "../../../../Services/CustomAlertModel";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'


const SendCrypto = () => {

    const [step, setStep] = useState("step1")
    const [currencyList, setCurrencryList] = useState([]);
    const [currency, setCurrency] = useState('');
    const [walletBalance, setWalletBalance] = useState({});
    const [loading, setLoading] = useState(false);
    const [responseResult, setResponseResult] = useState("");
    const [reciverDetails, setReciverDetails] = useState();
    const [quickPayDetails, setQuickPayDetails] = useState();
    const [currencyBalance, setCurrencyBalance] = useState({});
    const [quickPayLimitDetails, setQuickPayLimitDetails] = useState();
    const [isOpenModel, setIsOpenModel] = useState(false);
    const [paylimitDetails, setPaylimitDetails] = useState();
    const fileInputRef = React.useRef(null);
    const navigate = useNavigate("");
    const [isActiveTransfer, setIsActiveTransfer] = useState(false);
    const [responseResultSuccess, setResponseResultSuccess] = useState("");
    const [responseResultWarning, setResponseResultWarning] = useState("");
    const [loadingFinal, setLoadingFinal] = useState(false);
    const [limitRangeData, setLimitRangeData] = useState();
    const [errorResponseLimit, setErrorResponseLimit] = useState();
    const [qrData, setQrData] = useState("");

    const [formData, setFormData] = useState({
        signId: "",
        amount: "",
        message: "",
        otp: "",
    });

    const handleInput = (event) => {
        const { name, value } = event.target;

        let updatedValue = value;

        if (name === "amount") {
            const regex = /^\d*\.?\d{0,8}$/;
            if (!regex.test(value) && value !== "") {
                return;
            }
            const numericValue = Number(value);
            if (numericValue < limitRangeData?.min) {
                setErrorResponseLimit(`Minimum Amount must be ${limitRangeData?.min}`);
            } else if (numericValue > limitRangeData?.max) {
                setErrorResponseLimit(`Maximum Amount must be ${limitRangeData?.max}`);
            } else if (numericValue > currencyBalance?.balance) {
                setErrorResponseLimit("Insufficient Balance");
            } else {
                setErrorResponseLimit("");
            }
        }
        setFormData((prevState) => ({
            ...prevState,
            [name]: updatedValue,
        }));

        setResponseResult("");
    };


    const handleSubmit = async (signId) => {
        setLoading(true);
        try {
            const result = await AuthService.getRecieverDetails(signId);
            if (result?.success) {
                setLoading(false);
                setStep("step2");
                setReciverDetails(result?.data)
                setResponseResult("");
                handleCurrencyList();
            } else {
                setResponseResult(result || "Internal Server Error.");
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            setResponseResult(error?.message || "An unexpected error occurred.");
        }

    };

    const handleSubmitStep2 = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const result = await AuthService.quickPayFeesDetails(formData?.amount, currency);
            if (result?.success) {
                setLoading(false);
                setStep("step3");
                setQuickPayDetails(result?.data)
                setResponseResult("");
                handleCurrencyList();
            } else {
                setResponseResult(result || "Internal Server Error.");
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            setResponseResult(error?.message || "An unexpected error occurred.");
        }
    };

    const handleCurrencyList = async () => {
        const result = await AuthService.getCurrencyList();
        if (result?.success) {
            setCurrencryList(result?.data);
            const balances = {};
            await Promise.all(
                result.data.map(async (item) => {
                    const balanceResult = await AuthService.walletBalance(item.short_name, "Funding");
                    if (balanceResult?.success) {
                        balances[item.short_name] = balanceResult.data.balance;
                    }
                })
            );
            setWalletBalance(balances);
        }
    };

    const optionsCurrency = currencyList?.map(item => ({
        label: item.short_name,
        value: item.short_name,
        image: item.icon_path,
        id: item._id,
    }));

    const handleCurrencyChange = (selectedOption) => {
        setCurrency(selectedOption);
        // handleQuickPayLimitRange(selectedOption?.value);
        setErrorResponseLimit("");
        const selectedCurrencyBalance = walletBalance[selectedOption.label];
        setCurrencyBalance(
            {
                currencyName: selectedOption.label,
                balance: selectedCurrencyBalance || "0"
            }
        );
        setFormData({
            amount: "",
        });
    };

    const handleQuickPayLimitRange = async (currencyName, newQrAmount, walletBal) => {
        const result = await AuthService.getLimitRangeDetails(currencyName);
        if (result?.success) {
            const { min, max } = result?.data || {};
            const minLimit = Number(min);
            const maxLimit = Number(max);
            const qrAmount = Number(newQrAmount);
            const balanceNew = Number(walletBal || 0);
            if (qrAmount < minLimit) {
                setErrorResponseLimit(`Minimum Amount must be ${minLimit}`);
            } else if (qrAmount > maxLimit) {
                setErrorResponseLimit(`Maximum Amount must be ${maxLimit}`);
            } else if (qrAmount > balanceNew) {
                setErrorResponseLimit("Insufficient Balance");
            } else {
                setErrorResponseLimit("");
            }
            setLimitRangeData(result?.data);
        }
    };

    const handleQuickPayLimit = async () => {
        setResponseResult("");
        setLoadingFinal(true);
        try {
            const result = await AuthService.quickPayLimit(formData);
            if (result?.success) {
                setLoadingFinal(false);
                setIsActiveTransfer(false)
                setStep("step4");
                setResponseResult("");
                setQuickPayLimitDetails(result?.data?.data);
                handlePayLimit(setPaylimitDetails);
            } else {
                setResponseResult(result || "Internal Server Error.");
                setLoadingFinal(false);
            }
        } catch (error) {
            setLoadingFinal(false);
            setResponseResult(error?.message || "An unexpected error occurred.");
        }
    };


    const handlequickpayotp = async () => {
        setLoading(true);
        try {
            const result = await AuthService.sendquickpayotp();
            if (result?.success) {
                setLoading(false);
                if (result?.message === "2FA is not enabled on your account. Please enable it for enhanced security.") {
                    setResponseResultWarning(result?.message)
                } else {
                    setResponseResultSuccess(result?.message)
                }
                setIsActiveTransfer(true);
            } else {
                setResponseResult(result || "Internal Server Error.");
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            setResponseResult(error?.message || "An unexpected error occurred.");
        }
    };

    const handleReset = () => {
        setStep("step1");
        setResponseResult("")
        setReciverDetails("");
        setQuickPayDetails("");
        setErrorResponseLimit("")
        setResponseResultWarning("")
        setResponseResultSuccess("")
        setCurrency("");
        setFormData({
            signId: "",
            amount: "",
            message: "",
            otp: "",
        });
    }

    useEffect(() => {
        handlePayLimit(setPaylimitDetails);
    }, []);

    const handleButtonClick = () => {
        fileInputRef.current.click();
        handleCurrencyList();
    };

    const handleQrUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        const qrScanner = new Html5Qrcode("qr-reader");
        try {
            const result = await qrScanner.scanFile(file, false);
            const url = new URL(result);
            const encodedData = url.searchParams.get("data");
            if (!encodedData) {
                alertWarningMessage("No 'data' parameter found in QR code URL.");
                return;
            }
            const decodedData = decodeURIComponent(encodedData);
            let qrData;
            try {
                qrData = JSON.parse(decodedData);
                setQrData(qrData);
            } catch (error) {
                console.error("Error parsing QR JSON:", error);
                return;
            }
            const matchedCurrency = optionsCurrency.find(
                (option) => option.value == qrData.currency
            );
            if (matchedCurrency) {
                setErrorResponseLimit("");
                const selectedCurrencyBalance = walletBalance[matchedCurrency.label];
                setCurrencyBalance({
                    currencyName: matchedCurrency.label,
                    balance: selectedCurrencyBalance || "0"
                });
                setCurrency(matchedCurrency);
                // handleQuickPayLimitRange(matchedCurrency.label, qrData?.amount, selectedCurrencyBalance);
            }
            setFormData((prev) => ({
                ...prev,
                signId: qrData.uid || "",
                amount: qrData.amount || "",
                message: qrData.remark || ""
            }));
            handleSubmit(qrData.uid);
        } catch (error) {
            alertWarningMessage("Upload valid QR Code");
        }
    };


    // timer countdown consts
    const minuteSeconds = 60;
    const hourSeconds = 3600;
    const daySeconds = 86400;

    const timerProps = {
        isPlaying: true, 
    };

    const renderTime = (dimension, time) => {
        return (
            <div className="time-wrapper">
                <div className="time">{time}</div>
                <div>{dimension}</div>
            </div>
        );
    };

    const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0; 


    const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
    const endTime = stratTime + 243248; // use UNIX timestamp in seconds

    const remainingTime = endTime - stratTime;



    return (
        <main className=" qp_wrapper qp_wrapper_send" >
            <div className="page_wrapper d-block" >

                <div className="qp_body" >
                    <div className="container" >
                        <div className="qp_sub_head" >
                            <div className="row align-items-center" >
                                <div className="col-lg-6" >
                                    <div className="sub_title" >
                                        <h3>
                                            <Link to="/quick_pay" className=" current-color" >
                                                <small className="cursor-pointer" >
                                                    <i class="ri-arrow-left-long-line me-2"></i></small>
                                            </Link>
                                            Send
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-lg-6" >
                                    <div className="cl_action" >
                                        <div className="sub_info" >
                                            <span className="text_link current-color cursor-pointer" onClick={() => setIsOpenModel(true)}> Pay Limit </span>
                                            <hr />
                                            <Link to="/pay_history" className="text_link current-color">Transaction History</Link>
                                            <Link to="/recive_quick_pay" className="btn btn-muted">Recive</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sub_body" >
                            {
                                step !== "step4" &&
                                <ul id="progressbar">
                                    <li class={`cursor-pointer ${step === "step1" ? 'active' : null}`} id="step1">
                                        <strong> Reciver Details </strong>
                                    </li>
                                    <li class={`cursor-pointer ${step === "step2" ? 'active' : null}`} id="step2">
                                        <strong>Enter Amount</strong>
                                    </li>
                                    <li class={`cursor-pointer ${step === "step3" ? 'active' : null}`} id="step3">
                                        <strong> Confirm Payment </strong>
                                    </li>
                                </ul>
                            }


                            <div class="row justify-content-center">
                                <div class="col-xl-8 multistep_form ">

                                    {
                                        step === "step1" &&
                                        <fieldset class="current">
                                            <div class="form-card  ">
                                                <div class="row justify-content-center ">
                                                    <div class="col-xl-6">
                                                        <div class="field-box text-center">
                                                            <div class="mb-3">
                                                                <h6> Send To</h6>
                                                                <p> <small>Send crypto to Crypto India users via email, phone number, or Crypto India ID</small> </p>
                                                            </div>
                                                        </div>
                                                        {responseResult && (
                                                            <div className="col-md-12">
                                                                <div className="alert alert-danger">{responseResult}</div>
                                                            </div>
                                                        )}
                                                        <div class="card-body">
                                                            <div className="row gx-2 gy-2">
                                                                <div className="col-12">
                                                                    <label className="form-label" htmlFor="signId">Email/Phone/User ID</label>
                                                                    <div className="field-otp-box">
                                                                        <input
                                                                            type="text"
                                                                            name="signId"
                                                                            id="signId"
                                                                            className="form-control mb-1"
                                                                            value={formData.signId}
                                                                            onChange={(e) => setFormData({ ...formData, signId: e.target.value })}
                                                                            required
                                                                        />
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-icon btn_view_pass btn-white"
                                                                            onClick={handleButtonClick}
                                                                        >
                                                                            <i className="ri-qr-scan-2-line"></i>
                                                                        </button>

                                                                        <input
                                                                            type="file"
                                                                            accept="image/*"
                                                                            ref={fileInputRef}
                                                                            onChange={handleQrUpload}
                                                                            className="d-none"
                                                                        />
                                                                    </div>
                                                                    <div id="qr-reader"></div>
                                                                </div>
                                                            </div>

                                                            <div class="col-12 mx-auto">
                                                                <button type="button" class="btn btn-warning btn-lg btn-block py-3 w-100 mt-3" onClick={() => handleSubmit(formData.signId)}>
                                                                    {loading ? <SpinLoader /> : <span>Continue</span>}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    }

                                    {
                                        step === "step2" &&
                                        <fieldset class="current">
                                            <div class="form-card  ">
                                                <div class="row justify-content-center ">
                                                    <div class="col-xl-6">
                                                        <form onSubmit={handleSubmitStep2}>
                                                            <div class="field-box text-center">
                                                                <div class="mb-3">
                                                                    <h6> Enter Amount</h6>
                                                                </div>
                                                            </div>
                                                            {responseResult && (
                                                                <div className="col-md-12">
                                                                    <div className="alert alert-danger">{responseResult}</div>
                                                                </div>
                                                            )}
                                                            <div class="card-body">
                                                                <div className="st_tab" >
                                                                    <h6>  Receiver's Info </h6>
                                                                    <div className="rc_profile" >
                                                                        <i> <img src="assets/images/user_icon.svg" className="img-fluid" alt="" /> </i>
                                                                        <div class="ud d-flex align-items-center justify-content-start gap-2 ">
                                                                            <h5>{reciverDetails}</h5>
                                                                            <span>
                                                                                <i class="ri-verified-badge-fill text-success ri-xl"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row gx-2 gy-2">
                                                                    <div class="col-12">
                                                                        <label class="form-label" for="password">  Currency </label>
                                                                        <div class=" field-otp-box  cursor-pointer">
                                                                            <Select
                                                                                options={Array.isArray(optionsCurrency) ? optionsCurrency : []}
                                                                                value={currency}
                                                                                required
                                                                                onChange={handleCurrencyChange}
                                                                                isDisabled={!!qrData?.currency}
                                                                                getOptionLabel={(option) => (
                                                                                    <div className="cc_row d-flex align-items-center  justify-content-between cursor-pointer"  >
                                                                                        <span className="d-flex align-items-center me-2" >
                                                                                            <img
                                                                                                src={option.image || "/assets/img/icon/nocoin.png"}
                                                                                                alt={option.label}
                                                                                                className="ms_select"
                                                                                                onError={(e) => { e.target.src = "/assets/img/icon/nocoin.png"; }}
                                                                                                style={{ width: "20px", height: "20px", MaxWidth: "20px", marginRight: "8px" }}
                                                                                            />
                                                                                            {option.label}
                                                                                        </span>
                                                                                        <small className="d-flex align-items-center me-2 cursor-pointer">
                                                                                            Aval Bal: {walletBalance[option.label] || "0"}
                                                                                        </small>
                                                                                    </div>
                                                                                )}
                                                                            />

                                                                        </div>
                                                                    </div>

                                                                    <div class="col-12">
                                                                        <label class="form-label" for="amount">  Amount </label>
                                                                        <div class=" field-otp-box">
                                                                            <input type="number" name="amount" id="amount" className={`form-control mb-1 ${errorResponseLimit ? "is-invalid" : ""}`} placeholder="Enter Amount" value={formData.amount}

                                                                                onChange={handleInput}


                                                                                required
                                                                                disabled={!currency?.label || qrData?.currency}
                                                                                onWheel={(e) => e.target.blur()}
                                                                                onKeyDown={(e) => {
                                                                                    if (["+", "-", "e", "E"].includes(e.key)) {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <button type="button" class="btn btn-icon btn_view_pass btn-white me-2"  > {currency?.label} </button>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-12">
                                                                        <label class="form-label" for="message"> Remark Note (Optional) </label>
                                                                        <div class=" field-otp-box">
                                                                            <input type="text" name="message" id="message" class="form-control mb-1" placeholder="Enter Note" value={formData.message} onChange={handleInput} disabled={qrData?.remark} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-2" >
                                                                    <div className="bs_error ps-2">{errorResponseLimit}</div>
                                                                </div>
                                                                <div class="col-12 mx-auto">
                                                                    <div className="d-flex gap-3 mt-4">
                                                                        <button type="button" class="btn btn-muted btn-lg btn-block py-3 w-50" onClick={() => { setStep("step1"); setResponseResult(""); }}>
                                                                            <span> Prev Step</span>
                                                                        </button>
                                                                        <button type="submit" class="btn btn-warning btn-lg btn-block py-3 w-50 " disabled={errorResponseLimit}>
                                                                            {loading ? <SpinLoader /> : <span>Continue</span>}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    }

                                    {
                                        step === "step3" &&
                                        <fieldset class="current">
                                            <div class="form-card  ">
                                                <div class="row justify-content-center ">
                                                    <div class="col-xl-6">
                                                        <form>
                                                            <div class="field-box text-center">
                                                                <div class="mb-3">
                                                                    <h6>   Confirm </h6>
                                                                </div>
                                                            </div>
                                                            {responseResult && (
                                                                <div className="col-md-12">
                                                                    <div className="alert alert-danger">{responseResult}</div>
                                                                </div>
                                                            )}
                                                            <div class="card-body">
                                                                <div className="st_tab " >
                                                                    <h6>  Receiver's Info </h6>
                                                                    <div className="rc_profile" >
                                                                        <i> <img src="assets/images/user_icon.svg" className="img-fluid" alt="" /> </i>

                                                                        <div class="ud d-flex align-items-center justify-content-start gap-2 ">
                                                                            <h5>{reciverDetails}</h5>
                                                                            <span>{quickPayLimitDetails?.reciever_entered_id}
                                                                                <i class="ri-verified-badge-fill text-success ri-xl"></i>
                                                                            </span>

                                                                        </div>

                                                                    </div>
                                                                    {
                                                                        quickPayLimitDetails?.remark &&
                                                                        <div className="note" >
                                                                            <span> <b>Note: </b> {quickPayLimitDetails?.remark}  </span>
                                                                        </div>
                                                                    }

                                                                </div>



                                                                <div className=" st_tab st_tab_amount " >
                                                                    <h6> Amount </h6>
                                                                    <div className="am_row rc_profile" >
                                                                        <div className="d-flex align-items-start justify-content-bnetween w-100" >
                                                                            <span>  Total to Pay  </span>
                                                                            <em>{quickPayDetails?.finalAmount} {currencyBalance?.currencyName}</em>
                                                                        </div>
                                                                        <div className="d-flex align-items-start justify-content-bnetween" >
                                                                            <span>Fee </span>
                                                                            <em>
                                                                                {quickPayDetails?.fee} {currencyBalance?.currencyName}
                                                                            </em>
                                                                        </div>
                                                                        <div className="d-flex align-items-start justify-content-bnetween" >
                                                                            <span>Payee Receives </span>
                                                                            <em>
                                                                                {formData?.amount} {currencyBalance?.currencyName}
                                                                            </em>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className=" st_tab st_tab_wallet " >
                                                                    <h6> Payment Method </h6>
                                                                    <div className="wallet_row rc_profile" >
                                                                        <div>
                                                                            <p>Funding Wallet</p>
                                                                            <span>Available Balance: <b>{currencyBalance?.balance} {currencyBalance?.currencyName}</b></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    currencyBalance?.balance < quickPayDetails?.finalAmount &&
                                                                    <div class="row gx-2 gy-2 mb-2">
                                                                        <div class="col-12">
                                                                            <label class="form-label" for="password">  Payment Currency </label>
                                                                            <div class=" field-otp-box">
                                                                                <select className=" form-control" disabled >
                                                                                    <option>Insufficient {currencyBalance?.currencyName}</option>
                                                                                </select>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                <div class="col-12 mx-auto">
                                                                    {currencyBalance?.balance < quickPayDetails?.finalAmount && (
                                                                        <p className="mb-2 mt-4">
                                                                            <small>
                                                                                Please deposit {(quickPayDetails?.finalAmount - currencyBalance?.balance)} {currencyBalance?.currencyName} before checkout.
                                                                            </small>
                                                                        </p>
                                                                    )}


                                                                    <small className="ext_bal text-start mt-4 mb-2 fw-light" > By continuing, I accept the <a href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/terms_and_conditions.pdf" target="_blank" > Terms of Use </a> </small>

                                                                    <div className="d-flex gap-3 mt-0">
                                                                        <button type="button" class="btn btn-muted btn-lg btn-block py-3 w-50" onClick={() => { setStep("step2"); setResponseResult(""); }}>
                                                                            <span> Prev Step</span>
                                                                        </button>
                                                                        {
                                                                            currencyBalance?.balance < quickPayDetails?.finalAmount ?
                                                                                <button type="button" class="btn btn-warning btn-lg btn-block py-3 w-50 "
                                                                                    onClick={() => navigate("/deposit_funds")}>
                                                                                    <span> Deposit Fund</span>
                                                                                </button>
                                                                                :
                                                                                <button type="button" class="btn btn-warning btn-lg btn-block py-3 w-50" onClick={() => { handlequickpayotp() }}>
                                                                                    {loading ? <SpinLoader /> : <span>Transfer Now</span>}
                                                                                </button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    }

                                    {
                                        step === "step4" &&
                                        <fieldset class="current">
                                            <div class="form-card  ">
                                                <div class="row justify-content-center ">
                                                    <div class="col-xl-6">
                                                        <form className="pp_success" >
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="svg-success" viewBox="0 0 24 24">
                                                                <g stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10">
                                                                    <circle class="success-circle-outline" cx="12" cy="12" r="11.5" />
                                                                    <circle class="success-circle-fill" cx="12" cy="12" r="11.5" />
                                                                    <polyline class="success-tick" points="17,8.5 9.5,15.5 7,13" />
                                                                </g>
                                                            </svg>
                                                            <p class="mt-3 text-gray-400 dark:text-gray-400" data-id="9">Payment Successful</p>
                                                            <h5 data-id="8"> {quickPayLimitDetails?.amount} <sub>{quickPayLimitDetails?.currency}</sub> </h5>
                                                            <div class="tt_data ">
                                                                <div class="tt_card  ">
                                                                    <div class="tt_item no-border">
                                                                        <span class="text-muted"> To </span>
                                                                        <span class="tt_normal"> {quickPayLimitDetails?.reciever_entered_id}  <br />
                                                                            <small className="fw-normal" > {reciverDetails} </small> </span>
                                                                    </div>
                                                                    <div class="tt_item">
                                                                        <span class="text-muted"> From </span>
                                                                        <span class="tt_normal">{quickPayLimitDetails?.sender_name || "-----"}</span>
                                                                    </div>
                                                                    <div class="tt_item">
                                                                        <span class="text-muted"> Note </span>
                                                                        <span class="tt_normal">{quickPayLimitDetails?.remark || "-----"}</span>
                                                                    </div>
                                                                    <div class="tt_item  ">
                                                                        <span class="text-muted"> Order No</span>
                                                                        <span class="tt_normal">{quickPayLimitDetails?.order_id}</span>
                                                                    </div>
                                                                    <div class="tt_item">
                                                                        <span class="text-muted"> Date </span>
                                                                        <span class="tt_normal"> {moment(quickPayLimitDetails.createdAt).format("DD MMM YYYY")} <br />
                                                                            <small className="fw-normal"> {moment(quickPayLimitDetails.createdAt).format("h:mm:ss a")}</small>
                                                                        </span>
                                                                    </div>
                                                                    <div class="tt_item">
                                                                        <span class="text-muted"> Amount </span>
                                                                        <span class="tt_normal"> {quickPayLimitDetails?.amount} {""} {quickPayLimitDetails?.currency}</span>
                                                                    </div>
                                                                    <div class="tt_item">
                                                                        <span class="text-muted"> Fee </span>
                                                                        <span class="tt_normal">{quickPayLimitDetails?.fee} {""} {quickPayLimitDetails?.currency}</span>
                                                                    </div>
                                                                    <div class="tt_item no-border">
                                                                        <span class="text-muted"> Total Paid :</span>
                                                                        <span class="tt_normal">{quickPayLimitDetails?.amount + quickPayLimitDetails?.fee} {""} {quickPayLimitDetails?.currency}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 mx-auto">
                                                                <div className="d-flex gap-3 mt-4">
                                                                    <button type="button" class="btn btn-muted btn-lg btn-block py-3 w-50" onClick={handleReset}>
                                                                        <span>  Send Again  </span>
                                                                    </button>
                                                                    <button type="button" class="btn btn-warning btn-lg btn-block py-3 w-50" onClick={() => navigate("/dashboard")}>
                                                                        <span> Back to Home </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <PayLimitModel isOpenModel={isOpenModel} setIsOpenModel={setIsOpenModel} paylimitDetails={paylimitDetails} />


            {
                isActiveTransfer &&
                <div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog  modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title" id="modal_otp_Label"> Verification </h5>
                                    <button type="button" className="btn-close" onClick={() => {
                                        setIsActiveTransfer(false); setResponseResult("");
                                        setResponseResultSuccess("");
                                        setFormData({
                                            otp: "",
                                        })
                                    }}>
                                    </button>
                                </div>
                                <div className="modal-body p-md-4 ">
                                    <div className="row justify-content-center">

                                        {responseResultSuccess && (
                                            <div className="fl_card mb-4 mtm_error mt-0 fl_card_success" >
                                                <div className="fl_row gap-2" >
                                                    <i class="ri-error-warning-fill"></i>
                                                    <p className="mt-0">
                                                        {responseResultSuccess}
                                                    </p>
                                                </div>
                                            </div>
                                        )}

                                        {responseResultWarning && (
                                            <div className="fl_card text-warning mb-4 mtm_error mt-0 " >
                                                <div className="fl_row gap-2" >
                                                    <i class="ri-error-warning-fill"></i>
                                                    <p className="mt-0">
                                                        {responseResultWarning}
                                                    </p>
                                                </div>
                                            </div>
                                        )}

                                        {
                                            responseResultSuccess &&
                                            <div className="col-lg-12 ">
                                                <label className="form-label" >Enter Verifaction Code</label>
                                                <input type="text" name="otp" id="otp" className="form-control" placeholder="Enter Verifaction Code...." required value={formData.otp} maxLength={6}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/\D/g, "");
                                                        handleInput({ target: { name: "otp", value } });
                                                    }}
                                                />
                                            </div>
                                        }


                                        {responseResult && (
                                            <div className="invalid-feedback" >
                                                {responseResult}
                                            </div>
                                        )}
                                        <div className="col-lg-12 text-center">
                                            <button type="button" className="btn custom-btn mt-3" onClick={handleQuickPayLimit}>
                                                {loadingFinal ? <SpinLoader /> : <span>SUBMIT</span>}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div>
            }



            
        </main >
    )
}

export default SendCrypto