import React, { useContext, useEffect, useState } from "react";
import DashboardModule from "../../DashboardModule";
import { ProfileContext } from "../../../../Services/ProfileProvider";
import { Link } from "react-router-dom";
import copy from 'copy-to-clipboard';
import PayLimitModel from "../SendCrypto/PayLimitModel";
import { handlePayLimit } from "../../../../Context/Utility/apiutils";
import DataTable from "react-data-table-component";
import AuthService from "../../../../Services/ApiServices/AuthService";
import moment from "moment";
import { alertErrorMessage } from "../../../../Services/CustomAlertModel";

const QuickPay = () => {

    const { userDetails, userKycDetails } = useContext(ProfileContext);
    const [isActiveCopy, setIsActiveCopy] = useState(null);
    const [isOpenModel, setIsOpenModel] = useState(false);
    const [paylimitDetails, setPaylimitDetails] = useState();
    const [transactionList, setTransactionList] = useState([]); 

    const handleCopy = (item) => {
        copy(item);
        setIsActiveCopy(true);
        setTimeout(() => setIsActiveCopy(false), 1000);
    };

    const handleTranactionList = async () => {
        try {
            const result = await AuthService.quickyTranactionHistory(0,5,"all");
            if (result?.success) {
                setTransactionList(result?.data?.list);
                handlePayLimit(setPaylimitDetails);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        handleTranactionList();
    }, []);

    const columns = [

        {
            name: "Date",
            cell: (row) => <div className="justify-content-start" >
                <span>{moment(row.createdAt).format("DD/MM/YYYY  ")} <br />
                    <small>{moment(row.createdAt).format("HH:mm:ss")}</small>
                </span>
            </div>,
            sortable: false,
        },

        {
            name: 'Type',
            selector: row => row.transaction_type || "-------",
            wrap: true,
            sortable: true,
        },

        {
            name: 'From / To',
            selector: row => row.transaction_type == "Debit" ? row?.reciever_name : row.transaction_type == "Credit" ? row.sender_name : "------" || "-------",
            wrap: true,
            sortable: true,
        },

        {
            name: 'Amount',
            selector: row => row.amount?.$numberDecimal + " " + row.currency,
            sortable: true,
        },

        {
            name: 'Fees',
            selector: row => row,
            wrap: true,
            sortable: true,
            format: row => <small>{row.transaction_type == "Credit" ? " -----" : row.fee?.$numberDecimal || "------"}</small>,
        },
    ];


    return (
        <main className=" qp_wrapper" >
            <div className="page_wrapper d-block" >
                <div className=" container" >
                    <div className="qp_head" >
                        <div className="qp_row" >
                            <div className="qp_left" >
                                <h2 className="" >
                                    Crypto India Pay
                                </h2>
                                <p>
                                    Send, receive Crypto and INR Easily
                                </p>
                            </div>
                            <img src="assets/images/quick_pay_img.svg" />
                        </div>
                    </div>
                </div>
                <div className="qp_body" >
                    <div className="container" >
                        <div className="qp_sub_head" >
                            <div className="row align-items-center" >
                                <div className="col-lg-6" >
                                    <div className="sub_title" >
                                        <h3>
                                            {userKycDetails?.pandetails?.name}
                                        </h3>
                                        <div className="sub_info" >
                                            <span className="" > User ID: {userDetails?.user?.uid}

                                                <i className={`text-black cursor-pointer ${isActiveCopy ? "ri-check-line text-success" : "ri-file-copy-line"}`}
                                                    onClick={() => handleCopy(userDetails?.user?.uid)}>
                                                </i>
                                            </span>
                                            <hr />
                                            <span className="text_link cursor-pointer" onClick={() => setIsOpenModel(true)}> Pay Limit </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6" >
                                    <div className="cl_action" >
                                        <Link to="/send_quick_pay" className="btn btn-warning btn-lg" > Send  </Link>
                                        <Link to="/recive_quick_pay" className="btn btn-muted btn-lg" > Receive  </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sub_body" >
                            <div className="row gx-5" >
                                <div className="col-lg-8" >
                                    <div class="Markets">
                                        <h5><small>Recent Transactions</small></h5>
                                        <Link to="/pay_history"> View All <i class=" ri-arrow-right-s-line"></i> </Link>
                                    </div>
                                    <DataTable
                                        columns={columns}
                                        data={transactionList}
                                        noDataComponent={
                                            <div className="no_data" >
                                                <img src="assets/images/no-data.svg" width="110" />
                                                <p>
                                                    <small> No transactions in the last 24 hours </small>
                                                </p>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="col-lg-4" >
                                    <div className="qp_others" >
                                        <Link to="/buy_sell" className="qp_link" >
                                            <div>
                                                <div className="in_icon">
                                                    <img src="assets/images/buy_crypto_icon.svg" alt="" className="img-fluid" /> </div>
                                                <div>
                                                    Buy Crypto
                                                </div>
                                            </div>
                                            <span> <i class="ri-arrow-right-long-line"></i> </span>
                                        </Link>
                                        <Link to="/coming_soon" className="qp_link" >
                                            <div>
                                                <div className="in_icon"> <img src="assets/images/invest_icon.svg" alt="" className="img-fluid" /> </div>
                                                <div>
                                                    Invest
                                                </div>
                                            </div>
                                            <span> <i class="ri-arrow-right-long-line"></i> </span>
                                        </Link>
                                        <Link to="/referral" className="qp_link" >
                                            <div>
                                                <div className="in_icon"> <img src="assets/images/gifts_icon.svg" alt="" className="img-fluid" /> </div>
                                                <div>
                                                    Gifts
                                                </div>
                                            </div>
                                            <span> <i class="ri-arrow-right-long-line"></i> </span>
                                        </Link>
                                        <Link to="/dashboard" className="qp_link" >
                                            <div>
                                                <div className="in_icon"> <img src="assets/images/deposit_crypto_icon.svg" alt="" className="img-fluid" /> </div>
                                                <div>
                                                    Deposit Crypto
                                                </div>
                                            </div>
                                            <span> <i class="ri-arrow-right-long-line"></i> </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PayLimitModel isOpenModel={isOpenModel} setIsOpenModel={setIsOpenModel} paylimitDetails={paylimitDetails} />
        </main>
    )
}

export default QuickPay