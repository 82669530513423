import React, { useContext, useEffect, useRef, useState } from "react";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import { alertErrorMessage, alertSuccessMessage } from "../../../../Services/CustomAlertModel";
import AuthService from "../../../../Services/ApiServices/AuthService";
import { ProfileContext } from "../../../../Services/ProfileProvider";
import { Link } from "react-router-dom/dist";
import Webcam from 'react-webcam';

const PaymentModules = () => {

    const [isOtpSent, setIsOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [isActiveAddBank, setIsActiveAddBank] = useState(false);
    const [isActiveAddUpi, setIsActiveAddUpi] = useState(false);
    const { userDetails, handleUserProfile } = useContext(ProfileContext);
    const [userKycDetails, setUserKycDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showImageModel, setShowImageModel] = useState("step1");
    const [isCameraAllowed, setIsCameraAllowed] = useState(false);
    const [imageStatus, setImageStatus] = useState(false);
    const [image, setImage] = useState(null);
    const [showSubmitButton, setShowSubmitButton] = useState(false);
    const [isActiveTab, setIsActiveTab] = useState("bank");
    const [showButton, setShowButton] = useState(false);
    const [ifscValid, setIfscValid] = useState(false);
    const [disableField, setDisableField] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isActiveBank, setIsActiveBank] = useState("Active");

    const webcamRef = useRef(null);

    const [formData, setFormData] = useState({
        emailId: "",
        emailOtp: '',
        bank_name: '',
        branch_name: '',
        accountHolderName: userKycDetails?.pandetails?.name || "",
        accountNumber: '',
        ifscCode: '',
        accountType: 'Saving',
        upiId: '',

    });

    useEffect(() => {
        setFormData({
            emailId: userDetails?.user?.email || "",
            accountHolderName: userKycDetails?.pandetails?.name || "",
            accountType: 'Saving',
        });
    }, [userKycDetails, userDetails]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        if (name === 'ifscCode') {
            formattedValue = formattedValue.toUpperCase().replace(/\s/g, '');
            formattedValue = formattedValue.replace(/(.{5})(.{4})(.{1})/, '$1$2$3');
            const panRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
            setIfscValid(panRegex.test(formattedValue));
        }
        setFormData(prevState => ({
            ...prevState,
            [name]: formattedValue
        }));
    };

    const base64ToBlob = (base64) => {
        const byteString = atob(base64.split(',')[1]);
        const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
        const buffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(buffer);
        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }
        return new Blob([buffer], { type: mimeString });
    };

    const handleSubmit = async () => {
        var formValue = new FormData();
        let formattedName = formData?.accountHolderName?.trim().replace(/\s+/g, ' ');
        formValue.append("email", formData.emailId);
        formValue.append("verification_code", formData.emailOtp);
        formValue.append("account_holder_name", formattedName);
        formValue.append("accountNumber", formData.accountNumber);
        formValue.append("ifsc", formData.ifscCode);
        formValue.append("account_type", formData.accountType);
        formValue.append("bank_name", formData.bank_name);
        formValue.append("branch_name", formData.branch_name);
        formValue.append("bankDocument", base64ToBlob(image), 'live-image.jpg');
        setIsLoading(true);
        try {
            const result = await AuthService.addBankAccount(formValue);
            if (result?.success) {
                handleUserProfile()
                setIsActiveAddBank(false);
                setIsOtpSent(false);
                setCountdown(0);
                setIsLoading(false);
                alertSuccessMessage(result.message);

            } else {
                alertErrorMessage(result || result.message);
                setIsLoading(false);
            }
        } catch (error) {
            alertErrorMessage(error);
            setIsLoading(false);
        }
    };

    const handleSubmitAddUpi = async () => {
        if (!formData?.emailOtp) {
            alertErrorMessage('Please Enter Email Otp..!!');
            return;
        } if (formData?.emailOtp.length > 6) {
            alertErrorMessage('Please Enter Valid Email Otp..!!');
            return;
        } if (!formData?.upiId) {
            alertErrorMessage('Please Enter Upi ID..!!');
            return;
        }
        setLoading(true);
        try {
            const result = await AuthService.addUpiDetails(formData);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile()
                setIsActiveAddUpi(false);
                setIsOtpSent(false);
                setCountdown(0);
                setFormData({
                    emailId: "",
                    emailOtp: '',
                    upiId: '',
                    emailId: userDetails?.user?.email || "",
                    accountHolderName: userKycDetails?.pandetails?.name || "",
                });
                setLoading(false);
            } else {
                alertErrorMessage(result);
                setLoading(false);
            }
        } catch (error) {
            alertErrorMessage(error);
            setLoading(false);
        }

    };


    const handleAddDefaultBank = async (accountNumber) => {
        try {
            const result = await AuthService.updateDefaultBank(accountNumber);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile()
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    }

    const handleAddDefaultUpi = async (vpaId) => {
        try {
            const result = await AuthService.updateDefaultUpi(vpaId);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile()
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    }

    const handleDeleteUpi = async (vpaId) => {
        try {
            const result = await AuthService.deletetUpi(vpaId);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile()
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    }

    const deleteBankDetails = async (accountNumber) => {
        try {
            const result = await AuthService.deletetBank(accountNumber);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleUserProfile()
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    }

    const handleGetOtp = async (emailId, type) => {
        try {
            const result = await AuthService.getOtp(emailId, type);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                setIsOtpSent(true);
                setShowButton(true);
                setCountdown(60);
                setDisableField(true)
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        let timer;
        if (isOtpSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, countdown]);

    const ResetCount = () => {
        setIsOtpSent(false);
        setCountdown(0);
        setFormData({
            emailId: '',
            emailOtp: '',
            emailId: userDetails?.user?.email || "",
            accountHolderName: userKycDetails?.pandetails?.name || "",
        });
    }

    const handleGetKycDetails = async () => {
        const result = await AuthService.getKycDetails();
        if (result?.success) {
            setUserKycDetails(result.data);
        }
    };

    useEffect(() => {
        handleGetKycDetails();
    }, []);

    const checkCameraPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setIsCameraAllowed(true);
            stream.getTracks().forEach(track => track.stop());
        } catch (error) {
            setIsCameraAllowed(false);
            alertErrorMessage('Camera access is denied. Please enable camera access in your browser settings.');
        }
    };


    useEffect(() => {
        if (showImageModel === "step2") {
            checkCameraPermission();
        }
    }, []);


    const capture = () => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            setImage(imageSrc);
            setImageStatus(true);
        }
    };

    const handleSubmitDetails = () => {
        if (imageStatus) {
            setShowSubmitButton(true);
            setShowImageModel("step1");
        } else {
            setShowSubmitButton(false);
        }
    };

    const userBankDetil = userDetails?.bank_details?.map(item => item?.status);

    const isRejectedBank = userDetails?.bank_details?.some((item) => item?.status === "rejected");



    return (

        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper" >
                    <section className="d_page history_page p_page m_account">
                        <div className="container" >
                            <div className="row justify-content-center" >
                                <div className="col-xxl-8 col-xl-10" >

                                    <div className="d_u_p align-items-center m-0" >
                                        <div className="dup_left" >
                                            <h6 className="mb-0" >  Payment Method   </h6>
                                        </div>
                                        <div className="up_right text-end" id="myTab" role="tablist" >
                                            <ul className="nav custom-tabs">
                                                <li role="presentation">
                                                    <button type="button" className={isActiveTab === "bank" ? 'show active' : ""} onClick={() => { setIsActiveTab("bank"); handleGetKycDetails() }}>
                                                        Bank Account
                                                    </button>
                                                </li>
                                                {
                                                    userBankDetil?.some(status => status === "verified") ?
                                                        <li role="presentation" className={userDetails?.bank_details?.length > 0 ? "" : "d-none"}>
                                                            <button type="button" className={isActiveTab === "upi" ? 'show active' : ""} onClick={() => { setIsActiveTab("upi"); handleGetKycDetails() }}>UPI ID </button>
                                                        </li>
                                                        :
                                                        ""
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="tab-content" >

                                        <div className={`tab-pane ${isActiveTab === "bank" ? 'show active' : ""}`}>
                                            <div className="g_start  mt-4">
                                                <div className="d_u_p align-items-center m-0" >
                                                    <div className="dup_left" >
                                                        <h6 className="mb-0"> Bank Account </h6>
                                                    </div>
                                                    <div className="up_right" >
                                                        <div className="btn_row">

                                                            {userDetails?.bank_details?.some((item) => item?.status === "rejected") ?
                                                                <>
                                                                    <button className="btn btn-success" onClick={() => setIsActiveBank("Active")}>Active Bank</button>
                                                                    <button className="btn btn-danger" onClick={() => setIsActiveBank("Rejected")}>Rejected</button>
                                                                </>
                                                                :
                                                                ""
                                                            }

                                                            {/*  {
                                                                userBankDetil?.some(status => status === "pending") ? ""
                                                                    : <button className="btn btn-muted" onClick={() => { setIsActiveAddBank(true); setDisableField(false) }}>Add New</button>
                                                            } */}

                                                            {
                                                                !userBankDetil?.some(status => status === "pending") && (
                                                                    <button
                                                                        className="btn btn-muted"
                                                                        onClick={() => {
                                                                            setIsActiveAddBank(true);
                                                                            setDisableField(false);
                                                                        }}
                                                                    >
                                                                        Add New
                                                                    </button>
                                                                )
                                                            }

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={isActiveBank === "Active" ? "" : "d-none"}>
                                                    {userDetails?.bank_details
                                                        ?.filter((item) => item?.status !== "rejected")
                                                        ?.map((item, index) => {
                                                            return (
                                                                <div className=" mt-4  p_card" key={index}>
                                                                    <div className="bb_head" >
                                                                        <div className="row align-items-center" >
                                                                            <div className="col-6" >
                                                                                <h6 className="mb-0" > <small>{item?.userNameInBank}</small> </h6>
                                                                            </div>


                                                                            <div className="row">
                                                                                <div className="col-md-6 text-start">
                                                                                    {
                                                                                        item?.status === "rejected" &&
                                                                                        <span className="mx-1 text-danger">
                                                                                            {item?.reject_reason?.[0]}
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                                <div className="col-md-6 text-end">
                                                                                    <span className={`badge mx-1 text-dark 
                                                                                    ${item?.status === "verified" ? 'badge-success' :
                                                                                            item?.status === "pending" ? 'bg-warning' :
                                                                                                item?.status === "rejected" ? 'badge-danger' : 'bg-secondary'}`}>

                                                                                        {item?.status?.toUpperCase()}
                                                                                    </span>
                                                                                    <span className={`badge mx-1 ${item?.default === true ? 'bg-primary' : ''}`}>
                                                                                        {item?.default === true ? 'Default' : ''}
                                                                                    </span>
                                                                                    {
                                                                                        item?.default === false && item?.status !== "rejected" &&
                                                                                        <span className="dropdown more_menu">
                                                                                            <button className="btn no-border" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                <i className="ri-more-2-fill"></i>
                                                                                            </button>
                                                                                            <ul className="dropdown-menu p-2" aria-labelledby="dropdownMenuLink">
                                                                                                <li>
                                                                                                    <div className="dropdown-item cursor-pointer" onClick={() => handleAddDefaultBank(item?.accountNumber)}>
                                                                                                        Set As Default
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="dropdown-item  cursor-pointer text-danger" onClick={() => deleteBankDetails(item?.accountNumber)}>
                                                                                                        Delete Account
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row g-3" >
                                                                        <div className="col-md-4" >
                                                                            <div className="ud" >
                                                                                <span>Bank Name </span>
                                                                                <h5> <small> {item?.bankName} </small>  </h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6" >
                                                                            <div className="ud" >
                                                                                <span> Account Number</span>
                                                                                <h5> <small>{item?.accountNumber}</small>  </h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4" >
                                                                            <div className="ud" >
                                                                                <span> Branch Name </span>
                                                                                <h5> <small> {item?.branchName} </small>  </h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4" >
                                                                            <div className="ud" >
                                                                                <span> IFSC Code </span>
                                                                                <h5> <small> {item?.ifsc} </small>  </h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4" >
                                                                            <div className="ud" >
                                                                                <span> Account Holder Name </span>
                                                                                <h5> <small> {item?.kycName} </small>  </h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>


                                                <div className={isActiveBank === "Rejected" ? "" : "d-none"}>
                                                    {userDetails?.bank_details
                                                        ?.filter((item) => item?.status === "rejected")
                                                        ?.map((item, index) => {
                                                            return (
                                                                <div className=" mt-4  p_card" key={index}>
                                                                    <div className="bb_head" >
                                                                        <div className="row align-items-center" >
                                                                            <div className="col-6" >
                                                                                <h6 className="mb-0" > <small>{item?.userNameInBank}</small> </h6>
                                                                            </div>


                                                                            <div className="row">
                                                                                <div className="col-md-6 text-start">
                                                                                    {
                                                                                        item?.status === "rejected" &&
                                                                                        <span className="mx-1 text-danger">
                                                                                            {item?.reject_reason?.[0]}
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                                <div className="col-md-6 text-end">
                                                                                    <span className={`badge mx-1 text-dark 
                                                                                    ${item?.status === "verified" ? 'badge-success' :
                                                                                            item?.status === "pending" ? 'bg-warning' :
                                                                                                item?.status === "rejected" ? 'badge-danger' : 'bg-secondary'}`}>

                                                                                        {item?.status?.toUpperCase()}
                                                                                    </span>
                                                                                    <span className={`badge mx-1 ${item?.default === true ? 'bg-primary' : ''}`}>
                                                                                        {item?.default === true ? 'Default' : ''}
                                                                                    </span>
                                                                                    {
                                                                                        item?.default === false && item?.status !== "rejected" &&
                                                                                        <span className="dropdown more_menu">
                                                                                            <button className="btn no-border" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                <i className="ri-more-2-fill"></i>
                                                                                            </button>
                                                                                            <ul className="dropdown-menu p-2" aria-labelledby="dropdownMenuLink">
                                                                                                <li>
                                                                                                    <div className="dropdown-item cursor-pointer" onClick={() => handleAddDefaultBank(item?.accountNumber)}>
                                                                                                        Set As Default
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="dropdown-item  cursor-pointer text-danger" onClick={() => deleteBankDetails(item?.accountNumber)}>
                                                                                                        Delete Account
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row g-3" >
                                                                        <div className="col-md-4" >
                                                                            <div className="ud" >
                                                                                <span>Bank Name </span>
                                                                                <h5> <small> {item?.bankName} </small>  </h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6" >
                                                                            <div className="ud" >
                                                                                <span> Account Number</span>
                                                                                <h5> <small>{item?.accountNumber}</small>  </h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4" >
                                                                            <div className="ud" >
                                                                                <span> Branch Name </span>
                                                                                <h5> <small> {item?.branchName} </small>  </h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4" >
                                                                            <div className="ud" >
                                                                                <span> IFSC Code </span>
                                                                                <h5> <small> {item?.ifsc} </small>  </h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4" >
                                                                            <div className="ud" >
                                                                                <span> Account Holder Name </span>
                                                                                <h5> <small> {item?.kycName} </small>  </h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>

                                            </div>
                                        </div>

                                        <div className={`tab-pane  ${isActiveTab === "upi" ? 'show active' : ""}`}>
                                            <div className="g_start  mt-4    " >
                                                <div className="d_u_p align-items-center m-0" >
                                                    <div className="dup_left" >
                                                        <h6 className="mb-0" > UPI ID   </h6>
                                                    </div>

                                                    <div className="up_right">
                                                        <div className="btn_row">
                                                            {userDetails?.upi_details?.length === 2 ? "" :
                                                                <button className="btn btn-muted" onClick={() => setIsActiveAddUpi(true)}> Add NEW </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                {userDetails?.upi_details ? userDetails?.upi_details?.map((item, index) => {
                                                    return (
                                                        <div className=" mt-4  p_card" key={index}>
                                                            <div className="bb_head" >
                                                                <div className="row align-items-center" >
                                                                    <div className="col-6" >
                                                                        <h6 className="mb-0" > <small>{item?.vpaId}</small> </h6>
                                                                    </div>
                                                                    <div className="col-6 text-end" >

                                                                        <span className={`badge mx-1 text-dark ${item?.nameMatch ? 'badge-success' : 'bg-warning'}`}
                                                                        > {item?.nameMatch ? 'Verified' : 'Pending'}</span>

                                                                        <span className={`badge mx-1 ${item?.default === true ? 'bg-primary' : ''}`}>
                                                                            {item?.default === true ? 'Default' : ''}
                                                                        </span>
                                                                        {
                                                                            item?.default === false &&
                                                                            <span className="dropdown more_menu">
                                                                                <button className="btn no-border" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    <i className="ri-more-2-fill"></i>
                                                                                </button>
                                                                                <ul className="dropdown-menu p-2" aria-labelledby="dropdownMenuLink">
                                                                                    <li>
                                                                                        <div className="dropdown-item cursor-pointer" onClick={() => handleAddDefaultUpi(item?.vpaId)}> Set As Default </div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div className="dropdown-item  cursor-pointer text-danger" onClick={() => handleDeleteUpi(item?.vpaId)}> Delete UPI </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row g-3" >
                                                                <div className="col-md-4" >
                                                                    <div className="ud" >
                                                                        <span> Name  </span>
                                                                        <h5> <small>{item?.UPIHolderName}</small> </h5>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6" >
                                                                    <div className="ud" >
                                                                        <span> UPI ID </span>
                                                                        <h5> <small> {item?.vpaId} </small>  </h5>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                }) : null}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>



                {/* <!-- Add account modal --> */}
                {isActiveAddBank && (
                    <div className="modal-open-custom" >
                        <div className="modal show d-block" >
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header flex-column px-8">
                                        <button type="button" className="btn-close" onClick={() => { setIsActiveAddBank(false); ResetCount(); setShowImageModel("step1"); setImageStatus(false) }}></button>
                                        <h5 className="modal-title" id="modal_add_bank_Label"> Add New Bank Account </h5>
                                    </div>
                                    <div className="modal-body modal_form">
                                        {
                                            userDetails?.user?.kycVerified != 2 ?

                                                <div className=" text-center" >
                                                    <img src="/assets/images/user_kyc.svg" className="img-fluid  m-auto" />
                                                    <span className={`m-auto mt-3 badge ${userDetails?.user?.kycVerified === 1 ? "badge-warning bg-warning" : userDetails?.user?.kycVerified === 0 ? "badge-warning bg-warning" : "badge-danger"}`} >
                                                        <Link className="text-dark" to={userDetails?.user?.kycVerified == 2 ? "#" : '/verify_kyc'}>
                                                            {userDetails?.user?.kycVerified == 1 ? "Kyc is Under Review" : userDetails?.user?.kycVerified == 0 ? "SUBMIT KYC" : "Verification Failed!"}
                                                        </Link>
                                                    </span>
                                                </div>
                                                :
                                                <form>
                                                    {/* step 1 */}
                                                    <div className={`row ${showImageModel === "step1" ? "" : "d-none"}`}>

                                                        <div className="col-6 form-group">
                                                            <label className="text-white mb-1" htmlFor="accountType">Account Type</label>
                                                            <select name="accountType" className="form-select form-control" value={formData.accountType} onChange={handleChange} disabled={disableField}>
                                                                <option value="Saving">Saving Account</option>
                                                                <option value="Current">Current Account</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-6 form-group">
                                                            <label className="text-white mb-1" htmlFor="bank_name">Bank Name</label>
                                                            <input type="text" name="bank_name" id="bank_name" className="form-control" value={formData.bank_name} onChange={handleChange} disabled={disableField} />
                                                        </div>
                                                        <div className="col-6 form-group">
                                                            <label className="text-white mb-1" htmlFor="branch_name">Branch Name</label>
                                                            <input type="text" name="branch_name" id="branch_name" className="form-control" value={formData.branch_name} onChange={handleChange} disabled={disableField} />
                                                        </div>
                                                        <div className="col-6 form-group">
                                                            <label className="text-white mb-1" htmlFor="accountHolderName">Account Holder Name</label>
                                                            <input type="text" name="accountHolderName" id="accountHolderName" className="form-control" value={formData.accountHolderName} disabled />
                                                        </div>
                                                        <div className="col-6 form-group">
                                                            <label className="text-white mb-1" htmlFor="accountNumber">Account Number</label>
                                                            <input type="text" name="accountNumber" id="accountNumber" className="form-control" value={formData.accountNumber} onChange={handleChange} disabled={disableField} />
                                                        </div>
                                                        <div className="col-6 form-group">
                                                            <label className="text-white mb-1" htmlFor="ifscCode">IFSC Code</label>
                                                            <input type="text" name="ifscCode" id="ifscCode" className="form-control" value={formData.ifscCode} onChange={handleChange} disabled={disableField} />
                                                        </div>


                                                        <div className="col-md-12 form-group" ><hr className="line_dashed" /></div>

                                                        <div className="col-md-12 up_cs_card mb-4">
                                                            <h6>Upload Selfie with Cheque</h6>
                                                            <div className="upswc" >
                                                                <div className="up_ac" >
                                                                    <label className="form_label mb-0" >
                                                                        {!imageStatus ?
                                                                            <div className="up_card" >
                                                                                <div className="up_card" ><img src="/assets/images/camera.svg" alt="Captured" className="" /> </div>
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                <div className="up_card" ><img src={image} alt="Captured" className="" /> </div>
                                                                            </>


                                                                        }
                                                                    </label>
                                                                    {!imageStatus ?
                                                                        <button className="btn btn-dark btn-dotted px-5" type="button" onClick={() => setShowImageModel("step2")}> Click Selfie </button>
                                                                        :
                                                                        <button className="btn btn-muted btn-dotted px-5 " type="button" onClick={() => { setImageStatus(false); setShowImageModel("step2") }} disabled={disableField}> Retake </button>
                                                                    }
                                                                </div>
                                                                <div className="up_details" >
                                                                    <h6 className="mb-2" > Instructions </h6>
                                                                    <p>  Make sure your face and <b className="text-warning">cancel cheque</b> with <b className="text-warning">signature</b> photo visible properly. </p>
                                                                    <img src="/assets/images/cheque_suggation.svg" alt="" className="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            (formData?.bank_name && formData?.branch_name && formData?.accountNumber && ifscValid && imageStatus) && (

                                                                <div className="col-6 form-group">
                                                                    <div className="field-otp-box">
                                                                        <input type="number" name="emailOtp" id="email_otp" className="form-control" placeholder="Email verification Code" required value={formData.emailOtp} onChange={handleChange} maxLength={6} disabled={!showButton} />

                                                                        <button type="button" className="btn btn-sm btn-warning" onClick={() => { handleGetOtp(formData?.emailId, 'addbank') }} disabled={isOtpSent}>
                                                                            <span>{isOtpSent ? `Resend in ${countdown}s` : 'GET OTP'}</span>
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        {
                                                            imageStatus && showSubmitButton && showButton && formData?.emailOtp?.length == 6 && ifscValid && (
                                                                <div className="col-6">
                                                                    <button
                                                                        onClick={handleSubmit}
                                                                        type="button"
                                                                        className="form-control btn-muted"
                                                                    >
                                                                        SUBMIT
                                                                    </button>
                                                                </div>
                                                            )
                                                        }

                                                    </div>

                                                    {/*  step 2 */}
                                                    <div className={`row justify-content-center ${showImageModel === "step2" ? "" : "d-none"}`}>
                                                        <div className="col-lg-8 col-xl-8" >

                                                            {/*  */}
                                                            <div className="fl_title" >
                                                                <label htmlFor="Liveness" className="form-label text-center m-auto w-100 mb-3">
                                                                    <h5> Click selfie with cancel cheque. </h5>
                                                                    <p> Make sure your face and cheque photo visible properly. </p>
                                                                </label>
                                                                {isCameraAllowed === false && <p className="text-center" >Checking camera permission...</p>}
                                                                {isCameraAllowed === false && (
                                                                    <button onClick={checkCameraPermission} type="button" className=" w-100 btn-block btn btn-dark mt-2">Try Again</button>
                                                                )}
                                                            </div>
                                                            {isCameraAllowed && !imageStatus ? (
                                                                <div className="web_cam_row"  >
                                                                    <div className="web_cam_box " >
                                                                        <Webcam
                                                                            audio={false}
                                                                            ref={webcamRef}
                                                                            screenshotFormat="image/jpeg"
                                                                        />
                                                                    </div>
                                                                    <button onClick={capture} type="button" className="form-control btn-lg btn-dark mb-2   w-50">
                                                                        Capture
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                imageStatus &&
                                                                <div className="web_cam_row" >
                                                                    <div className="web_cam_box" >
                                                                        <img src={image} alt="Captured" />
                                                                    </div>
                                                                    <div className="w-100 row align-items-center gx-3" >

                                                                        <div className="col-6">
                                                                            <button onClick={() => { setImageStatus(false) }} type="button" className="form-control btn-muted mt-2">
                                                                                Re-take
                                                                            </button>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <button onClick={handleSubmitDetails} type="button" className="btn-block w-100 form-control btn-dark mt-2" disabled={isLoading}>
                                                                                {
                                                                                    isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Submit</span>
                                                                                }
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                )}


                {/* <!-- Add UPI modal --> */}
                {isActiveAddUpi && (
                    <div className="modal-open-custom">
                        <div className="modal show d-block">
                            <div className="modal-dialog modal-md">
                                <div className="modal-content">
                                    <div className="modal-header flex-column px-8">
                                        <button type="button" className="btn-close" onClick={() => { setIsActiveAddUpi(false); ResetCount() }}></button>
                                        <h5 className="modal-title" id="modal_add_upi_Label">
                                            Add New UPI Id
                                        </h5>
                                    </div>
                                    <div className="modal-body modal_form">
                                        {
                                            userDetails?.user?.kycVerified != 2 ?

                                                <div className=" text-center" >
                                                    <img src="/assets/images/user_kyc.svg" className="img-fluid  m-auto" />
                                                    <span className={`m-auto mt-3 badge ${userDetails?.user?.kycVerified === 1 ? "badge-warning bg-warning" : userDetails?.user?.kycVerified === 0 ? "badge-warning bg-warning" : "badge-danger"}`} >
                                                        <Link className="text-dark" to={userDetails?.user?.kycVerified == 2 ? "#" : '/verify_kyc'}>
                                                            {userDetails?.user?.kycVerified == 1 ? "Kyc is Under Review" : userDetails?.user?.kycVerified == 0 ? "SUBMIT KYC" : "Verification Failed!"}
                                                        </Link>
                                                    </span>
                                                </div>
                                                :
                                                <form>
                                                    <div className="row">
                                                        <div className="col-12 form-group ">
                                                            <label className="text-white mb-1" htmlFor="upiHolder"> Name  </label>
                                                            <input type="text" name="upiHolder" id="upiHolder" className="form-control" value={formData.accountHolderName} disabled />
                                                        </div>
                                                        <div className="col-12 form-group">
                                                            <label className="text-white mb-1" htmlFor="upiId"> UPI VPA Id    </label>
                                                            <input type="text" name="upiId" id="upiId" className="form-control" value={formData.upiId} onChange={handleChange} />
                                                        </div>

                                                        <div className="col-12 form-group">
                                                            <label className="text-white mb-1" htmlFor="email_otp">Email OTP</label>
                                                            <div className="field-otp-box">
                                                                <input type="number" name="emailOtp" id="email_otp" className="form-control" placeholder="Email verification Code" required value={formData.emailOtp} onChange={handleChange} />

                                                                <button type="button" className="btn btn-sm btn-warning" onClick={() => { handleGetOtp(formData?.emailId, 'addbank') }} disabled={isOtpSent || !formData.upiId}>
                                                                    <span>{isOtpSent ? `Resend in ${countdown}s` : 'GET OTP'}</span>
                                                                </button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className=" text-center ">
                                                        <button type="button" className="btn btn-dark w-50 mt-3" onClick={handleSubmitAddUpi}>
                                                            {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                : <span> Verify UPI</span>}


                                                        </button>
                                                    </div>
                                                </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                )}
            </div>
        </main>

    )
}

export default PaymentModules