import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";

import copy from 'copy-to-clipboard';
import AuthService from "../../../../Services/ApiServices/AuthService";
import { fetchCoinDetails, fetchWalletList } from "../../../../Context/Utility/apiutils";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import { alertErrorMessage, alertSuccessMessage } from "../../../../Services/CustomAlertModel";
import { ProfileContext } from "../../../../Services/ProfileProvider";


const DepositFunds = () => {

    const [values, setValues] = React.useState([2000]);

    const location = useLocation();
    const { state } = location;
    const [stateData, setStateData] = useState(state || null);
    const [isActive, setIsActive] = useState('step1')
    const [coinList, setCoinList] = useState([]);
    const [genrateDepositqr, setGenrateDepositqr] = useState("");
    const [search, setsearch] = useState("");
    const [fiterPairData, setFiterPairData] = useState([]);
    const [coinDetails, setCoinDetails] = useState('');
    const [chainName, setChainName] = useState('');
    const [activeDepostInr, setActiveDepostInr] = useState('step1');
    const { userDetails } = useContext(ProfileContext);


    const getDepostAddress = async (chain) => {
        await AuthService.generateDepositAddress(chain).then((result) => {
            if (result?.success) {
                try {
                    setGenrateDepositqr(result?.data);
                } catch (error) {
                    alertErrorMessage(error)
                }
            }
            else {
                alertErrorMessage(result?.message)
                setIsActive('step1')
            }
        });
    };

    useEffect(() => {
        let filteredData = coinList?.filter((item) => {
            return (
                item?.short_name?.toLowerCase().includes(search?.toLowerCase())
            );
        });
        setFiterPairData(filteredData);
    }, [search, coinList]);


    const handleDepositDetails = (item, step) => {

        if (item?.short_name === 'INR') {
            /*   setIsActive('step4')
              setChainName("") */
        } else {
            if (!userDetails?.permissions?.deposit) {
                alertErrorMessage('Disable Deposit..');
                return;
            }
            setIsActive(step)
            fetchCoinDetails(setCoinDetails, item?.currency_id)
            getDepostAddress(item?.chain[0])
            setChainName(item?.chain[0])
        }
    }

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setChainName(selectedValue);
        getDepostAddress(selectedValue, 'step2');
    };


    useEffect(() => {
        fetchWalletList(setCoinList, "", "");
    }, []);


    useEffect(() => {
        if (stateData) {
            handleDepositDetailsAuto(stateData, "step2");
        }
    }, [stateData]);


    const handleDepositDetailsAuto = (item, step) => {
        if (!userDetails?.permissions?.deposit) {
            alertErrorMessage('Disable Deposit..');
            return;
        }
        setIsActive(step);
        fetchCoinDetails(setCoinDetails, item?.currency_id);
        getDepostAddress(item?.chain[0]);
        setChainName(item?.chain[0]);
    };

    const handleBack = () => {
        setStateData(null);
        setIsActive('step1');
    };


    return (
        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper   ">
                    <section className="d_page history_page p_page deposit_wrapper">
                        <div className="container mx-auto">
                            <div className="row   gx-5">
                                <div className="col-xxl-7 col-xl-7">
                                    <div className="d_u_p align-items-center m-0">
                                        <div className="dup_left">
                                            <h6 className="mb-0">Desposit Funds </h6>
                                        </div>
                                        {/* <div className="up_right text-end">
                                            <Link to="/transaction_history" className="btn btn-muted"> Recent Transactions </Link>
                                        </div> */}
                                    </div>


                                    <div className="df_row" >


                                        {/* Step 1     */}
                                        <div className="df_col active">
                                            <span className="df_step" > <em className=" " > <i class="ri-check-line"></i> </em>  Select Currency </span>
                                            <div className="df_card position-relative" >
                                                <div className="df_top" >
                                                    <div className="custom_select" >
                                                        {/* label only */}
                                                        <label className="d-flex align-items-center justify-content-between" > Select Coin  <i class="ri-arrow-down-s-line"></i>  </label>

                                                        {/* after selected */}
                                                        <div class="c_view w-100 d-none align-items-center justify-content-between">
                                                            <div class="d-flex align-items-center">
                                                                <img width="22" class="img-fluid icon_img coinimg_max me-2" alt="" />
                                                                <span>INR   <small class="opacity-65 ms-1"> Full Name Here </small>
                                                                </span>
                                                            </div>
                                                            <i class="ri-arrow-down-s-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="df_dropbox" >
                                                    <div className="drop_card" >
                                                        <div className="df_cl" >
                                                            {/* search bar */}
                                                            <div className="cl_top" >
                                                                {coinList?.length > 0 ?
                                                                    <div className="searchBar custom-tabs">
                                                                        <i className="ri-search-2-line"></i>
                                                                        <input type="search" className="custom_search w-100" placeholder="Search Coin" value={search} onChange={(e) => setsearch(e.target.value)} />
                                                                    </div>
                                                                    :
                                                                    ""
                                                                }
                                                                <small className="text-muted" > Coin List </small>
                                                            </div>
                                                            <div className="cl_cl scroll_y" >

                                                                {fiterPairData?.length > 0 ? (
                                                                    fiterPairData?.map((item, index) => (
                                                                        <span className="cl_llist" key={index} onClick={() => handleDepositDetails(item, 'step2')}>
                                                                            <div class="c_view w-100">
                                                                                <div class="d-flex align-items-center">
                                                                                    <img src={item.icon_path} width="25" className="img-fluid icon_img coinimg_max me-2 " alt=""
                                                                                        onError={(e) => {
                                                                                            e.target.onerror = null;
                                                                                            e.target.src = "/assets/images/icon/nocoin.png";
                                                                                        }} />
                                                                                    <span>{item.short_name} <small className="opacity-65 ms-1" > Full Name Here </small></span>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    ))
                                                                ) : (
                                                                    <div className="favouriteData">
                                                                        <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                    </div>
                                                                )}

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Step 2 */}
                                        <div className="df_col  ">
                                            <span className="df_step" > <em>2</em>  Select Chain(Network) </span>
                                            <div className="df_card  position-relative " >
                                                <div className="df_top" >
                                                    {coinDetails?.chain?.length > 1 ? (
                                                        <select
                                                            className="form-select form-control width-50 fw-medium" 
                                                            value={chainName}
                                                            onChange={handleSelectChange}
                                                        >
                                                            <option disabled>
                                                                Select a network
                                                            </option>
                                                            {coinDetails.chain.map((item, index) => (
                                                                <option key={index} value={item} className="form-control">
                                                                    {item}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    ) : (
                                                        null
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {/* Step 3 */}
                                        <div className="df_col df_disabled ">
                                            <span className="df_step" > <em> 3 </em>  Deposit Address </span>
                                            <div className="df_card  " >
                                                <div className="df_top " >
                                                    <div className="nc_body nc_qr p-0">
                                                        <div className="qr_body">
                                                            <QRCodeCanvas value={genrateDepositqr} className="img-fluid" />
                                                            <div className="qr_add">
                                                                <div>
                                                                    <span className="text-muted">Address</span>
                                                                    <p> {genrateDepositqr} </p>
                                                                </div>
                                                                <span className="px-2 cursor-pointer btn-copy" onClick={() => { copy(genrateDepositqr); alertSuccessMessage("Deposit Address copied..!!") }}> <i className="ri-file-copy-fill "></i> </span>
                                                            </div>
                                                        </div>

                                                        <div className="p_card mt-2 p-3">
                                                            <ul className="desc mb-0">
                                                                <li>
                                                                    <i className="ri-error-warning-fill me-2 fw-normal ri-xl"></i>
                                                                    Minimum deposit of {coinDetails?.min_deposit} {coinDetails?.short_name}, deposit below that cannot be recovered.
                                                                </li>
                                                                <li>
                                                                    <i className="ri-error-warning-fill me-2 fw-normal  ri-xl"></i>
                                                                    Please deposit only {coinDetails?.short_name} on this address. If you deposit any other coin, it will be lost forever.
                                                                </li>
                                                                <li>
                                                                    <i className="ri-error-warning-fill me-2 fw-normal  ri-xl"></i>
                                                                    This is {chainName} deposit address type. Transferring to an unsupported network could result in loss of deposit.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>






                                    {/* ===============================  old data =============================== */}
                                    <div className="g_start d_card mt-3 d-none  ">

                                        <div className={`step-1 ${isActive === 'step1' ? 'active' : 'd-none'}`}>
                                            <div className="nc_header">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6  col-lg-7">
                                                        <span className="nc_icon justify-content-start">
                                                            Select Coin
                                                        </span>
                                                    </div>
                                                    {coinList?.length > 0 ?
                                                        <div className="col-md-6 col-lg-5">
                                                            <div className="searchBar custom-tabs">
                                                                <i className="ri-search-2-line"></i>
                                                                <input type="search" className="custom_search w-100" placeholder="Search Crypto Coin" value={search} onChange={(e) => setsearch(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </div>
                                            <div className="nc_body scroll_y">
                                                <div className="ng_table">
                                                    <div className="container">
                                                        <div className="row  t_head_chain">
                                                            <div className="col-3 text-start">
                                                                <p>Coin Name</p>
                                                            </div>
                                                            <div className="col-3 text-center">
                                                                <p>Available Balance </p>
                                                            </div>
                                                            <div className="col-3 text-center">
                                                                <p>Locked Balance </p>
                                                            </div>
                                                            <div className="col-3 text-end">
                                                                <p>Total Balance</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="t_body_row">
                                                        {fiterPairData?.length > 0 ? (
                                                            fiterPairData?.map((item, index) => (
                                                                <span className="row align-items-center t_body_chain cursor_pointer" key={index} onClick={() => handleDepositDetails(item, 'step2')}>
                                                                    <div className="col-3 text-start">
                                                                        <div className="td_div">
                                                                            <img src={item.icon_path} width="25" className="img-fluid icon_img coinimg_max me-2 " alt=""
                                                                                onError={(e) => {
                                                                                    e.target.onerror = null;
                                                                                    e.target.src = "/assets/images/icon/nocoin.png";
                                                                                }} />
                                                                            <b>{item.short_name}</b>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3 text-center">
                                                                        <p>{item.balance}</p>
                                                                    </div>
                                                                    <div className="col-3 text-center">
                                                                        <p>{item.locked_balance}</p>
                                                                    </div>
                                                                    <div className="col-3 text-end">
                                                                        <p>{(Number(item?.balance) || 0) + (Number(item?.locked_balance) || 0)}</p>
                                                                    </div>
                                                                </span>
                                                            ))
                                                        ) : (
                                                            <div className="favouriteData">
                                                                <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Step 3 */}
                                        <div className={`step-3 ${isActive === 'step2' ? 'active' : 'd-none'}`}>
                                            <div className="nc_header">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6  col-lg-7">
                                                        <span className="nc_icon justify-content-start ">
                                                            <span className="btn-icon cursor_pointer" onClick={() => { setIsActive('step1'); handleBack() }}><i className="ri-arrow-left-s-line"></i></span>
                                                            Deposit {coinDetails?.short_name}
                                                            <img src={coinDetails.icon_path} width="25" className="img-fluid icon_img coinimg_max me-2 " alt=""
                                                                onError={(e) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = "/assets/images/icon/nocoin.png";
                                                                }} />
                                                        </span>
                                                    </div>
                                                    <div className="col-md-6 col-lg-5 text-end">
                                                        <p className="text_ifo">
                                                            <small className="text-muted d-block">Minimum deposit</small>
                                                            <small>
                                                                <b className="text-dark">More than {coinDetails?.min_deposit} {coinDetails?.short_name}
                                                                </b>
                                                            </small>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="nc_body nc_qr">
                                                <div className="form-group mb-3" >
                                                    <div className="spot_tabs mb-2 mb-md-3">
                                                        <label htmlFor="deposit address">Select Chain</label>
                                                        {coinDetails?.chain?.length > 0 ? (
                                                            <select
                                                                className="form-select form-control width-50"
                                                                value={chainName}
                                                                onChange={handleSelectChange}
                                                            >
                                                                <option disabled>
                                                                    Select a network
                                                                </option>
                                                                {coinDetails.chain.map((item, index) => (
                                                                    <option key={index} value={item} className="form-control">
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            null
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="qr_body">
                                                    <QRCodeCanvas value={genrateDepositqr} className="img-fluid" />
                                                    <div className="qr_add">
                                                        <div>
                                                            <span className="text-muted">Address</span>
                                                            <p> {genrateDepositqr} </p>
                                                        </div>
                                                        <span className="px-2 cursor-pointer btn-copy" onClick={() => { copy(genrateDepositqr); alertSuccessMessage("Deposit Address copied..!!") }}> <i className="ri-file-copy-fill "></i> </span>
                                                    </div>
                                                </div>

                                                <div className="p_card mt-2 p-3">
                                                    <ul className="desc mb-0">
                                                        <li>
                                                            <i className="ri-error-warning-line me-2"></i>
                                                            Minimum deposit of {coinDetails?.min_deposit} {coinDetails?.short_name}, deposit below that cannot be recovered.
                                                        </li>
                                                        <li>
                                                            <i className="ri-error-warning-line me-2"></i>
                                                            Please deposit only {coinDetails?.short_name} on this address. If you deposit any other coin, it will be lost forever.
                                                        </li>
                                                        <li>
                                                            <i className="ri-error-warning-line me-2"></i>
                                                            This is {chainName} deposit address type. Transferring to an unsupported network could result in loss of deposit.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>


                                        {/* Step INR */}
                                        <div className={`step-4 ${isActive === 'step4' ? 'active' : 'd-none'}`}>
                                            <div className="nc_header">
                                                <div className="row align-items-center">
                                                    <div className="col-md-12  col-lg-12">
                                                        <span className="nc_icon justify-content-start ">
                                                            <span className="btn-icon cursor_pointer" onClick={() => { setIsActive('step1'); setActiveDepostInr('step1') }}><i className="ri-arrow-left-s-line"></i></span>
                                                            INR
                                                        </span>
                                                    </div>
                                                    <div className="ng_table">
                                                        {/* step 1 */}
                                                        <div className={`t_body_row ${activeDepostInr === 'step1' ? '' : 'd-none'}`}>
                                                            <div className="up_right text-center my-3" id="myTab" role="tablist" >
                                                                <ul className="nav custom-tabs">
                                                                    <li className="" role="presentation">
                                                                        <a href="/?" className="active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                                                                            role="tab" aria-controls="home-tab-pane" aria-selected="true">Bank Account</a>
                                                                    </li>
                                                                    <li className="" role="presentation">
                                                                        <a href="/?" className="" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                                                                            role="tab" aria-controls="profile-tab-pane" aria-selected="false">UPI ID </a>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <button className="btn custom-btn mt-3 w-100 btn-block" onClick={() => setActiveDepostInr('step2')}> Deposit INR </button>
                                                        </div>

                                                        {/* step 2 */}
                                                        <div className={`t_body_row modal_form p_card mt-3 ${activeDepostInr === 'step2' ? '' : 'd-none'}`}>
                                                            <div className="row">
                                                                <div className="col-12 text-center" >
                                                                    <h6> Verify Deposit </h6>
                                                                </div>
                                                                <div className="col-12 form-group ">
                                                                    <label className="text-white mb-1" htmlFor="signId"> Amount </label>
                                                                    <input type="text" name="" id="" className="form-control" />
                                                                </div>
                                                                <div className="col-12 form-group">
                                                                    <label className="text-white mb-1" htmlFor="signId"> Enter UTR No.</label>
                                                                    <input type="text" name="" id="" className="form-control" />
                                                                </div>
                                                                <div className="col-12" >
                                                                    <button className="btn custom-btn mt-3 w-100 btn-block" > Verify Deposit   </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* =============================== */}



                                </div>
                                <div className="col-xxl-4 col-xl-4">
                                    <div className="dup_left d-block d-none d-xl-block">
                                        <div className="Markets d-flex align-items-center justify-content-between">
                                            <h6 className="mb-0">  FAQ </h6>
                                            {/* <Link to="/pay_history"> View All <i class=" ri-arrow-right-s-line"></i> </Link> */}
                                        </div>

                                        <div class="accordion faq_mini" id="accordionExample">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        What precautions should I take before depositing crypto?
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse  " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>
                                                            Double-check the wallet address before sending.
                                                            Ensure the selected network matches the network you’re sending from (e.g., don't send ERC20 to a TRC20 address).
                                                            Verify the correct coin/token is selected.
                                                            Always start with a small test transaction if unsure.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingTwo">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Can I deposit from a different blockchain network?

                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>Only deposit using the same network mentioned in your deposit instructions. For example:
                                                        </p>
                                                        <p className="mb-0" > <b>Example:</b> If your deposit address is for USDT-TRC20, do not send USDT-ERC20 or USDT-BEP20 to it.
                                                            Sending to the wrong network could result in permanent loss of funds.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        What happens if I deposit an unsupported coin or token?


                                                    </button>
                                                </h2>
                                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>Depositing coins/tokens that are not supported by Crypto India Exchange can result in irreversible loss. Always check our supported assets list before sending.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                        What is the risk of phishing or fake addresses?
                                                    </button>
                                                </h2>
                                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>Beware of phishing scams or malware that may alter wallet addresses on your device.
                                                        </p>
                                                        <p>
                                                            Always access Crypto India Exchange via official website/app.
                                                            Use two-factor authentication (2FA) for added security.

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                        Is it safe to deposit large amounts at once?

                                                    </button>
                                                </h2>
                                                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>
                                                            If you're depositing large amounts:

                                                        </p>
                                                        <p>
                                                            Start with a small test transaction first.

                                                        </p>
                                                        <p>
                                                            Split your deposit into smaller parts if needed.
                                                        </p>
                                                        <p>
                                                            Monitor the confirmation process.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                        What if I entered the wrong deposit address?

                                                    </button>
                                                </h2>
                                                <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>
                                                            Unfortunately, if you send crypto to the wrong or third-party address, Crypto India Exchange cannot reverse or recover it. Always copy and paste carefully.

                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                        Should I check transaction status on blockchain?

                                                    </button>
                                                </h2>
                                                <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>
                                                            Yes. You can always track your transaction status via blockchain explorer using your transaction hash (TxID). This helps ensure transparency and troubleshooting if delays occur.

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                        What should I do if I face any issues during deposit?

                                                    </button>
                                                </h2>
                                                <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>
                                                            Check Deposit History in your Wallet section.
                                                            Contact Customer Support with your TxID, coin name, amount, and time.
                                                            Don’t panic—our team will help guide you based on your transaction status.

                                                        </p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>



                                </div> 
                                <div className="col-xxl-11 col-xl-11 mt-lg-4 mt-3" >
                                    <div class="Markets">
                                        <h5><small>Recent Transactions</small></h5>
                                        <Link to="/transaction_history"> View All <i class=" ri-arrow-right-s-line"></i> </Link>
                                    </div>
                                    <div className="custom_list rct_list" >
                                        <div className="list_row cursor-pointer">
                                            <div class="spotName gap-1">
                                                <img className="img-fluid" alt="" width="20" height="20" src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" />
                                                <div class="symblecurrency">45.00 USDT </div> <span className="badge badge-sm bg-success-lite"> Completetd </span> 
                                            </div>
                                            <div className="rct_row" >
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>Date:</span> 25-03-01 11:11
                                                    </div>
                                                </div>
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>Network:</span>  ETH
                                                    </div>
                                                </div>
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>Address:</span> <div>2123321....231231  <i class="ri-links-fill cursor-pointer"></i>  <i class="ri-file-copy-line cursor-pointer"></i></div>
                                                    </div>
                                                </div>
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>TxID:</span> <div> 2123321....231231  <i class="ri-links-fill cursor-pointer"></i>  <i class="ri-file-copy-line cursor-pointer"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>Deposite Wallet:</span> Spot Wallet
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="list_row cursor-pointer">
                                            
                                            <div class="spotName gap-1">
                                                <img className="img-fluid" alt="" width="20" height="20" src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" />
                                                <div class="symblecurrency">0.000 USDT</div> <span className="badge badge-sm bg-warning-lite"> Pending </span> <span className="badge badge-sm bg-primary cursor-pointer"> <i class="ri-add-line"></i> Add Details </span>
                                            </div>
                                            <div class="progress w-auto">
                                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style={{ width: '45%' }} aria-valuenow="85"
                                                    aria-valuemin="0" aria-valuemax="100"> 
                                                </div>
                                            </div>
                                            <div className="rct_row" >
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>Date:</span> 25-03-01 11:11
                                                    </div>
                                                </div>
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>Network:</span>  ETH
                                                    </div>
                                                </div>
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>Address:</span>  <div> 2123321....231231
                                                            <i class="ri-links-fill cursor-pointer"></i>  <i class="ri-file-copy-line cursor-pointer"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>TxID:</span>   <div>2123321....231231 <i class="ri-links-fill cursor-pointer"></i>  <i class="ri-file-copy-line cursor-pointer"></i></div>
                                                    </div>
                                                </div>
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>Deposite Wallet:</span> Spot Wallet
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="no_data" >
                                        <img src="assets/images/no-data.svg" width="110" />
                                        <p>
                                            <small> No transactions in the last 24 hours </small>
                                        </p>
                                    </div>  */} 
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>







            {/* transctions details modal */}
            <div div className="modal-open-custom  d-none " >
                <div className="modal show d-block" >
                    <div className="modal-dialog modal-dialog-centered ">
                        <div className="modal-content  ">
                            <div className="modal-header no-border" >
                                <h5 className="modal-title" > Deposit Details</h5>
                                <button type="button" className="btn-close"  ></button>
                            </div>
                            <div className="modal-body " >
                                <div class="row justify-content-center ">
                                    <div class="col-xl-12">
                                        <form className="pp_success" >
                                            <div class="tt_data mb-0">
                                                <div class="tt_card  ">
                                                    <div class="tt_item">
                                                        <span class="text-muted"> Order No.</span>
                                                        <span class="tt_normal"> <small>2123321....231231  <i class="ri-file-copy-line cursor-pointer"></i></small>  </span>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted">  Status </span>
                                                        <span class="tt_normal text-success"> Completed  </span>
                                                    </div>
                                                    <div class="tt_item  ">
                                                        <span class="text-muted"> Date  </span>
                                                        <span class="tt_normal"> "DD MMM YYYY  <br />
                                                            HH:mm:ss</span>
                                                    </div>

                                                    <div class="tt_item">
                                                        <span class="text-muted"> Coin </span>
                                                        <div class="spotName gap-1 py-1">
                                                            <img className="img-fluid" alt="" width="20" height="20" src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" />
                                                            <div class="symblecurrency">USDT/INR</div>
                                                        </div>
                                                    </div>

                                                    <div class="tt_item">
                                                        <span class="text-muted"> Deposit amount </span>
                                                        <span class="tt_normal">0000 00</span>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted">  From/To </span>
                                                        <span class="tt_normal"> Self/Other </span>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted"> Address</span>
                                                        <span class="tt_normal"> <small>2123321....231231  <i class="ri-links-fill cursor-pointer"></i>  <i class="ri-file-copy-line cursor-pointer"></i></small>  </span>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted"> TxID </span>
                                                        <span class="tt_normal"> <small>2123321....231231  <i class="ri-links-fill cursor-pointer"></i>  <i class="ri-file-copy-line cursor-pointer"></i></small>  </span>
                                                    </div>
                                                    <div class="tt_item no-border">
                                                        <span class="text-muted"> Deposit Wallet </span>
                                                        <span class="tt_normal"> Spot Wallet </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"> </div>
            </div>

            {/* Add  details modal */}
            <div className="modal-open-custom d-none " >
                <div className="modal show d-block modal_add_details " >
                    <div className="modal-dialog modal-dialog-end modal-dialog-centered-lg modal-md">
                        <div className="modal-content  ">
                            <div className="modal-header    " >
                                <h5 className="modal-title" >  Add Deposite Details </h5>
                                <button type="button" className="btn-close"  ></button>
                            </div>
                            <div className="modal-body p-0 ">

                                <div className="ddp_in   " >
                                    <div className="dd_mid modal_form " >

                                        {/* condition 1 form */}
                                        <div class="row gx-2 gy-2 d-none ">
                                            <div className="col-12 form_group mb-2" >
                                                <label class="mb-2" for="signId"> <b>Send To?</b> </label>
                                                <div className="row" >
                                                    <div className=" col-4 col-lg-3" >
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="Sendto" id="flexSendmyself" checked />
                                                            <label class="form-check-label" for="flexSendmyself">
                                                                My Selft
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className=" col-8  col-lg-7" >
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="Sendto" id="flexSendothert" />
                                                            <label class="form-check-label" for="flexSendothert">
                                                                Other
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-12 form_group mb-1" >
                                                <label class="mb-2" for="signId"> <b> Which Wallet ? </b> </label>
                                                <div className="row" >
                                                    <div className=" col-4 col-lg-3" >
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="WhichWallet" id="flexprivatewallet" />
                                                            <label class="form-check-label" for="flexprivatewallet">
                                                                Private Wallet
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className=" col-8  col-lg-7" >
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="WhichWallet" id="otherwalletRadioDefault2" checked />
                                                            <label class="form-check-label" for="otherwalletRadioDefault2">
                                                                Exchange
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {/* if choose option exchange */}
                                            <div className="col-12 form_group mb-2 " >
                                                <label class="form-label" for="signId"> Exchange Name </label>
                                                <div class="field-otp-box">
                                                    <input type="text" name="Name" id="Name" class="form-control form-control-sm mb-1" placeholder="Enter Name" required="" />
                                                </div>
                                            </div>
                                        </div>

                                        {/* condition 2 form */}
                                        <div class="row gx-2 gy-1">
                                            <div className="col-12 form_group mb-3" >
                                                <label class="mb-2" for="signId"> <b>Send To?</b> </label>
                                                <div className="row" >
                                                    <div className=" col-4 col-lg-3" >
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="Sendto" id="flexSendmyself" />
                                                            <label class="form-check-label" for="flexSendmyself">
                                                                My Selft
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className=" col-8  col-lg-7" >
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="Sendto" id="flexSendothert" checked />
                                                            <label class="form-check-label" for="flexSendothert">
                                                                Other
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-12 form_group mb-2" >
                                                <label class="mb-2" for="signId"> <b> User Type ? </b> </label>
                                                <div className="row" >
                                                    <div className=" col-4 col-lg-3" >
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="UserType" id="IndividualUser" />
                                                            <label class="form-check-label" for="IndividualUser">
                                                                Individual User
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className=" col-8  col-lg-7" >
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" name="UserType" id="CorporateUser" />
                                                            <label class="form-check-label" for="CorporateUser">
                                                                Corporate User
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 form-group  form_group-sm  mb-0" >
                                                <label class="form-label" for="signId"> Name </label>
                                                <div class="field-otp-box">
                                                    <input type="text" name="Name" id="Name" class="form-control form-control-sm mb-1" placeholder="Enter Name" required="" />
                                                </div>
                                            </div>
                                            <div className="col-6 form_group  form_group-sm   mb-1" >
                                                <label class="form-label" for="signId"> Pin </label>
                                                <div class="field-otp-box">
                                                    <input type="text" name="Name" id="Name" class="form-control form-control-sm mb-1" placeholder="Enter Pin" required="" />
                                                </div>
                                            </div>
                                            <div className="col-6 form_group form_group-sm  mb-1" >
                                                <label class="form-label" for="signId"> City </label>
                                                <div class="field-otp-box">
                                                    <input type="text" name="Name" id="Name" class="form-control form-control-sm mb-1" placeholder="Enter City" required="" />
                                                </div>
                                            </div>
                                            <div className="col-6 form_group form_group-sm   mb-1" >
                                                <label class="form-label" for="signId"> State </label>
                                                <div class="field-otp-box">
                                                    <input type="text" name="Name" id="Name" class="form-control form-control-sm mb-1" placeholder="Enter State" required="" />
                                                </div>
                                            </div>
                                            <div className="col-6 form_group form_group-sm    mb-1" >
                                                <label class="form-label" for="signId"> Pan </label>
                                                <div class="field-otp-box">
                                                    <input type="text" name="Name" id="Name" class="form-control form-control-sm mb-1" placeholder="Enter Pan" required="" />
                                                </div>
                                            </div>
                                            <div className="col-12 form_group mb-2 " >
                                                <label class="form-label" for="signId"> Address </label>
                                                <div class="field-otp-box">
                                                    <input type="text" name="Name" id="Name" class="form-control form-control-sm mb-1" placeholder="Enter Address" required="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="  mx-auto mt-3">
                                            <button type="button" class="btn btn-dark btn-lg btn-block  w-100 "  >
                                                <span> Submit  </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </div>







        </main>

    )
}

export default DepositFunds