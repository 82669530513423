import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProfileContext } from "../../../Services/ProfileProvider";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../../Services/CustomAlertModel";
import AuthService from "../../../Services/ApiServices/AuthService";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { Range } from "react-range";
import { fetchVerifyDeposit } from "../../../Context/Utility/apiutils";
import Select from 'react-select'


const ProfileWalletSubPage = ({ walletType }) => {

    const { maskedEmail, userDetails, userKycDetails } = useContext(ProfileContext);
    const userDataDetails = userDetails?.user;
    const KycStatus = userDetails?.user?.kycVerified;
    const navigate = useNavigate();
    const location = useLocation();


    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedWalletId, setSelectedWalletId] = useState("");
    const [selectedFromWallet, setSelectedFromWallet] = useState("");
    const [selectedToWallet, setSelectedToWallet] = useState("");
    const [amount, setAmount] = useState("");
    const [activeModelAddFund, setActiveModelAddFund] = useState(false);
    const [coinCurrencyList, setCoinCurrencyList] = useState([]);
    const [wlletBalance, setWalletBalance] = useState('');
    const [walletDetails, setWalletDetails] = useState('');
    const [currencyName, setCurrencyName] = useState(userDetails?.user?.currency_prefrence || 'USDT');
    const [isActiveWithdrawalModel, setIsActiveWithdrawalModel] = useState(false);
    const [isActiveWithdrawalInr, setIsActiveWithdrawalInr] = useState(false);

    const [withdrawalLimitData, setWithdrawalLimitData] = useState("");


    const handleWalletBalance = async (currency) => {
        try {
            const result = await AuthService.walletBalance(currency, walletType);
            if (result?.success) {
                setWalletBalance(result?.data);
            } else {
                setWalletBalance("")
            }
        } catch (error) {
            setWalletBalance("")
        }
    };


    const nextPageWallet = () => {
        if (!userDetails?.permissions?.deposit) {
            alertErrorMessage('Disable Deposit..');
            return;
        } if (KycStatus == 0) {
            alertWarningMessage('Kyc Pending Submit Your Kyc..');
            return;
        } if (KycStatus == 1) {
            alertWarningMessage('Your Kyc is Under Review..');
            return;
        }
        navigate("/deposit_funds");
    };

    const nextPageWalletWithdraw = () => {
        if (!userDetails?.permissions?.withdrawal) {
            alertErrorMessage('Disable withdrawal..');
            return;
        } if (KycStatus == 0) {
            alertWarningMessage('Kyc Pending Submit Your Kyc..');
            return;
        } if (KycStatus == 1) {
            alertWarningMessage('Your Kyc is Under Review..');
            return;
        }
        setIsActiveWithdrawalModel(true)
    };

    const nextPageQicukPay = () => {
        if (KycStatus == 0) {
            alertWarningMessage('Kyc Pending Submit Your Kyc..');
            return;
        } if (KycStatus == 1) {
            alertWarningMessage('Your Kyc is Under Review..');
            return;
        }
        navigate("/quick_pay");
    };

    const handleGetCurrencyList = async () => {
        try {
            const result = await AuthService.getCurrencyList();
            if (result?.success) {
                setCoinCurrencyList(result?.data);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const handleWalletBalanceByTransfer = async (selectedType) => {
        let filters = {};
        if (selectedType) {
            filters.wallet_type = selectedType;
        }
        const queryString = new URLSearchParams(filters).toString();
        try {
            const result = await AuthService.getWalletList(queryString);
            if (result?.success) {
                setWalletDetails(result?.data?.wallets);
            } else {
                setWalletDetails("")
            }
        } catch (error) {
            setWalletDetails("")
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchVerifyDeposit();
        }, 60000);
        return () => clearInterval(intervalId);
    }, []);


    useEffect(() => {
        handleGetCurrencyList();
        handleWalletBalance(currencyName);
    }, []);


    const coinOptions = coinCurrencyList?.map((wallet) => {
        const matchingWallet = walletDetails && walletDetails?.find((w) => w.currency_id === wallet._id);
        return {
            value: wallet._id,
            label: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={wallet.icon_path}
                        alt={wallet.short_name}
                        style={{ width: 24, height: 24, marginRight: 10 }}
                    />
                    {wallet.name} ({wallet.short_name})
                    {matchingWallet && (
                        <span style={{ marginLeft: 10, fontWeight: "bold" }}>
                            - Balance: {matchingWallet.balance}
                        </span>
                    )}
                </div>
            ),
        };
    });


    const walletOptions = [
        { value: "Funding", label: "Funding Wallet" },
        { value: "SPOT", label: "Spot Wallet" },
        { value: "Futures", label: "Future Wallet" },
        { value: "Invest", label: "Invest Wallet" },
    ];

    const filteredToWalletOptions = walletOptions.filter(
        (option) => option.value !== selectedFromWallet
    );

    const filteredFromWalletOptions = walletOptions.filter(
        (option) => option.value !== selectedToWallet
    );


    const handleFundTransfer = async () => {
        if (!selectedFromWallet) {
            setErrorMessage("Please Select Transfer From Wallet")
            return;
        } if (!selectedWalletId) {
            setErrorMessage("Please Select Select Coin")
            return;
        } if (!selectedToWallet) {
            setErrorMessage("Please Select Transfer To Wallet")
            return;
        } if (!amount) {
            setErrorMessage("Please Enter Amount")
            return;
        }
        try {
            setLoading(true);
            const result = await AuthService.userFundTransfer(
                selectedFromWallet,
                selectedToWallet,
                amount,
                selectedWalletId
            );
            if (result?.success) {
                setLoading(false);
                alertSuccessMessage(result?.message);
                setActiveModelAddFund(false)
                setSelectedFromWallet("");
                setSelectedToWallet("");
                setAmount("");
                setSelectedWalletId("");
            } else {
                setLoading(false);
                setErrorMessage(result || "Fund transfer failed.");
            }
        } catch (error) {
            setLoading(false);
            setErrorMessage(error?.message || "An unexpected error occurred.");
        }
    };

    const matchingWallet = walletDetails && walletDetails.find((w) => w.currency_id === selectedWalletId);



    const minuteSeconds = 60;
    const hourSeconds = 3600;
    const daySeconds = 86400;

    const timerProps = {
        isPlaying: true,
    };

    const renderTime = (dimension, time) => {
        return (
            <div className="time-wrapper">
                <div className="time">{time}</div>
                <div>{dimension}</div>
            </div>
        );
    };
    const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
    const stratTime = Date.now() / 1000;
    const endTime = stratTime + 243248;
    const remainingTime = endTime - stratTime;




    const [values, setValues] = useState([1000]); // Default value is 1000

    // Handle Input Change
    /*     const handleInputChange = (e) => {
          let inputValue = Number(e.target.value);
          if (inputValue < 1000) inputValue = 1000;
          if (inputValue > 5000) inputValue = 5000;
          setValues([inputValue]);
        };
     */


    const handleInputChange = (e) => {
        let inputValue = e.target.value; // Get raw input
        if (inputValue === "") {
            setValues([""]); // Allow clearing input
            return;
        }

        inputValue = Number(inputValue);
        if (isNaN(inputValue)) return; // Prevent NaN errors

        if (inputValue >= 1000 && inputValue <= 5000) {
            setValues([inputValue]); // Set value if within range
        }
    };


    const handleGetWithdrawalLimit = async () => {
        try {
            const result = await AuthService.getWithdrawalLimit();
            if (result?.success) {
                setWithdrawalLimitData(result?.data);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };



    return (
        <>
            <div className="d_u_p" >
                <div className="dup_left" >
                    <div className="up" >
                        <img className="img-fluid" alt="user"
                            src={userKycDetails?.livenessdetails?.imageUrl || "/assets/images/user_thumb.png"}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/assets/images/user_thumb.png";
                            }}
                        />

                    </div>
                    <div className="ud" >
                        <h5>{userKycDetails?.pandetails?.name}</h5>
                        <span> {maskedEmail}</span>
                    </div>
                </div>
                <div className="up_right text-end" >
                    <span className="ur_data">
                        <small>User ID</small>
                        {userDataDetails?.uid || '------'}
                    </span>
                    <span className="ur_data">
                        <small>User Type  </small>
                        {userDataDetails?.user_type || '------'}
                    </span>
                </div>
            </div>

            {/* wallet */}
            <div className="upgrr_card g_start   ww_card">
                <div class="points_wrapper points_wrapper_2">
                    <i class="point"></i>
                    <i class="point"></i>
                    <i class="point"></i>
                    <i class="point"></i>
                    <i class="point"></i>
                    <i class="point"></i>
                    <i class="point"></i>
                    <i class="point"></i>
                    <i class="point"></i>
                    <i class="point"></i>
                </div>
                <div className="g_row row" >
                    <div className="col-lg-7" >
                        <div className="g_card ">
                            <h6>Estimated Total Balance</h6>
                            <div className="d-flex align-items-center">
                                <h5>{wlletBalance.balance || '00000.00'}</h5>
                                <div className="dropdown">
                                    <div className="btn-link dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                        {currencyName}
                                    </div>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink" onClick={(e) => {
                                        const newCurrency = e.target.getAttribute('value');
                                        setCurrencyName(newCurrency);
                                        handleWalletBalance(newCurrency);
                                    }}>
                                        <li><div className="dropdown-item cursor-pointer" value="USDT">USDT</div></li>
                                        <li><div className="dropdown-item cursor-pointer" value="BTC">BTC</div></li>
                                        <li><div className="dropdown-item cursor-pointer" value="BNB">BNB</div></li>
                                        <li><div className="dropdown-item cursor-pointer" value="INR">INR</div></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="avl_card" >
                                <div className="row" >
                                    <div className="col-6 col-md-4" >
                                        <span className="as_lb" >
                                            Available Balance
                                            <small> 0 </small>
                                        </span>
                                    </div>
                                    <div className="col-6 col-md-4" >
                                        <span className="as_lb" >
                                            Locked Balance
                                            <small> {wlletBalance.locked_balance || '0'}  </small>
                                        </span>
                                    </div>
                                    <div className="col-6 col-md-4" >
                                        <span className="as_lb" >
                                            Hold Balance
                                            <small>  {wlletBalance.hold_balance || '0'} </small>
                                        </span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="col-lg-5" >
                        <div className="g_right" >
                            <div className="btn_row" >
                                <button type="button" className="btn btn-warning" onClick={nextPageWallet}>Deposit</button>
                                <button type="button" className="btn btn-warning" onClick={nextPageWalletWithdraw}>Withdraw</button>
                                <button type="button" className="btn btn-warning" onClick={nextPageQicukPay}> Quick Pay </button>
                                {
                                    location?.pathname === "/wallet" &&
                                    <button type="button" className="btn btn-dark" onClick={() => setActiveModelAddFund(true)}>
                                        <i class="ri-exchange-funds-fill me-2"></i>Transfer</button>
                                }

                                <Link to="/transaction_history" className="btn btn-muted">History</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {
                activeModelAddFund && (
                    <div>
                        <div className="modal show d-block">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content pb-3">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Add Fund</h5>
                                    </div>
                                    <div className="modal-body p-3 p-lg-4">

                                        <div className="mb-2">
                                            <label className="form-label">Transfer From Wallet</label>
                                            <Select
                                                options={filteredFromWalletOptions}
                                                value={filteredFromWalletOptions.find(option => option.value === selectedFromWallet)}
                                                onChange={(selectedOption) => {
                                                    setSelectedFromWallet(selectedOption.value);
                                                    handleWalletBalanceByTransfer(selectedOption.value);
                                                    setErrorMessage("");
                                                }}
                                            />
                                        </div>


                                        <div className="mb-2">
                                            <label className="form-label">Select Coin</label>
                                            <Select
                                                options={coinOptions}
                                                value={coinOptions.find(option => option.value === selectedWalletId)}
                                                onChange={(selectedOption) => {
                                                    setSelectedWalletId(selectedOption.value);
                                                    setErrorMessage("");
                                                }}
                                            />
                                        </div>


                                        <div className="mb-2">
                                            <label className="form-label">Transfer To Wallet</label>
                                            <Select
                                                options={filteredToWalletOptions}
                                                value={filteredToWalletOptions.find(option => option.value === selectedToWallet)}
                                                onChange={(selectedOption) => {
                                                    setSelectedToWallet(selectedOption.value);
                                                    setErrorMessage("");
                                                }}
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <label className="form-label">Enter Amount</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={amount}
                                                placeholder={matchingWallet?.balance && `Max : ${matchingWallet?.balance}`}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;

                                                    if (!selectedWalletId) {
                                                        setErrorMessage("Please select a wallet first.");
                                                        return;
                                                    }

                                                    const maxBalance = matchingWallet?.balance || 0;

                                                    if (parseFloat(inputValue) > maxBalance) {
                                                        setErrorMessage(`Amount cannot be greater than ${maxBalance}`);
                                                    } else {
                                                        setAmount(inputValue);
                                                        setErrorMessage("");
                                                    }
                                                }}
                                                onWheel={(e) => e.target.blur()}
                                                onKeyDown={(e) => {
                                                    if (["+", "-", "e", "E"].includes(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>


                                        {errorMessage && (
                                            <div className="invalid-feedback">{errorMessage}</div>
                                        )}

                                        <div className="text-center  mt-4" >
                                            <button className="btn btn-success btn-block w-100 fw-semibold" type="button" onClick={handleFundTransfer} disabled={errorMessage}>
                                                {loading ? (
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                ) : (
                                                    <span>Submit</span>
                                                )}
                                            </button>

                                            {/* Cancel Button */}
                                            <button className="btn btn-secondary px-5 mt-3" type="button" onClick={() => setActiveModelAddFund(false)}>
                                                Cancel
                                            </button>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                )
            }






            {
                isActiveWithdrawalModel && (
                    <div className="modal-open-custom" >
                        <div className="modal show d-block dcp_modal " >
                            <div className="modal-dialog modal-dialog-end modal-dialog-centered-lg">
                                <div className="modal-content">
                                    <div className="modal-body p-md-4  ">
                                        <div className="dcp_options p-lg-2" >
                                            <div className="dc-col  " >
                                                <h6>I  have assets</h6>
                                                <div className="dcp_card mb-3" >
                                                    <div class="d-flex  align-items-center gap-3 " onClick={() => navigate("/withdraw_funds")}>
                                                        <img src="/assets/images/depositcrypto.svg" width="40" className="img-fluid" alt="" />
                                                        <div >
                                                            <span>  Withdraw Crypto  </span>
                                                            <small>Withdraw Crypto India Account </small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dcp_card" >
                                                    <div class="d-flex  align-items-center gap-3"
                                                        onClick={() => { setIsActiveWithdrawalInr(true); setIsActiveWithdrawalModel(false) }}>
                                                        <img src="/assets/images/buywithinr.svg" width="40" className="img-fluid" alt="" />
                                                        <div>
                                                            <span>  Withdraw INR  </span>
                                                            <small> Withdraw funds via bank transfer, UPI, cash, and more.</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="btn btn-link text-decoration-none text-muted btn-lg btn-block pb-0 w-100 mt-2"
                                                    onClick={() => setIsActiveWithdrawalModel(false)}>
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                )
            }





            {
                isActiveWithdrawalInr && (

                    /*  <div className="modal-open-custom" >
                         <div className="modal show d-block dcp_modal " >
                             <div className="modal-dialog modal-dialog-end modal-dialog-centered-lg">
                                 <div className="modal-content no-border bg-warning overflow-hidden round-30">
                                     <div className="modal-body p-2">
                                         <div className="ddp_in" >
                                             <div className="ab_slice illustration_box" ></div>
                                             <img src="/assets/images/ab_img_new.svg" className="ab_img" alt="" />
                                             <div className="dd_top" >
                                                 <div className="dd_avl" >
                                                     <h5>00.00 <sub>INR</sub></h5>
                                                     <p> Available Balance </p>
                                                 </div>
                                             </div>
                                             <div className="dd_mid" >
                                                 <div class="row gx-2 gy-2">
                                                     <div class="col-12">
                                                         <h6> Withdraw INR </h6>
                                                         <div className="form_group " >
                                                             <label class="form-label" for="signId"> Enter Amount in INR </label>
                                                             <div class="field-otp-box">
                                                                 <input type="text" name="signId" id="signId" class="form-control mb-1" required="" placeholder="1000" />
                                                             </div>
                                                         </div>
                                                         <div class="balance-libra card-success px-0 mb-5">
                                                             <div class="token-img-bg_right fw-medium"  >
                                                                 Today's Remaning Limit:  1,00,000
                                                             </div>
                                                         </div>
 
                                                         <div className="cstm_range" >
                                                             <Range
                                                                 label="00"
                                                                 step={3}
                                                                 min={1000}
                                                                 max={50000}
                                                                 draggableTrack={2}
                                                                 values={values}
                                                                 onChange={(values) => setValues(values)}
                                                                 renderTrack={({ props, children }) => {
                                                                     const trackBackgroundColor = values[0] < 2000 ? '#3333' : '#4CAF50';
                                                                     return (
                                                                         <div
                                                                             {...props}
                                                                             style={{
                                                                                 ...props.style,
                                                                                 height: '6px',
                                                                                 width: '100%',
                                                                                 backgroundColor: trackBackgroundColor,
                                                                                 borderRadius: '4px',
                                                                             }}
                                                                         >
                                                                             {children}
                                                                         </div>
                                                                     );
                                                                 }}
                                                                 renderThumb={({ props, value }) => {
                                                                     const thumbPosition = (value - 1000) / (50000 - 1000) * 100;
 
                                                                     return (
                                                                         <div
                                                                             className="trigger"
                                                                             {...props}
                                                                             key={props.key}
                                                                             style={{
                                                                                 ...props.style,
                                                                                 height: '28px',
                                                                                 width: '28px',
                                                                                 backgroundColor: 'rgb(0, 0, 0)',
                                                                                 borderRadius: '40px',
                                                                                 position: 'relative',
                                                                             }}
                                                                         >
                                                                             <div
                                                                                 className="tracker_label"
                                                                                 style={{
                                                                                     position: 'absolute',
                                                                                     bottom: '35px',
                                                                                     left: '50%',
                                                                                     transform: 'translateX(-50%)',
                                                                                     backgroundColor: 'rgb(0, 0, 0)',
                                                                                     color: 'white',
                                                                                     padding: '4px 8px',
                                                                                     borderRadius: '4px',
                                                                                     fontSize: '12px',
                                                                                     whiteSpace: 'nowrap',
                                                                                 }}
                                                                             >
                                                                                 {value}
                                                                             </div>
                                                                         </div>
                                                                     );
                                                                 }}
                                                             />
 
                                                             <div className="range_labels" >
                                                                 <span>1,000</span>
                                                                 <span>50, 000</span>
                                                             </div>
                                                             <div className="fandc_sec" >
                                                                 <a class="collapsed" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                                     You Pay ₹ 1078 including fees. <i class="ri-arrow-up-s-line"></i>
                                                                 </a>
                                                                 <div class="collapse" id="collapseExample">
                                                                     <div class="tt_data mb-0 mt-2">
                                                                         <div class="tt_item">
                                                                             <span class="text-muted">  Fees </span>
                                                                             <span class="tt_normal  "> 000.00  </span>
                                                                         </div>
                                                                         <div class="tt_item no-border">
                                                                             <span class="text-muted">  TDS </span>
                                                                             <span class="tt_normal  ">  000.00  </span>
                                                                         </div>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                     </div>
                                                     <div class="col-12 mx-auto">
                                                         <button type="button" class="btn btn-dark btn-lg btn-block py-3 w-100 mt-3"  >
                                                             <span> Withdraw Now</span>
                                                         </button>
                                                         <button className="btn btn-link text-decoration-none text-muted btn-lg btn-block pb-0 w-100 mt-2" onClick={() => setIsActiveWithdrawalInr(false)} > Cancel </button>
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                         <div className="modal-backdrop fade show"></div>
                     </div> */




                    <div className="modal-open-custom">
                        <div className="modal show d-block dcp_modal">
                            <div className="modal-dialog modal-dialog-end modal-dialog-centered-lg">
                                <div className="modal-content no-border bg-warning overflow-hidden round-30">
                                    <div className="modal-body p-2">
                                        <div className="ddp_in">
                                            <div className="ab_slice illustration_box"></div>
                                            <img src="/assets/images/ab_img_new.svg" className="ab_img" alt="" />
                                            <div className="dd_top">
                                                <div className="dd_avl">
                                                    <h5>00.00 <sub>INR</sub></h5>
                                                    <p>Available Balance</p>
                                                </div>
                                            </div>
                                            <div className="dd_mid">
                                                <div className="row gx-2 gy-2">
                                                    <div className="col-12">
                                                        <h6>Withdraw INR</h6>
                                                        <div className="form_group">
                                                            <label className="form-label" htmlFor="signId">Enter Amount in INR</label>
                                                            <div className="field-otp-box">
                                                                <input
                                                                    type="number"
                                                                    name="signId"
                                                                    id="signId"
                                                                    className="form-control mb-1"
                                                                    required
                                                                    placeholder="1000"
                                                                    min="1000"
                                                                    max="5000"
                                                                    value={values[0]}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="balance-libra card-success px-0 mb-5">
                                                            <div className="token-img-bg_right fw-medium">
                                                                Today's Remaining Limit: 1,00,000
                                                            </div>
                                                        </div>

                                                        <div className="cstm_range">
                                                            <Range
                                                                step={100}
                                                                min={1000}
                                                                max={5000}
                                                                values={values}
                                                                onChange={(newValues) => setValues(newValues)}
                                                                renderTrack={({ props, children }) => (
                                                                    <div
                                                                        {...props}
                                                                        style={{
                                                                            ...props.style,
                                                                            height: '6px',
                                                                            width: '100%',
                                                                            backgroundColor: values[0] < 2000 ? '#3333' : '#4CAF50',
                                                                            borderRadius: '4px',
                                                                        }}
                                                                    >
                                                                        {children}
                                                                    </div>
                                                                )}
                                                                renderThumb={({ props, value }) => (
                                                                    <div
                                                                        {...props}
                                                                        className="trigger"
                                                                        style={{
                                                                            ...props.style,
                                                                            height: '28px',
                                                                            width: '28px',
                                                                            backgroundColor: 'rgb(0, 0, 0)',
                                                                            borderRadius: '40px',
                                                                            position: 'relative',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="tracker_label"
                                                                            style={{
                                                                                position: 'absolute',
                                                                                bottom: '35px',
                                                                                left: '50%',
                                                                                transform: 'translateX(-50%)',
                                                                                backgroundColor: 'rgb(0, 0, 0)',
                                                                                color: 'white',
                                                                                padding: '4px 8px',
                                                                                borderRadius: '4px',
                                                                                fontSize: '12px',
                                                                                whiteSpace: 'nowrap',
                                                                            }}
                                                                        >
                                                                            {value}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            />
                                                            <div className="range_labels">
                                                                <span>1,000</span>
                                                                <span>5,000</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mx-auto">
                                                        <button type="button" className="btn btn-dark btn-lg btn-block py-3 w-100 mt-3">
                                                            <span>Withdraw Now</span>
                                                        </button>
                                                        <button className="btn btn-link text-decoration-none text-muted btn-lg btn-block pb-0 w-100 mt-2"
                                                            onClick={() => setIsActiveWithdrawalInr(false)}>
                                                            Cancel
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                        <div className="modal-backdrop fade show"></div>
                    </div >
                )
            }



















            {/* modal deposite assets */}
            <div className="modal-open-custom   d-none " >
                <div className="modal show d-block dcp_modal " >
                    <div className="modal-dialog modal-dialog-end modal-dialog-centered-lg">
                        <div className="modal-content">
                            <div className="modal-body p-md-4  ">

                                <div className="dcp_options p-lg-2" >
                                    <div className="dc-col mb-4" >
                                        <h6>I don't have crypto assets</h6>
                                        <a href="/buy_sell" className="dcp_card" >
                                            <div class="d-flex  align-items-center gap-3">
                                                <img src="/assets/images/buywithinr.svg" width="40" className="img-fluid" alt="" />
                                                <div>
                                                    <span>  Buy with INR  </span>
                                                    <small>Buy crypto easily via bank transfer, UPI, cash etc. </small>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="dc-col  " >
                                        <h6>I  have assets</h6>
                                        <div className="dcp_card mb-3" >
                                            <div class="d-flex  align-items-center gap-3 ">
                                                <img src="/assets/images/depositcrypto.svg" width="40" className="img-fluid" alt="" />
                                                <div>
                                                    <span>  Deposit Crypto  </span>
                                                    <small> Receive crypto in your Crypto India Account </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dcp_card" >
                                            <div class="d-flex  align-items-center gap-3">
                                                <img src="/assets/images/buywithinr.svg" width="40" className="img-fluid" alt="" />
                                                <div>
                                                    <span>  Deposit INR  </span>
                                                    <small> Deposit funds via bank transfer, UPI, cash, and more.</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </div>

            {/*  deposite INR modal   */}
            <div className="modal-open-custom  d-none" >
                <div className="modal show d-block dcp_modal " >
                    <div className="modal-dialog modal-dialog-end modal-dialog-centered-lg">
                        <div className="modal-content no-border bg-warning overflow-hidden round-30">
                            <div className="modal-body p-2">

                                <div className="ddp_in" >
                                    <div className="ab_slice illustration_box" ></div>
                                    <img src="/assets/images/ab_img_new.svg" className="ab_img" alt="" />
                                    <div className="dd_top" >
                                        <div className="dd_avl" >
                                            <h5>00.00 <sub>INR</sub></h5>
                                            <p> Available Balance </p>
                                        </div>
                                    </div>
                                    <div className="dd_mid" >
                                        <div class="row gx-2 gy-2">
                                            <div class="col-12">
                                                <h6> Add INR </h6>
                                                <div className="form_group " >
                                                    <label class="form-label" for="signId"> Enter Amount in INR </label>
                                                    <div class="field-otp-box">
                                                        <input type="text" name="signId" id="signId" class="form-control mb-1" required="" placeholder="1000" />
                                                    </div>
                                                </div>
                                                <div class="balance-libra card-success px-0 mb-5">
                                                    <div class="token-img-bg_right fw-medium"  >
                                                        Today's Remaning Limit:  1,00,000
                                                    </div>
                                                </div>

                                                <div className="cstm_range" >
                                                    {/* <h5> Your deposite amount will be <span className="text-success" > 5,000 INR </span> </h5> */}
                                                    <Range
                                                        label="00"
                                                        step={3}
                                                        min={1000}
                                                        max={50000}
                                                        draggableTrack={2}
                                                        values={values}
                                                        onChange={(values) => setValues(values)} // Update the values state
                                                        renderTrack={({ props, children }) => {
                                                            // Dynamically set the background color based on the current value
                                                            const trackBackgroundColor = values[0] < 2000 ? '#3333' : '#4CAF50'; // Green for values < 25000, red for values >= 25000

                                                            return (
                                                                <div
                                                                    {...props}
                                                                    style={{
                                                                        ...props.style,
                                                                        height: '6px',
                                                                        width: '100%',
                                                                        backgroundColor: trackBackgroundColor, // Use the dynamic background color
                                                                        borderRadius: '4px',
                                                                    }}
                                                                >
                                                                    {children}
                                                                </div>
                                                            );
                                                        }}
                                                        renderThumb={({ props, value }) => {
                                                            // Calculate the position of the thumb's label based on the value
                                                            const thumbPosition = (value - 1000) / (50000 - 1000) * 100; // Percentage of thumb position

                                                            return (
                                                                <div
                                                                    className="trigger"
                                                                    {...props}
                                                                    key={props.key}
                                                                    style={{
                                                                        ...props.style,
                                                                        height: '28px',
                                                                        width: '28px',
                                                                        backgroundColor: 'rgb(0, 0, 0)',
                                                                        borderRadius: '40px',
                                                                        position: 'relative', // Ensure the label can be positioned relative to the thumb
                                                                    }}
                                                                >
                                                                    {/* Label above the thumb */}
                                                                    <div
                                                                        className="tracker_label"
                                                                        style={{
                                                                            position: 'absolute',
                                                                            bottom: '35px', // Distance above the thumb
                                                                            // left: `calc(${thumbPosition}% - 50%)`, // Center the label over the thumb
                                                                            left: '50%',
                                                                            transform: 'translateX(-50%)', // Center the label horizontally over the thumb
                                                                            backgroundColor: 'rgb(0, 0, 0)',
                                                                            color: 'white',
                                                                            padding: '4px 8px',
                                                                            borderRadius: '4px',
                                                                            fontSize: '12px',
                                                                            whiteSpace: 'nowrap',
                                                                        }}
                                                                    >
                                                                        {value}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }}
                                                    />

                                                    <div className="range_labels" >
                                                        <span>1,000</span>
                                                        <span>50, 000</span>
                                                    </div>
                                                    <div className="fandc_sec" >
                                                        <a class="collapsed" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                            You Pay ₹ 1078 including fees. <i class="ri-arrow-up-s-line"></i>
                                                        </a>
                                                        <div class="collapse" id="collapseExample">
                                                            <div class="tt_data mb-0 mt-2">
                                                                <div class="tt_item">
                                                                    <span class="text-muted">  Fees </span>
                                                                    <span class="tt_normal  "> 000.00  </span>
                                                                </div>
                                                                <div class="tt_item no-border">
                                                                    <span class="text-muted">  TDS </span>
                                                                    <span class="tt_normal  ">  000.00  </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 mx-auto">
                                                <button type="button" class="btn btn-dark btn-lg btn-block py-3 w-100 mt-3"  >
                                                    <span> Deposit Now</span>
                                                </button>
                                                <button className="btn btn-link text-decoration-none text-muted btn-lg btn-block pb-0 w-100 mt-2" > Cancel </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </div>

            {/* payment confirm modal */}
            <div className="d-none " >
                <div className="modal-open-custom" >
                    <div className="modal show d-block pymt_modal" >
                        <div className="modal-dialog modal-dialog-centered modal-xl">
                            <div className="modal-content bg-warning no-border ">
                                {/* <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title" id="modal_otp_Label"> Verification </h5>
                                    <button type="button" className="btn-close" >
                                    </button>
                                </div> */}
                                <div className="modal-body pymt_body">
                                    <div className="pumt_row" >
                                        <div className="pymt_sidebar" >
                                            <div className="pymt_profile" >
                                                <div class="rc_profile">
                                                    <span class="ri-arrow-left-line ri-xl d-flex d-xl-none"></span>
                                                    <i class=" ">
                                                        <img src="assets/images/user_icon.svg" class="img-fluid" alt="" />
                                                    </i>
                                                    <div class="ud">
                                                        <h5> Unknown User</h5>
                                                        <span>useremailaddress.com</span>
                                                    </div>
                                                </div>

                                                <div className="o_s">
                                                    <div>
                                                        <span> Pay Amount </span>
                                                        <h5>₹216,117 </h5>
                                                    </div>
                                                    <i class="ri-arrow-right-s-line"></i>



                                                </div>

                                                <div className="Pay_using d-none d-xl-flex " >
                                                    <div className=" d-flex align-items-start gap-2  w-100 ">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM13.5003 8C13.8278 8.43606 14.0625 8.94584 14.175 9.5H16V11H14.175C13.8275 12.7117 12.3142 14 10.5 14H10.3107L14.0303 17.7197L12.9697 18.7803L8 13.8107V12.5H10.5C11.4797 12.5 12.3131 11.8739 12.622 11H8V9.5H12.622C12.3131 8.62611 11.4797 8 10.5 8H8V6.5H16V8H13.5003Z"></path></svg>
                                                        <div className="" >
                                                            <span>  Pay Using  </span>
                                                            <p>Bank  Ac. - ***003414</p>
                                                            <p>UPI - ***sdds@ybl</p>
                                                        </div>
                                                    </div>
                                                    <i class="ri-arrow-right-s-line"></i>
                                                </div>

                                            </div>
                                            <div className="payment_secured d-none d-xl-flex" >
                                                <img src="/assets/images/secured_by.svg" className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                        <div className="pymt_mainbbar" >
                                            <div className="modal-header header_sm d-none d-xl-flex justify-content-between">
                                                <h5 className="modal-title" id="modal_otp_Label"> Payment Options </h5>
                                                <button type="button" className="btn-close" >
                                                </button>
                                            </div>
                                            <div className="pymt_space  d-none" >
                                                <div class="d-flex align-items-start">
                                                    <div className="nav_tabs_bar" >
                                                        <h5 className="d-flex align-items-center justify-content-between" >All Payment Options
                                                            <div class="upsc_more d-xl-none">
                                                                <span class="upac_mob"> Instructions <i class="ri-question-fill"></i>
                                                                </span>
                                                                <div class="up_details upsc_inst_mob ">
                                                                    <div className="pay_instructions ">
                                                                        <span> Pay Instructions</span>
                                                                        <div className="ins_tab" >
                                                                            <p>✅ Use Registered Accounts Only – Pay only from your registered bank account or UPI ID.</p>
                                                                            <p> ❌ No Third-Party Payments – Payments from unregistered accounts will be rejected.</p>
                                                                            <p> 🔄 Refund Policy – Incorrect payments will be refunded to the source bank within 7-10 days.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </h5>
                                                        <div className="ap_ins" >
                                                            <div class="nav flex-column nav-pills " id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                                <button class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                                                    <div className="pp_space" >
                                                                        <span>UPI</span>
                                                                        <img src="/assets/images/upi_labels.svg" alt="" />
                                                                    </div>
                                                                </button>
                                                                <button class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                                                    <div className="pp_space" >
                                                                        <span>Bank Transfer  <small> IMPS, NEFT, RTGS </small> </span>
                                                                        <img src="/assets/images/upi_labels.svg" alt="" />
                                                                    </div>
                                                                </button>
                                                                <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                                                    <div className="pp_space" >
                                                                        <span>Cash  <small> Deposit to branch/CDM</small> </span>
                                                                        <img src="/assets/images/upi_labels.svg" alt="" />
                                                                    </div>
                                                                </button>
                                                            </div>
                                                            <div className="pay_instructions d-none d-xl-block">
                                                                <span> Pay Instructions</span>
                                                                <div className="ins_tab" >
                                                                    <p>✅ Use Registered Accounts Only – Pay only from your registered bank account or UPI ID.</p>
                                                                    <p> ❌ No Third-Party Payments – Payments from unregistered accounts will be rejected.</p>
                                                                    <p> 🔄 Refund Policy – Incorrect payments will be refunded to the source bank within 7-10 days.</p>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="bg-white scroll_pop fc_tab_container" >

                                                        <div class=" pay_step tab-content fc_tab_content" id="v-pills-tabContent">

                                                            {/* UPI Tab */}
                                                            <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                                <div class="fc_row">
                                                                    <div className="uttr_title d-flex align-items-center justify-content-between " >
                                                                        <h5>  <span className="step_icon"> 1 </span> Scan & Pay </h5>
                                                                        <span className="utr_timer" > <i class="ri-timer-line"></i>  6:15 </span>
                                                                    </div>
                                                                    <div className="upi_details" >
                                                                        <div className="upi_qr" >
                                                                            <img src="/assets/images/qr_code.png" className="img-fluid" alt="" />
                                                                        </div>
                                                                        <div className="upi_info" >
                                                                            <small>Scan the QR & Click below to pay <br /> using any UPI App</small>
                                                                            <div class="d-flex align-items-center gap-3">
                                                                                <a title="Pay via GooglePay" href="upi://pay?pa=jangidajay7021@ybl&pn=AjayKumarJangid&cu=INR&am=100&tn=Note+Here" >
                                                                                    <img class="h-auto w-6" src="assets/images/googlepay.svg" alt="Google Pay icon" />
                                                                                </a>
                                                                                <a title="Pay via PhonePay" href="upi://pay?pa=jangidajay7021@ybl&pn=AjayKumarJangid&cu=INR&am=100&tn=Note+Here" >
                                                                                    <img class="h-auto w-6" src="assets/images/phonepe.svg" alt="PhonePe icon" />
                                                                                </a>
                                                                                <a title="Pay via Bhem UPI" href="upi://pay?pa=jangidajay7021@ybl&pn=AjayKumarJangid&cu=INR&am=100&tn=Note+Here" >
                                                                                    <img class="h-auto w-6" src="assets/images/bhim.svg" alt="Bhim icon" />
                                                                                </a>
                                                                                <a title="Pay via Bhem UPI" href="upi://pay?pa=jangidajay7021@ybl&pn=AjayKumarJangid&cu=INR&am=100&tn=Note+Here" >
                                                                                    <img class="h-auto w-6" src="assets/images/paytm.svg" alt="Bhim icon" />
                                                                                </a>
                                                                            </div>
                                                                            {/* <a href="upi://pay?pa=jangidajay7021@ybl&pn=AjayKumarJangid&cu=INR&am=100&tn=Note+Here"  className="upiid" > sachinfuloria7@oksbi <i class="ri-file-copy-line text-muted"></i> </a> */}

                                                                            <small className="upi_name" >UPI Name - <b className="text-success" >Crypto India <i class="ri-verified-badge-fill"></i> </b>  </small>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>


                                                            {/* bank tab */}
                                                            <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                                                <div class="fc_row">
                                                                    <div className="uttr_title d-flex align-items-center justify-content-between " >
                                                                        <h5>  <span className="step_icon"> 1 </span> Add Beneficiary and pay</h5>
                                                                        <span className="utr_timer" > <i class="ri-timer-line"></i>  6:15 </span>
                                                                    </div>
                                                                    <div class="accordion list_accertab " id="accordionExample">
                                                                        <div class="accordion-item mb-3">
                                                                            <h2 class="accordion-header" id="headingOne">
                                                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                                    <b class="pr_icon pr_icon_sm fw-normal ">
                                                                                        <i class="ri-bank-fill"></i>
                                                                                    </b>  State Bank of India
                                                                                </button>
                                                                            </h2>
                                                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                                <div className="accordian-body" >
                                                                                    <div class="row g-3">
                                                                                        <div class="col-lg-6">
                                                                                            <div class="label-group">
                                                                                                <label> Account Type </label>
                                                                                                <p> Savings </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-6">
                                                                                            <div class="label-group">
                                                                                                <label> Beneficiary Name </label>
                                                                                                <p className="text-success" > Samyak Rastogi  <i class="ri-file-copy-line text-muted"></i>  </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-6">
                                                                                            <div class="label-group">
                                                                                                <label> Account Number </label>
                                                                                                <p> 38671185644 <i class="ri-file-copy-line text-muted"></i> </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-6">
                                                                                            <div class="label-group">
                                                                                                <label> IFSC Code </label>
                                                                                                <p> SBIN0013052  <i class="ri-file-copy-line text-muted"></i>  <br />
                                                                                                    <small class="fw-normal"> Branch: Malviya nagar, Jaipur </small>
                                                                                                </p>
                                                                                                :                                                 </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="accordion-item">
                                                                            <h2 class="accordion-header" id="headingThree">
                                                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                                    <b class="pr_icon pr_icon_sm fw-normal ">
                                                                                        <i class="ri-bank-fill"></i>
                                                                                    </b>  State Bank of India  </button>
                                                                            </h2>
                                                                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                                                <div className="accordian-body" >
                                                                                    <div class="row g-3">
                                                                                        <div class="col-lg-6">
                                                                                            <div class="label-group">
                                                                                                <label> Account Type </label>
                                                                                                <p> Savings </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-6">
                                                                                            <div class="label-group">
                                                                                                <label> Beneficiary Name </label>
                                                                                                <p className="text-success" > Samyak Rastogi  <i class="ri-file-copy-line text-muted"></i>  </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-6">
                                                                                            <div class="label-group">
                                                                                                <label> Account Number </label>
                                                                                                <p> 38671185644 <i class="ri-file-copy-line text-muted"></i> </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-6">
                                                                                            <div class="label-group">
                                                                                                <label> IFSC Code </label>
                                                                                                <p> SBIN0013052  <i class="ri-file-copy-line text-muted"></i>  <br />
                                                                                                    <small class="fw-normal"> Branch: Malviya nagar, Jaipur </small>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            {/* RTGS Tab */}
                                                            <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                                                <div class="fc_row">
                                                                    <div className="uttr_title d-flex align-items-center justify-content-between " >
                                                                        <h5>  <span className="step_icon"> 1 </span>Cash Deposit to Branch or ATM </h5>
                                                                        <span className="utr_timer" > <i class="ri-timer-line"></i>  6:15 </span>
                                                                    </div>
                                                                    <div class="fc_details mb-3">
                                                                        <div class="d-flex gap-3 align-items-canter justify-content-between mb-3">
                                                                            <h5 class="mb-0 d-flex gap-3 align-items-center  ">
                                                                                <b class="pr_icon pr_icon_sm fw-normal me-0">
                                                                                    <i class="ri-bank-fill"></i>
                                                                                </b> State Bank of India
                                                                            </h5>
                                                                        </div>

                                                                        <div class="row g-3">
                                                                            <div class="col-lg-6">
                                                                                <div class="label-group">
                                                                                    <label> Account Type </label>
                                                                                    <p> Savings </p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-6">
                                                                                <div class="label-group">
                                                                                    <label> Beneficiary Name </label>
                                                                                    <p className="text-success" > Samyak Rastogi  <i class="ri-file-copy-line text-muted"></i>  </p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-6">
                                                                                <div class="label-group">
                                                                                    <label> Account Number </label>
                                                                                    <p> 38671185644 <i class="ri-file-copy-line text-muted"></i> </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>

                                                        {/* step 2 Submit payment details */}
                                                        <div className=" utr_tab pay_step " >
                                                            <div className="uttr_title" >
                                                                <h5> <span className="step_icon step_icon_two">2 </span> Submit Payment Details</h5>
                                                                <p>  Add Transaction details after complete your payment. </p>
                                                            </div>
                                                            <div class=" p-0 modal_form">
                                                                <div className="row" >
                                                                    {/* on banktransfer condition */}
                                                                    {/* <div class="form-group form-group-sm col-md-6">
                                                                        <label class="mb-1"> Paymen Mode </label>
                                                                        <select className="form-control form-control-sm form-select" >
                                                                            <option>IMPS</option>
                                                                            <option>NEFT</option>
                                                                            <option>RTGS</option>
                                                                            <option>Intra Bank Transfer</option>
                                                                        </select>
                                                                    </div> */}

                                                                    {/* on cash mode condition */}
                                                                    <div class="form-group form-group-sm col-md-6">
                                                                        <label class="mb-1"> Paymen Mode </label>
                                                                        <select className="form-control form-control-sm form-select" >
                                                                            <option> Deposit To Branch </option>
                                                                            <option>Deposit To ATM</option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="form-group form-group-sm col-md-6">
                                                                        <label class="mb-1"> TXn./Ref. Number </label>
                                                                        <input type="text" class="form-control form-control-sm" placeholder="Enter Number" />
                                                                    </div>

                                                                    <div class="form-group form-group-sm  col-md-6">
                                                                        <label class="mb-1"> Description(Optional) </label>
                                                                        <input type="text" class="form-control form-control-sm" placeholder="Enter Description" />
                                                                    </div>

                                                                    <div class="form-group form-group-sm col-md-6">
                                                                        <label class="mb-1"> Attachment </label>
                                                                        <input type="file" class="form-control form-control-sm" />
                                                                    </div>

                                                                </div>
                                                                <div class="form-group form-group-sm mb-0 mt-3">
                                                                    <button class="form-control btn-warning form-control-sm" type="button" >
                                                                        <span> Submit </span>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="pymt_rem " >
                                                <div className="rem_col" >
                                                    <div className="rem_top" >
                                                        <CountdownCircleTimer
                                                            size={100}
                                                            strokeWidth={8}
                                                            {...timerProps}
                                                            colors={['#11c973', '#F0B90B', '#dc3545', '#ff0018']}
                                                            colorsTime={[7, 5, 2, 0]}
                                                            duration={hourSeconds}
                                                            initialRemainingTime={hourSeconds % hourSeconds}
                                                            onComplete={(totalElapsedTime) => ({
                                                                shouldRepeat: remainingTime - totalElapsedTime > hourSeconds
                                                            })}
                                                        >
                                                            {({ elapsedTime, color }) => (
                                                                <span style={{ color }}>
                                                                    {renderTime("Min", getTimeMinutes(hourSeconds - elapsedTime))}
                                                                </span>
                                                            )}
                                                        </CountdownCircleTimer>

                                                        <h5> Wating for admin Reponse </h5>
                                                    </div>
                                                    <hr />
                                                    <div className="rem_mid" >
                                                        <p > Order Status </p>
                                                        <ul className="order_progress" >
                                                            <li className="st_complete" > <em></em> <span> Order Placed </span> </li>
                                                            <li className="st_complete" > <em></em> <span>Payment Initiated</span> </li>
                                                            <li className="st_complete" > <em></em> <span> UTR Submission </span>   </li>
                                                            <li className="st_pending" > <em></em> <span> Payment Confirmation <small> It uselly take ~ 15 mins to reflect to your wallet </small> </span>   </li>
                                                        </ul>
                                                    </div>
                                                    <div className="rem_end" >
                                                        <div class="tt_data rem_card">
                                                            <div class="tt_item ">
                                                                <span class="text-muted">   Amount: </span>
                                                                <span class="tt_normal">00.00</span>
                                                            </div>
                                                            <div class="tt_item  ">
                                                                <span class="text-muted"> Initiated Date </span>
                                                                <span class="tt_normal">00.00</span>
                                                            </div>
                                                            <div class="tt_item  ">
                                                                <span class="text-muted">  UPI ID   </span>
                                                                <span class="tt_normal">00.00</span>
                                                            </div>
                                                            <div class="tt_item no-border ">
                                                                <span class="text-muted"> Reference ID </span>
                                                                <span class="tt_normal"> 54854554..<i class="ri-file-copy-line"></i> </span>
                                                            </div>
                                                        </div>
                                                        <a href="#" className="btn btn-md btn-warning btn-block mt-3" > Go to Home </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="illustration_box" >   </div>
                                    <img src="/assets/images/illustration_box.svg" className="img-fluid pop_img " alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div>
            </div>
        </>
    )
}

export default ProfileWalletSubPage