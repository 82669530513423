import AuthService from '../../Services/ApiServices/AuthService';
import { alertErrorMessage } from '../../Services/CustomAlertModel';

export const fetchWalletList = async (setCoinList, setSelectedPairs) => {
  try {
    const result = await AuthService.getWalletList();
    if (result?.success) {
      setCoinList(result?.data);
      setSelectedPairs(result?.data[0])
    } else {
      alertErrorMessage(result);
    }
  } catch (error) {
    alertErrorMessage(error);
  }
};


export const fetchCoinDetails = async (setCoinDetails, currencyId, setSelectedChain, setSelectedChainFees) => {
  try {
    const result = await AuthService.getCoinDetails(currencyId);
    if (result?.success) {
      setCoinDetails(result?.data);
      setSelectedChain(result?.data?.chain[0])
      setSelectedChainFees(result?.data?.quick_buy_sell)
    } else {
      alertErrorMessage(result);
    }
  } catch (error) {
    alertErrorMessage(error);
  }
};



export const fetchVerifyDeposit = async () => {
  await AuthService.verifyDeposit();
};

