import React, { useContext, useEffect, useState } from "react";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import ProfileWalletSubPage from "../../../CoustamComponent/ProfileWalletSubPage";
import { fetchWalletList, fetchVerifyDeposit } from "../../../../Context/Utility/apiutils";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { ProfileContext } from "../../../../Services/ProfileProvider";
import { alertErrorMessage, alertWarningMessage } from "../../../../Services/CustomAlertModel";


const WalletPage = () => {

    const [coinList, setCoinList] = useState([]);
    const [hideAssets, setHideAssets] = useState(false);
    const [activeModel, setActiveModel] = useState(false);
    const [coinDetails, setCoinDetails] = useState([]);
    const navigate = useNavigate();
    const { userDetails } = useContext(ProfileContext);
    const KycStatus = userDetails?.user?.kycVerified;
    // const KycStatus = userDetails?.user?.kycVerified;
    
    useEffect(() => {
        fetchWalletList(setCoinList);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchVerifyDeposit();
        }, 60000);
        return () => clearInterval(intervalId);
    }, []);


    const handleCheckboxChange = () => {
        setHideAssets(!hideAssets);
    };

    const filteredCoinList = hideAssets
        ? coinList.filter(item => item.balance > 0)
        : coinList;

    const handleCoinDetails = (data) => {
        setCoinDetails(data);
        setActiveModel(true)
    };



    const nextPageWallet = (coinDetails) => {
        if (!userDetails?.permissions?.deposit) {
            alertErrorMessage('Disable Deposit..');
            return;
        } if (KycStatus == 0) {
            alertWarningMessage('Kyc Pending Submit Your Kyc..');
            return;
        }if (KycStatus == 1) {
            alertWarningMessage('Your Kyc is Under Review..');
            return;
        }
        navigate("/deposit_funds", { state: coinDetails });
    };

    const nextPageWalletWithdraw = (coinDetails) => {
        if (!userDetails?.permissions?.withdrawal) {
            alertErrorMessage('Disable withdrawal..');
            return;
        }if (KycStatus == 0) {
            alertWarningMessage('Kyc Pending Submit Your Kyc..');
            return;
        }if (KycStatus == 1) {
            alertWarningMessage('Your Kyc is Under Review..');
            return;
        }
        navigate("/withdraw_funds", { state: coinDetails });
    };


    return (

        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper">
                    <section className="d_page">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xxl-10">
                                    <ProfileWalletSubPage />
                                    <div className="g_start m_card">
                                        <div className="Markets">
                                            <h5>  My Assets </h5>
                                            <div className="form-check">
                                                <input className="form-check-input cursor-pointer" type="checkbox" id="flexCheckDefault" onChange={handleCheckboxChange} />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    Hide Assets <i className=" ri-arrow-left-s-line"></i> 1 USD
                                                </label>
                                            </div>
                                        </div>
                                        <div className="m_table">
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    {coinList?.length > 0 ? (
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Coin Name</th>
                                                                <th scope="col" className="text-end">Available Balance</th>
                                                                <th scope="col" className="text-end">Locked Balance</th>
                                                                <th scope="col" className="text-end">Total Balance</th>
                                                            </tr>
                                                        </thead>
                                                    ) : null
                                                    }
                                                    <tbody>
                                                        {filteredCoinList?.length > 0 ? (
                                                            filteredCoinList?.map((item, index) => (
                                                                <tr key={index} onClick={() => handleCoinDetails(item)}>
                                                                    <td>
                                                                        <div className="c_view">
                                                                            <img src={item.icon_path} className="img-fluid" alt=""
                                                                                onError={(e) => {
                                                                                    e.target.onerror = null;
                                                                                    e.target.src = "/assets/images/icon/nocoin.png";
                                                                                }} />
                                                                            <span>{item.short_name}<br /> <small>{item.currency}</small> </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <div className="c_view justify-content-end">
                                                                            <span>{item.balance?.toFixed(5)}<br /> </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <span> {item.locked_balance?.toFixed(5)} </span>
                                                                    </td>

                                                                    <td className="text-end">
                                                                        <span> {(item.balance + item.locked_balance)?.toFixed(2)} </span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6">
                                                                    <div className="favouriteData">
                                                                        <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            {
                activeModel &&
                <div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-md ">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title  " id="modal_otp_Label"> {coinDetails?.currency} </h5>
                                    <button type="button" className="btn-close" onClick={() => setActiveModel(false)}></button>
                                </div>
                                <div className="modal-body p-md-4 ">
                                    <div className="tt_data ">
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Created At: </span>
                                            <span className="tt_normal">{moment(coinDetails?.createdAt).format('DD-MM-YYYY HH:mm:ss')}</span>
                                        </div>


                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Coin Name:</span>
                                            <span className="tt_normal">{coinDetails?.short_name}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted">Status: </span>
                                            <span className="tt_normal">{coinDetails?.status}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Locked Balance: </span>
                                            <span className="tt_normal">{coinDetails?.locked_balance?.toFixed(2)}</span>
                                        </div>
                                        <div className="tt_item no-border pt-2">
                                            <span className="tt_normal"> Available balance: </span>
                                            <span className="tt_normal">{coinDetails?.balance?.toFixed(2)} {coinDetails?.short_name}</span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="col-lg-12 mt-3" >
                                        <div className="btn_row" >
                                        <button type="button" className="btn btn-sm btn-dark" onClick={() => nextPageWallet(coinDetails)}>Deposit</button>
                                        <button type="button" className="btn btn-sm btn-dark" onClick={() => nextPageWalletWithdraw(coinDetails)}>Withdraw</button>
                                            <Link to="/coming_soon" className="btn btn-sm btn-dark">Transfer</Link>
                                            <Link to="/transaction_history" className="btn btn-sm btn-dark">History</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div>
            }
        </main>

    )
}

export default WalletPage