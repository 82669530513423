import React from "react";
const PayLimitModel = ({ isOpenModel, setIsOpenModel, paylimitDetails }) => {

    return (

        isOpenModel &&
        <div div className="modal-open-custom" >
            <div className="modal show d-block" >
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" >  Pay Limit</h5>
                            <button type="button" className="btn-close" onClick={() => setIsOpenModel(false)} ></button>
                        </div>
                        <div className="modal-body py-md-3 p-md-4">
                            <div class="tt_data ">
                                <div class="   ">
                                    <div class="tt_item no-border no-border ">
                                        <span class="tt_normal"> Daily Limit </span>
                                    </div>
                                    <div class="tt_item">
                                        <span class="text-muted"> INR Limit </span>
                                        <span class="tt_normal">₹ {paylimitDetails?.dailyInrLimit?.total?.toFixed(0) || "------"}</span>
                                    </div>
                                    <div class="tt_item">
                                        <span class="text-muted"> Remanning INR Limit </span>
                                        <span class="tt_normal"> ₹ {paylimitDetails?.dailyInrLimit?.remaining?.toFixed(0) || 0} </span>
                                    </div>
                                  

                                    <div class="tt_item">
                                        <span class="text-muted"> Consumed Daily INR Limit</span>
                                        <span class="tt_normal">₹{(paylimitDetails?.dailyInrLimit?.total - paylimitDetails?.dailyInrLimit?.remaining)?.toFixed(0)} </span>
                                    </div>

                                    <div class="tt_item">
                                        <span class="text-muted"> Crypto Limit </span>
                                        <span class="tt_normal">  $ {paylimitDetails?.dailyUsdLimit?.total?.toFixed(0) || "------"} </span>
                                    </div>


                                    <div class="tt_item">
                                        <span class="text-muted"> Remanning Crypto Limit</span>
                                        <span class="tt_normal"> $ {paylimitDetails?.dailyUsdLimit?.remaining?.toFixed(0) || 0} </span>
                                    </div>

                                    <div class="tt_item">
                                        <span class="text-muted"> Consumed Daily Crypto Limit</span>
                                        <span class="tt_normal">$ {(paylimitDetails?.dailyUsdLimit?.total - paylimitDetails?.dailyUsdLimit?.remaining)?.toFixed(0)} </span>
                                    </div>


                                    <div class="tt_item ">
                                        <span class="text-muted"> Total No of Orders Limit </span>
                                        <span class="tt_normal">  {paylimitDetails?.totalOrders?.total?.toFixed(0) || "--------"} </span>
                                    </div>
                                    <div class="tt_item no-border">
                                        <span class="text-muted"> Remanning Total No of Orders Limit </span>
                                        <span class="tt_normal"> {paylimitDetails?.totalOrders?.remaining?.toFixed(0) || 0} </span>
                                    </div>
                                    <div class="tt_item mt-2 no-border">
                                        <span class="tt_normal"> Monthly Free Limit </span>
                                    </div>
                                    <div class="tt_item">
                                        <span class="text-muted"> INR Limit </span>
                                        <span class="tt_normal">₹ {paylimitDetails?.freeInrLimit?.total?.toFixed(0) || "------"} </span>
                                    </div>
                                    <div class="tt_item">
                                        <span class="text-muted">Remaining INR Limit</span>

                                        <span class="tt_normal"> ₹ {paylimitDetails?.freeInrLimit?.remaining < 0 ? 0 : paylimitDetails?.freeInrLimit?.remaining}
                                        </span>
                                    </div>

                                    <div class="tt_item">
                                        <span class="text-muted"> Consumed INR</span>
                                        <span class="tt_normal">₹ {String(paylimitDetails?.freeInrLimit?.total - paylimitDetails?.freeInrLimit?.remaining)} </span>
                                    </div>

                                    <div class="tt_item">
                                        <span class="text-muted"> Crypto Limit </span>
                                        <span class="tt_normal"> $ {paylimitDetails?.freeUsdLimit?.total?.toFixed(0) || "------"} </span>
                                    </div>
                                    <div class="tt_item no-border">
                                        <span class="text-muted"> Remanning Crypto Limit</span>
                                        <span class="tt_normal"> $ {paylimitDetails?.freeUsdLimit?.remaining < 0 ? 0 : (paylimitDetails?.freeUsdLimit?.remaining)?.toFixed(0)} </span>
                                    </div>

                                    <div class="tt_item">
                                        <span class="text-muted"> Consumed Crypto Limit</span>
                                        <span class="tt_normal">$ {String(paylimitDetails?.freeUsdLimit?.total - paylimitDetails?.freeUsdLimit?.remaining)} </span>
                                    </div>

                                    <div class="tt_item mt-2 no-border">
                                        <span class="tt_normal">  Fee After Free Limit  </span>
                                    </div>
                                    <div class="tt_item">
                                        <span class="text-muted"> Crypto (Equivalent to 1 USD per Transaction) </span>
                                        <span class="tt_normal">$ {paylimitDetails?.cryptoFee?.total?.toFixed(0) || "------"} </span>
                                    </div>
                                    <div class="tt_item">
                                        <span class="text-muted"> INR (Flat 1 INR per Transaction)</span>
                                        <span class="tt_normal">₹ {paylimitDetails?.inrFee?.total?.toFixed(0) || "------"} </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"> </div>
        </div >
    )
}

export default PayLimitModel