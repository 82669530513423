import React, { useEffect, createContext, useState, useRef } from 'react';
import { alertErrorMessage } from '../CustomAlertModel';
import AuthService from '../ApiServices/AuthService';

export const ProfileContext = createContext();

export function ProfileProvider(props) {

  const [loginDetails, setLoginDetails] = useState({});
  const [userDetails, setUserDetails] = useState([]);
  const token = localStorage.getItem("token");
  const [tradeTheme, setTradeTheme] = useState(true);

  useEffect(() => {
    if (token) {
      handleUserProfile();
    }
  }, [token]);

  const handleUserProfile = async () => {
    const result = await AuthService.getDetails();
    if (result?.success) {
      setUserDetails(result?.data);
    } else {
      alertErrorMessage(result?.message);
    }
  };

  function maskEmailOrPhone(input, type) {
    if (!input) return '';
    if (type === 'email' && input.includes('@')) {
      const [username, domain] = input.split('@');
      const maskedUsername = username.slice(0, Math.max(username.length - 2, 0)).padEnd(username.length, '*');
      return `${maskedUsername}@${domain}`;
    }
    else {
      return input;
    }
  }


  const functCheckRef = useRef(true);

  useEffect(() => {
      if (functCheckRef.current) {
          const body = document.body; 

          // let theme = localStorage.getItem('theme-color'); 

          const activeTheme = document.documentElement.getAttribute('data-theme');
  
          if (activeTheme === 'light') {
              body.classList.add('light');
              body.classList.remove('dark');
              document.documentElement.setAttribute('data-theme', 'light');
          } else {
              localStorage.setItem('theme-color', 'dark');
              body.classList.add('dark');
              body.classList.remove('light');
              document.documentElement.setAttribute('data-theme', 'dark');
          }  
          functCheckRef.current = false;
      }
  }, []);
  


  const maskedEmail = maskEmailOrPhone(userDetails?.user?.email, 'email');

  return (
    <ProfileContext.Provider value={{ setLoginDetails, loginDetails, userDetails, handleUserProfile, maskedEmail,tradeTheme, setTradeTheme }}>
      {props.children}
    </ProfileContext.Provider>
  );
}
