import React, { useContext, useEffect, useState } from "react";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import ProfileWalletSubPage from "../../../CoustamComponent/ProfileWalletSubPage";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { ProfileContext } from "../../../../Services/ProfileProvider";
import { alertErrorMessage, alertWarningMessage } from "../../../../Services/CustomAlertModel";
import AuthService from "../../../../Services/ApiServices/AuthService";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';


const WalletPage = () => {

    const [coinList, setCoinList] = useState([]);
    const [hideAssets, setHideAssets] = useState(false);
    const [activeModel, setActiveModel] = useState(false);
    const [coinDetails, setCoinDetails] = useState([]);
    const navigate = useNavigate();
    const { userDetails } = useContext(ProfileContext);
    const KycStatus = userDetails?.user?.kycVerified;
    const [visibleRows, setVisibleRows] = useState({});
    const [coinCurrencyList, setCoinCurrencyList] = useState([]);


    const handleCheckboxChange = () => {
        setHideAssets(!hideAssets);
    };

    const filteredCoinList = hideAssets
        ? coinCurrencyList.filter(item => item.balance > 0)
        : coinCurrencyList;

    const handleCoinDetails = (data) => {
        setCoinDetails(data);
        setActiveModel(true)
    };



    const nextPageWallet = (coinDetails) => {
        if (!userDetails?.permissions?.deposit) {
            alertErrorMessage('Disable Deposit..');
            return;
        } if (KycStatus == 0) {
            alertWarningMessage('Kyc Pending Submit Your Kyc..');
            return;
        } if (KycStatus == 1) {
            alertWarningMessage('Your Kyc is Under Review..');
            return;
        }
        navigate("/deposit_funds", { state: coinDetails });
    };

    const nextPageWalletWithdraw = (coinDetails) => {
        if (!userDetails?.permissions?.withdrawal) {
            alertErrorMessage('Disable withdrawal..');
            return;
        } if (KycStatus == 0) {
            alertWarningMessage('Kyc Pending Submit Your Kyc..');
            return;
        } if (KycStatus == 1) {
            alertWarningMessage('Your Kyc is Under Review..');
            return;
        }
        navigate("/withdraw_funds", { state: coinDetails });
    };


    const toggleRowVisibility = (index, item) => {
        setVisibleRows((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
        fetchWalletListDetails(item?._id)
    };


    const fetchWalletListDetails = async (currencyId) => {
        let filters = {};

        if (currencyId) {
            filters.currency_id = currencyId;
        }
        const queryString = new URLSearchParams(filters).toString();
        const result = await AuthService.getWalletList(queryString);
        if (result?.success) {
            setCoinList(result?.data?.wallets);
        }
    };


    const handleGetCurrencyList = async () => {
        try {
            const result = await AuthService.getCurrencyList();
            if (result?.success) {
                setCoinCurrencyList(result?.data);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    useEffect(() => {
        handleGetCurrencyList();
    }, []);

    return (

        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper">
                    <section className="d_page">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xxl-10">
                                    <ProfileWalletSubPage walletType={'Funding'} />
                                    <div className="ot_assets ot_wall" >
                                        <Swiper
                                            slidesPerView="auto"
                                            navigation
                                            className="wallets_wrapper"
                                            modules={[Navigation]}
                                            spaceBetween={15}
                                        >
                                            <SwiperSlide>
                                                <div className="as-card" >
                                                    <div className="as_title" >
                                                        <span className="as_icon" >
                                                            <img src="https://www.shutterstock.com/image-vector/3d-save-money-icon-salary-260nw-2443274803.jpg" className="img-fluid" alt="" />
                                                        </span>
                                                        <div >
                                                            <h5>Funding Wallet</h5>
                                                            <p>Total: 0000.00 </p>
                                                        </div>
                                                    </div>
                                                    <div className="row" >
                                                        <div className="col-4" >
                                                            <span className="as_lb" >
                                                                Available
                                                                <small> 00.00000 </small>
                                                            </span>
                                                        </div>
                                                        <div className="col-4" >
                                                            <span className="as_lb" >
                                                                Locked
                                                                <small> 00.00000 </small>
                                                            </span>
                                                        </div>
                                                        <div className="col-4" >
                                                            <span className="as_lb" >
                                                                Hold
                                                                <small> 00.00000 </small>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="as-card" >
                                                    <div className="as_title" >
                                                        <span className="as_icon" >
                                                            <img src="https://t4.ftcdn.net/jpg/08/17/66/53/360_F_817665396_WNFuMQfuyKheROArsdOAI2wREWq9qQBr.jpg" className="img-fluid" alt="" />
                                                        </span>
                                                        <div >
                                                            <h5>Spot Wallet</h5>
                                                            <p>Total: 0000.00 </p>
                                                        </div>
                                                    </div>
                                                    <div className="row" >
                                                        <div className="col-4" >
                                                            <span className="as_lb" >
                                                                Available
                                                                <small> 00.00000 </small>
                                                            </span>
                                                        </div>

                                                        <div className="col-4" >
                                                            <span className="as_lb" >
                                                                Locked
                                                                <small> 00.00000 </small>
                                                            </span>
                                                        </div>
                                                        <div className="col-4" >
                                                            <span className="as_lb" >
                                                                Hold
                                                                <small> 00.00000 </small>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="as-card" >
                                                    <div className="as_title" >
                                                        <span className="as_icon" >
                                                            <img src="https://img.freepik.com/premium-photo/vibrant-3d-wallet-icon-with-textured-blue-leather-finish-touch-sparkle_1057-196448.jpg" className="img-fluid" alt="" />
                                                        </span>
                                                        <div >
                                                            <h5>Futures Wallet</h5>
                                                            <p>Total: 0000.00 </p>
                                                        </div>
                                                    </div>
                                                    <div className="row" >

                                                        <div className="col-4" >
                                                            <span className="as_lb" >
                                                                Available
                                                                <small> 00.00000 </small>
                                                            </span>
                                                        </div>

                                                        <div className="col-4" >
                                                            <span className="as_lb" >
                                                                Locked
                                                                <small> 00.00000 </small>
                                                            </span>
                                                        </div>
                                                        <div className="col-4" >
                                                            <span className="as_lb" >
                                                                Hold
                                                                <small> 00.00000 </small>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="as-card" >
                                                    <div className="as_title" >
                                                        <span className="as_icon" >
                                                            <img src="https://img.freepik.com/premium-photo/vibrant-3d-wallet-icon-with-textured-blue-leather-finish-touch-sparkle_1057-196448.jpg" className="img-fluid" alt="" />
                                                        </span>
                                                        <div >
                                                            <h5> Invest Wallet</h5>
                                                            <p>Total: 0000.00 </p>
                                                        </div>
                                                    </div>
                                                    <div className="row" >

                                                        <div className="col-4" >
                                                            <span className="as_lb" >
                                                                Available
                                                                <small> 00.00000 </small>
                                                            </span>
                                                        </div>

                                                        <div className="col-4" >
                                                            <span className="as_lb" >
                                                                Locked
                                                                <small> 00.00000 </small>
                                                            </span>
                                                        </div>
                                                        <div className="col-4" >
                                                            <span className="as_lb" >
                                                                Hold
                                                                <small> 00.00000 </small>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <div className="g_start m_card">
                                        <div className="Markets">
                                            <h5>  My Assets </h5>
                                            <div className="form-check">
                                                <input className="form-check-input cursor-pointer" type="checkbox" id="flexCheckDefault" onChange={handleCheckboxChange} />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    Hide Assets <i className=" ri-arrow-left-s-line"></i> 1 USD
                                                </label>
                                            </div>
                                        </div>
                                        <div className="m_table">
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Coin Name</th>
                                                            <th scope="col" className="text-end">Available Balance</th>
                                                            <th scope="col" className="text-end">Locked Balance</th>
                                                            <th scope="col" className="text-end">Hold Balance</th>
                                                            <th scope="col" className="text-end">Total Balance</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {coinCurrencyList?.length > 0 ? (
                                                            coinCurrencyList?.map((item, index) => (
                                                                <React.Fragment key={index}>
                                                                    <tr
                                                                        className={`${visibleRows[index] ? "active" : ""}`}
                                                                        onClick={() => toggleRowVisibility(index, item)}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        <td>
                                                                            <div className="c_view">
                                                                                <img
                                                                                    src={item.icon_path || "/assets/images/icon/nocoin.png"}
                                                                                    className="img-fluid"
                                                                                    onError={(e) => {
                                                                                        e.target.onerror = null;
                                                                                        e.target.src = "/assets/images/icon/nocoin.png";
                                                                                    }}
                                                                                    alt={item.short_name}
                                                                                />
                                                                                <span>
                                                                                    {/* {item.currency} */}
                                                                                    {item.currency || "----"}
                                                                                    <br /> <small>{item.short_name}</small>
                                                                                </span>
                                                                            </div>
                                                                        </td>

                                                                        <td className="text-end">
                                                                            <span>{item.balance || "00000"}</span>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <span>{item.locked_balance || "00000"}</span>
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <span>{item.hold_balance || "00000"}</span>
                                                                        </td>

                                                                        <td className="text-end">
                                                                            <div className="c_view justify-content-end">
                                                                                <span>

                                                                                    {(Number(item?.balance) || 0) + (Number(item?.locked_balance) || 0) + (Number(item?.hold_balance) || 0)}
                                                                                    <br />
                                                                                </span>
                                                                                <em className="em_icon">
                                                                                    <i className={`ri-arrow-${visibleRows[index] ? "up" : "down"}-s-line`}></i>
                                                                                </em>
                                                                            </div>
                                                                        </td>

                                                                    </tr>

                                                                    {/* Wallet rows */}
                                                                    {visibleRows[index] && (
                                                                        <>
                                                                            <tr className="wall_row wall_row_a">
                                                                                <td>Funding Wallet</td>
                                                                                <td className="text-end">{item.fundingWallet || "000.000"}</td>
                                                                                <td className="text-end">{item.fundingAvailable || "000.000"}</td>
                                                                                <td className="text-end">{item.fundingLocked || "000.000"}</td>
                                                                                <td className="text-end">{item.fundingHold || "000.000"}</td>
                                                                            </tr>
                                                                            <tr className="wall_row wall_row_b">
                                                                                <td>Spot Wallet</td>
                                                                                <td className="text-end">{item.spotWallet || "000.000"}</td>
                                                                                <td className="text-end">{item.spotAvailable || "000.000"}</td>
                                                                                <td className="text-end">{item.spotLocked || "000.000"}</td>
                                                                                <td className="text-end">{item.spotHold || "000.000"}</td>
                                                                            </tr>
                                                                            <tr className="wall_row wall_row_c">
                                                                                <td>Futures Wallet</td>
                                                                                <td className="text-end">{item.futureWallet || "000.000"}</td>
                                                                                <td className="text-end">{item.futureAvailable || "000.000"}</td>
                                                                                <td className="text-end">{item.futureLocked || "000.000"}</td>
                                                                                <td className="text-end">{item.futureHold || "000.000"}</td>
                                                                            </tr>
                                                                            <tr className="wall_row wall_row_d">
                                                                                <td> Invest Wallet</td>
                                                                                <td className="text-end">{item.futureWallet || "000.000"}</td>
                                                                                <td className="text-end">{item.futureAvailable || "000.000"}</td>
                                                                                <td className="text-end">{item.futureLocked || "000.000"}</td>
                                                                                <td className="text-end">{item.futureHold || "000.000"}</td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                </React.Fragment>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6">
                                                                    <div className="favouriteData">
                                                                        <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>

                                                {/* <table className="table table-hover">
                                                    {coinList?.length > 0 ? (
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Coin Name</th>
                                                                <th scope="col" className="text-end">Available Balance</th>
                                                                <th scope="col" className="text-end">Locked Balance</th>
                                                                <th scope="col" className="text-end">Total Balance</th>
                                                            </tr>
                                                        </thead>
                                                    ) : null
                                                    }
                                                    <tbody>
                                                        {filteredCoinList?.length > 0 ? (
                                                            filteredCoinList?.map((item, index) => (
                                                                <tr key={index} onClick={() => handleCoinDetails(item)}>
                                                                    <td>
                                                                        <div className="c_view">
                                                                            <img src={item.icon_path} className="img-fluid" alt=""
                                                                                onError={(e) => {
                                                                                    e.target.onerror = null;
                                                                                    e.target.src = "/assets/images/icon/nocoin.png";
                                                                                }} />
                                                                            <span>{item.short_name}<br /> <small>{item.currency}</small> </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <div className="c_view justify-content-end">
                                                                            <span>{item.balance}<br /> </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <span> {item.locked_balance} </span>
                                                                    </td>

                                                                    <td className="text-end">
                                                                        <span> {(item.balance + item.locked_balance)} </span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6">
                                                                    <div className="favouriteData">
                                                                        <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            {
                activeModel &&
                <div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-md ">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title  " id="modal_otp_Label"> {coinDetails?.currency} </h5>
                                    <button type="button" className="btn-close" onClick={() => setActiveModel(false)}></button>
                                </div>
                                <div className="modal-body p-md-4 ">
                                    <div className="tt_data ">
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Created At: </span>
                                            <span className="tt_normal">{moment(coinDetails?.createdAt).format('DD-MM-YYYY HH:mm:ss')}</span>
                                        </div>


                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Coin Name:</span>
                                            <span className="tt_normal">{coinDetails?.short_name}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted">Status: </span>
                                            <span className="tt_normal">{coinDetails?.status}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Locked Balance: </span>
                                            <span className="tt_normal">{coinDetails?.locked_balance}</span>
                                        </div>
                                        <div className="tt_item no-border pt-2">
                                            <span className="tt_normal"> Available balance: </span>
                                            <span className="tt_normal">{coinDetails?.balance} {coinDetails?.short_name}</span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="col-lg-12 mt-3" >
                                        <div className="btn_row" >
                                            <button type="button" className="btn btn-sm btn-dark" onClick={() => nextPageWallet(coinDetails)}>Deposit</button>
                                            <button type="button" className="btn btn-sm btn-dark" onClick={() => nextPageWalletWithdraw(coinDetails)}>Withdraw</button>
                                            <Link to="/coming_soon" className="btn btn-sm btn-dark">Transfer</Link>
                                            <Link to="/transaction_history" className="btn btn-sm btn-dark">History</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div>
            }
        </main>

    )
}

export default WalletPage