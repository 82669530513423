
import html2pdf from 'html2pdf.js';

export const genratePdfLoa = async () => {
    
  
    

    try {

        const htmlContent =
            `
              <style type="text/css">
    @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
  
    body {
      margin: 0;
      padding: 0;
      background: #ffffff;
    }
  
    div,
    p,
    a,
    li,
    td {
      -webkit-text-size-adjust: none;
    }
  
    .ReadMsgBody {
      width: 100%;
      background-color: #ffffff;
    }
  
    .ExternalClass {
      width: 100%;
      background-color: #ffffff;
    }
  
    body {
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      margin: 0;
      padding: 0;
      -webkit-font-smoothing: antialiased;
    }
  
    html {
      width: 100%;
    }
  
    p {
      padding: 0 !important;
      margin-top: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important;
    }
  
    .visibleMobile {
      display: none;
    }
  
    .hiddenMobile {
      display: block;
    }
    .table-bordered >* {
      border-width: 1px 1px;
      border-color: black;
    }
    .table-bordered  >* {
        padding: .5rem .5rem; 
    }
  </style>
  
  
  </head><body> 
  
  <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" className="fullTable" bgcolor="#ffffff">
    <tbody>
      <tr> 
        <td>
          <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" className="fullTable" bgcolor="#ffffff">
            <tbody>
              <tr>
              </tr><tr className="hiddenMobile">
                <td height="30"></td>
              </tr>
              <tr className="visibleMobile">
                <td height="20"></td>
              </tr>
              <tr>
                <td>
                  <table   width="720" cellpadding="2"  align="center"  >
                    <tbody>  
                      <tr>
                        <td  colspan="2"  style="font-size: 22px; font-weight: 600; color: #000; font-family: 'Open Sans', sans-serif; line-height: 20px; vertical-align: middle; text-align: center;">
                          Company Letterhead
                        </td>
                      </tr> 
                      <td height="15" colspan="2"></td>
                      </tr> 
                        <tr>
                          <td height="1" colspan="2" style="border-bottom:1px solid #a8a8a8"></td>
                        </tr>
                        <td height="15" colspan="2"></td>
                      </tr> 


                      
                      <tr>
                        <td height="40" colspan="2"  ></td>
                      </tr>

                       
                      <tr>
                        <td  colspan="2"   style=" text-decoration: underline; font-size: 18px; font-weight: 600; color: #000; font-family: 'Open Sans', sans-serif; line-height: 20px; vertical-align: middle; text-align: center;">
                          BOARD RESOLUTION
                        </td>
                      </tr>
                      <tr>
                        <td  colspan="2"  style="font-size: 13px; font-weight: 600; color: #000; font-family: 'Open Sans', sans-serif; line-height: 20px; vertical-align: middle; text-align: center;">
                          FOR APPOINTMENT OF AUTHORIZED SIGNATORY
                        </td>
                      </tr>   

                      <tr>
                        <td height="20" colspan="2"></td>
                      </tr> 

                      <tr>
                        <td  height="40" style="  font-size: 15px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                          Date: ………………...
                        </td>
                        <td  height="40"> </td>
                      </tr>
                      <tr>
                        <td colspan="2"    style="  font-size: 15; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; line-height: 25px;" className="article">
                          CERTIFIED TRUE COPY OF THE RESOLUTION PASSED IN THE MEETING OF THE BOARD
                          OF DIRECTORS/ PARTNERS OF <b>[COMPANY NAME]</b> HELD ON <b>[DATE]</b> AT <b>[TIME]</b>, AT
                          <b>[ADDRESS]</b>. 
                        </td> 
                      </tr>
                      <tr>
                        <td   colspan="2" height="40"    style="  font-size: 13px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                         Resolved that:
                        </td> 
                      </tr>
                      <tr>
                        <td   colspan="2"  height="40"   style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                            <ol  style="line-height: 25px; margin: 0;"  >
                              <li >
                                  The Board of Directors/Partners approves the opening of an account and authorizes the
                                company to avail all necessary services offered by CRYPTO INDIA (INCRYP
                                BLOCKCHAIN PRIVATE LIMITED) for the smooth functioning of its operations. 
                              </li>
                              <br> 
                              <li>
                                The following person are hereby authorized to:

                                <ul>
                                  <li>
                                    Create, operate and maintain accounts in the name of the company with CRYPTO INDIA. 
                                  </li>
                                  <li>
                                    Sign, execute and deliver all necessary documents, agreements, and forms required by
                                    CRYPTO INDIA for availing its services, including but not limited to account opening,
                                    crypto wallet agreements, and other relevant contracts.
                                  </li>
                                  <li>
                                    Deposit and withdraw funds, give instructions for the transfer of funds and perform any
                                      other actions required for the operation of the company's/firm’s account or services
                                      with CRYPTO INDIA.
                                  </li>
                                </ul>
                                  <br> 
                                  <table className="table table-bordered" style="border: 0;" border="1" width="720" cellpadding="5" align="center">
                                    <thead>
                                      <tr style="font-size: 13px;" > 
                                        <th>NAME OF AUTHORIZED
                                          PERSON</th>
                                        <th>PAN
                                          NUMBER</th>
                                        <th>MOBILE
                                          NUMBER &
                                          EMAIL ID</th>
                                        <th>SPECIMEN
                                          SIGNATURE
                                          </th> 
                                      </tr>
                                    </thead>
                                    <tbody>   
                                      <tr> 
                                        <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                          
                                        </td> 
                                        <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                          
                                        </td> 
                                        <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                          
                                        </td> 
                                        <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                          
                                        </td> 
                                      </tr>   
                  
                                      
                                    </tbody>
                                  </table> 
                                  <br>
                              </li>

                              <li >
                                The Authorized Person(s) are hereby empowered to act individually as may be required in the
                              performance of their duties, including signing checks, electronic fund transfers, executing
                              contracts, and taking necessary actions related to the account or services with CRYPTO INDIA
                            </li>
                            <br>
                            <li >
                              A certified true copy of this resolution be forwarded to CRYPTO INDIA and any statutory or
                              regulatory authorities as required.
                            </li>
                            <br>
                            <li >
                              The resolution shall remain in force until notice in writing to CRYPTO INDIA by the Company.

                              <br> 
                              <br>
                              <br>
                              <br>
                              <br>
                              <br>
                              <br>
                              <br>
                              <table className="table table-bordered" style="border: 0;" border="1" width="720" cellpadding="5" align="center">  
                                <tbody> 
                               
                                  <tr>
                                    <td height="80" width="33.3333%" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: bottom; " className="article">
                                      Name
                                    </td> 
                                    <td height="80" width="33.3333%" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: bottom; " className="article">
                                      Name
                                    </td> 
                                    <td height="80" width="33.3333%" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: bottom; " className="article">
                                      Name
                                    </td>  
                                  </tr>  
                                  <tr>
                                    <td height="80" width="33.3333%" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: bottom; " className="article">
                                      Name
                                    </td> 
                                    <td height="80" width="33.3333%" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: bottom; " className="article">
                                      Name
                                    </td> 
                                    <td height="80" width="33.3333%" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: bottom; " className="article">
                                      Name
                                    </td>  
                                  </tr>   
                                </tbody> 
                              </table>    
                            </li>
                            </ol>
                        </td> 
                      </tr>   
                      <tr>
                        <td   colspan="2"   style=" text-align: center; font-size: 12px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                          Signature and Stamp Inside Boxes   
                        </td> 
                      </tr>  
                      <tr><td height="40" colspan="2" ></td></tr>
                      <tr>
                        <td colspan="2" >
                          <br> 
                          <table className="table table-bordered" style="border: 0;" border="1" width="100%" cellpadding="5" align="center">
                            <thead>
                              <tr>
                                <th>Date </th> 
                                <th> Name </th>
                                <th>Designation  </th>
                                <th>Mobile No. </th>
                                <th>Email ID </th>
                              </tr>
                            </thead>
                            <tbody>   
                              <tr>
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                              </tr>   
                              <tr>
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                              </tr>   
                              <tr>
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                              </tr>   
                              <tr>
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                                <td height="40" style="  font-size: 14px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                                  
                                </td> 
                              </tr>  
          
                              
                            </tbody>
                          </table> 
                          <br>
                        </td>
                      </tr> 
                      <tr>
                        <td colspan="2" height="40" style="  font-size: 15px; font-weight:500;  color: #000000; font-family: 'Open Sans', sans-serif;  vertical-align: middle; " className="article">
                         <small> CERTIFIED TRUE COPY</small> <br> For <b>CRYPTO INDIA</b>
                        </td> 
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              
              <tr>
                <td height="20"></td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table> 


</body></html>


           `
        const element = document.createElement("div");
        element.innerHTML = htmlContent;
        await html2pdf()
            .from(element)
            .save(`LOA.pdf`);
    } catch (error) {
        console.error('Error generating PDF:', error);
    }
};
