import React, { useContext, useState, useRef } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../../Services/ApiServices/AuthService";
import Select from "react-select";
import { ProfileContext } from "../../../../Services/ProfileProvider";
import { QRCodeCanvas } from "qrcode.react";
const ReciveCrypto = () => {

    const [isActiveModel, setIsActiveModel] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState({});
    const [walletBalance, setWalletBalance] = useState({});
    const [currencyList, setCurrencryList] = useState([]);
    const [currency, setCurrency] = useState('');
    const { userKycDetails, userDetails } = useContext(ProfileContext);
    const qrRef = useRef(null);



    const [formData, setFormData] = useState({
        currency: "",
        amount: "",
        remark: "",
    });

    const handleInput = (event) => {
        const { name, value } = event.target;

        let updatedValue = value;

        if (name === "amount") {
            const regex = /^\d*\.?\d{0,8}$/;
            if (!regex.test(value) && value !== "") {
                return;
            }
        }
        setFormData((prevState) => ({
            ...prevState,
            [name]: updatedValue,
        }));
    };


    const handleCurrencyList = async () => {
        const result = await AuthService.getCurrencyList();
        if (result?.success) {
            setCurrencryList(result?.data);
            const balances = {};
            await Promise.all(
                result.data.map(async (item) => {
                    const balanceResult = await AuthService.walletBalance(item.short_name, "Funding");
                    if (balanceResult?.success) {
                        balances[item.short_name] = balanceResult.data.balance;
                    }
                })
            );
            setWalletBalance(balances);
        }
    };

    const optionsCurrency = currencyList?.map(item => ({
        label: item.short_name,
        value: item.short_name,
        image: item.icon_path,
        id: item._id,
    }));

    const handleCurrencyChange = (selectedOption) => {
        setCurrency(selectedOption);
        const selectedselectedCurrency = walletBalance[selectedOption.label];
        setSelectedCurrency(
            {
                currencyName: selectedOption.label,
                balance: selectedselectedCurrency || "0",
                image: selectedOption?.image,
            }
        );
    };

    /*     const generateQRData = () => {
                return JSON.stringify({
                    currency: selectedCurrency?.currencyName,
                    amount: formData.amount,
                    remark: formData.remark,
                    userName: userKycDetails?.pandetails?.name,
                    uid: userDetails?.user?.uid,
                });        
        }; */
    /* 
        const generateQRData = () => {
            const baseUrl = window.location.origin; // Dynamically get the current origin
        
            const params = new URLSearchParams({
                currency: selectedCurrency?.currencyName,
                amount: formData.amount,
                remark: formData.remark,
                userName: userKycDetails?.pandetails?.name,
                uid: userDetails?.user?.uid,
            });
        
            return `${baseUrl}/send_quick_pay?${params.toString()}`;
        }; */


    const generateQRData = () => {
        const jsonData = JSON.stringify({
            currency: selectedCurrency?.currencyName,
            amount: formData.amount,
            remark: formData.remark,
            userName: userKycDetails?.pandetails?.name,
            uid: userDetails?.user?.uid,
        });

        return `${window.location.origin}/send_quick_pay?data=${encodeURIComponent(jsonData)}`;
    };




    const downloadQR = () => {
        if (qrRef.current) {
            setTimeout(() => {
                const canvas = qrRef.current.querySelector("canvas");
                if (canvas) {
                    const imageUrl = canvas.toDataURL("image/png");
                    const link = document.createElement("a");
                    link.href = imageUrl;
                    link.download = "QRCode.png";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }, 1000);
        }
    };

    return (
        <main className=" qp_wrapper qp_wrapper_send" >
            <div className="page_wrapper d-block" >
                <div className="qp_body" >
                    <div className="container" >
                        <div className="qp_sub_head" >
                            <div className="row align-items-center" >
                                <div className="col-lg-6" >
                                    <div className="sub_title" >
                                        <h3>
                                            <Link to="/quick_pay" className="current-color" >
                                                <small className="cursor-pointer" ><i class="ri-arrow-left-long-line me-2"></i></small>
                                            </Link>
                                            Receive
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-lg-6" >
                                    <div className="cl_action" >
                                        <div className="sub_info" >
                                            <Link to="/pay_history" className="text_link current-color">Transaction History</Link>
                                            <Link to="/send_quick_pay" className="btn btn-muted">Send</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sub_body" >
                            <div class="row justify-content-center">
                                <div class="col-xl-8 multistep_form ">
                                    <fieldset class="current  ">
                                        <div class="row justify-content-center ">
                                            <div class="col-xl-6">
                                                <div className="form-card" >
                                                    <div class="st_tab">
                                                        <div class="rc_profile">
                                                            <i class=" ">
                                                                <img src="assets/images/user_icon.svg" class="img-fluid" alt="" />
                                                            </i>
                                                            <div class="ud">
                                                                <h5>{userKycDetails?.pandetails?.name}</h5>
                                                                <span>{userDetails?.user?.uid}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="qr_body qr_recive" ref={qrRef}>
                                                        <div className="" >
                                                            <p>Scan this QR to send</p>
                                                        </div>
                                                        <QRCodeCanvas
                                                            value={generateQRData()}
                                                            size={176}
                                                            className="img-fluid qr_img"
                                                        />
                                                    </div>
                                                    {
                                                        selectedCurrency?.balance &&
                                                        <div className="c_view  c_view_xl" >
                                                            <div class="d-flex align-items-center">
                                                                <img src={selectedCurrency?.image} alt="" />
                                                                <span>{selectedCurrency?.currencyName} </span>
                                                            </div>
                                                            <h5 className="mb-0" >{formData?.amount}</h5>
                                                        </div>
                                                    }
                                                    <div className="qr_link" >
                                                        <a href="javascript:void(0)" className="btn btn-link p-0" onClick={() => { setIsActiveModel(true); handleCurrencyList() }}> Set  Amount </a>
                                                        <a href="javascript:void(0)" className="btn btn-link p-0" onClick={downloadQR}> Save QR Code </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isActiveModel &&
                <div div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                                <div className="modal-header" >
                                    <h5 className="modal-title" >Select Currency</h5>
                                    <button type="button" className="btn-close" onClick={() => setIsActiveModel(false)} ></button>
                                </div>
                                <div className="modal-body p-md-4">
                                    <div className="row align-items-center" >
                                        <div className="col-lg-12 mb-3" >
                                            <label className="form-label" > Currency (optional) </label>
                                            <Select
                                                options={Array.isArray(optionsCurrency) ? optionsCurrency : []}
                                                value={currency}
                                                required
                                                onChange={handleCurrencyChange}
                                                getOptionLabel={(option) => (
                                                    <div className="cc_row d-flex align-items-center  justify-content-between cursor-pointer"  >
                                                        <span className="d-flex align-items-center me-2" >
                                                            <img
                                                                src={option.image || "/assets/img/icon/nocoin.png"}
                                                                alt={option.label}
                                                                className="ms_select"
                                                                onError={(e) => { e.target.src = "/assets/img/icon/nocoin.png"; }}
                                                                style={{ width: "20px", height: "20px", MaxWidth: "20px", marginRight: "8px" }}
                                                            />
                                                            {option.label}
                                                        </span>
                                                        <small className="d-flex align-items-center me-2 cursor-pointer">
                                                            Aval Bal: {walletBalance[option.label] || "0"}
                                                        </small>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className="col-lg-12 mb-3" >
                                            <label className="form-label d-flex align-items-center justify-content-between"> Amount (optional)    </label>
                                            <input type="number" className="form-control" name="amount" placeholder="Enter Amount" value={formData.amount} onChange={handleInput} required

                                                onWheel={(e) => e.target.blur()}
                                                onKeyDown={(e) => {
                                                    if (["+", "-", "e", "E"].includes(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-12 mb-4" >
                                            <label className="form-label d-flex align-items-center justify-content-between"> Note (optional)    </label>
                                            <input type="text" className="form-control" placeholder="Type a remark" name="remark" value={formData.remark} onChange={handleInput} />
                                        </div>
                                        <div className="col-lg-12  " >
                                            <button className="form-control btn-warning" onClick={() => setIsActiveModel(false)}>
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div >
            }
        </main>
    )
}

export default ReciveCrypto