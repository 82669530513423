import React, { useContext, useEffect, useRef, useState } from "react";
import { alertErrorMessage, alertSuccessMessage } from "../../../Services/CustomAlertModel";
import Select from 'react-select'
import AuthService from "../../../Services/ApiServices/AuthService";
import { Link, useNavigate } from "react-router-dom";
import { SocketContext } from "../../../Context/SocketContext";
import moment from "moment";
import BuysellSidebar from "../../CoustamComponent/BuysellSidebar";
import { ProfileContext } from "../../../Services/ProfileProvider";
import SpinLoader from "../../CoustamComponent/SpinLoader";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { QRCodeCanvas } from "qrcode.react";
import Webcam from "react-webcam";
import CountdownTimer from "../../../Services/CountdownTimer";
import CountdownTimerOrder from "../../../Services/CountdownTimerOrder";
import copy from "copy-to-clipboard";


const BuySell = () => {

    const { userDetails } = useContext(ProfileContext);
    const { socket } = useContext(SocketContext);
    const [ordersTab, setOrdersTab] = useState('Pending');
    const [transactionList, setTransactionList] = useState([]);
    const [currencyName, setCurrencyName] = useState("");
    const [currencyPrice, setCurrencyPrice] = useState();
    const [currencyImage, setCurrencyImage] = useState('');
    const [payAmount, setPayAmount] = useState("");
    const [getAmount, setGetAmount] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [isActiveAddMoney, setIsActiveAddMoney] = useState(false);
    const [showAddMoneyError, setShowAddMoneyError] = useState("");
    const [isActiveSell, setIsActiveSell] = useState(false);
    const [buysellDetails, setBuysellDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [walletBalance, setWalletBalance] = useState('');
    const [coinDetails, setCoinDetails] = useState("");
    const [checkButton, setCheckButton] = useState(false);
    const [coinList, setCoinList] = useState();
    const [walletBalanceSellSide, setWalletBalanceSellSide] = useState('');
    const [socketData, setSocketData] = useState([]);
    const [minimumPrice, setMinimumPrice] = useState(0);
    const [maximumPrice, setMaximumPrice] = useState(0);
    const [transactionDetail, setTransactionDetail] = useState("");
    const [isOpenChatModel, setIsOpenChatModel] = useState(false);
    const [isOpenOrderdetailsModal, setIsOpenOrderdetailsModal] = useState(false);
    const [responseResult, setResponseResult] = useState("");
    const [showSelfieModel, setShowSelfieModel] = useState(false);
    const [isCameraAllowed, setIsCameraAllowed] = useState(false);
    const [error, setError] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [fileUrl, setFileUrl] = useState("");
    const [paymentOptions, setPaymentOptions] = useState('');
    const [activePaymentMode, setActivePaymentMode] = useState("");
    const [activeUpiIndex, setActiveUpiIndex] = useState(0);
    const [activeBankIndex, setActiveBankIndex] = useState(0);
    const [activeCashIndex, setActiveCashIndex] = useState(0);
    const [errors, setErrors] = useState({});
    const [image, setImage] = useState(null);
    const [imageStatus, setImageStatus] = useState(false);
    const [showTimerModel, setShowTimerModel] = useState(false);
    const [isActiveCopy, setIsActiveCopy] = useState(null);
    const webcamRef = useRef(null);
    const navigate = useNavigate();
    const Authtoken = localStorage.getItem("token");
    const KycStatus = userDetails?.user?.kycVerified;
    const limit = 6;
    const skip = 0;


    const userBankDetail = userDetails?.bank_details?.find(item => item?.default);
    const userUpiDetail = userDetails?.upi_details?.find(item => item?.default);
    const [qbsLimitsDetails, setQbsLimitsDetails] = useState('');


    useEffect(() => {
        if (socket) {
            const payload = { message: 'market' };
            socket.emit('message', payload);

            const handleMessage = (data) => setSocketData(data);
            socket.on('message', handleMessage);

            return () => {
                socket.off('message', handleMessage);
            };
        }
    }, [socket]);

    useEffect(() => {
        if (paymentOptions) {
            if (paymentOptions?.upi) {
                setActivePaymentMode("UPI");
            } else if (paymentOptions?.bank) {
                setActivePaymentMode("Bank");
            } else if (paymentOptions?.cash) {
                setActivePaymentMode("Cash");
            }
        }
    }, [paymentOptions]);

    const handleCoinList = async (bulkTrader) => {
        try {
            const result = await AuthService.coinListDetails(bulkTrader);
            if (!result?.success) return alertErrorMessage(result);
            setCoinList(result.data);
            const isBuy = 'BUY';
            const data = isBuy ? result.data.sell_data[0] : result.data.buy_data[0];
            setCurrencyName(data?.base_currency);
            setCurrencyPrice(data?.price);
            setCurrencyImage(data?.base_currency_image);
            setCoinDetails(data);
            setMaximumPrice(data?.quote_max || 0);
            setMinimumPrice(data?.quote_min || 0);
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const handleSeletedTab = (side) => {
        const selectedData = side === 'BUY' ? coinList?.sell_data?.[0] || {} : coinList?.buy_data?.[0] || {};
        setCurrencyName(selectedData.base_currency || "");
        setCurrencyPrice(selectedData.price || 0);
        setCurrencyImage(selectedData.base_currency_image || "");
        setCoinDetails(selectedData);
        setMaximumPrice(selectedData.quote_max || 0);
        setMinimumPrice(selectedData.quote_min || 0);
        if (side === 'SELL') {
            handleWalletBalanceData(selectedData.base_currency);
        } else {
            if (Authtoken) {
                handleWalletBalance('INR');
            }
        }
        setPayAmount("");
        setGetAmount("");
        setError("");
    };

    const handleChange = (selectedOption) => {
        if (selectedOption) {
            setCurrencyName(selectedOption.label);
            setCurrencyPrice(selectedOption.price);
            setCurrencyImage(selectedOption.image);
            setCoinDetails(selectedOption?.items);
            handleWalletBalanceData(selectedOption.label);
            handleWalletBalanceData(selectedOption?.items?.base_currency)
            setMaximumPrice(selectedOption?.items?.quote_max || 0);
            setMinimumPrice(selectedOption?.items?.quote_min || 0);
            setPayAmount("");
            setGetAmount("");
        }
    };

    const handleWalletBalanceData = async (currency) => {
        if (Authtoken) {
            try {
                const result = await AuthService.walletBalance(currency, "Funding");
                if (result?.success) {
                    setWalletBalanceSellSide(result?.data);
                } else {
                    alertErrorMessage(result);
                    setWalletBalanceSellSide("");
                }
            } catch (error) {
                alertErrorMessage(error);
            }
        }
    };

    const handlePayAmountChange = (value) => {
        const formattedValue = value.replace(/[^0-9]/g, "").slice(0, 8);
        setPayAmount(formattedValue);
        const numericValue = parseFloat(formattedValue);
        if (formattedValue) {
            const calculatedAmount = numericValue / currencyPrice;
            const truncatedAmount = Math.floor(calculatedAmount * 100) / 100;
            setGetAmount(truncatedAmount);
        } else {
            setGetAmount("");
        }
        if (numericValue < minimumPrice) {
            setError(`Amount must be greater than ₹${minimumPrice}`);
            setGetAmount("");
        } else if (numericValue > maximumPrice) {
            setError(`You can only buy up to ₹${maximumPrice}`);
            setGetAmount("");
        } else {
            setError("");
        }
    };

    const handleGetAmountChange = (value) => {
        const formattedValue = value.replace(/[^0-9.]/g, "").slice(0, 8);
        setGetAmount(formattedValue);
        const numericValue = parseFloat(formattedValue);
        if (numericValue) {
            const calculatedPayAmount = numericValue * currencyPrice;
            const truncatedPayAmount = Math.floor(calculatedPayAmount * 100) / 100;
            setPayAmount(truncatedPayAmount.toFixed(2));
        } else {
            setPayAmount("");
        }
        if (numericValue * currencyPrice < minimumPrice) {
            setError(`Amount must be greater than ₹${minimumPrice}`);
            setPayAmount("");
        } else if (numericValue * currencyPrice > maximumPrice) {
            setError(`You can only buy up to ₹${maximumPrice}`);
            setPayAmount("");
        } else {
            setError("");
        }
    };

    const handlePayAmountChangeSell = (value) => {
        const formattedValue = value.replace(/[^0-9]/g, "").slice(0, 8);
        setPayAmount(formattedValue);
        const numericValue = parseFloat(formattedValue);
        if (formattedValue) {
            const calculatedAmount = numericValue * currencyPrice;
            const truncatedAmount = Math.floor(calculatedAmount * 100) / 100;
            setGetAmount(truncatedAmount);
        } else {
            setGetAmount("");
        }
        if (numericValue * currencyPrice < minimumPrice) {
            setError(`Amount must be greater than ₹${minimumPrice}`);
            setGetAmount("");
        } else if (numericValue * currencyPrice > maximumPrice) {
            setError(`You can only buy up to ₹${maximumPrice}`);
            setGetAmount("");
        } else {
            setError("");
        }
    };

    const handleGetAmountChangeSell = (value) => {
        const formattedValue = value.replace(/[^0-9.]/g, "").slice(0, 8);
        setGetAmount(formattedValue);
        const numericValue = parseFloat(formattedValue);
        if (numericValue) {
            const calculatedPayAmount = numericValue / currencyPrice;
            const truncatedPayAmount = Math.floor(calculatedPayAmount * 100) / 100;
            setPayAmount(truncatedPayAmount.toFixed(2));
        } else {
            setPayAmount("");
        }
        if (numericValue < minimumPrice) {
            setError(`Amount must be greater than ₹${minimumPrice}`);
            setPayAmount("");
        } else if (numericValue > maximumPrice) {
            setError(`You can only buy up to ₹${maximumPrice}`);
            setPayAmount("");
        } else {
            setError("");
        }
    };

    const handleTranactionList = async () => {
        let filters = new URLSearchParams();
        if (limit) {
            filters.append("limit", limit);
        }
        if (ordersTab) {
            if (ordersTab === "Pending") {
                filters.append("status", "Pending", "Refund Initiated");
            } else if (ordersTab !== "all") {
                filters.append("side", ordersTab);
                filters.append("status", "Completed");
            }
        }
        const queryString = filters.toString();
        try {
            const result = await AuthService.buySellTranactionList(queryString);
            if (result?.success) {
                setTransactionList(result?.data);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };


    useEffect(() => {
        if (Authtoken) {
            handleTranactionList();
        }
    }, [skip, limit, ordersTab]);

    useEffect(() => {
        Authtoken &&
            handleWalletBalance('INR');
    }, []);

    const showAlert = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: `You are going to Cancel Your Order`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "NO",
            confirmButtonText: "Yes, Cancel it!"
        }).then((result) => {
            if (result.isConfirmed) {
                handleRemoveOrder(id)
                    .then((response) => {
                        if (response?.success) {
                            Swal.fire({
                                title: "Cancelled!",
                                text: "Your order has been cancelled successfully.",
                                icon: "success"
                            });
                        } else {
                            Swal.fire({
                                title: "Error!",
                                text: response || "Something went wrong!",
                                icon: "error"
                            });
                        }
                    })
                    .catch((error) => {
                        Swal.fire({
                            title: "Error!",
                            text: error?.message || "Something went wrong while cancelling your order.",
                            icon: "error"
                        });
                    });
            }
        });
    };

    const handleRemoveOrder = async (id) => {
        try {
            const result = await AuthService.cancelBuySellOrder(id);
            if (result?.success) {
                handleTranactionList();
                handleUserLimits();
                setIsOpenOrderdetailsModal(false);
                handleWalletBalance('INR');
            }
            return result;
        } catch (error) {
            return { success: false, message: error.message || "Something went wrong!" };
        }
    };

    const handleWalletBalance = async () => {
        try {
            const result = await AuthService.walletBalance('INR', "Funding");
            if (result?.success) {
                setWalletBalance(result?.data);
            } else {
                alertErrorMessage(result?.message);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const handleIsActiveBuySell = (payAmount, getAmount, side) => {
        if (!userDetails?.permissions?.buy_sell) {
            alertErrorMessage('Disable Buy Sell.');
            return;
        }
        if (!payAmount) {
            alertErrorMessage('Please Enter Amount.');
            return;
        }
        if (side === "BUY") {
            if (payAmount < minimumPrice) {
                alertErrorMessage(`Please Enter Minimum ₹${minimumPrice} Amount`);
                return;
            }
            if (payAmount > maximumPrice) {
                alertErrorMessage(`You can only buy up to ₹${maximumPrice}`);
                return;
            }
            setIsActive(true);
            setBuysellDetails({ payAmount, getAmount, side });
        } else {
            if (getAmount < minimumPrice) {
                alertErrorMessage(`Please Enter Minimum ₹${minimumPrice} Amount`);
                return;
            } if (getAmount > maximumPrice) {
                alertErrorMessage(`You can only Sell up to ₹${maximumPrice}`);
                return;
            }
            setIsActive(false);
            setIsActiveSell(true);
            setBuysellDetails({ payAmount, getAmount, side });
        }
    };



    const handleBuySell = async (payAmount, side) => {
        setIsLoading(true);
        await AuthService.buy_sell(payAmount, side, coinDetails?.base_currency, "INR", coinDetails?.base_currency_id, coinDetails?.quote_currency_id, coinDetails?._id, checkButton).then(async (result) => {
            if (result?.success) {
                handlePaymentOptions();
                if (!result?.inr_deposit_amount) {
                    setIsLoading(false);
                    setIsActive(false);
                    setIsActiveSell(false);
                    alertSuccessMessage(result?.message);
                    setCheckButton(false);
                    setPayAmount("");
                    setGetAmount("");
                    handleUserLimits();
                    handleTranactionList();
                } else {
                    setIsActiveAddMoney(true);
                    setShowAddMoneyError(result?.message);
                    setTransactionDetail(result?.data);
                    setIsLoading(false);
                    setCheckButton(false);
                    setIsActive(false);
                    setShowTimerModel(false);
                    setPayAmount("");
                    setGetAmount("");
                    handleUserLimits();
                    handleTranactionList();
                    setOrdersTab("Pending");
                }
                if (result?.data?.use_available === false) {
                    setOrdersTab("Pending");
                } else {
                    setOrdersTab(result?.data?.side);
                }
                if (result?.data?.side === "BUY") {
                    handleWalletBalance('INR');
                } else {
                    handleWalletBalanceData(coinDetails?.base_currency);
                };
                setTransactionDetail(result?.data);
            } else {
                alertErrorMessage(result);
                setIsLoading(false);
                setCheckButton(false);
            }
        });
    };

    const downloadReceipt = () => {
        const modal = document.querySelector(".isOpenOrderdetailsModal");
        if (!modal) return;
        const elementsToHide = document.querySelectorAll(".Attachment");
        elementsToHide.forEach((el) => (el.style.display = "none"));
        html2canvas(modal, { scale: 4 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const pdf = new jsPDF("p", "mm", "a4");
            let yPosition = 10;
            if (imgHeight > pageHeight) {
                let heightLeft = imgHeight;
                while (heightLeft > 0) {
                    pdf.addImage(imgData, "PNG", 0, yPosition, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                    yPosition = heightLeft > 0 ? -pageHeight + 10 : 10;
                    if (heightLeft > 0) pdf.addPage();
                }
            } else {
                pdf.addImage(imgData, "PNG", 0, yPosition, imgWidth, imgHeight);
            }
            pdf.save("receipt.pdf");
            elementsToHide.forEach((el) => (el.style.display = "block"));
        });
    };

    const handleOpen = (url) => {
        if (!url) return;
        setFileUrl(url);
        setIsOpen(true);
    };

    const handlePaymentOptions = async () => {
        try {
            const result = await AuthService.getPaymentOptions();
            if (result?.success) {
                setPaymentOptions(result?.data);
                setActiveUpiIndex(0);
                setActiveBankIndex(0);
                setActiveCashIndex(0);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const paymentApps = [
        { name: "Google Pay", image: "/assets/images/googlepay.svg" },
        { name: "PhonePe", image: "/assets/images/phonepe.svg" },
        { name: "Bhim UPI", image: "/assets/images/bhim.svg" },
        { name: "Paytm", image: "/assets/images/paytm.svg" }
    ];


    const upiLink = `upi://pay?pa=${paymentOptions?.upi?.[activeUpiIndex]?.vpaId}&pn=${paymentOptions?.upi?.[activeUpiIndex]?.upiName}&cu=INR&am=${transactionDetail?.inr_deposit_amount}&tn=qbs_ord_${Number(transactionDetail?.ref_no)}`;

    const [formData, setFormData] = useState({
        paymentMode: activePaymentMode === "UPI" ? "UPI" : "",
        utrNumber: "",
        description: "",
        attachment: null,
    });

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            paymentMode: activePaymentMode,
        }));
    }, [activePaymentMode]);

    const handleChangeAddUtr = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (value.trim()) {
            setErrors((prev) => ({ ...prev, [name]: "" }));
        }
        setResponseResult("")
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, attachment: e.target.files[0] });
        setErrors({ ...errors, attachment: "" });
    };

    const validateForm = () => {
        let newErrors = {};
        if (!formData.paymentMode) newErrors.paymentMode = "Payment Mode is required";
        if (!formData.utrNumber) newErrors.utrNumber = "Txn./Ref. Number is required";
        if (activePaymentMode !== "Cash") {
            if (!formData.attachment) newErrors.attachment = "Attachment is required";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const base64ToBlob = (base64) => {
        const byteString = atob(base64.split(',')[1]);
        const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
        const buffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(buffer);
        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }
        return new Blob([buffer], { type: mimeString });
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        if (activePaymentMode === "Cash" && !image) {
            setResponseResult("Upload Payment Receipt.");
            return;
        }
        const formValue = new FormData();
        formValue.append("qbsId", transactionDetail?._id || "");
        formValue.append("orderId", transactionDetail?.order_id || "");
        formValue.append(
            "paymentOptionId",
            activePaymentMode === "UPI"
                ? paymentOptions?.upi?.[activeUpiIndex]?._id
                : activePaymentMode === "Bank"
                    ? paymentOptions?.bank?.[activeBankIndex]?._id
                    : paymentOptions?.cash?.[activeCashIndex]?._id || ""
        );
        formValue.append("amount", transactionDetail?.inr_deposit_amount || "");
        formValue.append("refNumber", transactionDetail?.ref_no || "");
        formValue.append(
            "paymentMode",
            ["Deposit To Branch", "Deposit To ATM"].includes(formData?.paymentMode)
                ? "Cash"
                : formData?.paymentMode || ""
        );
        formValue.append("utrNumber", formData?.utrNumber || "");
        formValue.append("adId", transactionDetail?.ad_id || "");
        formValue.append("remark", formData?.description || "");

        if (["Deposit To Branch", "Deposit To ATM"].includes(formData?.paymentMode)) {
            formValue.append("paymentChannel", formData?.paymentMode || "");
        }

        if (activePaymentMode !== "Cash") {
            formValue.append("attachment", formData?.attachment || "");
        } else {
            formValue.append("attachment", base64ToBlob(image), "live-image.jpg");
        }
        setIsLoading(true);
        try {
            const result = await AuthService.addInr(formValue);
            if (result?.success) {
                setFormData({
                    paymentMode: activePaymentMode === "UPI" ? "UPI" : "",
                    utrNumber: "",
                    description: "",
                    attachment: null,
                });
                setResponseResult("");
                handleTranactionList();
                setOrdersTab("Pending");
                setShowSelfieModel(false);
                setShowTimerModel(true)
                setImageStatus(false);
                if (activePaymentMode === "Cash") {
                    document.getElementById("fileInput").value = "";
                    return;
                }
            } else {
                setResponseResult(result || "Internal Server Error.");
            }
        } catch (error) {
            setResponseResult(error?.message || "An unexpected error occurred.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleActivePaymentMode = (mode) => {
        setActivePaymentMode(mode);
        // handlePaymentOptions(mode);
        setFormData({
            paymentMode: mode === "UPI" ? "UPI" : "",
            utrNumber: "",
            description: "",
            attachment: null,
        });
        setResponseResult("");
        setErrors({});
    }

    const checkCameraPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setIsCameraAllowed(true);
            stream.getTracks().forEach(track => track.stop());
        } catch (error) {
            setIsCameraAllowed(false);
            alertErrorMessage('Camera access is denied. Please enable camera access in your browser settings.');
        }
    };

    useEffect(() => {
        if (activePaymentMode === "Cash") {
            checkCameraPermission();
        }
    }, []);


    const capture = () => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            setImage(imageSrc);
            setImageStatus(true);
        }
    };

    const handleUploadSelfie = () => {
        setShowSelfieModel(true);
        setIsActiveAddMoney(false);
        setResponseResult("")
    }

    const handleUserLimits = async () => {
        if (Authtoken) {
            try {
                const result = await AuthService.getQbsLimits();
                if (result?.success) {
                    setQbsLimitsDetails(result?.data);
                    handleCoinList(result?.data?.bulkTrader || false);
                } else {
                    alertErrorMessage(result);
                }
            } catch (error) {
                alertErrorMessage(error);
            }
        } else {            
            handleCoinList(false);
        }
    };

    useEffect(() => {
        handlePaymentOptions();
        handleUserLimits();
    }, []);


    const [remainingTimes, setRemainingTimes] = useState({});
    const [storedTimes, setStoredTimes] = useState({});

    const fifteenMinutes = 900 * 1000;

    useEffect(() => {
        if (!transactionList?.length) return;

        const updatedRemainingTimes = {};
        const updatedStoredTimes = {};

        transactionList.forEach((data) => {
            if (!data?.amount_deposited_at) return;

            const createdTime = moment(data.amount_deposited_at).valueOf();
            const deadline = createdTime + fifteenMinutes;
            const currentTime = moment().valueOf();

            const newRemainingTime = Math.max((deadline - currentTime) / 1000, 0);

            // ⬆️ Increase % from 50% to 100%
            updatedRemainingTimes[data.order_id] = newRemainingTime;
            updatedStoredTimes[data.order_id] = `${(50 + ((900 - newRemainingTime) / 900) * 50).toFixed(2)}%`;
        });

        setRemainingTimes(updatedRemainingTimes);
        setStoredTimes(updatedStoredTimes);

        const interval = setInterval(() => {
            const newRemainingTimes = {};
            const newStoredTimes = {};

            transactionList.forEach((data) => {
                if (!data?.amount_deposited_at) return;

                const createdTime = moment(data.amount_deposited_at).valueOf();
                const deadline = createdTime + fifteenMinutes;
                const currentTime = moment().valueOf();

                const newRemainingTime = Math.max((deadline - currentTime) / 1000, 0);

                // ⬆️ Increase % from 50% to 100%
                newRemainingTimes[data.order_id] = newRemainingTime;
                newStoredTimes[data.order_id] = `${(50 + ((900 - newRemainingTime) / 900) * 50).toFixed(2)}%`;
            });

            setRemainingTimes(newRemainingTimes);
            setStoredTimes(newStoredTimes);
        }, 1000);

        return () => clearInterval(interval);
    }, [transactionList]);

    const getProgressWidth = (remainingTime) => {
        return `${50 + ((900 - remainingTime) / 900) * 50}%`;
    };

    const bankLogos = {
        "SBI Bank": "assets/images/sbi_bank.svg",
        "HDFC Bank": "assets/images/hdfc_bank.svg",
        "AU Bank": "assets/images/au_bank.svg",
        "ICICI Bank": "assets/images/icici_bank.svg",
        "YES Bank": "assets/images/yes_bank.svg",
        "KOTAK Bank": "assets/images/kotak_bank.svg",
        "AXIS Bank": "assets/images/axis_bank.svg"
    };

    const handleResetFile = () => {
        setFormData({ ...formData, attachment: null });
        if (formData?.attachment) {
            document.getElementById("fileInput").value = "";
        }
        setImageStatus(false);
    };

    const handleCashEgilibity = async () => {
        const result = await AuthService.cash_eligibility(transactionDetail?._id, transactionDetail?.order_id, transactionDetail?.ad_id, transactionDetail?.ref_no, transactionDetail?.pay_amount, transactionDetail?.inr_deposit_amount, paymentOptions?.cash?.[activeCashIndex]?._id);
        if (result?.success || !result?.success) {
            handleTranactionList();
        }
    };


    const [timeProgress, setTimeProgress] = useState({});
    const [storedTimesPaying, setStoredTimesPaying] = useState({});

    const fifteenMinutesPaying = transactionDetail?.payment_details?.cashSelected ? 1800 * 1000 : 900 * 1000;

    useEffect(() => {
        if (!transactionList?.length) return;

        const updateProgress = () => {
            const updatedRemainingTimes = {};
            const updatedStoredTimes = {};

            transactionList.forEach((data) => {
                if (!data?.order_created_at) return;

                const createdTime = moment(data.order_created_at).valueOf();
                const deadline = createdTime + fifteenMinutesPaying;
                const currentTime = moment().valueOf();

                const newRemainingTime = Math.max((deadline - currentTime) / 1000, 0);

                const progress = transactionDetail?.payment_details?.cashSelected ? ((newRemainingTime / 1800) * 100).toFixed(2) + "%" : ((newRemainingTime / 900) * 100).toFixed(2) + "%";

                updatedRemainingTimes[data.order_id] = newRemainingTime;
                updatedStoredTimes[data.order_id] = progress;
            });

            setTimeProgress(updatedRemainingTimes);
            setStoredTimesPaying(updatedStoredTimes);
        };

        updateProgress();
        const interval = setInterval(updateProgress, 1000);

        return () => clearInterval(interval);
    }, [transactionList]);

    const getProgressWidthPaying = (remainingTime) => {
        return `${transactionDetail?.payment_details?.cashSelected
            ? ((remainingTime / 1800) * 100).toFixed(2)
            : ((remainingTime / 900) * 100).toFixed(2)
            }%`;
    };

    return (
        <main>
            <div className="page_wrapper lb_animation_gradient buy_sell_bg_img gradient-background" >
                {Authtoken ?
                    <BuysellSidebar qbsLimitsDetails={qbsLimitsDetails} setQbsLimitsDetails={setQbsLimitsDetails} />
                    : ""
                }
                <div className="af_wrapper  ">
                    <section className=" buy_sell_sec">
                        <div className="container">
                            <div className="row gx-5 align-items-center-xl ">
                                <div className="col-12  col-lg-6 col-xxl-7  ">
                                    <form className="bc_card" >
                                        <div className="card-header login-header qbs_header">
                                            <ul className="nav nav-pills  " id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active nav_buy" id="pills-Buy-tab"
                                                        data-bs-toggle="pill" data-bs-target="#pills-Buy" type="button"
                                                        role="tab" aria-controls="pills-Buy" aria-selected="true" onClick={() => { handleSeletedTab("BUY"); handleUserLimits() }}>
                                                        <span>Buy</span>
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link nav_sell" id="pills-Sell-tab"
                                                        data-bs-toggle="pill" data-bs-target="#pills-Sell" type="button"
                                                        role="tab" aria-controls="pills-Sell" aria-selected="false" onClick={() => { handleSeletedTab("SELL"); handleUserLimits() }}>
                                                        <span>Sell</span>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>


                                        <div className="tab-content" id="pills-tabContent">

                                            <div className="tab-pane show active" id="pills-Buy" role="tabpanel"
                                                aria-labelledby="pills-Buy-tab">
                                                <div className="card-body ">
                                                    <div className="mb-4">
                                                        <div className="swap_form form-group mb-0">
                                                            <label className="" htmlFor="buypayamount"> Pay Amount </label>
                                                            <div className="input_lab">
                                                                <input type="text"
                                                                    className={`form-control ${error ? "error" : !error ? "correct" : ""}`}
                                                                    name="amount"
                                                                    id="buypayamount"
                                                                    placeholder={`min ₹ ${minimumPrice}`}
                                                                    value={payAmount}
                                                                    onChange={(e) => handlePayAmountChange(e.target.value)}
                                                                />
                                                                <span> <img src="/assets/images/icon/inr.png" alt="INR" style={{ width: '20px', marginRight: '10px' }} />INR</span>
                                                            </div>
                                                        </div>
                                                        {
                                                            Authtoken &&
                                                            <div className="input_footer" >
                                                                <div className="avil_bal my-2 mb-1" >
                                                                    <div className="form-check   d-block">
                                                                        <input className="form-check-input cursor-pointer" type="checkbox" id="flexCheckDefault" checked={checkButton} value={checkButton} onClick={() => setCheckButton((prev) => !prev)} />
                                                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                            Deduct from wallet
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="balance-libra card-success my-2">
                                                                    <div className="token-img-bg_right" id="lc-data"> Available: {" "}
                                                                        <b>
                                                                            {walletBalance?.balance
                                                                                ? (walletBalance?.balance).toFixed(2)
                                                                                : "0.00"
                                                                            }{" "}
                                                                            INR
                                                                        </b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="mb-2" >
                                                            <div className="bs_error ps-2">{error}</div>
                                                        </div>
                                                        <div className="pr_suggest" >
                                                            <span className="badge bg-muted" onClick={() => handlePayAmountChange("1000")}> 1,000  </span>
                                                            <span className="badge bg-muted" onClick={() => handlePayAmountChange("5000")}> 5,000  </span>
                                                            <span className="badge bg-muted" onClick={() => handlePayAmountChange("10000")}> 10,000  </span>
                                                            <span className="badge bg-muted" onClick={() => handlePayAmountChange("50000")}> 50,000  </span>
                                                        </div>
                                                        <div className="py-3 d-flex justify-contennt-center text-center">
                                                            <i className="ri-arrow-up-down-fill ar_hs  mx-auto cursor-pointer bg-warning text-dark"></i>
                                                        </div>
                                                        <div className="swap_form form-group ">
                                                            <div className="balance-libra card-success my-3 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <div className="token-img-bg-left">
                                                                    <label className="mb-0" >You will Get</label>
                                                                </div>
                                                                <div className="token-img-bg_right" id="lc-data">
                                                                    <label className="mb-0" >Choose Crypto </label>
                                                                </div>
                                                            </div>
                                                            <div className="input_lab ">
                                                                <input type="text"
                                                                    className="form-control"
                                                                    name="amount"
                                                                    id="buycurrency"
                                                                    value={getAmount}
                                                                    placeholder="0.00"
                                                                    onChange={(e) => handleGetAmountChange(e.target.value)} />
                                                                <span className="sw_img sw_control cursor-pointer">
                                                                    <Select
                                                                        className="sw_select cursor-pointer"
                                                                        options={coinList?.sell_data?.map(item => ({
                                                                            label: item.base_currency,
                                                                            value: item.price,
                                                                            price: item.price,
                                                                            image: item.base_currency_image,
                                                                            items: item
                                                                        }))}
                                                                        getOptionLabel={(e) => (
                                                                            <div className="cc_row">
                                                                                <img
                                                                                    src={e.image || "/assets/images/icon/nocoin.png"}
                                                                                    alt=""
                                                                                    onError={(e) => (e.target.src = "/assets/images/icon/nocoin.png")}
                                                                                    style={{ width: 20, height: 20, marginRight: 5 }}
                                                                                />
                                                                                {e.label}
                                                                            </div>
                                                                        )}
                                                                        onChange={handleChange}
                                                                        value={{ value: currencyPrice, label: currencyName, image: currencyImage }}
                                                                    />

                                                                </span>
                                                            </div>
                                                            <div className="balance-libra card-success my-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <div className="token-img-bg-left"></div>

                                                                <div className="token-img-bg_right" id="lc-data">
                                                                    <b>1 {currencyName || "---"} =

                                                                        {currencyPrice
                                                                            ? Number(currencyPrice).toFixed(2)
                                                                            : "0.00"
                                                                        }{" "}INR

                                                                    </b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-10 col-12 mx-auto mt-3">
                                                        {Authtoken ?
                                                            KycStatus === 0 || KycStatus == 1 || KycStatus == 3 || KycStatus == 4 ?
                                                                <div className="order-btns">
                                                                    <Link to='/verify_kyc' className={`form-control btn ${KycStatus === 1 ? "btn-warning" : KycStatus === 0 ? "btn-warning" : "btn-danger"}`}>
                                                                        {KycStatus == 1 ? "Verification Pending" : KycStatus == 0 ? "Submit Kyc" : "Kyc Rejected Verify Again"}
                                                                    </Link>
                                                                </div> :
                                                                !userBankDetail ?
                                                                    <div className="order-btns">
                                                                        <Link to="/payments" className="form-control btn btn-warning">
                                                                            Verify Bank Details
                                                                        </Link>
                                                                    </div>
                                                                    :
                                                                    !userUpiDetail ?
                                                                        <div className="order-btns">
                                                                            <Link to="/payments" className="form-control btn btn-warning">
                                                                                Verify UPI Details
                                                                            </Link>
                                                                        </div>
                                                                        : currencyName &&
                                                                        <button type="button" className="form-control btn btn-success" onClick={() => handleIsActiveBuySell(payAmount, getAmount, 'BUY')} disabled={error}>
                                                                            Buy {currencyName}
                                                                        </button>
                                                            :
                                                            <Link to="/login" className="form-control btn btn-warning text-center" >
                                                                Sign In
                                                            </Link>
                                                        }
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-12 text-center mt-4"><p className="mb-0">Missing Deposit? {" "}
                                                        <Link className="fw-medium" to="/support"> Click here! </Link>
                                                    </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane" id="pills-Sell" role="tabpanel"
                                                aria-labelledby="pills-Sell-tab">
                                                <div className="card-body ">
                                                    <div className="mb-4">
                                                        <div className="swap_form form-group mb-0">
                                                            <label className="text-end" htmlFor="currencyget">
                                                                Choose Crypto
                                                            </label>
                                                            <div className="input_lab ">
                                                                <input type="text" className="form-control" placeholder="Enter Amount" name="amount" id="payamount" value={payAmount} onChange={(e) => handlePayAmountChangeSell(e.target.value)} />
                                                                <span className="sw_img sw_control cursor-pointer">
                                                                    {/* <Select
                                                                        className="sw_select cursor-pointer"
                                                                        options={coinList?.buy_data?.map(item => ({
                                                                            label: item.base_currency,
                                                                            value: item.base_currency,
                                                                            price: item.price,
                                                                            image: item.base_currency_image,
                                                                            items: item
                                                                        }))}
                                                                        onChange={handleChange}
                                                                        value={{ value: currencyName, label: currencyName, image: currencyImage }}
                                                                        defaultValue={currencyId}
                                                                        getOptionLabel={(optionItem, index) => (
                                                                            <div key={index} className="cc_row cursor-pointer">
                                                                                <img src={optionItem.image || "/assets/images/icon/nocoin.png"} alt="" onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} />
                                                                                {optionItem.value}
                                                                            </div>
                                                                        )}
                                                                    /> */}

                                                                    <Select
                                                                        className="sw_select cursor-pointer"
                                                                        options={coinList?.buy_data?.map(item => ({
                                                                            label: item.base_currency,
                                                                            value: item.price,
                                                                            price: item.price,
                                                                            image: item.base_currency_image,
                                                                            items: item
                                                                        }))}
                                                                        getOptionLabel={(e) => (
                                                                            <div className="cc_row">
                                                                                <img
                                                                                    src={e.image || "/assets/images/icon/nocoin.png"}
                                                                                    alt=""
                                                                                    onError={(e) => (e.target.src = "/assets/images/icon/nocoin.png")}
                                                                                    style={{ width: 20, height: 20, marginRight: 5 }}
                                                                                />
                                                                                {e.label}
                                                                            </div>
                                                                        )}
                                                                        onChange={handleChange}
                                                                        value={{ value: currencyPrice, label: currencyName, image: currencyImage }}
                                                                    // styles={customStyles}
                                                                    />

                                                                </span>
                                                            </div>
                                                            {
                                                                Authtoken &&
                                                                <div className="balance-libra card-success my-2">
                                                                    <div className="token-img-bg_right" id="lc-data">
                                                                        <div className="token-img-bg_right" id="lc-data">
                                                                            Available: {" "}
                                                                            <b>

                                                                                {walletBalanceSellSide?.balance
                                                                                    ? (walletBalanceSellSide?.balance).toFixed(2)
                                                                                    : "0.00"
                                                                                }{" "} {walletBalanceSellSide?.short_name}


                                                                            </b>
                                                                            <Link className="actions_deposit__Ydutk" to='/wallet'>
                                                                                <i className="ri-add-circle-fill" style={{ fontSize: "15px" }}></i>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="py-3 d-flex justify-contennt-center text-center">
                                                            <i className="ri-arrow-up-down-fill ar_hs  mx-auto cursor-pointer bg-warning text-dark"></i>
                                                        </div>
                                                        <div className="swap_form form-group mb-0 mt-3">
                                                            <label className="" htmlFor="buypayamount">You will Get </label>
                                                            <div className="input_lab">
                                                                <input type="text" className={`form-control ${error ? "error" : !error ? "correct" : ""}`}
                                                                    placeholder={"min ₹" + minimumPrice} name="amount" id="currencyget" value={getAmount} onChange={(e) => handleGetAmountChangeSell(e.target.value)} />
                                                                <span>
                                                                    <img src="/assets/images/icon/inr.png" alt="INR" style={{ width: '20px', marginRight: '10px' }} />INR</span>
                                                            </div>
                                                        </div>
                                                        <div className="input_footer" >
                                                            <div className="bs_error ps-2">{error}</div>
                                                            <div className="balance-libra card-success my-2">

                                                                <div className="token-img-bg_right" id="lc-data">
                                                                    <b>1 {currencyName || "---"} = {" "}
                                                                        {" "}

                                                                        {currencyPrice
                                                                            ? Number(currencyPrice).toFixed(2)
                                                                            : "0.00"
                                                                        }{" "}INR</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="pr_suggest" >
                                                            <span className="badge bg-muted"
                                                                onClick={() => handleGetAmountChangeSell("1000")}> 1,000  </span>
                                                            <span className="badge bg-muted"
                                                                onClick={() => handleGetAmountChangeSell("5000")}> 5,000  </span>
                                                            <span className="badge bg-muted"
                                                                onClick={() => handleGetAmountChangeSell("10000")}> 10,000  </span>
                                                            <span className="badge bg-muted"
                                                                onClick={() => handleGetAmountChangeSell("50000")}> 50,000  </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-10 col-12 mx-auto  mt-3">
                                                        <div className="col-lg-12 col-md-10 col-12 mx-auto mt-3">

                                                            {Authtoken ?
                                                                KycStatus === 0 || KycStatus == 1 || KycStatus == 3 || KycStatus == 4 ?
                                                                    <div className="order-btns">
                                                                        <Link to='/verify_kyc' className={`form-control btn ${KycStatus === 1 ? "btn-warning" : KycStatus === 0 ? "btn-warning" : "btn-danger"}`}>
                                                                            {KycStatus == 1 ? "Verification Pending" : KycStatus == 0 ? "Submit Kyc" : "Kyc Rejected Verify Again"}
                                                                        </Link>
                                                                    </div> :
                                                                    !userBankDetail ?
                                                                        <div className="order-btns">
                                                                            <Link to="/payments" className="form-control btn btn-warning">
                                                                                Verify Bank Details
                                                                            </Link>
                                                                        </div>
                                                                        :
                                                                        !userUpiDetail ?
                                                                            <div className="order-btns">
                                                                                <Link to="/payments" className="form-control btn btn-warning">
                                                                                    Verify UPI Details
                                                                                </Link>
                                                                            </div>
                                                                            :
                                                                            currencyName &&
                                                                            <button type="button" className="form-control btn btn-danger" onClick={() => handleIsActiveBuySell(payAmount, getAmount, 'SELL')} disabled={error}>
                                                                                Sell {currencyName}
                                                                            </button>
                                                                :
                                                                <Link to="/login" className="form-control btn btn-warning text-center" >
                                                                    Sign In
                                                                </Link>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-12 text-center mt-4"><p className="mb-0">Missing Deposit? {" "}
                                                        <Link className="fw-bold" to="/support"> Click here! </Link>
                                                    </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                                <div className="col-12 col-lg-6 col-xxl-5  d-none d-lg-block">
                                    <div className="d-flex-between  custom-flex mrk_menu no-border mb-0" >
                                        <h6 className="mb-0"> Buy or Sell Your Crypto </h6>
                                    </div>
                                    <div className="hero_card bc_h_card">
                                        <div className="treade_card_header justify-content-between"><div className="card_header_title active">  Exchange Recent Orders </div></div>
                                        <div className="c_body" >
                                            <div className="m_table r_table ">
                                                <div className="table-responsive">
                                                    <div className="qbs_buy" style={{ minHeight: '247px' }} >
                                                        <table className="table table-hover"  >
                                                            <thead className="">
                                                                <tr className="">
                                                                    <th scope="col"  > <span className=""  > Date & Time </span> </th>
                                                                    <th scope="col"> <span className=""  > Coin Name</span></th>
                                                                    <th scope="col" > <span className=""  > Total Recived</span></th>
                                                                    <th scope="col"> <span className=""  > Side</span> </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {
                                                                    socketData?.recent_qbs && socketData?.recent_qbs.length > 0 ? (
                                                                        socketData?.recent_qbs?.slice(0, 5)?.map((item, index) => (
                                                                            item.side === "BUY" && (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        <div className="c_view justify-content-start">
                                                                                            <span>
                                                                                                {moment(item.createdAt).format("DD/MM/YYYY")}
                                                                                                <small>{moment(item.createdAt).format("HH:mm:ss")}</small>
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td >
                                                                                        {item?.base_currency + '/' + item?.quote_currency}
                                                                                    </td>
                                                                                    <td >
                                                                                        {item.final_get_amount || "--"}
                                                                                        &nbsp;{item?.side === "BUY" ? item.base_currency : item.quote_currency}
                                                                                    </td>

                                                                                    <td>
                                                                                        <span className={item?.side === "BUY" ? "text-success" : "text-danger"}>{item?.side || "----"}</span>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="5">
                                                                                <div className="favouriteData">
                                                                                    <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <table className="table table-hover ">
                                                        <tbody>
                                                            <tr>
                                                                <td className="no-border p-0" >
                                                                    <div className="mrkt_trde_tab justify-content-center">
                                                                        1  {currencyName || "---"}   <span className="text-success">  = {" "}
                                                                            {currencyPrice
                                                                                ? Number(currencyPrice).toFixed(2)
                                                                                : "0.00"
                                                                            }</span>
                                                                        <i className="ri-arrow-up-line ri-xl mx-3 text-success"></i>
                                                                        <span className="text-success">1.53%</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="qbs_sell" style={{ minHeight: '247px' }} >
                                                        <table className="table table-hover "  >
                                                            <thead className="">
                                                                <tr className="">
                                                                    <th scope="col"  > <span className=""  > Date & Time </span> </th>
                                                                    <th scope="col"> <span className=""  > Coin Name</span></th>
                                                                    <th scope="col" > <span className=""  > Total Recived</span></th>
                                                                    <th scope="col"> <span className=""  > Side</span> </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    socketData?.recent_qbs && socketData?.recent_qbs.length > 0 ? (
                                                                        socketData?.recent_qbs?.slice(-5)?.map((item, index) => (
                                                                            item.side === "SELL" && (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        <div className="c_view justify-content-start">
                                                                                            <span>
                                                                                                {moment(item.createdAt).format("DD/MM/YYYY")}
                                                                                                <small>{moment(item.createdAt).format("HH:mm:ss")}</small>
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td >
                                                                                        {item?.base_currency + '/' + item?.quote_currency}
                                                                                    </td>
                                                                                    <td >
                                                                                        {item.final_get_amount || "--"}
                                                                                        &nbsp;{item?.side === "BUY" ? item.base_currency : item.quote_currency}
                                                                                    </td>

                                                                                    <td>
                                                                                        <span className={item?.side === "BUY" ? "text-success" : "text-danger"}>{item?.side || "----"}</span>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="5">
                                                                                <div className="favouriteData">
                                                                                    <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Order history */}
                                <div className="ord_his_sec">
                                    <div className="col-12 col-lg-12">
                                        <div className="d-flex-between  custom-flex mrk_menu no-border mb-0">
                                            <h6 className="mb-0" > Order History </h6>

                                            {
                                                Authtoken &&
                                                <Link to="/buy_sell_transactions" className="px-0" >
                                                    See All <i className=" ri-arrow-right-s-line"></i>
                                                </Link>
                                            }

                                        </div>
                                        <div className="trade_card mb-0">
                                            <div className="treade_card_header d-flex-between trade_tabs w-100 align-items-center buy_sell_cards p-0">
                                                <ul className="nav custom-tabs order_his_tabs m_scroll ">
                                                    <li className="buysell-tab">
                                                        <span className={ordersTab === 'Pending' ? 'active' : ''} onClick={() => setOrdersTab('Pending')}> Ongoing Orders </span>
                                                    </li>

                                                    <li className="buysell-tab">
                                                        <span className={ordersTab === 'all' ? 'active' : ''} onClick={() => { setOrdersTab('all') }}> All Orders </span>
                                                    </li>
                                                    <li className="buysell-tab">
                                                        <span className={ordersTab === 'BUY' ? 'active' : ''} onClick={() => { setOrdersTab('BUY') }} > Buy Orders </span>
                                                    </li>
                                                    <li className="buysell-tab">
                                                        <span className={ordersTab === 'SELL' ? 'active' : ''} onClick={() => { setOrdersTab('SELL') }} > Sell Orders </span>
                                                    </li>
                                                </ul>

                                            </div>
                                            <div className="tab-content">
                                                <div className="tab-pane show active px-0" id="open_orders">
                                                    {Authtoken ? (
                                                        <div className="bs_row qbs_oht row g-2" >
                                                            {transactionList?.length > 0 ? transactionList?.map((data, index) => {
                                                                return (
                                                                    <div className="bs_col col-md-12 col-lg-4" key={index}
                                                                        onClick={() => {
                                                                            setTransactionDetail(data);
                                                                        }}>
                                                                        <div className="bs_card trade_card cursor-pointer">
                                                                            <div className="" onClick={() => { setIsOpenOrderdetailsModal(true) }}>
                                                                                <div className="bst_row" >
                                                                                    <div>

                                                                                        <div className="d-flex align-items-center gap-2" >
                                                                                            <h6>{data?.base_currency} / {data?.quote_currency}</h6>

                                                                                            <span className={`badge badge-sm ${data?.status === 'Completed' ? " bg-success-lite" : data?.status === 'Cancelled By User' ? "bg-danger-lite" : data?.status === 'Rejected' ? "bg-danger-lite" : data?.status === 'Pending' ? "bg-warning-lite" : data?.status === 'Refund Initiated' ? "bg-warning-lite" : data?.status === 'Refund Processed' ? "bg-danger-lite" : !data?.amount_deposited ? "bg-warning-lite" : "bg-success-lite"}`}>
                                                                                                {data?.status === "Cancelled By User" ? "Cancelled" : data?.status === "Refund Processed" ? "Refunded" : data?.status}
                                                                                            </span>
                                                                                        </div>

                                                                                        <span className={data?.side === "SELL" ? "text-danger" : "text-success"}> {data?.side} </span>
                                                                                    </div>
                                                                                    <div className="bst_end" >
                                                                                        <small className="text-link text-underline">{data?.order_id}</small>
                                                                                        <small className="row_date text-muted" >
                                                                                            {moment(data?.order_created_at).format('Do MMM YYYY, h:mm:ss a')}
                                                                                        </small>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="item_row" >
                                                                                    <span className="text-muted" > Amount </span>
                                                                                    <span className="" > {data?.pay_amount} {data?.side === "SELL" ? data?.base_currency : data?.quote_currency} </span>
                                                                                </div>
                                                                                <div className="item_row" >
                                                                                    <span className="text-muted" > Quantity </span>
                                                                                    <span className="" >{data?.get_amount} {data?.side === "SELL" ? data?.quote_currency : data?.base_currency}  </span>
                                                                                </div>

                                                                                {
                                                                                    data?.side !== "SELL" &&
                                                                                    <div className="item_row" >
                                                                                        <span className="text-muted" > Payment Status </span>
                                                                                        <span className={data?.amount_deposited === true ? "text-success" : data?.remark === "Auto Completed" ? "text-success" : "text-warning"} >{data?.amount_deposited === true ? "Paid" : data?.remark === "Auto Completed" ? "Debit From Wallet" : "Unpaid"} </span>
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                            <div className="item_action" >

                                                                                {data?.status === 'Pending' && data?.amount_deposited &&

                                                                                    <button className="btn btn-warning btn-sm"
                                                                                        onClick={() => { setIsActiveAddMoney(true); setShowTimerModel(true) }}
                                                                                    >View</button>
                                                                                }

                                                                                {ordersTab === "Pending" &&
                                                                                    < >

                                                                                        {
                                                                                            !data?.amount_deposited &&
                                                                                            <button className="btn btn-warning btn-sm"
                                                                                                onClick={() => { setIsActiveAddMoney(true); setShowTimerModel(false); handlePaymentOptions(); }}
                                                                                            >Pay Now</button>
                                                                                        }

                                                                                        {
                                                                                            !data?.amount_deposited ?
                                                                                                <button className="btn btn-secondary btn-sm" onClick={() => showAlert(data?._id)}>Cancel Order</button>
                                                                                                : null
                                                                                        }
                                                                                    </>
                                                                                }
                                                                                {
                                                                                    (data?.status === "Refund Initiated" || data?.status === "Refund Processed") && (
                                                                                        <button className="btn btn-dark btn-sm" type="button" onClick={() => setIsOpenChatModel(true)} >
                                                                                            <i className="ri ri-message-3-line" ></i>
                                                                                        </button>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                            {data?.status === 'Pending' &&
                                                                                <div className="progress progress-sm progress_w_time mt-3">
                                                                                    {
                                                                                        !data?.amount_deposited_at ?
                                                                                            <>
                                                                                                <span className="progress_time" >
                                                                                                    {storedTimesPaying[data.order_id] !== "100.00%" ? "Pay within :" : "Pay...."}

                                                                                                    {storedTimesPaying[data.order_id] !== "100.00%" ? <CountdownTimerOrder orderCreatedAt={data?.order_created_at} orderStatus={data?.payment_details?.cashSelected} /> : ""}

                                                                                                </span>

                                                                                                <div className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                                                                                    role="progressbar"
                                                                                                    style={{
                                                                                                        width: timeProgress[data.order_id] > 0
                                                                                                            ? getProgressWidthPaying(timeProgress[data.order_id])
                                                                                                            : "100%",
                                                                                                        transition: "width 1s linear",
                                                                                                    }}
                                                                                                    aria-valuenow={parseFloat(storedTimesPaying[data.order_id])}
                                                                                                    aria-valuemin="50"
                                                                                                    aria-valuemax="100"
                                                                                                ></div>
                                                                                                {/*  <span className="time_short" >{storedTimesPaying[data.order_id]} {" "}</span> */}
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <span className="progress_time" >
                                                                                                    {storedTimes[data.order_id] === "100.00%" ? "Order will release shortly" : "Releasing shortly : "}
                                                                                                    <img
                                                                                                        src="/assets/images/loadingNew.png"
                                                                                                        alt=""
                                                                                                        style={{ width: "20px" }}
                                                                                                        className="d-inline-flex fw-semibold"
                                                                                                    />

                                                                                                    {storedTimes[data.order_id] !== "100.00%" ? <CountdownTimerOrder orderCreatedAt={data?.amount_deposited_at} /> : ""}
                                                                                                </span>

                                                                                                <div className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                                                                                    role="progressbar"
                                                                                                    style={{
                                                                                                        width: remainingTimes[data.order_id] > 0
                                                                                                            ? getProgressWidth(remainingTimes[data.order_id])
                                                                                                            : "100%",
                                                                                                        transition: "width 1s linear",
                                                                                                    }}
                                                                                                    aria-valuenow={parseFloat(storedTimes[data.order_id])}
                                                                                                    aria-valuemin="50"
                                                                                                    aria-valuemax="100"
                                                                                                ></div>
                                                                                                <span className="time_short" >{storedTimes[data.order_id]} {" "}</span>
                                                                                            </>
                                                                                    }
                                                                                    {/* {isCompleted && (
                                                                                        <button onClick={handleRestart} className="btn btn-primary mt-2">
                                                                                            Restart Progress
                                                                                        </button>
                                                                                    )} */}

                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }) :
                                                                <div className="favouriteData mt-3 mb-3">
                                                                    <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                </div>
                                                            }


                                                        </div>
                                                    ) : (
                                                        <div className="favouriteData mt-3 mb-3">
                                                            <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                            <p className="mt-2">
                                                                Go to&nbsp;
                                                                <Link className="btn-link px-0 d-inline-flex" to="/login">
                                                                    <b>&nbsp;Sign in&nbsp;</b>
                                                                </Link>
                                                                &nbsp;and check your {ordersTab} Orders.
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div >
            </div >

            {/* buy details model */}

            {isActive && (
                <div div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title" id="modal_otp_Label">{buysellDetails.side} {" "} {coinDetails.base_currency} </h5>
                                    <button type="button" className="btn-close" onClick={() => setIsActive(false)}></button>
                                </div>
                                <div className="modal-body p-md-4">
                                    <div className="tt_data  ">
                                        <div className="tt_card">

                                            <div className="tt_item no-border">
                                                <span className="text-muted">Pay Amount :</span>
                                                {" "}
                                                <span className="tt_normal">{buysellDetails.payAmount} {coinDetails.quote_currency}</span>
                                            </div>
                                            <div className="tt_item">
                                                <span className="text-muted"> Price  </span>
                                                <span className="tt_normal">{currencyPrice} {" "} {coinDetails.quote_currency}</span>
                                            </div>
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Fees: </span>
                                                <span className="tt_normal">
                                                    {
                                                        buysellDetails.side === "BUY"
                                                            ? Number(qbsLimitsDetails?.buyFees * buysellDetails.payAmount) / 100
                                                            : Number(qbsLimitsDetails?.sellFees * buysellDetails.payAmount) / 100
                                                    }
                                                    {" "}
                                                    {coinDetails.quote_currency}
                                                </span>
                                            </div>

                                            <div className="tt_item no-border">
                                                <span className="text-muted"> TDS: </span>
                                                <span className="tt_normal">
                                                    {
                                                        buysellDetails.side === "BUY"
                                                            ? Number(qbsLimitsDetails?.buyTds * buysellDetails.payAmount) / 100
                                                            : Number(qbsLimitsDetails?.sellTds * buysellDetails.payAmount) / 100
                                                    }
                                                    {" "}
                                                    {coinDetails.quote_currency}

                                                </span>
                                            </div>



                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Get Amount : </span>
                                                <span className="tt_normal ">
                                                    {
                                                        buysellDetails.side === "BUY" ? ((Number(buysellDetails.payAmount) -
                                                            ((Number(qbsLimitsDetails?.buyTds * buysellDetails.payAmount) / 100) +
                                                                (Number(qbsLimitsDetails?.buyFees * buysellDetails.payAmount) / 100))) / currencyPrice)?.toFixed(6)
                                                            :
                                                            ((Number(buysellDetails.payAmount) -
                                                                ((Number(qbsLimitsDetails?.sellTds * buysellDetails.payAmount) / 100) +
                                                                    (Number(qbsLimitsDetails?.sellFees * buysellDetails.payAmount) / 100))) / currencyPrice)?.toFixed(6)
                                                    } {" "}
                                                    {coinDetails.base_currency}
                                                </span>
                                            </div>
                                        </div>
                                        <button className="form-control btn btn-success mt-3 w-100 btn-block" onClick={() => handleBuySell(buysellDetails.payAmount, 'BUY')}>
                                            {isLoading ? <SpinLoader />
                                                : <span>Proceed to Buy</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div >
            )}


            {isActiveSell && (
                <div div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title" id="modal_otp_Label">{buysellDetails.side} {" "} {coinDetails.base_currency} </h5>
                                    <button type="button" className="btn-close" onClick={() => setIsActiveSell(false)}></button>
                                </div>
                                <div className="modal-body p-md-4">
                                    <div className="tt_data ">
                                        <div className="tt_card" >

                                            <div className="tt_item">
                                                <span className="text-muted">Pay Amount :</span>
                                                <span className="tt_normal">{buysellDetails.payAmount} {" "} {coinDetails.base_currency}</span>
                                            </div>

                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Price :  </span>
                                                <span className="tt_normal">{currencyPrice} {" "} {coinDetails.quote_currency}</span>
                                            </div>

                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Get Amount : </span>
                                                <span className="tt_normal ">
                                                    {buysellDetails.getAmount} {" "} {coinDetails.quote_currency}
                                                </span>
                                            </div>

                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Fees: </span>
                                                <span className="tt_normal">
                                                    {
                                                        buysellDetails.side === "BUY"
                                                            ? Number(qbsLimitsDetails?.buyFees * buysellDetails.getAmount) / 100
                                                            : Number(qbsLimitsDetails?.sellFees * buysellDetails.getAmount) / 100
                                                    }
                                                    {" "}
                                                    {coinDetails.quote_currency}
                                                </span>
                                            </div>

                                            <div className="tt_item no-border">
                                                <span className="text-muted"> TDS: </span>
                                                <span className="tt_normal">
                                                    {
                                                        buysellDetails.side === "BUY"
                                                            ? Number(qbsLimitsDetails?.buyTds * buysellDetails.getAmount) / 100
                                                            : Number(qbsLimitsDetails?.sellTds * buysellDetails.getAmount) / 100
                                                    }
                                                    {" "}
                                                    {coinDetails.quote_currency}

                                                </span>
                                            </div>
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Final Get Amount : </span>
                                                <span className="tt_normal">
                                                    {
                                                        buysellDetails.side === "BUY" ? ((Number(buysellDetails.payAmount) -
                                                            ((Number(qbsLimitsDetails?.buyTds * buysellDetails.payAmount) / 100) +
                                                                (Number(qbsLimitsDetails?.buyFees * buysellDetails.payAmount) / 100))) / currencyPrice)?.toFixed(6)
                                                            :
                                                            ((Number(buysellDetails.getAmount) -
                                                                ((Number(qbsLimitsDetails?.sellTds * buysellDetails.getAmount) / 100) +
                                                                    (Number(qbsLimitsDetails?.sellFees * buysellDetails.getAmount) / 100))))?.toFixed(0)
                                                    }
                                                    {" "} INR</span>
                                            </div>
                                        </div>

                                        <button className="form-control btn btn-danger mt-3 w-100 btn-block" onClick={() => handleBuySell(buysellDetails.payAmount, "SELL")}>
                                            {isLoading ? <SpinLoader />
                                                : <span>Quick Sell</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div >
            )}


            {isActiveAddMoney && (
                <div className="d-nones" >
                    <div className="modal-open-custom" >
                        <div className="modal show d-block pymt_modal" >
                            <div className="modal-dialog modal-dialog-centered modal-xl">
                                <div className="modal-content bg-warning no-border ">
                                    <div className="modal-body pymt_body">
                                        <div className="pumt_row" >
                                            <div className="pymt_sidebar" >
                                                <div className="pymt_profile" >
                                                    <div className="rc_profile">
                                                        <span className="ri-arrow-left-line ri-xl d-flex d-xl-none" onClick={() => { setIsActiveAddMoney(false); setShowAddMoneyError(""); handleTranactionList(); }} ></span>
                                                        <i className=" ">
                                                            <img src="assets/images/user_icon.svg" className="img-fluid" alt="" />
                                                        </i>
                                                        <div className="ud">
                                                            <h5>{transactionDetail?.user_name}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="o_s">
                                                        <div>
                                                            <span> Pay Amount </span>
                                                            <h5>{transactionDetail?.inr_deposit_amount} {transactionDetail?.pay_currency}</h5>

                                                            {/*  <div className="mt-2">
                                                                <small className="invalid-feedback text-danger">{showAddMoneyError}</small>
                                                            </div> */}

                                                        </div>
                                                    </div>

                                                    <div className="Pay_using d-none d-xl-flex " >
                                                        <div className=" d-flex align-items-start gap-2  w-100 ">
                                                            <i className="ri-money-rupee-circle-fill"></i>
                                                            <div className="" >
                                                                <span>  Pay Using  </span>
                                                                <p>Bank  Ac. - {userBankDetail?.accountNumber}</p>
                                                                <p>UPI - {userUpiDetail?.vpaId}</p>
                                                            </div>
                                                        </div>
                                                        <i className="ri-arrow-right-s-line cursor-pointer" onClick={() => navigate("/payments")}></i>
                                                    </div>

                                                </div>
                                                <div className="payment_secured d-none d-xl-flex" >
                                                    <img src="/assets/images/secured_by.svg" className="img-fluid" alt="" />
                                                </div>
                                            </div>
                                            <div className="pymt_mainbbar" >
                                                <div className="modal-header header_sm d-none d-xl-flex justify-content-between">
                                                    <h5 className="modal-title" id="modal_otp_Label"> Payment Options </h5>
                                                    <button type="button" className="btn-close" onClick={() => { setIsActiveAddMoney(false); setResponseResult("") }}>
                                                    </button>
                                                </div>

                                                <div className={`pymt_space ${!showTimerModel ? "" : "d-none"}`}>
                                                    <div className="d-flex align-items-start">
                                                        <div className="nav_tabs_bar" >

                                                            <h5 className="d-flex align-items-center justify-content-between" >All Payment Options
                                                                <div className="upsc_more d-xl-none">
                                                                    <span className="upac_mob"> Instructions <i className="ri-question-fill"></i>
                                                                    </span>
                                                                    <div className="up_details upsc_inst_mob ">
                                                                        <div className="pay_instructions ">
                                                                            <span> Pay Instructions</span>
                                                                            <div className="ins_tab" >
                                                                                <p><span>✅</span> Use Registered Accounts Only – Pay only from your registered bank account or UPI ID.</p>
                                                                                <p> <span>❌</span> No Third-Party Payments – Payments from unregistered accounts will be rejected.</p>
                                                                                <p> <span>🔄</span> Refund Policy – Incorrect payments will be refunded to the source bank within 7-10 days.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </h5>

                                                            <div className="ap_ins" >

                                                                <div className="nav flex-column nav-pills" >

                                                                    {

                                                                        paymentOptions?.upi?.length > 0 &&
                                                                        <button className={`nav-link   ${activePaymentMode === "UPI" ? "active" : ""}`} type="button" onClick={() => { handleActivePaymentMode("UPI"); handleResetFile() }}>
                                                                            <div className="pp_space" >
                                                                                <span>UPI</span>
                                                                                <img src="/assets/images/upi_labels.svg" alt="" />
                                                                            </div>
                                                                        </button>
                                                                    }

                                                                    {

                                                                        paymentOptions?.bank?.length > 0 &&
                                                                        <button button className={`nav-link   ${activePaymentMode === "Bank" ? "active" : ""}`} type="button" onClick={() => { handleActivePaymentMode("Bank"); handleResetFile() }}>
                                                                            <div className="pp_space" >
                                                                                <span>Bank Transfer  <small> IMPS, NEFT, RTGS </small> </span>
                                                                                <img src="/assets/images/bank_labels.svg" alt="" />
                                                                            </div>
                                                                        </button>
                                                                    }

                                                                    {
                                                                        paymentOptions?.cash?.length > 0 &&
                                                                        <button className={`nav-link ${activePaymentMode === "Cash" ? "active" : ""}`} type="button" onClick={() => { handleActivePaymentMode("Cash"); handleResetFile(); handleCashEgilibity() }}>
                                                                            <div className="pp_space" >
                                                                                <span>Cash  <small> Deposit to branch/CDM</small> </span>
                                                                                <img src="/assets/images/cash_labels.svg" alt="" />
                                                                            </div>
                                                                        </button>
                                                                    }



                                                                </div>


                                                                <div className="pay_instructions d-none d-xl-block">
                                                                    <span> Pay Instructions</span>
                                                                    <div className="ins_tab" >
                                                                        <p><span>✅</span> Use Registered Accounts Only – Pay only from your registered bank account or UPI ID.</p>
                                                                        <p> <span>❌</span> No Third-Party Payments – Payments from unregistered accounts will be rejected.</p>
                                                                        <p> <span>🔄</span> Refund Policy – Incorrect payments will be refunded to the source bank within 7-10 days.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="bg-white scroll_pop fc_tab_container" >
                                                            {
                                                                activePaymentMode === "UPI" ?
                                                                    <div>
                                                                        <div className="uttr_title ">
                                                                            <h5>
                                                                                Choose UPI Options
                                                                            </h5>
                                                                        </div>
                                                                        <div className="bank_options">
                                                                            {paymentOptions?.upi &&
                                                                                paymentOptions?.upi.map((item, index) => (
                                                                                    <div
                                                                                        key={index}
                                                                                        className={`option_col ${index === activeUpiIndex ? "active" : ""}`}
                                                                                        onClick={() => setActiveUpiIndex(index)}
                                                                                    >

                                                                                        {'UPI'}
                                                                                        <img src="/assets/images/upi-icon.svg" className="img-fluid" alt={item?._id} />
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    activePaymentMode === "Bank" ?
                                                                        <div>
                                                                            <div className="uttr_title ">
                                                                                <h5>
                                                                                    Choose Bank Options
                                                                                </h5>
                                                                            </div>
                                                                            <div className="bank_options">
                                                                                {paymentOptions?.bank &&
                                                                                    paymentOptions?.bank?.map((item, index) => (
                                                                                        <div
                                                                                            key={index}
                                                                                            className={`option_col ${index === activeBankIndex ? "active" : ""}`}
                                                                                            onClick={() => setActiveBankIndex(index)}
                                                                                        >
                                                                                            {item?.bankName}
                                                                                            {bankLogos[item?.bankName] && (
                                                                                                <img src={bankLogos[item?.bankName]} className="img-fluid" alt={item?.bankName} />
                                                                                            )}
                                                                                        </div>
                                                                                    ))}
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        activePaymentMode === "Cash" ?
                                                                            <div>
                                                                                <div className="uttr_title ">
                                                                                    <h5>
                                                                                        Choose Cash Options
                                                                                    </h5>
                                                                                </div>
                                                                                <div className="bank_options">
                                                                                    {paymentOptions?.cash &&
                                                                                        paymentOptions?.cash?.map((item, index) => (
                                                                                            <div
                                                                                                key={index}
                                                                                                className={`option_col ${index === activeCashIndex ? "active" : ""}`}
                                                                                                onClick={() => setActiveCashIndex(index)}
                                                                                            >
                                                                                                {item?.bankName}
                                                                                                {bankLogos[item?.bankName] && (
                                                                                                    <img src={bankLogos[item?.bankName]} className="img-fluid" alt={item?.bankName} />
                                                                                                )}
                                                                                            </div>
                                                                                        ))}
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            ""
                                                            }
                                                            <div className=" pay_step tab-content fc_tab_content" id="v-pills-tabContent">


                                                                <div className={`tab-pane fade ${activePaymentMode === "UPI" ? "show active" : ""}`}>
                                                                    <div className="fc_row">
                                                                        <div className="uttr_title d-flex align-items-center justify-content-between">
                                                                            <h5>
                                                                                <span className="step_icon">1</span> Scan & Pay
                                                                            </h5>
                                                                            <CountdownTimerOrder orderCreatedAt={transactionDetail?.createdAt} orderStatus={transactionDetail?.payment_details?.cashSelected} />
                                                                        </div>
                                                                        <div className="upi_details">
                                                                            <div className="upi_qr">
                                                                                <QRCodeCanvas value={upiLink} className="img-fluid" />
                                                                            </div>
                                                                            <div className="upi_info">
                                                                                <small>
                                                                                    Scan the QR & Click below to pay <br /> using any UPI App
                                                                                </small>
                                                                                <div className="d-flex align-items-center gap-3">
                                                                                    {paymentApps.map((app, index) => (
                                                                                        <a key={index} title={`Pay via ${app.name}`} href={upiLink}>
                                                                                            <img className="h-auto w-6" src={app.image} alt={`${app.name} icon`} />
                                                                                        </a>
                                                                                    ))}
                                                                                </div>
                                                                                <small className="upi_name">
                                                                                    UPI Name -{" "}
                                                                                    <b className="d-inline-flex align-items-center gap-1">
                                                                                        {paymentOptions?.upi?.[activeUpiIndex]?.upiName}
                                                                                        <i className="text-success ri-verified-badge-fill"></i>
                                                                                    </b>
                                                                                </small>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div className={`tab-pane fade  ${activePaymentMode === "Bank" ? "show active" : ""}`}>
                                                                    <div className="fc_row">
                                                                        <div className="uttr_title d-flex align-items-center justify-content-between " >
                                                                            <h5>  <span className="step_icon"> 1 </span> Add Beneficiary and pay</h5>

                                                                            <CountdownTimerOrder orderCreatedAt={transactionDetail?.createdAt} orderStatus={transactionDetail?.payment_details?.cashSelected} />

                                                                        </div>
                                                                        <div className="accordion list_accertab " id="accordionExample">
                                                                            <div className="accordion-item mb-2">
                                                                                <h2 className="accordion-header" id="headingOne">
                                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                                        <b className="pr_icon pr_icon_sm fw-normal ">
                                                                                            {bankLogos[paymentOptions?.bank?.[activeBankIndex]?.bankName] && (
                                                                                                <img src={bankLogos[paymentOptions?.bank?.[activeBankIndex]?.bankName]} className="img-fluid" alt={paymentOptions?.bank?.[activeBankIndex]?.bankName} />
                                                                                            )}
                                                                                        </b>
                                                                                        {paymentOptions?.bank?.[activeBankIndex]?.bankName}
                                                                                    </button>
                                                                                </h2>
                                                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                                    <div className="accordian-body" >
                                                                                        <div className="row g-3">
                                                                                            <div className="col-6">
                                                                                                <div className="label-group">
                                                                                                    <label> Account Type </label>
                                                                                                    <p>
                                                                                                        {paymentOptions?.bank?.[activeBankIndex]?.accountType}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-6">
                                                                                                <div className="label-group">
                                                                                                    <label> Beneficiary Name </label>
                                                                                                    <p className="text-success" >
                                                                                                        {paymentOptions?.bank?.[activeBankIndex]?.accountHolderName}
                                                                                                        <i className="ms-1 fw-normal ri-file-copy-line text-muted cursor-pointer" onClick={() => copy(paymentOptions?.bank?.[activeBankIndex]?.accountHolderName)} ></i>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-6">
                                                                                                <div className="label-group">
                                                                                                    <label> Account Number </label>
                                                                                                    <p>
                                                                                                        {paymentOptions?.bank?.[activeBankIndex]?.accountNumber}
                                                                                                        <i className="ms-1 fw-normal ri-file-copy-line text-muted cursor-pointer"
                                                                                                            onClick={() => copy(paymentOptions?.bank?.[activeBankIndex]?.accountNumber)}></i>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-6">
                                                                                                <div className="label-group">
                                                                                                    <label> IFSC Code </label>
                                                                                                    <p>
                                                                                                        {paymentOptions?.bank?.[activeBankIndex]?.ifsc}
                                                                                                        <i className="ms-1 fw-normal ri-file-copy-line text-muted cursor-pointer"
                                                                                                            onClick={() => copy(paymentOptions?.bank?.[activeBankIndex]?.ifsc)}></i>
                                                                                                        <br />
                                                                                                        <small className="fw-normal">
                                                                                                            Branch: {paymentOptions?.bank?.[activeBankIndex]?.ifsc}
                                                                                                        </small>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className={`tab-pane fade  ${activePaymentMode === "Cash" ? "show active" : ""}`}>
                                                                    <div className="fc_row ">
                                                                        <div className="uttr_title d-flex align-items-center justify-content-between " >
                                                                            <h5>  <span className="step_icon"> 1 </span>Cash Deposit to Branch or ATM </h5>

                                                                            <CountdownTimerOrder orderCreatedAt={transactionDetail?.createdAt} orderStatus={transactionDetail?.payment_details?.cashSelected} />

                                                                        </div>
                                                                        <div className="fc_details  mb-3">
                                                                            <div className="d-flex gap-3 align-items-canter justify-content-between mb-3">
                                                                                <h5 className="mb-0 d-flex    align-items-center bank_name ">
                                                                                    <b className="pr_icon pr_icon_sm fw-normal me-0">
                                                                                        {bankLogos[paymentOptions?.cash?.[activeCashIndex]?.bankName] && (
                                                                                            <img src={bankLogos[paymentOptions?.cash?.[activeCashIndex]?.bankName]} className="img-fluid" alt={paymentOptions?.cash?.[activeCashIndex]?.bankName} />
                                                                                        )}
                                                                                    </b>
                                                                                    {paymentOptions?.cash?.[activeCashIndex]?.bankName}
                                                                                </h5>
                                                                            </div>

                                                                            <div className="row g-3">
                                                                                <div className="col-6">
                                                                                    <div className="label-group">
                                                                                        <label> Account Type </label>
                                                                                        <p>
                                                                                            {paymentOptions?.cash?.[activeCashIndex]?.accountType}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <div className="label-group">
                                                                                        <label> Beneficiary Name </label>
                                                                                        <p className="text-success" >
                                                                                            {paymentOptions?.cash?.[activeCashIndex]?.accountHolderName}
                                                                                            <i className="ms-1 fw-normal ri-file-copy-line text-muted cursor-pointer"
                                                                                                onClick={() => copy(paymentOptions?.cash?.[activeCashIndex]?.accountHolderName)}></i>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <div className="label-group">
                                                                                        <label> Account Number </label>
                                                                                        <p>
                                                                                            {paymentOptions?.cash?.[activeCashIndex]?.accountNumber}
                                                                                            <i className="ms-1 fw-normal ri-file-copy-line text-muted cursor-pointer"
                                                                                                onClick={() => copy(paymentOptions?.cash?.[activeCashIndex]?.accountNumber)}></i>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className=" utr_tab pay_step" >
                                                                <div className="uttr_title" >
                                                                    <h5> <span className="step_icon step_icon_two">2 </span> Submit Payment Details</h5>
                                                                    <p>  Add Transaction details after complete your payment. </p>
                                                                </div>

                                                                <div className="p-0 modal_form">
                                                                    <div className="row">

                                                                        {activePaymentMode === "Bank" && (
                                                                            <div className="form-group form-group-sm col-md-6">
                                                                                <select
                                                                                    className="form-control form-control-sm form-select"
                                                                                    name="paymentMode"
                                                                                    value={formData.paymentMode}
                                                                                    onChange={handleChangeAddUtr}
                                                                                >
                                                                                    <option value="">Select Payment Mode</option>
                                                                                    <option value="IMPS">IMPS</option>
                                                                                    <option value="NEFT">NEFT</option>
                                                                                    <option value="RTGS">RTGS</option>
                                                                                    <option value="Intra Bank Transfer">Intra Bank Transfer</option>
                                                                                </select>
                                                                                {errors.paymentMode && <small className="invalid-feedback text-danger">{errors.paymentMode}</small>}
                                                                            </div>
                                                                        )}
                                                                        {activePaymentMode === "Cash" && (
                                                                            <div className="form-group form-group-sm col-md-6">
                                                                                <select
                                                                                    className="form-control form-control-sm form-select"
                                                                                    name="paymentMode"
                                                                                    value={formData.paymentMode}
                                                                                    onChange={handleChangeAddUtr}
                                                                                >
                                                                                    <option value="">Select Payment Mode </option>
                                                                                    <option value="Deposit To Branch">Deposit To Branch</option>
                                                                                    <option value="Deposit To ATM">Deposit To ATM</option>
                                                                                </select>
                                                                                {errors.paymentMode && <small className="invalid-feedback text-danger">{errors.paymentMode}</small>}
                                                                            </div>
                                                                        )}
                                                                        <div className="form-group form-group-sm col-md-6">
                                                                            <input
                                                                                type="number"
                                                                                className="form-control form-control-sm"
                                                                                placeholder="Txn./Ref. Number"
                                                                                name="utrNumber"
                                                                                value={formData.utrNumber}
                                                                                onChange={handleChangeAddUtr}
                                                                                onWheel={(e) => e.target.blur()}
                                                                                onKeyDown={(e) => {
                                                                                    if (["+", "-", "e", "E"].includes(e.key)) {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {errors.utrNumber && <small className="invalid-feedback text-danger">{errors.utrNumber}</small>}
                                                                        </div>
                                                                        <div className="form-group form-group-sm col-md-6">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control form-control-sm"
                                                                                placeholder="Description (Optional)"
                                                                                name="description"
                                                                                value={formData.description}
                                                                                onChange={handleChangeAddUtr}
                                                                            />
                                                                        </div>

                                                                        {
                                                                            activePaymentMode === "Cash" ?

                                                                                <div className="form-group form-group-sm col-md-6">
                                                                                    <button type="button" className="btn-custom attchment_btn form-control form-control-sm" onClick={handleUploadSelfie} disabled={!formData?.paymentMode && !formData?.utrNumber}>  {imageStatus ?

                                                                                        <span className="fw-semibold" >
                                                                                            <i className="ri-check-double-line me-1 fw-semibold"></i>
                                                                                            <em>Attached</em>
                                                                                        </span>
                                                                                        :
                                                                                        <span>
                                                                                            <i className="ri-add-line   me-1 fw-semibold"></i>
                                                                                            Add Attachment
                                                                                        </span>

                                                                                    } </button>
                                                                                </div>
                                                                                :
                                                                                <div className="form-group form-group-sm col-md-6">
                                                                                    <input type="file" className="form-control form-control-sm" id="fileInput" onChange={handleFileChange} />
                                                                                    {errors.attachment && <small className="invalid-feedback text-danger">{errors.attachment}</small>}
                                                                                </div>
                                                                        }

                                                                        {responseResult && <small className="invalid-feedback text-danger">{responseResult}</small>}
                                                                    </div>
                                                                    <div className="form-group form-group-sm mb-0 mt-2">
                                                                        <button type="button" className="form-control btn-warning form-control-sm" disabled={isLoading} onClick={handleSubmit}>
                                                                            {
                                                                                isLoading ? <SpinLoader /> : <span>Submit</span>
                                                                            }
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={`pymt_rem ${showTimerModel ? "" : "d-none"}`} >
                                                    <div className="rem_col" >
                                                        <div className="rem_top" >
                                                            <CountdownTimer amountDepositedAt={transactionDetail?.amount_deposited_at} id={transactionDetail?._id} reminder={transactionDetail?.payment_details?.remindedAdmin}
                                                                handleTranactionList={handleTranactionList} />
                                                            <h5> Wating for admin Reponse </h5>
                                                        </div>
                                                        <hr />
                                                        <div className="rem_mid" >
                                                            <p > Order Status </p>
                                                            <ul className="order_progress" >
                                                                <li className="st_complete" > <em></em> <span> Order Placed </span> </li>
                                                                <li className="st_complete" > <em></em> <span>Payment Initiated</span> </li>
                                                                <li className="st_complete" > <em></em> <span> UTR Submission </span>   </li>
                                                                <li className="st_pending" > <em></em> <span> Payment Confirmation <small> It uselly take ~ 15 mins to reflect to your wallet </small> </span>   </li>
                                                            </ul>
                                                        </div>
                                                        <div className="rem_end" >
                                                            <div className="tt_data rem_card">
                                                                <div className="tt_item ">
                                                                    <span className="text-muted">  Paid Amount: </span>
                                                                    <span className="tt_normal">{transactionDetail?.inr_deposit_amount} {transactionDetail?.pay_currency}</span>
                                                                </div>
                                                                <div className="tt_item  ">
                                                                    <span className="text-muted"> Initiated Date </span>
                                                                    <span className="tt_normal">{moment(transactionDetail?.amount_deposited_at).format('Do MMM YYYY, h:mm:ss a')}</span>
                                                                </div>
                                                                <div className="tt_item  ">
                                                                    <span className="text-muted">  UTR No  </span>
                                                                    <span className="tt_normal">
                                                                        {transactionDetail?.payment_details?.utrNumber}
                                                                    </span>
                                                                </div>
                                                                <div className="tt_item  ">
                                                                    <span className="text-muted">  Order No  </span>
                                                                    <span className="tt_normal">
                                                                        {transactionDetail?.order_id}
                                                                    </span>
                                                                </div>
                                                                <div className="tt_item no-border ">
                                                                    <span className="text-muted"> Reference No </span>
                                                                    <span className="tt_normal"> {transactionDetail?.ref_no}
                                                                        <i className={`text-black cursor-pointer ${isActiveCopy === transactionDetail?.ref_no ? "ri-check-line" : "ri-file-copy-line"}`} onClick={() => { copy(transactionDetail?.ref_no); setIsActiveCopy(transactionDetail?.ref_no) }} ></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <button type="button" className="btn btn-md btn-warning btn-block mt-3 w-100" onClick={() => setIsActiveAddMoney(false)}>
                                                                Go to Home
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="illustration_box" >   </div>
                                        <img src="/assets/images/illustration_box.svg" className="img-fluid pop_img " alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </div>
                </div>
            )
            }


            {
                isOpenOrderdetailsModal && (
                    <div className="d-block" >
                        <div className="isOpenOrderdetailsModal modal chat-modal ccc_modal show d-block" >
                            <div className="modal-dialog modal-dialog-centered ">
                                <div className="modal-content p-0  ">
                                    <div className="Attachment">
                                        <button type="button" className="btn-sm btn-icon btn-dark modal-close"
                                            onClick={() => setIsOpenOrderdetailsModal(false)}>
                                            <i className="ri-close-large-line"></i>
                                        </button>
                                    </div>
                                    <div className="modal-body p-0">
                                        <div className="row g-0" >
                                            <div className="col-lg-12" >
                                                <div className="oh_left" >

                                                    <div className={`oh_top ${transactionDetail?.side === "SELL" ? "bg-danger" : transactionDetail?.status === "Completed" ? "bg-green" : transactionDetail?.status === "Rejected" ? "bg-danger" :
                                                        transactionDetail?.status === "Cancelled By User" ? "bg-danger" : transactionDetail?.status === "Pending" ? "bg-warning" : transactionDetail?.status === "Refund Initiated" ? "bg-warning" : transactionDetail?.status === "Refund Processed" ? "bg-danger" : "bg-primary"}`} >
                                                        <div className="ot_top" >
                                                            <span><b>Order No: {transactionDetail?.order_id}</b> </span>



                                                            <span>
                                                                <b> {transactionDetail?.status === "Cancelled By User" ? "Cancelled" : transactionDetail?.status === "Refund Processed" ? "Refunded" : transactionDetail?.status}</b>


                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="Attachment">
                                                        <div className="ot_body" >
                                                            <div>
                                                                <span className={transactionDetail?.side === "SELL" ? "text-danger" : "text-success"} >{transactionDetail?.side}</span> | <span> Total amount </span>
                                                                <h6 className="mb-0"> {transactionDetail?.pay_amount} <sub>
                                                                    {transactionDetail?.side === "BUY" ? transactionDetail?.quote_currency : transactionDetail?.base_currency}
                                                                </sub> </h6>
                                                            </div>
                                                            <div className="d-flex align-items-center gap-1 w-auto justify-content-end ms-auto">
                                                                {ordersTab === "Pending" &&
                                                                    <div className="d-flex align-items-center gap-1" >
                                                                        {
                                                                            !transactionDetail?.amount_deposited &&
                                                                            <button className="btn btn-warning btn-sm"
                                                                                onClick={() => { setIsActiveAddMoney(true); setShowTimerModel(false); setIsOpenOrderdetailsModal(false) }}
                                                                            >Pay Now</button>
                                                                        }
                                                                        {
                                                                            !transactionDetail?.amount_deposited ?
                                                                                <button className="btn btn-secondary btn-sm" onClick={() => showAlert(transactionDetail?._id)}>Cancel</button>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                }

                                                                <div className="dropdown w-auto d-inline-block">
                                                                    <button type="button" className="btn btn-blank btn-sm p-0" data-bs-toggle="dropdown" fdprocessedid="1s52ls" aria-expanded="false">
                                                                        <i className="ri-more-2-fill ri-xl"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu">

                                                                        {(transactionDetail?.status === "Refund Initiated" || transactionDetail?.status === "Refund Processed") &&
                                                                            (
                                                                                <span className="dropdown-item cursor-pointer" onClick={() => { setIsOpenChatModel(true); setIsOpenOrderdetailsModal(false) }}> Chat History </span>
                                                                            )
                                                                        }



                                                                        <span className="dropdown-item cursor-pointer" onClick={() => { downloadReceipt() }}> Download Receipt </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="oh_body">
                                                        <img src="assets/imges/logo.svg" className="wt_mark" alt="" />
                                                        <div className="d-flex align-items-center justify-content-between" >
                                                            <h6> Order Details </h6>
                                                            <div className="oh_data text-end">
                                                                <p className="mb-0">  Final Amonut:<br /> {transactionDetail?.final_pay_amount} <small> {transactionDetail?.side === "BUY" ? transactionDetail?.quote_currency : transactionDetail?.base_currency}</small> </p>
                                                            </div>
                                                        </div>
                                                        <div className="row g-1">
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" > Reff. No   </span>
                                                                    <p className="mb-0"> {transactionDetail?.ref_no || "---"} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted"  > Order Currency </span>
                                                                    <p className="mb-0"> {transactionDetail?.base_currency} / {transactionDetail?.quote_currency} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" > Rate  </span>
                                                                    <p className="mb-0"> {transactionDetail?.price} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" >    Quantity </span>
                                                                    <p className="mb-0"> {transactionDetail?.get_amount}   {transactionDetail?.side === "SELL" ? transactionDetail?.quote_currency : transactionDetail?.base_currency} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" > Order Type   </span>
                                                                    <p className="mb-0"> {transactionDetail?.bulk_order || "Normal"} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" > Fees ({transactionDetail?.qbs_fees}%) </span>
                                                                    <p className="mb-0"> {transactionDetail?.qbs_fees_amount} {transactionDetail?.quote_currency} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" > TDS  ({transactionDetail?.tds}%)  </span>
                                                                    <p className="mb-0"> {transactionDetail?.tds_amount} {transactionDetail?.quote_currency}  </p>
                                                                </div>
                                                            </div>



                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" >Order Created Time   </span>
                                                                    <p className="mb-0"> {moment(transactionDetail?.order_created_at).format('Do MMM YYYY')} <br />
                                                                        <small>{moment(transactionDetail?.order_created_at).format('h:mm:ss a')}</small>
                                                                    </p>
                                                                </div>
                                                            </div>


                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" >Payment Status </span>
                                                                    <p className={transactionDetail?.amount_deposited === true ? "text-success" : transactionDetail?.remark === "Auto Completed" ? "text-success" : "text-warning"}> {transactionDetail?.amount_deposited === true ? "Paid" : transactionDetail?.remark === "Auto Completed" ? "Debit From Wallet" : "Unpaid"}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            {
                                                                transactionDetail?.use_available && transactionDetail?.inr_deposit_amount == 0 ? null :

                                                                    <>

                                                                        <div className="col-4" >
                                                                            <div className="oh_data" >
                                                                                <span className="text-muted" >Order Paid Time   </span>
                                                                                <p className="mb-0">
                                                                                    {
                                                                                        transactionDetail?.amount_deposited_at ? (
                                                                                            <>
                                                                                                {moment(transactionDetail.amount_deposited_at).format("Do MMM YYYY")}
                                                                                                <br />
                                                                                                <small>{moment(transactionDetail.amount_deposited_at).format("h:mm:ss a")}</small>
                                                                                            </>
                                                                                        ) : (
                                                                                            null
                                                                                        )
                                                                                    }

                                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-4" >
                                                                            <div className="oh_data" >
                                                                                <span className="text-muted" >Order Remind Time   </span>

                                                                                {
                                                                                    transactionDetail?.remindAdminAfter ? (
                                                                                        <p className="mb-0">
                                                                                            {moment(transactionDetail.remindAdminAfter).format("Do MMM YYYY")}
                                                                                            <br />
                                                                                            <small>{moment(transactionDetail.remindAdminAfter).format("h:mm:ss a")}</small>
                                                                                        </p>
                                                                                    ) : (
                                                                                        null
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        </div>

                                                                        <div className="col-4" >
                                                                            <div className="oh_data" >
                                                                                <span className="text-muted" >Order Updatd Time   </span>
                                                                                {
                                                                                    transactionDetail?.status_updated_at ? (
                                                                                        <p className="mb-0">
                                                                                            {moment(transactionDetail.status_updated_at).format("Do MMM YYYY")}
                                                                                            <br />
                                                                                            <small>{moment(transactionDetail.status_updated_at).format("h:mm:ss a")}</small>
                                                                                        </p>
                                                                                    ) : (
                                                                                        null
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                            }
                                                        </div>

                                                        {
                                                            transactionDetail?.use_available && transactionDetail?.inr_deposit_amount > 0 &&
                                                            <>
                                                                <hr />
                                                                <h6> Deduct From Wallet </h6>
                                                                <div className="row g-1">

                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted"> Payment Type </span>
                                                                            <p className="mb-0">From Wallet</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted"> Paid Amount </span>
                                                                            <p className="mb-0">{transactionDetail?.pay_amount - transactionDetail?.inr_deposit_amount} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted"> Remaining Amount </span>
                                                                            <p className="mb-0">{transactionDetail?.inr_deposit_amount} </p>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </>
                                                        }


                                                        <hr />
                                                        <h6> Payment Details </h6>
                                                        <div className="row g-1">
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted"  > Payment Type </span>
                                                                    <p className="mb-0">  {transactionDetail?.use_available && transactionDetail?.inr_deposit_amount == 0 ? "From Wallet" : transactionDetail?.payment_details?.paymentType} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" >
                                                                <div className="oh_data" >
                                                                    <span className="text-muted" > Payment Mode </span>
                                                                    <p className="mb-0"> {transactionDetail?.use_available && transactionDetail?.inr_deposit_amount == 0 ? "Debit From Wallet" : transactionDetail?.payment_details?.paymentMode} </p>
                                                                </div>
                                                            </div>

                                                            {
                                                                !transactionDetail?.use_available && !transactionDetail?.utrNumber &&
                                                                <>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > Payment Details </span>
                                                                            {
                                                                                transactionDetail?.payment_details?.paymentMode ?
                                                                                    <p className="mb-0">
                                                                                        {transactionDetail?.payment_details?.paymentMode === "UPI"
                                                                                            ? `UPI ID: ${transactionDetail?.payment_details?.paymentOptionData?.upiDetails?.vpaId || "---"}`
                                                                                            : `Bank Name: ${transactionDetail?.payment_details?.paymentOptionData?.bankDetails?.bankName || "---"} 
                                                                                            Ac. No: ${transactionDetail?.payment_details?.paymentOptionData?.bankDetails?.accountNumber || "---"}
                                                                                             IFSC: ${transactionDetail?.payment_details?.paymentOptionData?.bankDetails?.ifsc || "---"}`
                                                                                        }
                                                                                    </p>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > UTR No.   </span>
                                                                            <p className="mb-0">  {transactionDetail?.payment_details?.utrNumber || "---"}  </p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                transactionDetail?.use_available && transactionDetail?.inr_deposit_amount > 0 &&

                                                                <>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > {transactionDetail?.payment_details?.paymentMode === "UPI" ? "UPI" : "Bank"} Details </span>
                                                                            {
                                                                                transactionDetail?.payment_details?.paymentMode ?
                                                                                    <p className="mb-0">
                                                                                        {transactionDetail?.payment_details?.paymentMode === "UPI"
                                                                                            ? `UPI ID: ${transactionDetail?.payment_details?.paymentOptionData?.upiDetails?.vpaId || "---"}`
                                                                                            : `Bank Name: ${transactionDetail?.payment_details?.paymentOptionData?.bankDetails?.bankName || "---"}  
                                                                                            Ac. No: ${transactionDetail?.payment_details?.paymentOptionData?.bankDetails?.accountNumber || "---"} IFSC: ${transactionDetail?.payment_details?.paymentOptionData?.bankDetails?.ifsc || "---"}`
                                                                                        }
                                                                                    </p>
                                                                                    :
                                                                                    null
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > UTR No.   </span>
                                                                            <p className="mb-0">  {transactionDetail?.payment_details?.utrNumber || "---"}  </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted"> Amount </span>
                                                                            <p className="mb-0">{transactionDetail?.inr_deposit_amount} </p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }


                                                            {
                                                                !transactionDetail?.use_available && !transactionDetail?.utrNumber &&
                                                                <>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > UTR Added At </span>
                                                                            {
                                                                                transactionDetail?.amount_deposited_at ? (
                                                                                    <p className="mb-0">
                                                                                        {moment(transactionDetail.amount_deposited_at).format("Do MMM YYYY")}
                                                                                        <br />
                                                                                        <small>
                                                                                            {moment(transactionDetail.amount_deposited_at).format("h:mm:ss a")}
                                                                                        </small>
                                                                                    </p>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > Utr Remark </span>
                                                                            <p className="mb-0">{transactionDetail?.payment_details?.remark || "---"}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > UTR Added By  </span>
                                                                            <p className="mb-0">{transactionDetail?.payment_details?.utrAddedBy || "---"} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > Order Status Remark   </span>
                                                                            <p className="mb-0"> {transactionDetail?.remark} </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-4 Attachment">
                                                                        <div className="oh_data">
                                                                            <span className="text-muted">Attachment</span>
                                                                            <p className="mb-0">
                                                                                {
                                                                                    transactionDetail?.payment_details?.attachment ?
                                                                                        <button
                                                                                            className="btn btn-outline-dark btn-xs"
                                                                                            type="button"
                                                                                            onClick={() => handleOpen(transactionDetail?.payment_details?.attachment)}
                                                                                        >
                                                                                            View
                                                                                        </button>
                                                                                        : "---"
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        {isOpen && (
                                                                            <Lightbox
                                                                                open={isOpen}
                                                                                close={() => setIsOpen(false)}
                                                                                slides={[
                                                                                    {
                                                                                        src: fileUrl.endsWith(".pdf")
                                                                                            ? `https://docs.google.com/gview?url=${fileUrl}&embedded=true`
                                                                                            : fileUrl,
                                                                                    },
                                                                                ]}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </>
                                                            }




                                                            {
                                                                transactionDetail?.use_available && transactionDetail?.inr_deposit_amount > 0 &&
                                                                <>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > UTR Added At </span>
                                                                            {
                                                                                transactionDetail?.amount_deposited_at ? (
                                                                                    <p className="mb-0">
                                                                                        {moment(transactionDetail.amount_deposited_at).format("Do MMM YYYY h:mm")}

                                                                                    </p>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-8" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > User Remark </span>
                                                                            <p className="mb-0">{transactionDetail?.payment_details?.remark || "---"}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > UTR Added By  </span>
                                                                            <p className="mb-0">{transactionDetail?.payment_details?.utrAddedBy || "---"} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-8" >
                                                                        <div className="oh_data" >
                                                                            <span className="text-muted" > Order Status Remark   </span>
                                                                            <p className="mb-0"> {transactionDetail?.remark} </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-4 Attachment">
                                                                        <div className="oh_data">
                                                                            <span className="text-muted">Attachment</span>
                                                                            <p className="mb-0">
                                                                                {
                                                                                    transactionDetail?.payment_details?.attachment ?
                                                                                        <button
                                                                                            className="btn btn-outline-dark btn-xs"
                                                                                            type="button"
                                                                                            onClick={() => handleOpen(transactionDetail?.payment_details?.attachment)}
                                                                                        >
                                                                                            View
                                                                                        </button>
                                                                                        : "---"
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        {isOpen && (
                                                                            <Lightbox
                                                                                open={isOpen}
                                                                                close={() => setIsOpen(false)}
                                                                                slides={[
                                                                                    {
                                                                                        src: fileUrl.endsWith(".pdf")
                                                                                            ? `https://docs.google.com/gview?url=${fileUrl}&embedded=true`
                                                                                            : fileUrl,
                                                                                    },
                                                                                ]}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </>
                                                            }

                                                        </div>


                                                        {
                                                            (transactionDetail?.status === "Refund Initiated" || transactionDetail?.status === "Refund Processed") &&
                                                            (
                                                                <>
                                                                    <hr />
                                                                    <h6> Refund Details </h6>
                                                                    <div className="row g-1">

                                                                        <div className="col-4" >
                                                                            <div className="oh_data" >
                                                                                <span className="text-muted" > Refund Initited At </span>
                                                                                {
                                                                                    transactionDetail?.status_updated_at ? (
                                                                                        <p className="mb-0">
                                                                                            {moment(transactionDetail.status_updated_at).format("Do MMM YYYY h:mm")}

                                                                                        </p>
                                                                                    ) : (
                                                                                        null
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4" >
                                                                            <div className="oh_data" >
                                                                                <span className="text-muted"> Refund Amount </span>
                                                                                <p className="mb-0"> {transactionDetail?.inr_deposit_amount} </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4" >
                                                                            <div className="oh_data" >
                                                                                <span className="text-muted"> Refund Remark </span>
                                                                                <p className="mb-0">{transactionDetail?.remark}</p>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            transactionDetail?.refund_processed_at &&
                                                                            <>
                                                                                <div className="col-4" >
                                                                                    <div className="oh_data" >
                                                                                        <span className="text-muted" > Refund Processed At </span>
                                                                                        {
                                                                                            transactionDetail?.refund_processed_at ? (
                                                                                                <p className="mb-0">
                                                                                                    {moment(transactionDetail.refund_processed_at).format("Do MMM YYYY h:mm")}

                                                                                                </p>
                                                                                            ) : (
                                                                                                null
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-4">
                                                                                    <div className="oh_data">
                                                                                        <span className="text-muted"> Refund Mode/UTR </span>
                                                                                        <p className="mb-0">UTR</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-4">
                                                                                    <div className="oh_data">
                                                                                        <span className="text-muted"> Refund Processed Remark </span>
                                                                                        <p className="mb-0">{transactionDetail?.refund_processed_remark}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"> </div>
                    </div>
                )
            }

            {
                isOpenChatModel &&
                <div className="d-block" >
                    <div className="modal chat-modal ccc_modal show d-block" >
                        <div className="modal-dialog modal-dialog-centered ">
                            <div className="modal-content p-0  ">
                                <button type="button" className="btn-sm btn-icon btn-dark modal-close" onClick={() => setIsOpenChatModel(false)}>
                                    <i className="ri-close-large-line"></i>
                                </button>
                                <div className="modal-body p-0">
                                    <div className="row g-0" >
                                        <div className="col-lg-12" >
                                            <div className="oh_right" >
                                                <div className="or_top" >
                                                    <div className="oh_user" >
                                                        <img src="/assets/images/user_thumb.png" className="u_img img-fluid" alt="" />
                                                        <span className=""> {transactionDetail?.user_name} <small> {transactionDetail?.order_id} </small> </span>
                                                    </div>
                                                    <div className="or_action" >
                                                        <button className="badge no-border  btn-secondary" onClick={() => { setIsOpenOrderdetailsModal(true); setIsOpenChatModel(false) }}> Order Details </button>
                                                    </div>
                                                </div>

                                                {/* modal chat */}
                                                <div className="chat_box g_start p-0 chat-sm">
                                                    <div className="chat_body scroll_y">
                                                        <div className="msg_history scroll_bar">
                                                            <div className="ag_status">
                                                                <p>You are currently chating with - Unknown Agent  </p>
                                                            </div>
                                                            <div className="incoming_msg" >
                                                                <div className="received_msg important_msg">
                                                                    <div className="received_withd_msg">
                                                                        <p>
                                                                            <b>Importane Note: </b>
                                                                            <br />
                                                                            sadasd asd asd asdasd
                                                                            asd asd asdasd asd asd asdas asd asda asd
                                                                            asdasdasdasd asd as
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="outgoing_msg"  >
                                                                <div className="sent_msg">
                                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.  </p>
                                                                </div>
                                                                <div className="msg_time">
                                                                    <small> 15 min </small>
                                                                </div>
                                                            </div>
                                                            <div className="incoming_msg" >
                                                                <div className="received_msg">
                                                                    <div className="received_withd_msg">
                                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.  </p>
                                                                    </div>
                                                                    <div className="msg_time">
                                                                        <small> 18 min </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {(transactionDetail?.status === "Refund Initiated" || transactionDetail?.status === "Refund Processed") &&
                                                        (
                                                            <div className="chat-footer send-message cup">
                                                                <form>
                                                                    <input type="text" cols="10" rows="1" className="form-control" placeholder="Type your message here ..." />
                                                                    <span className="chat_action">
                                                                        <button className="btn_attach  btn-icon">
                                                                            <input type="file" />
                                                                            <i className="ri-link"></i>
                                                                        </button>
                                                                        <button type="button" className="btn btn-primary send " fdprocessedid="fi0lzb">
                                                                            <i className="ri-send-plane-fill  "></i>  </button>
                                                                    </span>
                                                                </form>
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div>
            }

            {
                showSelfieModel &&
                <div className="d-block" >
                    <div className="modal chat-modal ccc_modal show d-block">
                        <div className="modal-dialog modal-md  modal-dialog-centered ">
                            <div className="modal-content p-0  ">
                                <div className="modal-header" >
                                    <h5 className="modal-title"> Upload Selfie  </h5>
                                    <button type="button" className="btn-close" onClick={() => { setShowSelfieModel(false); setImageStatus(false); setIsActiveAddMoney(true); setShowTimerModel(false); }}> </button>
                                </div>
                                <div className="modal-body modal_form">
                                    <div className="form-group" >
                                        <div className="row" >

                                            {isCameraAllowed === false &&
                                                <div className="col-lg-12" >
                                                    <div className="field-box text-center">
                                                        <img alt="Liveness Check" className="img-fluid box-shadow" src="/assets/images/receipt_mockup.svg" />
                                                        <div className="tip_ul">
                                                            <h6>Tips</h6>
                                                            <ul>
                                                                <li>
                                                                    <i className="ri-checkbox-circle-fill text-success"></i> Find a well lit place
                                                                </li>
                                                                <li>
                                                                    <i className="ri-checkbox-circle-fill text-success"></i>Make sure your face and the receipt are within the frame
                                                                </li>
                                                                <li>
                                                                    <i className="ri-close-circle-fill text-danger"></i>Don't wear hats, glasses &amp; masks
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <div className="col-lg-12" >

                                                {isCameraAllowed && !imageStatus ? (
                                                    <div className="wcr_card" >
                                                        <div className="web_cam_row wcr_solo  "  >
                                                            <div className="web_cam_box" >
                                                                <Webcam
                                                                    audio={false}
                                                                    ref={webcamRef}
                                                                    screenshotFormat="image/jpeg"
                                                                />
                                                            </div>
                                                            <button onClick={capture} type="button" className="btn btn-dark fw-semibold px-5 no-border">
                                                                <i className="ri-camera-fill fw-normal me-2 ri-xl"></i>  Capture
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    imageStatus &&
                                                    <div className="wcr_card wcr_captured" >
                                                        <div className="web_cam_row wcr_solo  " >
                                                            <div className="web_cam_box" >
                                                                <img src={image} alt="Captured" />
                                                            </div>
                                                            <button onClick={() => { setImageStatus(false) }} type="button" className="btn btn-dark fw-semibold px-5 no-border">
                                                                <i className="ri-reset-left-line  fw-normal  me-2"></i>  Re-take
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>


                                        </div>
                                    </div>
                                    <div className="form-group mb-0 mt-3" >

                                        {isCameraAllowed === false && <p className="text-center" >Checking camera permission...</p>}
                                        {isCameraAllowed === false ? (
                                            <button onClick={checkCameraPermission} type="button" className=" w-100 btn-block btn btn-dark mt-2">Check permission</button>
                                        )
                                            :
                                            <button type="button" className=" w-100 btn-block btn btn-warning mt-2"
                                                onClick={() => { setShowSelfieModel(false); setIsActiveAddMoney(true) }}>
                                                PROCEED
                                            </button>
                                        }
                                        {responseResult && <small className="invalid-feedback text-danger">{responseResult}</small>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div>
            }
        </main >
    )
}

export default BuySell