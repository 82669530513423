import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Footer";
import PartnerSection from "../../../HomePage/PartnerSection";
import Whyus from "../../../HomePage/Whyus";

const CompanyManagement = () => {


    return (
        <>
            <main>


                <section className="tm_sec  cm_sec about_bg" > 
                    <div className="container" >
                        <div className="row align-items-center" >
                            <div className="col-lg-6 col-xl-5" >
                                <h2>
                                    India's Trusted
                                    Crypto Trading Platform
                                </h2>
                                <p>
                                    Crypto India is India's most trusted and reliable cryptocurrency trading platform, dedicated to empowering millions of Indians with seamless and secure access to the world of digital assets. Our mission is to offer a user-friendly and transparent platform where both beginners and experienced traders can buy, sell, and trade a wide range of cryptocurrencies with confidence.
                                </p>
                                <a href="/user_support" className="btn btn-dark px-5 btn-lg" > Get in Touch </a>
                            </div>
                            <div className="col-lg-6  col-xl-7" >
                                <div className="company_img" >
                                    <img src="assets/images/company_img.png" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="  cm_partner_sec p-0" >
                    <PartnerSection />
                </section>
                <section className="  cm_target_sec bg-light " >
                    <div className="container" >
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <h2 className="font-weight-normal mt-4">
                                    A team of digital enthusiasts ready for the next challenge
                                </h2>
                                <p className="text-muted mt-4">
                                    Over the past decade, we've helped hundreds of companies thrive in the digital world. Growing businesses through innovation is our goal.
                                </p>
                            </div>
                            <div className="col-lg-7" >
                                <div className="row g-0">
                                    <div className="col-6 bg-white text-purple text-center py-5 py-md-7">
                                        <h1 className="display-3">
                                            30k
                                        </h1>
                                        <p className="m-0"> Crypto Sell</p>
                                    </div>
                                    <div className="col-6 bg-purple text-center py-5 py-md-7">
                                        <h1 className="display-3 text-white">
                                            $100m
                                        </h1>
                                        <p className="m-0 text-white"> Digital Assets </p>
                                    </div>
                                    <div className="col-6 bg-purple text-center py-5 py-md-7">
                                        <h1 className="display-3 ">
                                            2012
                                        </h1>
                                        <p className="m-0 "> Founded </p>
                                    </div>
                                    <div className="col-6 bg-white text-purple text-center py-5 py-md-7">
                                        <h1 className="display-3">
                                            99.8%
                                        </h1>
                                        <p className="m-0">  Happy User </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section className="about-team sp">
                    <div className="container position-relative">
                        <div className="sec_title mb-5">
                            <h2 className="text-dark text-center "> Our Dedicated Team </h2>
                            <p>

                            </p>
                        </div>


                        <div className="row team_row g-4  justify-content-center" >
                            <div className="col-lg-3 col-md-6" >
                                <div className="how_card card_why" >
                                    <img src="assets/images/blank_img.png" className="img-fluid" />
                                    <div className="card_contant" >
                                        <h5>
                                            Pradeep kumar yadav
                                            <small>
                                                CEO
                                            </small>
                                        </h5>
                                        {/* <a  href="mailto:ceo@cryptoindia.in" >ceo@cryptoindia.in </a>   */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6" >
                                <div className="how_card card_why" >
                                    <img src="assets/images/blank_img.png" className="img-fluid" />
                                    <div className="card_contant" >
                                        <h5>
                                            Rahul kumar yadav
                                            <small>
                                                DIRECTOR
                                            </small>
                                        </h5>
                                        {/* <a  href="mailto:director@cryptoindia.in" >director@cryptoindia.in </a>   */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img className="box-shape1 animate3" src="assets/images/footer-shape2.png" alt="" />
                        <img className="box-shape2" src="assets/images/footer-shape5.png" alt="" />
                        <img className="box-shape3 animate1" src="assets/images/footer-shape5.png" alt="" />
                    </div>
                    <img className="shape1 animate3" src="assets/images/footer-shape4.png" alt="" />
                    <img className="shape2 animate4" src="assets/images/footer-shape1.png" alt="" />
                </section>

                <Whyus />

                <section className="cta_sec" >
                    <div className="container" >
                        <div className="row justify-content-center" >
                            <div className="col-lg-10 col-xl-8 col-xxl-8" >
                                <div className="cta_content" >
                                    <div className="sec_title">
                                        <h2 className="text-dark text-center "> Know more about Us?</h2>
                                        <p>
                                            Discover who we are and what drives us! Learn more about our mission, values, and the people behind our work.
                                        </p>
                                        <div className="cta_social" >
                                            <a href="https://www.instagram.com/cryptoindiaexchange/?igsh=MWh3bTZ2Z3RzMmFvMg%3D%3D" className="cta_btn" >
                                                <i className="ri-instagram-line"></i>
                                                <span></span>
                                                Instagram
                                            </a>
                                            <a href="https://t.me/cryptoindiasupport" className="cta_btn" >
                                                <i className="ri-telegram-2-fill"></i>
                                                <span></span>
                                                Telegram
                                            </a>
                                            <a href="https://x.com/cryptoindia_in?s=11&t=g9-804Y9YmuEcyomNZ0b5A" className="cta_btn" >
                                                <i className="ri-twitter-x-line"></i>
                                                <span></span>
                                                Twitter - X
                                            </a> 
                                            <a href="https://whatsapp.com/channel/0029VahMtDlAu3aT8uo21t2L" className="cta_btn" >
                                                <i className="ri-whatsapp-line"></i>
                                                <span></span>
                                                 Whatsapp
                                            </a>
                                            <a href="https://www.youtube.com/@CryptoIndiaExchange" className="cta_btn" >
                                                <i className="ri-youtube-line"></i>
                                                <span></span>
                                                 Youtube
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




            </main>

            <Footer />
        </>

    )
}

export default CompanyManagement