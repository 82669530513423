import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import { alertErrorMessage, alertSuccessMessage } from "../../../../Services/CustomAlertModel";
import AuthService from "../../../../Services/ApiServices/AuthService";
import { fetchCoinDetails, fetchWalletList } from "../../../../Context/Utility/apiutils";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import { ProfileContext } from "../../../../Services/ProfileProvider";
const WithdrawFunds = () => {


    const location = useLocation();
    const { state } = location;
    const [stateData, setStateData] = useState(state || null);


    const [isActive, setIsActive] = useState('step1')
    const [coinList, setCoinList] = useState([]);
    const [search, setsearch] = useState("");
    const [fiterPairData, setFiterPairData] = useState([]);
    const [coinDetails, setCoinDetails] = useState([]);
    const [coinFee, setCoinFee] = useState([]);
    const [adjustedAmount, setAdjustedAmount] = useState(null);
    const [amountValue, setAmountValue] = useState("")
    const [walletAddress, setWalletAddress] = useState("")
    const [otp, setOtp] = useState("");
    const [chainName, setChainName] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const { userDetails } = useContext(ProfileContext);
    const [activeModel, setActiveModel] = useState(false);
    const [withdrawAmount, setWithdrawAmount] = useState("")
    const [isActiveInr, setIsActiveInr] = useState('step1');
    const KycStatus = userDetails?.user?.kycVerified;

    const handleInputChange = (e) => {
        const value = parseFloat(e.target.value);
        if (isNaN(value)) {
            setAmountValue("");
            setAdjustedAmount(null);
        } else {
            setAmountValue(value);
            if (value >= coinFee?.min_withdrawal) {
                setAdjustedAmount(value - coinFee?.withdrawal_fee);
            } else {
                setAdjustedAmount(null);
            }
        }
    };


    const handleGetOtp = async (signId, type) => {
        try {
            const result = await AuthService.getOtp(signId, type);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                setIsOtpSent(true);
                setCountdown(60);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const handleWithdrawalDetails = (item, step) => {
        if (!userDetails?.permissions?.withdrawal) {
            alertErrorMessage('Disable withdrawal..');
            return;
        }
        if (item?.short_name === 'INR') {
            setIsActive('step3')
            setCoinDetails(item)
            fetchCoinDetails(setCoinFee, item?.currency_id)
        } else {
            setIsActive(step)
            setCoinDetails(item)
            fetchCoinDetails(setCoinFee, item?.currency_id)
        }
    }

    useEffect(() => {
        fetchWalletList(setCoinList, "", "");
    }, []);

    useEffect(() => {
        let filteredData = coinList?.filter((item) => {
            return (
                item?.short_name?.toLowerCase().includes(search?.toLowerCase())
            );
        });
        setFiterPairData(filteredData);
    }, [search, coinList]);


    const steponeWithdraw = () => {

        if (!chainName) {
            alertErrorMessage("please select a chain");
            return;
        } if (!walletAddress) {
            alertErrorMessage("please enter withdraw address");
            return;
        } if (!amountValue) {
            alertErrorMessage("please enter withdraw amount");
            return;
        } if (!isOtpSent) {
            alertErrorMessage("please send otp first");
            return;
        } if (!otp) {
            alertErrorMessage("please enter otp");
            return;
        }
        else {
            setActiveModel(true);
        }
    }

    useEffect(() => {
        let timer;
        if (isOtpSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, countdown]);

    const showAlert = (walletAddress, amountValue, otp) => {
        Swal.fire({
            title: "Are you sure?",
            text: `You are going to withdraw ${amountValue} ${coinFee?.short_name}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, withdraw it!"
        }).then((result) => {
            if (result.isConfirmed) {
                handleWithdrawal(walletAddress, amountValue, otp)
            }
        });
    };

    const handleWithdrawal = async (walletAddress, amountValue, otp) => {
        try {
            const result = await AuthService.cryptoWithdrawal(walletAddress, amountValue, otp, chainName, userDetails?.user?.email, coinDetails?.currency_id);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                setIsActive('step1')
                handleReset();
                setActiveModel(false);
            } else {
                alertErrorMessage(result);
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setChainName(selectedValue);
    }

    const handleReset = () => {
        setWalletAddress("");
        setAmountValue("");
        setOtp("");
        setChainName("");
        setCountdown(0);
        setWithdrawAmount("");
    }


    const withdrawalInrProcess = (withdrawAmount, otp) => {
        if (!withdrawAmount || withdrawAmount < 1000 || withdrawAmount > coinDetails?.balance) {
            alertErrorMessage("Invalid withdrawal amount. Please enter an amount between 1000 and your available balance.");
            return;
        }
        if (!withdrawAmount) {
            alertErrorMessage("please enter withdraw amount");
            return;
        } if (!isOtpSent) {
            alertErrorMessage("please send otp first");
            return;
        } if (!otp) {
            alertErrorMessage("please enter otp");
            return;
        }
        setIsActiveInr('step2')
    }

    const withdrawalInr = (withdrawAmount, otp) => {
        alertErrorMessage('Something went wrong');
    }


    const handleOtpChange = (event) => {
        const value = event.target.value;
        if (value.length <= 6) {
            setOtp(value);
        }
    };


    const handleWithdrawalDetailsAuto = (item, step) => {
        if (userDetails?.permissions?.withdrawal) {
            alertErrorMessage('Disable withdrawal..');
            return;
        }
        if (item?.short_name === 'INR') {
            setIsActive('step3')
            setCoinDetails(item)
            fetchCoinDetails(setCoinFee, item?.currency_id)
        } else {
            setIsActive(step)
            setCoinDetails(item)
            fetchCoinDetails(setCoinFee, item?.currency_id)
        }
    }

    const handleBack = () => {
        setStateData(null);
        setIsActive('step1');
    };

    useEffect(() => {
        if (stateData) {
            handleWithdrawalDetailsAuto(stateData, "step2");
        }
    }, [stateData]);

    useEffect(() => {
        fetchWalletList(setCoinList);
    }, []);


    return (
        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper">
                    <section className="d_page history_page p_page deposit_wrapper">
                        <div className="container">

                            <div className="row   gx-5">
                                <div className="col-xxl-7 col-xl-7">
                                    <div className="d_u_p align-items-center m-0">
                                        <div className="dup_left">
                                            <h6 className="mb-0">Withdraw  Funds </h6>
                                        </div>
                                    </div>


                                    <div className="df_row" >


                                        {/* Step 1     */}
                                        <div className="df_col">
                                            <span className="df_step" > <em className=" " > <i class="ri-check-line"></i> </em>  Select Currency </span>
                                            <div className="df_card position-relative" >
                                                <div className="df_top" >
                                                    <div className="custom_select" >
                                                        {/* label only */}
                                                        <label className="d-flex align-items-center justify-content-between" > Select Coin  <i class="ri-arrow-down-s-line"></i>  </label>

                                                        {/* after selected */}
                                                        <div class="c_view w-100 d-none align-items-center justify-content-between">
                                                            <div class="d-flex align-items-center">
                                                                <img width="22" class="img-fluid icon_img coinimg_max me-2" alt="" />
                                                                <span>INR   <small class="opacity-65 ms-1"> 00.00</small>
                                                                </span>
                                                            </div>
                                                            <i class="ri-arrow-down-s-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="df_dropbox" >
                                                    <div className="drop_card" >
                                                        <div className="df_cl" >
                                                            {/* search bar */}
                                                            <div className="cl_top" >
                                                                <div className="searchBar custom-tabs">
                                                                    <i className="ri-search-2-line"></i>
                                                                    <input type="search" className="custom_search w-100" placeholder="Search Crypto Coin" value={search} onChange={(e) => setsearch(e.target.value)} />
                                                                </div>
                                                                <small className="text-muted" > Coin List </small>
                                                            </div>
                                                            <div className="cl_cl scroll_y" >
                                                                {fiterPairData?.length > 0 ? (
                                                                    fiterPairData?.map((item, index) => (
                                                                        <>
                                                                            <span class="cl_llist">
                                                                                <div class="c_view w-100">
                                                                                    <div class="d-flex align-items-center" key={index} onClick={() => handleWithdrawalDetails(item, 'step2')} >
                                                                                        <img src={item.icon_path} width="25" className="img-fluid icon_img coinimg_max me-2 " alt=""
                                                                                            onError={(e) => {
                                                                                                e.target.onerror = null;
                                                                                                e.target.src = "/assets/images/icon/nocoin.png";
                                                                                            }} />
                                                                                        <span> {item.short_name}
                                                                                        </span>
                                                                                    </div>
                                                                                    <small class="opacity-65 ms-1"> {item.balance} </small>
                                                                                </div>
                                                                            </span>
                                                                        </>
                                                                    ))
                                                                ) : (
                                                                    <div className="favouriteData">
                                                                        <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                                    </div>
                                                                )}


                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Step 2 df_disabled*/}
                                        <div className="df_col  ">
                                            <span className="df_step" > <em>2</em> Select Chain (Network) </span>
                                            <div className="df_card  position-relative " >
                                                <div className="df_top" >
                                                    <div className="form-group" >
                                                        {coinDetails?.chain?.length > 0 ? (
                                                            <select
                                                                className="form-select form-control fw-semibold "
                                                                value={chainName}
                                                                onChange={handleSelectChange}
                                                            >
                                                                <option>
                                                                    Select a network
                                                                </option>
                                                                {coinDetails.chain.map((item, index) => (
                                                                    <option key={index} value={item} className="form-control">
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            null
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/* Step 3 df_disabled */}
                                        <div className="df_col ">
                                            <span className="df_step" > <em> 3 </em> Withdraw to </span>
                                            <div className="df_card " >
                                                <div className="df_top " >
                                                    <div className="form-group mb-3">
                                                        <div className=" field-otp-box" >
                                                            <select
                                                                className="form-select form-control fw-semibold " >
                                                                <option>
                                                                    Select  Address
                                                                </option>
                                                                <option>
                                                                    Address 1
                                                                </option >
                                                                <option>
                                                                    Address 2
                                                                </option >
                                                                <option>
                                                                    Address 3
                                                                </option >
                                                            </select>
                                                            <button type="button" class="btn btn-xs btn-secondary"  > Add Address </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        {/* Step 4 df_disabled */}
                                        <div className="df_col ">
                                            <span className="df_step" > <em> 3 </em> Withdraw amount </span>
                                            <div className="df_card " >
                                                <div className="df_top " >
                                                    <div className="form-group ">
                                                        <div className=" field-otp-box">
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                onWheel={(e) => e.target.blur()}
                                                                name="amount_val"
                                                                value={amountValue}
                                                                placeholder="Amount"
                                                                onChange={handleInputChange}
                                                                onBlur={(e) => {
                                                                    const value = parseFloat(e.target.value);
                                                                    if (value > parseFloat(coinDetails?.balance)) {
                                                                        setAmountValue(coinDetails?.balance);
                                                                        if (value >= coinFee?.min_withdrawal) {
                                                                            setAdjustedAmount(coinDetails?.balance - coinFee?.withdrawal_fee);
                                                                        } else {
                                                                            setAdjustedAmount(null);
                                                                        }
                                                                    }
                                                                }}
                                                                max={coinDetails?.balance}
                                                            />
                                                            <button type="button" className="btn btn-xs btn-dark" onClick={() => setAmountValue(coinDetails?.balance)}>MAX</button>
                                                        </div>
                                                    </div>
                                                    <div className="fandc_sec" >
                                                        <a class="collapsed" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                            <span className="fw-normal" >Available Withdraw</span> <span>0.00 USDT <i class="ri-arrow-down-s-line"></i> </span>
                                                        </a>
                                                        <div class="collapse" id="collapseExample">
                                                            <div class="tt_data mb-0 mt-2">
                                                                <div class="tt_item">
                                                                    <span class="text-muted"> Remaining Limit 24h </span>
                                                                    <span class="tt_normal  "> 000.00  </span>
                                                                </div>
                                                                <div class="tt_item no-border">
                                                                    <span class="text-muted">  Network Fees </span>
                                                                    <span class="tt_normal  ">  000.00  </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wa_row" >
                                                        <div className="wc_left" >
                                                            <small>Receive Amount</small>
                                                            <p className="mb-0" > 00.00 USDT </p>
                                                        </div>
                                                        <button className="btn btn-warning form-control px-5" > withdraw </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>



                                </div>
                                <div className="col-xxl-4 col-xl-4">
                                    <div className="dup_left d-block d-none d-xl-block">
                                        <div className="Markets d-flex align-items-center justify-content-between">
                                            <h6 className="mb-0">  FAQ </h6>
                                            {/* <Link to="/pay_history"> View All <i class=" ri-arrow-right-s-line"></i> </Link> */}
                                        </div>

                                        <div class="accordion faq_mini" id="accordionExample">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        What precautions should I take before Withdraw crypto?
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse  " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>
                                                            Double-check the wallet address before sending.
                                                            Ensure the selected network matches the network you’re sending from (e.g., don't send ERC20 to a TRC20 address).
                                                            Verify the correct coin/token is selected.
                                                            Always start with a small test transaction if unsure.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingTwo">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Can I Withdraw from a different blockchain network?

                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>Only Withdraw using the same network mentioned in your Withdraw instructions. For example:
                                                        </p>
                                                        <p className="mb-0" > <b>Example:</b> If your Withdraw address is for USDT-TRC20, do not send USDT-ERC20 or USDT-BEP20 to it.
                                                            Sending to the wrong network could result in permanent loss of funds.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        What happens if I Withdraw an unsupported coin or token?


                                                    </button>
                                                </h2>
                                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>Depositing coins/tokens that are not supported by Crypto India Exchange can result in irreversible loss. Always check our supported assets list before sending.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                        What is the risk of phishing or fake addresses?
                                                    </button>
                                                </h2>
                                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>Beware of phishing scams or malware that may alter wallet addresses on your device.
                                                        </p>
                                                        <p>
                                                            Always access Crypto India Exchange via official website/app.
                                                            Use two-factor authentication (2FA) for added security.

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                        Is it safe to deposit large amounts at once?

                                                    </button>
                                                </h2>
                                                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>
                                                            If you're depositing large amounts:

                                                        </p>
                                                        <p>
                                                            Start with a small test transaction first.

                                                        </p>
                                                        <p>
                                                            Split your deposit into smaller parts if needed.
                                                        </p>
                                                        <p>
                                                            Monitor the confirmation process.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                        What if I entered the wrong deposit address?

                                                    </button>
                                                </h2>
                                                <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>
                                                            Unfortunately, if you send crypto to the wrong or third-party address, Crypto India Exchange cannot reverse or recover it. Always copy and paste carefully.

                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                        Should I check transaction status on blockchain?

                                                    </button>
                                                </h2>
                                                <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>
                                                            Yes. You can always track your transaction status via blockchain explorer using your transaction hash (TxID). This helps ensure transparency and troubleshooting if delays occur.

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                        What should I do if I face any issues during deposit?

                                                    </button>
                                                </h2>
                                                <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>
                                                            Check Deposit History in your Wallet section.
                                                            Contact Customer Support with your TxID, coin name, amount, and time.
                                                            Don’t panic—our team will help guide you based on your transaction status.

                                                        </p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>



                                </div>
                                <div className="col-xxl-11 col-xl-11 mt-lg-4 mt-3" >
                                    <div class="Markets">
                                        <h5><small>Recent Transactions</small></h5>
                                        <Link to="/transaction_history"> View All <i class=" ri-arrow-right-s-line"></i> </Link>
                                    </div>
                                    <div className="custom_list rct_list" >
                                        <div className="list_row cursor-pointer">
                                            <div class="spotName gap-1">
                                                <img className="img-fluid" alt="" width="20" height="20" src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" />
                                                <div class="symblecurrency">45.00 USDT </div> <span className="badge badge-sm bg-success-lite"> Completetd </span>
                                            </div>
                                            <div className="rct_row" >
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>Date:</span> 25-03-01 11:11
                                                    </div>
                                                </div>
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>Network:</span>  ETH
                                                    </div>
                                                </div>
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>Address:</span> <div>2123321....231231  <i class="ri-links-fill cursor-pointer"></i>  <i class="ri-file-copy-line cursor-pointer"></i></div>
                                                    </div>
                                                </div>
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>TxID:</span> <div> 2123321....231231  <i class="ri-links-fill cursor-pointer"></i>  <i class="ri-file-copy-line cursor-pointer"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>Deposite Wallet:</span> Spot Wallet
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="list_row cursor-pointer">

                                            <div class="spotName gap-1">
                                                <img className="img-fluid" alt="" width="20" height="20" src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" />
                                                <div class="symblecurrency">0.000 USDT</div> <span className="badge badge-sm bg-warning-lite"> Pending </span> <span className="badge badge-sm bg-primary cursor-pointer"> <i class="ri-add-line"></i> Add Details </span>
                                            </div>
                                            <div class="progress w-auto">
                                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style={{ width: '45%' }} aria-valuenow="85"
                                                    aria-valuemin="0" aria-valuemax="100">
                                                </div>
                                            </div>
                                            <div className="rct_row" >
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>Date:</span> 25-03-01 11:11
                                                    </div>
                                                </div>
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>Network:</span>  ETH
                                                    </div>
                                                </div>
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>Address:</span>  <div> 2123321....231231
                                                            <i class="ri-links-fill cursor-pointer"></i>  <i class="ri-file-copy-line cursor-pointer"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>TxID:</span>   <div>2123321....231231 <i class="ri-links-fill cursor-pointer"></i>  <i class="ri-file-copy-line cursor-pointer"></i></div>
                                                    </div>
                                                </div>
                                                <div className="list_col" >
                                                    <div className="list_span" >
                                                        <span>Deposite Wallet:</span> Spot Wallet
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="no_data" >
                                        <img src="assets/images/no-data.svg" width="110" />
                                        <p>
                                            <small> No transactions in the last 24 hours </small>
                                        </p>
                                    </div>  */}
                                </div>
                            </div>

                            {/* old data */}
                            <div className=" row justify-content-center d-none">
                                <div className="col-xxl-10 col-xl-12  ">
                                    <div className="d_u_p align-items-center m-0">
                                        <div className="dup_left">
                                            <h6 className="mb-0"> Withdraw Funds </h6>
                                        </div>
                                        <div className="up_right text-end">
                                            <Link to="/transaction_history" className="btn btn-muted"> Recent Transactions </Link>
                                        </div>
                                    </div>
                                    <div className="g_start d_card mt-3">

                                        {/* Step 1  */}
                                        <div className={`step-1 ${isActive === 'step1' ? 'active' : 'd-none'}`}>
                                            <div className="nc_header">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6  col-lg-7">
                                                        <span className="nc_icon justify-content-start">
                                                            Select Coin
                                                        </span>
                                                    </div>
                                                    {coinList?.length > 0 ?
                                                        <div className="col-md-6 col-lg-5">
                                                            <div className="searchBar custom-tabs">
                                                                <i className="ri-search-2-line"></i>
                                                                <input type="search" className="custom_search w-100" placeholder="Search Crypto Coin" value={search} onChange={(e) => setsearch(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </div>
                                            <div className="nc_body scroll_y">
                                                <div className="ng_table">
                                                    <div className="container">
                                                        <div className="row  t_head_chain">
                                                            <div className="col-3 text-start">
                                                                <p>Coin Name</p>
                                                            </div>
                                                            <div className="col-3 text-center">
                                                                <p>Available Balance</p>
                                                            </div>
                                                            <div className="col-3 text-center">
                                                                <p>Locked Balance</p>
                                                            </div>
                                                            <div className="col-3 text-end">
                                                                <p>Total Balance</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="t_body_row">
                                                        {fiterPairData?.length > 0 ? (
                                                            fiterPairData?.map((item, index) => (
                                                                <span className="row align-items-center t_body_chain cursor_pointer" key={index} onClick={() => handleWithdrawalDetails(item, 'step2')}>
                                                                    <div className="col-3 text-start">
                                                                        <div className="td_div">
                                                                            <img src={item.icon_path} width="25" className="img-fluid icon_img coinimg_max me-2 " alt=""
                                                                                onError={(e) => {
                                                                                    e.target.onerror = null;
                                                                                    e.target.src = "/assets/images/icon/nocoin.png";
                                                                                }} />
                                                                            <b>{item.short_name}</b>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3 text-center">
                                                                        <p>{item.balance}</p>
                                                                    </div>
                                                                    <div className="col-3 text-center">
                                                                        <p>{item.locked_balance}</p>
                                                                    </div>
                                                                    <div className="col-3 text-end">
                                                                        <p>{(Number(item?.balance) || 0) + (Number(item?.locked_balance) || 0)}</p>
                                                                    </div>
                                                                </span>
                                                            ))
                                                        ) : (
                                                            <div className="favouriteData">
                                                                <img src="/assets/images/not_found.svg" className="img-fluid" width="240" alt="" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Step 2 */}
                                        <div className={`step-3 ${isActive === 'step2' ? 'active' : 'd-none'}`}>
                                            <div className="nc_header">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6  col-lg-7">
                                                        <span className="nc_icon justify-content-start ">

                                                            <span className="btn-icon cursor_pointer" onClick={() => { setIsActive('step1'); handleReset(); handleBack() }}><i className="ri-arrow-left-s-line"></i></span>

                                                            Withdraw {coinDetails.short_name}
                                                            <img src={coinDetails.icon_path} width="25" className="img-fluid icon_img coinimg_max me-2 " alt=""
                                                                onError={(e) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = "/assets/images/icon/nocoin.png";
                                                                }} />
                                                        </span>
                                                    </div>
                                                    <div className="col-md-6 col-lg-5 text-end">
                                                        <p className="text_ifo">
                                                            <small className="text-muted d-block">Balance </small>
                                                            <small><b className="text-dark">{coinDetails?.balance} {coinDetails?.short_name}</b></small>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="nc_body nc_qr">
                                                <div className="qr_body d-block">
                                                    <div className="form-group mb-4" >
                                                        <label>Select Chain</label>
                                                        {coinDetails?.chain?.length > 0 ? (
                                                            <select
                                                                className="form-select form-control width-50"
                                                                value={chainName}
                                                                onChange={handleSelectChange}
                                                            >
                                                                <option>
                                                                    Select a network
                                                                </option>
                                                                {coinDetails.chain.map((item, index) => (
                                                                    <option key={index} value={item} className="form-control">
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            null
                                                        )}
                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <label> Address</label>
                                                        <input className="form-control" type="text" name="wallet_Add" value={walletAddress} placeholder="Wallet Address" onChange={(e) => setWalletAddress(e.target.value)} />
                                                    </div>
                                                    <div className="form-group  col-lg-12 col-md-12 col-12 mb-4">
                                                        <label> Enter Amount </label>
                                                        <div className=" field-otp-box">
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                onWheel={(e) => e.target.blur()}
                                                                name="amount_val"
                                                                value={amountValue}
                                                                placeholder="Amount"
                                                                onChange={handleInputChange}
                                                                onBlur={(e) => {
                                                                    const value = parseFloat(e.target.value);
                                                                    if (value > parseFloat(coinDetails?.balance)) {
                                                                        setAmountValue(coinDetails?.balance);
                                                                        if (value >= coinFee?.min_withdrawal) {
                                                                            setAdjustedAmount(coinDetails?.balance - coinFee?.withdrawal_fee);
                                                                        } else {
                                                                            setAdjustedAmount(null);
                                                                        }
                                                                    }
                                                                }}
                                                                max={coinDetails?.balance}
                                                            />
                                                            <button type="button" className="btn btn-xs btn-dark" onClick={() => setAmountValue(coinDetails?.balance)}>MAX</button>
                                                        </div>
                                                    </div>
                                                    {KycStatus === 2 &&
                                                        <div className=" form-group  col-lg-12 col-md-12 col-12 mb-4">
                                                            <label> Verification Code </label>
                                                            <div className=" field-otp-box">
                                                                <input type="number" name="form-otp" id="form-otp" className="form-control" placeholder="Enter Verification Code" value={otp} onChange={handleOtpChange} onWheel={(e) => e.target.blur()} />

                                                                <button type="button" className="btn btn-xs custom-btn" onClick={() => handleGetOtp(userDetails?.user?.email, 'withdrawal')} disabled={isOtpSent || !chainName || !walletAddress || !amountValue}>
                                                                    <span>{isOtpSent ? `Resend OTP in ${countdown}s` : 'GET OTP'}</span>
                                                                </button>

                                                            </div>
                                                        </div>
                                                    }

                                                    {(amountValue || amountValue === 0) &&
                                                        (amountValue < coinFee?.min_withdrawal ? (
                                                            <span className="mb-2 d-block text-center text-danger">
                                                                <small>Minimum withdrawal amount should be {coinFee?.min_withdrawal}</small>
                                                            </span>
                                                        ) : (
                                                            amountValue > coinDetails?.balance && (
                                                                <span className="mb-2 d-block text-center text-danger">
                                                                    <small>Insufficient Available balance</small>
                                                                </span>
                                                            )
                                                        ))
                                                    }
                                                    <div className="form-group">
                                                        <button
                                                            type="button"
                                                            className="btn custom-btn btn-block w-100 btn-xl"
                                                            onClick={KycStatus === 2 ? steponeWithdraw : undefined}
                                                            disabled={KycStatus !== 2 || amountValue < coinFee?.min_withdrawal}
                                                        >
                                                            <span>{KycStatus === 2 ? "Withdraw" : "KYC Pending"}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/* Step INR */}
                                        <div className={`step-4 ${isActive === 'step3' ? 'active' : 'd-none'}`}>
                                            <div className="nc_header">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6  col-lg-7">
                                                        <span className="nc_icon justify-content-start ">
                                                            <span className="btn-icon cursor_pointer" onClick={() => { setIsActive('step1'); handleReset(); setIsActiveInr('step1'); handleBack() }}><i className="ri-arrow-left-s-line"></i></span>
                                                            INR
                                                        </span>
                                                    </div>
                                                    <div className="col-md-6 col-lg-5 text-end">
                                                        <p className="text_ifo">
                                                            <small className="text-muted d-block">Balance </small>
                                                            <small><b className="text-dark">
                                                                {coinDetails?.balance} {coinDetails?.short_name}
                                                            </b></small>
                                                        </p>
                                                    </div>

                                                    <div className="ng_table">

                                                        {/* step 2 */}
                                                        <div className={`t_body_row modal_form p_card mt-3 ${isActiveInr === 'step1' ? '' : 'd-none'}`}>
                                                            <div className="row">
                                                                <div className="col-12 text-center" >
                                                                    <h6> Withdraw Funds </h6>
                                                                </div>
                                                                <div className="col-12 form-group ">
                                                                    <label className="text-white mb-1"> Amount </label>
                                                                    <input type="number" className="form-control" placeholder="Enter Amount" value={withdrawAmount} onChange={(e) => setWithdrawAmount(e.target.value)} onWheel={(e) => e.target.blur()} />

                                                                </div>
                                                                <div className="balance-libra card-success mb-3">
                                                                    <div className="token-img-bg_right" id="lc-data"> Min. Withdraw:- <b> 1,000 INR </b></div>
                                                                </div>
                                                                {
                                                                    withdrawAmount && withdrawAmount >= 1000 &&

                                                                    <div className=" form-group  col-12 col-md-12 col-12 mb-4">
                                                                        <label> Verification Code </label>
                                                                        <div className=" field-otp-box">
                                                                            <input type="number" name="form-otp" id="form-otp" className="form-control" placeholder="Enter Verification Code" value={otp} onChange={handleOtpChange} onWheel={(e) => e.target.blur()} />

                                                                            <button type="button" className="btn btn-sm btn-dark" onClick={() => handleGetOtp(userDetails?.user?.email, 'withdrawal')} disabled={isOtpSent}>
                                                                                <span>{isOtpSent ? `Resend OTP in ${countdown}s` : 'GET OTP'}</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="col-12 form-group">
                                                                    {/* bank account */}
                                                                    {userDetails?.bank_details ? userDetails?.bank_details
                                                                        ?.filter(item => item.is_default === true)?.map((item, index) => {
                                                                            return (
                                                                                <div className="b_card" key={index}>
                                                                                    <div className="b_row" >
                                                                                        <div className="b_icon" >
                                                                                            <i className="ri-bank-line"></i>
                                                                                        </div>
                                                                                        <div className="b-content" >
                                                                                            <h6> {item?.bank_name} </h6>
                                                                                            <p className="mb-0" >{item?.account_number}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Link to="/payments" className="btn btn-sm btn-dark" > Change  </Link>
                                                                                </div>
                                                                            )
                                                                        }) : null}

                                                                    {/* UPI Id   */}

                                                                    {userDetails?.upi_details ? userDetails?.upi_details
                                                                        ?.filter(item => item.is_default === true)?.map((item, index) => {
                                                                            return (
                                                                                <div className="b_card" key={index}>
                                                                                    <div className="b_row" >
                                                                                        <div className="b_icon" >
                                                                                            <i className="ri-qr-code-line"></i>
                                                                                        </div>
                                                                                        <div className="b-content" >
                                                                                            <h6>{item?.upi_name}</h6>
                                                                                            <p className="mb-0" > {item?.upi_vpa_id} </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Link to="/payments" className="btn btn-sm btn-dark" > Change  </Link>
                                                                                </div>
                                                                            )
                                                                        }) : null}
                                                                </div>
                                                                <div className="col-md-12" >

                                                                </div>
                                                                {
                                                                    withdrawAmount && withdrawAmount < 1000 &&
                                                                    <span className="mt-3" style={{ color: 'red' }}>Min. Withdraw 1,000 INR</span>
                                                                }
                                                                {
                                                                    withdrawAmount && withdrawAmount > coinDetails?.balance &&
                                                                    <span className="mt-3" style={{ color: 'red' }}>Insufficient Balance</span>
                                                                }
                                                                <div className="col-12" >
                                                                    <button className="btn custom-btn mt-3 w-100 btn-block p-3" onClick={() => withdrawalInrProcess(withdrawAmount, otp)} > Process </button>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* step 3 */}
                                                        <div className={`t_body_row modal_form p_card mt-3 ${isActiveInr === 'step2' ? '' : 'd-none'}`}>
                                                            <div className="row">
                                                                <div className="col-12 text-center" >
                                                                    <h6> Order Details </h6>
                                                                </div>
                                                                <div className="col-12 ">
                                                                    <div className="tt_data ">
                                                                        <div className="tt_card">
                                                                            <div className="tt_item no-border">
                                                                                <span className="text-muted"> Order No : </span>
                                                                                <span className="tt_normal">5265123156</span>
                                                                            </div>
                                                                            <div className="tt_item">
                                                                                <span className="text-muted"> Amount </span>
                                                                                <span className="tt_normal">{withdrawAmount} ₹</span>
                                                                            </div>
                                                                            <div className="tt_item">
                                                                                <span className="text-muted"> Fee </span>
                                                                                <span className="tt_normal">10 ₹</span>
                                                                            </div>
                                                                            <div className="tt_item no-border">
                                                                                <span className="text-muted"> Total :</span>
                                                                                <span className="tt_normal">{withdrawAmount - 10} ₹</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12" >
                                                                    <button className="btn custom-btn mt-3 w-100 btn-block p-3" onClick={() => withdrawalInr(withdrawAmount, otp)}> Withdraw INR </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            {/* withdraw funds details */}
            {
                activeModel &&
                <div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-md ">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title  " id="modal_otp_Label"> Withdraw Bitcoin </h5>
                                    <button type="button" className="btn-close" onClick={() => setActiveModel(false)}></button>
                                </div>
                                <div className="modal-body p-md-4 ">
                                    <div className="tt_data ">
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Withdrawal Address: </span>
                                            <span className="tt_normal">{walletAddress}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Withdrawal fee: </span>
                                            <span className="tt_normal">{coinFee?.withdrawal_fee} {coinFee?.short_name}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted"> Contact fee:</span>
                                            <span className="tt_normal"> 0.00 {coinFee?.short_name}</span>
                                        </div>
                                        <div className="tt_item no-border">
                                            <span className="text-muted">Min. withdrawal amount: </span>
                                            <span className="tt_normal">{coinFee?.min_withdrawal} {coinFee?.short_name}</span>
                                        </div>
                                        <div className="tt_item">
                                            <span className="text-muted pb-2"> You will get: </span>
                                            <span className="tt_normal h6"> <b>{adjustedAmount || 0} {coinFee?.short_name}</b> </span>
                                        </div>
                                        <div className="tt_item no-border pt-2">
                                            <span className="tt_normal"> Available balance: </span>
                                            <span className="tt_normal">{coinDetails?.balance} {coinDetails?.short_name}</span>
                                        </div>
                                        <button className="btn custom-btn mt-3 w-100 btn-block" onClick={() => showAlert(walletAddress, amountValue, otp)}> Withdraw </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div>
            }


            {/* transctions details modal */}
            <div div className="modal-open-custom  d-none">
                <div className="modal show d-block" >
                    <div className="modal-dialog modal-dialog-centered ">
                        <div className="modal-content  ">
                            <div className="modal-header no-border" >
                                <h5 className="modal-title" > Withdrwal Details</h5>
                                <button type="button" className="btn-close"  ></button>
                            </div>
                            <div className="modal-body pt-0" >
                                <div class="row justify-content-center ">
                                    <div class="col-xl-12">
                                        <form className="pp_success" >
                                            <div class="rem_mid widraw_process ">
                                                <ul class="order_progress mt-0">
                                                    <li class=" Text Dark st_complete">
                                                        <em></em>
                                                        <span> withdrawal order submitted </span>
                                                    </li>
                                                    <li class="st_complete">
                                                        <em></em>
                                                        <span>  System Processing </span>
                                                    </li>
                                                    <li class="st_pending">
                                                        <em></em>
                                                        <span> Completed <small> 23-11-2025 11:44 <br /> Please Note that you will receive a email once is completed </small>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="tt_data mb-0">
                                                <div class="tt_card  ">
                                                    <div class="tt_item">
                                                        <span class="text-muted"> Order No.</span>
                                                        <span class="tt_normal"> <small>2123321....231231  <i class="ri-file-copy-line cursor-pointer"></i></small>  </span>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted">  Status </span>
                                                        <span class="tt_normal text-success"> Completed  </span>
                                                    </div>
                                                    <div class="tt_item  ">
                                                        <span class="text-muted"> Date  </span>
                                                        <span class="tt_normal"> "DD MMM YYYY  <br />
                                                            HH:mm:ss</span>
                                                    </div>
                                                    <div class="tt_item  ">
                                                        <span class="text-muted"> Debit from  </span>
                                                        <span class="tt_normal"> Funding Wallet</span>
                                                    </div>

                                                    <div class="tt_item">
                                                        <span class="text-muted"> Coin </span>
                                                        <div class="spotName gap-1 py-1">
                                                            <img className="img-fluid" alt="" width="20" height="20" src="https://cei-user-images.s3.us-east-1.amazonaws.com/coin-image-1728918833345-usdt.png" />
                                                            <div class="symblecurrency">USDT/INR</div>
                                                        </div>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted"> Withdrawal amount </span>
                                                        <span class="tt_normal">0000 00</span>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted">  From/To </span>
                                                        <span class="tt_normal"> Self/Other </span>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted"> Address</span>
                                                        <span class="tt_normal"> <small>2123321....231231  <i class="ri-links-fill cursor-pointer"></i>  <i class="ri-file-copy-line cursor-pointer"></i></small>  </span>
                                                    </div>
                                                    <div class="tt_item">
                                                        <span class="text-muted"> TxID </span>
                                                        <span class="tt_normal"> <small>2123321....231231  <i class="ri-links-fill cursor-pointer"></i>  <i class="ri-file-copy-line cursor-pointer"></i></small>  </span>
                                                    </div>
                                                    <div class="tt_item no-border">
                                                        <span class="text-muted"> Network Fees   </span>
                                                        <span class="tt_normal"> 00.00</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"> </div>
            </div>
        </main>

    )
}

export default WithdrawFunds