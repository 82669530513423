import React, { useEffect, useState } from "react";
import Footer from "../../../Footer";
import AuthService from "../../../../../Services/ApiServices/AuthService";
import Swal from "sweetalert2";

const CareerPage = () => {

    const [openFormIndex, setOpenFormIndex] = useState(null);
    const [formErrors, setFormErrors] = useState([]);
    const [jobOpeningData, setJobOpeningData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [responseResult, setResponseResult] = useState("");
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^\d{10}$/;

    const [formData, setFormData] = useState(
        jobOpeningData.map(() => ({
            fullName: '',
            email: '',
            phone: '',
            resume: null,
        }))
    );

    const handleInputChange = (index, e) => {
        const { name, value, type, files } = e.target;

        if (name === "phone" && !/^\d*$/.test(value)) {
            return;
        }

        setFormData((prevFormData) =>
            prevFormData.map((data, i) =>
                i === index ? { ...data, [name]: type === "file" && files.length > 0 ? files[0] : value } : data
            )
        );

        setFormErrors((prevErrors) =>
            prevErrors.map((error, i) => (i === index && error?.field === name ? null : error))
        );
        setResponseResult("")
    };


    const handleApplyClick = (index) => {
        setOpenFormIndex(index);
    };

    const handleCancelClick = () => {
        setOpenFormIndex(null);
    };


    const getErrorMessage = (field) => {
        switch (field) {
            case 'fullName':
                return 'Full Name is required';
            case 'email':
                return 'Enter a valid Email Address';
            case 'phone':
                return 'Enter a valid 10-digit Phone Number';
            case 'resume':
                return 'Resume is required';
            default:
                return '';
        }
    };


    useEffect(() => {
        if (jobOpeningData.length > 0) {
            setFormData(
                jobOpeningData.map(() => ({
                    fullName: '',
                    email: '',
                    phone: '',
                    resume: null,
                }))
            );
        }
    }, [jobOpeningData]);

    const getFirstInvalidField = (data) => {
        if (!data?.fullName) return 'fullName';
        if (!data?.email || !emailRegex.test(data.email)) return 'email';
        if (!data?.phone || !phoneRegex.test(data.phone)) return 'phone';
        if (!data?.resume) return 'resume';
        return null;
    };

    const showAlert = (jobTitle,userName) => {
        Swal.fire({
            title: `Dear ${userName} <br/> Thank you for Showing Intrest`,
            text: "Application Submited Successfully",
            icon: "success",
        })
    };

    const handleSubmit = async (index, item) => {
        const data = formData[index];
        if (!data) {
            setResponseResult("Form data is not initialized for this job.");
            return;
        }
        const invalidField = getFirstInvalidField(data);
        if (invalidField) {
            setFormErrors((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = { field: invalidField, message: getErrorMessage(invalidField) };
                return updatedErrors;
            });
        } else {
            setFormErrors((prevErrors) => prevErrors.map((_, i) => (i === index ? null : _)));
            const formValue = new FormData();
            formValue.append("full_name", data.fullName);
            formValue.append("email", data.email);
            formValue.append("phone", data.phone);
            formValue.append("resume", data.resume);
            formValue.append("skills", item.skills);
            formValue.append("job_opening_id", item._id);
            setLoading(true);
            try {
                const result = await AuthService.applyforJob(formValue);
                if (result?.success) {
                    showAlert(item.job_title, data.fullName);
                    handleCancelClick();
                    setLoading(false);
                    setFormData((prevFormData) =>
                        prevFormData.map((item, i) =>
                            i === index
                                ? { fullName: '', email: '', phone: '', resume: null }
                                : item
                        )
                    );
                } else {
                    setLoading(false);
                    setResponseResult(result || "Internal Server Error.");
                }
            } catch (error) {
                setLoading(false);
                setResponseResult(error?.message || "An unexpected error occurred.");
            }
        }
    };

    const handleJobOpening = async () => {
        try {
            const result = await AuthService.getJobOpening();
            if (result?.success) {
                setJobOpeningData(result?.data);
            } else {
                console.error("Failed to fetch job openings:", result?.message);
            }
        } catch (error) {
            console.error("Error fetching job openings:", error);
        }
    };

    useEffect(() => {
        handleJobOpening();
    }, []);


    return (
        <>
            <main>
                <section className="tm_sec d-block">
                    <div className="container">
                        <div className="row mt-md-5 justify-content-center">
                            <div className="col-lg-12">
                                <div className="section-title  partner_title_sect text-start ">
                                    <div className="row align-items-center g-4" >
                                        <div className="col-lg-5" >
                                            <h2 className="text-dark  "> Join the Future of Finance at <br />  Crypto India Exchange!   </h2>
                                            <div className="ddd text-start"  >
                                                <p>
                                                    At *Crypto India Exchange*, we're shaping the future of digital finance by providing innovative solutions in cryptocurrency trading, blockchain technology, and financial services. As India’s leading crypto exchange, we are committed to creating a secure, user-friendly, and efficient platform for our growing community of traders, investors, and blockchain enthusiasts.
                                                </p>
                                                <p>If you're passionate about technology, finance, and innovation, and want to be part of the rapidly evolving crypto space, this is your opportunity! We’re looking for dynamic individuals to join our team and drive the future of decentralized finance.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-7" >
                                            <div className="sec_img" >
                                                <img src="assets/images/mm_program.svg" className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="features_sec feature_bg">
                    <div className="container">
                        <div className="sec_title mb-5">
                            <h2 className="text-dark text-center ">Learn, Grow and Innovate with <br /> Crypto India Exchange </h2>
                            <p>At Crypto India Exchange, we believe that learning is key to innovation. That’s why we provide a work environment where you can grow your skills, explore cutting-edge blockchain technologies, and work on real-world projects that shape the future of finance.</p>
                        </div>
                        <div className="row g-4 justify-content-start">
                            <div className="col-lg-4">
                                <div className="card h-100">
                                    <div className="p-0 h-100">
                                        <div className="h-100">
                                            <div>
                                                <h6 className="text  mb-3">
                                                    Innovative Culture:
                                                </h6>
                                                <span></span>
                                                <p>Work with a passionate team that encourages creativity and out-of-the-box thinking</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="card h-100">
                                    <div className="p-0">
                                        <div className="h-100">
                                            <div>
                                                <h6 className="text mb-3">
                                                    Growth Opportunities
                                                </h6>
                                                <span></span>
                                                <p>Build your career with us in a thriving, global market</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4">
                                <div className="card h-100">
                                    <div className="p-0">
                                        <div className="h-100">
                                            <div>
                                                <h6 className="text mb-3">
                                                    Flexible Work Environment:
                                                </h6>
                                                <span></span>
                                                <p>We offer a collaborative and flexible workspace that adapts to your needs</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="job_opens" >
                    <div className="container mt-5  " >
                        <div className="section-title" >
                            <h2>Current Openings</h2>
                        </div>
                        <div className="grm_row">
                            {jobOpeningData.map((item, index) => (
                                <div className="grm_card" key={index}>
                                    <h5>{item?.job_title}</h5>
                                    <div className="grm_con">
                                        <p>
                                            <b>{item?.job_description}</b>
                                        </p>
                                        <p>
                                            Location: <b>{item?.location}</b>
                                        </p>
                                        <p>
                                            Skills Required: <b>{item?.skills}</b>
                                        </p>
                                        <p>
                                            Experience: <b>{item?.experience}</b>
                                        </p>
                                    </div>
                                    <hr className="my-3" />
                                    {openFormIndex === index ? (
                                        <div>
                                            <div className="row g-3 justify-content-center">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Enter Full Name</label>
                                                        <input
                                                            type="text"
                                                            name="fullName"
                                                            className={`form-control ${formErrors[index]?.field === 'fullName' ? "is-invalid" : ""}`}
                                                            value={formData[index]?.fullName}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                        />
                                                        {formErrors[index]?.field === 'fullName' && (
                                                            <small className="text-danger">{formErrors[index]?.message}</small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Enter Email Address</label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            className={`form-control ${formErrors[index]?.field === 'email' ? "is-invalid" : ""}`}
                                                            value={formData[index]?.email}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                        />
                                                        {formErrors[index]?.field === 'email' && (
                                                            <small className="text-danger">{formErrors[index]?.message}</small>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="form-groupd" >
                                                        <label className="form-label">Enter Phone Number</label>
                                                        <input
                                                            type="text"
                                                            name="phone"
                                                            className={`form-control ${formErrors[index]?.field === 'phone' ? "is-invalid" : ""}`}
                                                            value={formData[index]?.phone}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                            maxLength={10}
                                                        />
                                                        {formErrors[index]?.field === 'phone' && (
                                                            <small className="text-danger">{formErrors[index]?.message}</small>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="form-groupd" >
                                                        <label className="form-label">Upload Your Resume</label>
                                                        <input
                                                            type="file"
                                                            name="resume"
                                                            className={`form-control ${formErrors[index]?.field === 'resume' ? "is-invalid" : ""}`}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                        />

                                                        {formErrors[index]?.field === 'resume' && (
                                                            <small className="text-danger">{formErrors[index]?.message}</small>
                                                        )}
                                                    </div>
                                                </div>
                                                {responseResult && (
                                                    <div className="col-md-12">
                                                        <div className="alert alert-danger">
                                                            {responseResult}</div>
                                                    </div>
                                                )}
                                                <div className="col-lg-12">
                                                    <div className="row gx-2 justify-content-center">
                                                        <div className="col-2" >
                                                            <button className="btn btn-block custom-btn customsignup customsignup-white w-100 py-3" onClick={() => handleCancelClick(false)}> Cancel </button>
                                                        </div>
                                                        <div className="col-3" >
                                                            <button className="btn btn-block btn-dark w-100 py-3" onClick={() => handleSubmit(index, item)}>
                                                                {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                    : <span>Submit</span>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <button
                                            className="btn btn-warning px-5"
                                            onClick={() => handleApplyClick(index)}
                                        >
                                            Apply
                                        </button>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>

    )
}

export default CareerPage