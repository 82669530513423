import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { SocketContext } from '../../../../Context/SocketContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';


const HeroSection = () => {
    const Authtoken = localStorage.getItem("token");
    const [socketData, setSocketData] = useState([]);
    const { socket } = useContext(SocketContext);
    const navigate = useNavigate();

    const chunkArray = (array, chunkSize) => {
        const result = [];
        for (let i = 0; i < array?.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    };

    const chunkedData = chunkArray(socketData, 5);

    useEffect(() => {
        if (socket) {
            let payload = {
                'message': 'market',
            };
            socket.emit('message', payload);
            socket.on('message', (data) => {
                setSocketData(data?.hot);
            });
        }
    }, [socket, chunkedData]);

    const nextPage = (item) => {
        if (item?.quote_currency === "INR") {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            navigate('/buy_sell');
        } else {
            navigate(`/exchange/${item?.base_currency}_${item?.quote_currency}`, { replace: true });
        }
    };
    
    return (
        <section className="hero-section">
            <div className='icons_row' >
                <img src="/assets/images/r_icon_1.svg" className="r_icon r_1" alt='' />
                <img src="/assets/images/r_icon_4.svg" className="r_icon r_4" alt='' />
                <img src="/assets/images/r_icon_5.svg" className="r_icon r_6" alt='' />
            </div>
            <div className="container">
                <div className="row align-items-center gx-5">
                    <div className="col-md-12 col-lg-6  col-xl-7">
                        <h6>open door to new opportunities</h6>
                        <h1 className=" mb-3 text-warning ">Buy, Sell, Trade and Hold <span className="text-dark">350+</span> Cryptocurrencies</h1>
                        <h6>
                            start today and build your <br /> crypto portfolio
                        </h6>
                        <Link className="btn custom-btn btn-xl" to={Authtoken ? '/markets' : '/login'}>
                            Trade Now
                            <i className="ri-arrow-right-circle-line ms-3 ms-md-4 rotate_45 ri-xl"></i>
                        </Link>
                        <div className="text-success txt_secure">
                            <i className="ri-shield-check-line me-2  ri-xl"></i>
                            Secure and Fast Transactions
                        </div>
                    </div> 
                    <div className="col-md-12 col-lg-6 col-xl-5">
                        <div className='hero_card'>
                            <div className='hero_title'>
                                <h5>Top Crypto Coins</h5>
                                <Link to='/markets' className='btn btn-link p-0'>See All <i className="ri-arrow-right-s-line"></i></Link>
                            </div>

                            <Swiper
                                slidesPerView={1}
                                loop={socketData?.length >= 3}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                // modules={[Autoplay]}
                                className="swiper-wrapper"
                            >
                                {chunkedData.map((chunk, slideIndex) => (
                                    <SwiperSlide key={slideIndex}>
                                        {chunk.map((item, index) => (
                                            <div className='h_card hover-active cursor-pointer' key={index} onClick={() => nextPage(item)}>
                                                <div className="td_div">
                                                    <div className='td_img'>
                                                        <img src={item?.icon_path} onError={(e) => { e.target.src = "/assets/images/icon/nocoin.png"; }} className="img-fluid" />
                                                    </div>
                                                    <div>
                                                        <b>{item.name} <em className='text-muted'>{item.base_currency + "/" + item?.quote_currency}</em></b>
                                                        <span className='d-block text-muted'>{item.sell_price?.toFixed(2)}</span>
                                                    </div>
                                                </div>
                                                <span className={item?.change >= 0 ? "badge badge-success text-dark" : "badge badge-danger text-dark"}>{item?.change >= 0 ? '+' : ''} {item.change?.toFixed(2)}</span>
                                            </div>
                                        ))}
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                        </div>
                    </div>
                </div>
            </div>            
        </section>
    );
};

export default HeroSection;
