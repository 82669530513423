import React, { useContext } from "react";
import { Link } from "react-router-dom";
import DashboardSidebar from "../../../CoustamComponent/DashboardSidebar";
import { ProfileContext } from "../../../../Services/ProfileProvider";
import moment from "moment";

const PersonalInformation = () => {

    const { userDetails, userKycDetails } = useContext(ProfileContext);

    return (
        <main>
            <div className="page_wrapper" >
                <div className="sidebar" >
                    <DashboardSidebar />
                </div>
                <div className="af_wrapper" >
                    <section className="d_page m_account">
                        <div className="container" >
                            <div className="row justify-content-center" >
                                <div className="col-xxl-8 col-xl-10" >

                                    <div className="g_start " >
                                        <div className="d_u_p align-items-start m-0" >
                                            <div className="dup_left" >
                                                <h6 className="mb-0" > Personal Information </h6>
                                            </div>
                                            {!userDetails?.nominee?.isLatest &&
                                                <div className="up_right" >
                                                    <div className="btn_row">
                                                        <Link to="/add_nominee" className="btn btn-muted">Add Nominee</Link>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="d_u_p align-items-start mt-4" >
                                            <div className="dup_left" >
                                                <div className="up up_mini" >
                                                    <img className="img-fluid" alt="user"
                                                        src={userKycDetails?.livenessdetails?.imageUrl || "/assets/images/user_thumb.png"}
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = "/assets/images/user_thumb.png";
                                                        }}
                                                    />
                                                </div>
                                                <div className="ud" >
                                                    <span> Name </span>
                                                    <h5> {userKycDetails?.pandetails?.name || "------"}</h5>
                                                </div>
                                            </div>
                                            <div className="up_right" ></div>
                                        </div>

                                        <div className="d_u_p align-items-center mt-4" >
                                            <div className="dup_left" >
                                                <div className="ud" >
                                                    <span> User ID </span>
                                                    <h5>{userDetails?.user?.uid || "-----"}</h5>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            userDetails?.user?.kycVerified === 2 &&
                                            <div className="d_u_p align-items-center mt-4" >
                                                <div className="dup_left" >
                                                    <div className="ud" >
                                                        <span>Account Status</span>
                                                        <h5>{userDetails?.user?.status || "-----"}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        }


                                        <div className="d_u_p align-items-center mt-4" >
                                            <div className="dup_left" >
                                                <div className="ud" >
                                                    <span> Kyc Status  </span>
                                                    <h5 className={`w-100 my-md-0 ${userDetails?.user?.kycVerified === 1 ? "text-warning" : userDetails?.user?.kycVerified === 0 ? "text-warning" : userDetails?.user?.kycVerified === 2 ? "text-success" : "text-danger"}`}>
                                                        {userDetails?.user?.kycVerified == 0 ? "Kyc Pending" : userDetails?.user?.kycVerified == 1 ? "Verification Pending" : userDetails?.user?.kycVerified == 2 ? "Kyc Approved" : userDetails?.user?.kycVerified == 3 || userDetails?.user?.kycVerified == 4 ? "Kyc Rejected" : ""}
                                                    </h5>
                                                </div>
                                            </div>
                                            {
                                                userDetails?.user?.kycVerified == 3 || userDetails?.user?.kycVerified == 4 ?
                                                    <div className="dup_right" >
                                                        <Link to="/verify_kyc" className="btn btn-danger btn-xs " > Verify Again </Link>
                                                    </div>
                                                    : null
                                            }

                                        </div>

                                        <div className="d_u_p align-items-center mt-4" >
                                            <div className="dup_left" >
                                                <div className="ud" >
                                                    <span> Kyc Type  </span>
                                                    <h5>{userDetails?.user?.user_type || "-----"}</h5>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="d_u_p align-items-center mt-4" >
                                            <div className="dup_left" >
                                                <div className="ud" >
                                                    <span> Email Address   </span>
                                                    <h5> {userDetails?.user?.email} </h5>
                                                </div>
                                            </div>
                                            <div className="up_right text-end" >
                                                <span className="badge badge-success"><i className="ri-check-double-line"></i> &nbsp;verified</span>
                                            </div>
                                        </div>

                                        <div className="d_u_p align-items-center mt-4" >
                                            <div className="dup_left" >
                                                <div className="ud" >
                                                    <span> Mobile Number   </span>
                                                    <h5> {userDetails?.user?.countryCode + " " + userDetails?.user?.phone}  </h5>
                                                </div>
                                            </div>
                                            <div className="up_right text-end" >
                                                <span className="badge badge-success"><i className="ri-check-double-line"></i> &nbsp;verified</span>
                                            </div>
                                        </div>
                                        <div className="d_u_p align-items-center mt-4" >
                                            <div className="dup_left" >
                                                <div className="ud" >
                                                    <span>  Joined On   </span>
                                                    <h5>  {moment(userDetails?.user?.createdAt).format('Do MMMM YYYY, h:mm:ss a') || "-----"} </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        userDetails?.nominee?.isLatest &&
                                        <div className="g_start">
                                            <div className="d_u_p align-items-start m-0" >
                                                <div className="dup_left">
                                                    <h6 className="mb-0"> Nominee Details  </h6>
                                                </div>
                                            </div>

                                            <div className=" mt-4  p_card" >
                                            <div className="ud" >
                                            <span>User Name</span>
                                            </div>

                                                <h6 className=" mb-3" >
                                                     <small> {userDetails?.nominee?.full_name || "------"}  </small>
                                                </h6>

                                                <div className="row g-3" >
                                                    <div className="col-md-4" >
                                                        <div className="ud" >
                                                            <span>Relation</span>
                                                            <h5> <small> {userDetails?.nominee?.relation} </small>  </h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4" >
                                                        <div className="ud" >
                                                            <span>  Aadhaar No        </span>
                                                            <h5> <small> {userDetails?.nominee?.adhaar_number} </small>  </h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4" >
                                                        <div className="ud" >
                                                            <span> Date Of Birth        </span>
                                                            <h5> <small> {userDetails?.nominee?.dob} </small>  </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    )
}

export default PersonalInformation