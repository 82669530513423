import React, { useEffect, useState } from "react";
import Footer from "../../../Footer";
import AuthService from "../../../../../Services/ApiServices/AuthService";
import { Link } from "react-router-dom";
import moment from "moment";
import SpinLoader from "../../../SpinLoader";

const GrievanceRedressalPrincipalOfficer = () => {

    const [formData, setFormData] = useState({ ticketId: '', ticketMessage: '', document: null, });
    const [responseResult, setResponseResult] = useState("");
    const [esclateResponse, setEsclateResponse] = useState(null);
    const [isShowDiv, setIsShowDiv] = useState(false);
    const [isShowEscalation, setIsShowEscalation] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [ticketData, setTicketData] = useState();
    const [isShowEsclate, setIsShowEsclate] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [displayButton, setDisplayButton] = useState(false);

    const handleupper = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleInputChange = (e) => {
        const { name, type, files, value } = e.target;
        setFormData({
            ...formData,
            [name]: type === "file" ? files[0] : value,
        });
        if (formErrors && name === getFirstInvalidField()) {
            setFormErrors("");
        }
        if (formErrors && name === getFirstInvalidFieldEsclate()) {
            setFormErrors("");
        }
        setResponseResult("");
    };

    const getFirstInvalidField = () => {
        if (!formData.ticketId) return 'ticketId';
        return null;
    };

    const getFirstInvalidFieldEsclate = () => {
        if (!formData.ticketMessage) return 'ticketMessage';
        return null;
    };

    const validateForm = (name, value) => {
        switch (name) {
            case "ticketId":
                return value ? "" : "Ticket Id is required.";
            case "ticketMessage":
                return value ? "" : "Ticket Message is required.";
            default:
                return "";
        }
    };

    const handleSubmit = async () => {
        setResponseResult("")
        const firstInvalidField = getFirstInvalidField();
        if (firstInvalidField) {
            const error = validateForm(firstInvalidField, formData[firstInvalidField]);
            setFormErrors({ [firstInvalidField]: error });
            return;
        }
        setLoading(true);
        try {
            const result = await AuthService.raiseConcernDetails(formData?.ticketId);
            if (result?.success) {
                setIsShowDiv(false);
                setFormData({ ticketId: '', });
                setIsShowEscalation(true);
                setLoading(false);
                setTicketData(result?.data);
            } else {
                setResponseResult(result || "An error occurred.");
                setLoading(false);
            }
        } catch (error) {
            setResponseResult(error?.message || "An unexpected error occurred.");
            setLoading(false);
        }
    };

    const handleSubmitEsclate = async (status) => {
        setResponseResult("")
        const firstInvalidField = getFirstInvalidFieldEsclate();
        if (firstInvalidField) {
            const error = validateForm(firstInvalidField, formData[firstInvalidField]);
            setFormErrors({ [firstInvalidField]: error });
            return;
        }
        setLoading(true);
        const formValue = new FormData();
        formValue.append("response", formData.ticketMessage);
        formValue.append("user_ticket_attachment", formData.document);
        formValue.append("ticket_closed", status);
        setLoading(true);

        try {
            const result = await AuthService.raiseConcern(formValue, ticketData?.support_ticket_id);
            if (result?.success) {
                setFormData({ ticketId: '', ticketMessage: '', document: null });
                setLoading(false);
                setIsShowEscalation(false);
                setIsShowEsclate(false);
                setIsActive(true);
            } else {
                setResponseResult(result || "An error occurred.");
                setLoading(false);
            }
        } catch (error) {
            setResponseResult(error?.message || "An unexpected error occurred.");
            setLoading(false);
        }
    };

    const handleCheckTicketId = async (ticketId) => {
        const result = await AuthService.checkValidTicketId(ticketId);
        if (result?.success) {
            setResponseResult("");
            setDisplayButton(true);
        } else {
            setResponseResult(result)
            setDisplayButton(false);
        };
    };

    useEffect(() => {
        if (formData.ticketId?.length == 14) {
            handleCheckTicketId(formData.ticketId);
        } else {
            setResponseResult("");
            setDisplayButton(false);
        }
    }, [formData.ticketId]);

    return (
        <>
            <main>
                <section className="tm_sec d-block">
                    <div className="container">
                        <div className="row mt-lg-5 justify-content-center">
                            <div className="col-lg-12">
                                <div className="section-title  partner_title_sect text-start ">
                                    <div className="row align-items-center g-4" >
                                        <div className="col-lg-6" >
                                            <h2 className="text-warning"> Grievance Redressal <br /> Mechanism  </h2>
                                            <div className="ddd text-start"  >
                                                <p>
                                                    At Crypto India, we prioritize the satisfaction of our users and are committed to resolving any issues or concerns as quickly and efficiently as possible. Our multi-tier grievance redressal process ensures that your queries are addressed in a timely manner, with escalating levels of support available if needed.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6" >
                                            <div className="sec_img" >
                                                <img src="assets/images/grievance_redressal_mechanism.svg" className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-5  " >
                        <div className="section-title" >
                            <h2>How to Raise Your Concern</h2>
                            <p>If you have a complaint or feedback, please choose one of the following options:</p>
                        </div>
                        <div className="grm_row" >
                            <div className="grm_card" >
                                <div className="" >
                                    <h6>Step 1:</h6>
                                    <h5>Customer Support</h5>
                                    <p>
                                        If you have a query, issue, or concern, please contact our Customer Support Team first.
                                    </p>
                                    <div className="grm_con" >
                                        <p>Email: <a href="mailto:support@cryptoindia.in" > support@cryptoindia.in </a></p>
                                        <p>Contact: <a href="tel:014119166489" > 0141-19166489 </a></p>
                                        <p className="pr_txt  " >Response Time: <b>Within 24 hours</b></p>
                                    </div>
                                    <hr className="my-1" />
                                    <p className="mb-0 text-dark" >
                                        Our customer support team will do their best to resolve your issue at this initial level.
                                    </p>
                                </div>
                                <hr className="my-3" />
                                <Link to="/user_support" className="btn btn-warning px-5" onClick={handleupper}>
                                    Submit Ticket
                                </Link>
                            </div>
                            <div className="grm_card" >
                                <div className="" >
                                    <h6>Step 2:</h6>
                                    <h5>  Escalation to Nodal Officer (Level 2) </h5>
                                    <p>
                                        If your issue is not resolved satisfactorily by our customer support team, you can escalate the matter to our Principal Nodal Officer (Level 2). The nodal officer will review your case and ensure a proper resolution.
                                    </p>
                                    <p>Principal Nodal Officer Contact Information</p>
                                    <div className="grm_con" >
                                        <p>Name:  Pardeep Kumar Yadav </p>
                                        <p>Email: <a href="mailto:nodal@cryptoindia.in" > nodal@cryptoindia.in </a></p>
                                        <p className="pr_txt mb-0" >Response Time: <b>Time: Within 48 hours</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className="grm_card" >
                                <div className="" >
                                    <h6>Step 3:</h6>
                                    <h5>  Escalation to CEO (Level 3) </h5>
                                    <p>
                                        If you are still not satisfied with the resolution provided by the Nodal Officer, you may escalate the matter to the CEO (Level 3) of Crypto India.
                                    </p>
                                    <p>CEO Contact Information</p>
                                    <div className="grm_con" >
                                        <p>Name:  Pardeep Kumar Yadav </p>
                                        <p>Contact: <a href="ceo@cryptoindia.in" > ceo@cryptoindia.in </a></p>
                                        <p className="pr_txt mb-0" >Response Time: <b>Time: Within 72 hours
                                        </b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="esc_sec cm_target_sec  cta_bg" >
                        <div className="container" >
                            <div className="row justify-content-center" >
                                <div className="col-xxl-10 col-xl-10" >
                                    <div className="row align-items-center" >
                                        <div className="col-md-6" >
                                            <h2 className="font-weight-normal mt-4"> Esclate your Concern?  </h2>
                                            <p>Click below button or link and enter Ticket id to get your concern.  </p>

                                        </div>
                                        <div className="col-md-6 text-lg-end"  >
                                            <button className="btn btn-lg btn-warning" onClick={() => {
                                                setIsShowDiv((prevState) => !prevState); setFormData({ ticketId: '' });
                                                setResponseResult(""); setFormErrors({}); setIsActive(false); setIsShowEscalation(false); setIsShowEsclate(false);
                                            }}>
                                                Esclate Concern
                                            </button>
                                        </div>
                                    </div>
                                    <div className="g_start_card row_two" >
                                        <div className={!isShowDiv ? "d-none" : ""}>
                                            <div className="g_start" >
                                                <div className="row ">
                                                    <div className="col-lg-6">
                                                        <div className="form-groupd">
                                                            <div className="field-otp-box">

                                                                <input
                                                                    type="text"
                                                                    name="ticketId"
                                                                    className={`form-control ${formErrors.ticketId ? "is-invalid" : ""}`}
                                                                    value={formData.ticketId}
                                                                    onChange={handleInputChange}
                                                                    placeholder="Enter Your Ticket ID..."
                                                                />

                                                                <button
                                                                    type="button"
                                                                    className="btn btn-xs custom-btn"
                                                                    onClick={handleSubmit}
                                                                    disabled={loading || !displayButton}
                                                                >
                                                                    {loading ? <SpinLoader /> : <span>Get Details</span>}
                                                                </button>

                                                                {formErrors.ticketId && (
                                                                    <div className="invalid-feedback">{formErrors.ticketId}</div>
                                                                )}
                                                            </div>
                                                            {responseResult && (
                                                                <div className="invalid-feedback">
                                                                    {responseResult}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`g_start_card row_two m_account ${!isShowEscalation ? "d-none" : ""}`} >
                                        <div className="g_start" >
                                            <div className="row justify-content-end" >
                                                <div className="d_u_p align-items-center col-lg-6" >
                                                    <div className="cn_title" >
                                                        <h6>Concern Status</h6>
                                                        <p>
                                                            <span>   Raised at:  </span>
                                                            <small> {moment(ticketData?.ticket_raised_at).format('Do MMM YYYY, h:mm:ss a')} </small>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d_u_p align-items-center col-lg-6" >
                                                    <div className="dup_left ms-auto">
                                                        <div className="ud  ms-auto text-end">
                                                            <span>   Ticket ID  </span>
                                                            <h5> {ticketData?.support_ticket_id} </h5>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <ul className="form-stepper form-stepper-horizontal text-center mx-auto pl-0">

                                                {/* <!-- Step 1 --> */}
                                                <li className={`form-stepper-list text-center ${ticketData?.ticket_level === 1
                                                    ? "form-stepper-active"
                                                    : ticketData?.ticket_level >= 2 && ticketData?.ticket_level <= 3
                                                        ? "form-stepper-completed"
                                                        : "form-stepper-unfinished"
                                                    }`}
                                                    step="1"
                                                >

                                                    <a className="mx-2">
                                                        <span className="form-stepper-circle">
                                                            <span>1</span>
                                                        </span>
                                                        <div className="label"> Customer Support <small>(Level 1) </small> </div>
                                                        <div className="st_response" >
                                                            {responseResult && ticketData?.ticket_level === 1 && (
                                                                <div className="invalid-feedback">
                                                                    <small>{responseResult}</small></div>
                                                            )}
                                                            {esclateResponse && responseResult && ticketData?.ticket_level === 1 && (
                                                                <small className="text-dark">{esclateResponse}</small>
                                                            )}
                                                        </div>
                                                    </a>

                                                </li>

                                                {/* <!-- Step 2 --> */}
                                                <li className={`form-stepper-list text-center ${ticketData?.ticket_level === 2
                                                    ? "form-stepper-active"
                                                    : ticketData?.ticket_level >= 2 && ticketData?.ticket_level <= 3
                                                        ? "form-stepper-completed"
                                                        : "form-stepper-unfinished"
                                                    }`}
                                                    step="2">
                                                    <a className="mx-2">
                                                        <span className="form-stepper-circle text-muted">
                                                            <span>2</span>
                                                        </span>
                                                        <div className="label text-muted"> Escalation to Nodal Officer <small>(Level 2) </small> </div>



                                                        {/* step 2 response */}
                                                        <div className="st_response" >
                                                            {responseResult && ticketData?.ticket_level === 2 && (
                                                                <div className="invalid-feedback">
                                                                    <small>{responseResult}</small></div>
                                                            )}
                                                            {esclateResponse && responseResult && ticketData?.ticket_level === 2 && (
                                                                <small className="text-dark">{esclateResponse}</small>
                                                            )}
                                                        </div>

                                                    </a>
                                                </li>

                                                {/* <!-- Step 3 --> */}
                                                <li className={`form-stepper-list text-center ${ticketData?.ticket_level === 3
                                                    ? ticketData?.status === "Closed"
                                                        ? "form-stepper-completed"
                                                        : "form-stepper-active"
                                                    : "form-stepper-unfinished"
                                                    }`}
                                                    step="3">
                                                    <a className="mx-2">
                                                        <span className="form-stepper-circle text-muted">
                                                            <span>3</span>
                                                        </span>
                                                        <div className="label text-muted"> Escalation to CEO <small>(Level 3)</small> </div>
                                                    </a>
                                                </li>

                                            </ul>

                                            <div className="ml_st_row" >
                                                {/* step 1 Actions */}
                                                <div className="ml_st_col" >
                                                    {
                                                        ticketData?.ticket_level === 1 &&
                                                        <div className="mt_tk_status mb-3" >
                                                            <p> <i class="ri-ticket-2-line"></i> Ticket Status </p>
                                                            <span className={`badge ${ticketData?.status === "Closed" ? 'badge-danger' : 'badge-success'}`} >
                                                                {ticketData?.status}
                                                            </span>
                                                        </div>
                                                    }
                                                    {
                                                        ticketData?.level_1_response &&
                                                        <div className="mtrs_code" >
                                                            <div className="mtrs_col" >
                                                                <span> Action By </span>
                                                                <b> Admin </b>
                                                            </div>
                                                            <div className="mtrs_col" >
                                                                <span> Action </span>
                                                                <b> {ticketData?.level_1_action} </b>
                                                            </div>
                                                            <div className="mtrs_col" >
                                                                <span> Date   </span>
                                                                <b>
                                                                    {moment(ticketData?.level_1_timestamp).format('Do MMM YYYY')}
                                                                    <small> {moment(ticketData?.level_1_timestamp).format('h:mm:ss a')} </small>
                                                                </b>
                                                            </div>

                                                            <div className="mtrs_col col_full" >
                                                                <span> Response   </span>
                                                                <p> {ticketData?.level_1_response} </p>
                                                            </div>
                                                        </div>
                                                    }


                                                    {
                                                        ticketData?.user_esc_level_1_action &&
                                                        <div className="mtrs_code" >
                                                            <div className="mtrs_col" >
                                                                <span> Action By </span>
                                                                <b> User </b>
                                                            </div>
                                                            <div className="mtrs_col" >
                                                                <span> Action </span>
                                                                <b> {ticketData?.user_esc_level_1_action} </b>
                                                            </div>
                                                            <div className="mtrs_col" >
                                                                <span> Date   </span>
                                                                <b>
                                                                    {moment(ticketData?.user_esc_level_1_timestamp).format('Do MMMM YYYY')}
                                                                    <small> {moment(ticketData?.user_esc_level_1_timestamp).format('h:mm:ss a')} </small>
                                                                </b>
                                                            </div>
                                                            <div className="mtrs_col col_full" >
                                                                <span> Response </span>
                                                                <p>  {ticketData?.user_esc_level_1_response} </p>
                                                            </div>
                                                        </div>
                                                    }


                                                    {
                                                        ticketData?.ticket_level !== 3 &&
                                                        <button className={`btn btn-dark px-4 mt-3 btn-block w-100 ${ticketData?.ticket_level == 1 ? "" : "d-none"}`}
                                                            onClick={() => {
                                                                if (ticketData?.status === "Open") {
                                                                    if (Date.now() > ticketData?.level_2_eligibility) {
                                                                        setIsShowEsclate((prevState) => !prevState);
                                                                        setFormData({ ticketMessage: '', document: '' });
                                                                        setResponseResult("");
                                                                        setFormErrors({});
                                                                    } else {
                                                                        setResponseResult("You cannot escalate this ticket before");
                                                                        setEsclateResponse(moment(ticketData?.level_2_eligibility).format('Do MMMM YYYY, h:mm:ss a'));
                                                                    }
                                                                } else {
                                                                    setIsShowEsclate((prevState) => !prevState);
                                                                }
                                                            }}
                                                        >
                                                            Esclate
                                                        </button>
                                                    }


                                                </div>

                                                {/* step 2 Actions */}
                                                <div className="ml_st_col" >
                                                    {
                                                        ticketData?.ticket_level === 2 &&
                                                        <div className="mt_tk_status mb-3" >
                                                            <p> <i class="ri-ticket-2-line"></i> Ticket Status </p>
                                                            <span className={`badge ${ticketData?.status === "Closed" ? 'badge-danger' : 'badge-success'}`} >
                                                                {ticketData?.status}
                                                            </span>
                                                        </div>
                                                    }

                                                    {
                                                        ticketData?.level_2_response &&
                                                        <div className="mtrs_code" >
                                                            <div className="mtrs_col" >
                                                                <span> Action By </span>
                                                                <b> Admin </b>
                                                            </div>
                                                            <div className="mtrs_col" >
                                                                <span> Action </span>
                                                                <b> {ticketData?.level_2_action} </b>
                                                            </div>
                                                            <div className="mtrs_col" >
                                                                <span> Date   </span>
                                                                <b>
                                                                    {moment(ticketData?.level_2_timestamp).format('Do MMM YYYY')}
                                                                    <small> {moment(ticketData?.level_2_timestamp).format('h:mm:ss a')} </small>
                                                                </b>
                                                            </div>

                                                            <div className="mtrs_col col_full" >
                                                                <span> Response   </span>
                                                                <p> {ticketData?.level_2_response} </p>
                                                            </div>
                                                        </div>
                                                    }


                                                    {
                                                        ticketData?.user_esc_level_2_action &&
                                                        <div className="mtrs_code" >
                                                            <div className="mtrs_col" >
                                                                <span> Action By </span>
                                                                <b> User </b>
                                                            </div>
                                                            <div className="mtrs_col" >
                                                                <span> Action </span>
                                                                <b> {ticketData?.user_esc_level_2_action} </b>
                                                            </div>
                                                            <div className="mtrs_col" >
                                                                <span> Date   </span>
                                                                <b>
                                                                    {moment(ticketData?.user_esc_level_2_timestamp).format('Do MMMM YYYY')}
                                                                    <small> {moment(ticketData?.user_esc_level_2_timestamp).format('h:mm:ss a')} </small>
                                                                </b>
                                                            </div>
                                                            <div className="mtrs_col col_full" >
                                                                <span> Response </span>
                                                                <p>  {ticketData?.user_esc_level_2_response} </p>
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        ticketData?.ticket_level !== 3 &&
                                                        <button className={`btn btn-dark px-4 mt-3 btn-block w-100 ${ticketData?.ticket_level === 2 ? "" : "d-none"}`}

                                                            onClick={() => {
                                                                if (ticketData?.status === "Open") {
                                                                    if (Date.now() > ticketData?.level_3_eligibility) {
                                                                        setIsShowEsclate((prevState) => !prevState);
                                                                        setFormData({ ticketMessage: '', document: '' });
                                                                        setResponseResult("");
                                                                        setFormErrors({});
                                                                    } else {
                                                                        setResponseResult("You cannot escalate this ticket before");
                                                                        setEsclateResponse(moment(ticketData?.level_3_eligibility).format('Do MMMM YYYY, h:mm:ss a'));
                                                                    }
                                                                } else {
                                                                    setIsShowEsclate((prevState) => !prevState);
                                                                }
                                                            }}
                                                        >
                                                            Esclate
                                                        </button>
                                                    }

                                                </div>

                                                {/* step 3 Actions */}
                                                <div className="ml_st_col" >
                                                    {
                                                        ticketData?.ticket_level === 3 &&
                                                        <div className="mt_tk_status mb-3" >
                                                            <p> <i class="ri-ticket-2-line"></i> Ticket Status </p>
                                                            <span className={`badge ${ticketData?.status === "Closed" ? 'badge-danger' : 'badge-success'}`} >
                                                                {ticketData?.status}
                                                            </span>
                                                        </div>
                                                    }

                                                    {
                                                        ticketData?.level_3_response &&
                                                        <div className="mtrs_code" >
                                                            <div className="mtrs_col" >
                                                                <span> Action By </span>
                                                                <b> Admin </b>
                                                            </div>
                                                            <div className="mtrs_col" >
                                                                <span> Action </span>
                                                                <b> {ticketData?.level_3_action} </b>
                                                            </div>
                                                            <div className="mtrs_col" >
                                                                <span> Date   </span>
                                                                <b>
                                                                    {moment(ticketData?.level_3_timestamp).format('Do MMM YYYY')}
                                                                    <small> {moment(ticketData?.level_3_timestamp).format('h:mm:ss a')} </small>
                                                                </b>
                                                            </div>

                                                            <div className="mtrs_col col_full" >
                                                                <span> Response   </span>
                                                                <p> {ticketData?.level_3_response} </p>
                                                            </div>
                                                        </div>
                                                    }


                                                    {
                                                        ticketData?.user_esc_level_3_action &&
                                                        <div className="mtrs_code" >
                                                            <div className="mtrs_col" >
                                                                <span> Action By </span>
                                                                <b> User </b>
                                                            </div>
                                                            <div className="mtrs_col" >
                                                                <span> Action </span>
                                                                <b> {ticketData?.user_esc_level_3_action} </b>
                                                            </div>
                                                            <div className="mtrs_col" >
                                                                <span> Date   </span>
                                                                <b>
                                                                    {moment(ticketData?.user_esc_level_3_timestamp).format('Do MMMM YYYY')}
                                                                    <small> {moment(ticketData?.user_esc_level_3_timestamp).format('h:mm:ss a')} </small>
                                                                </b>
                                                            </div>
                                                            <div className="mtrs_col col_full" >
                                                                <span> Response </span>
                                                                <p>  {ticketData?.user_esc_level_3_response} </p>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className={`g_start mt-4 ${!isShowEsclate ? "d-none" : ""}`} >
                                        <div className="row g-3 justify-content-center">
                                            <div className="col-lg-8" >
                                                <div className="form-groupd" >
                                                    <label className="form-label" >Enter Message </label>
                                                    <textarea
                                                        type="text"
                                                        row="6"
                                                        name="ticketMessage"
                                                        className={`form-control ${formErrors.ticketMessage ? "is-invalid" : ""}`}
                                                        value={formData.ticketMessage}
                                                        onChange={handleInputChange}
                                                    ></textarea>
                                                    {formErrors.ticketMessage && (
                                                        <div className="invalid-feedback">{formErrors.ticketMessage}</div>
                                                    )}
                                                </div>
                                                <div className="form-groupd mt-2" >
                                                    <label className="form-label" >Upload Image (Optional)</label>
                                                    <input
                                                        type="file"
                                                        name="document"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>

                                                {responseResult && (
                                                    <div className="invalid-feedback">
                                                        <small>{responseResult}</small></div>
                                                )}
                                                <div className="col-lg-12 mt-4">
                                                    <div className="row gx-2">
                                                        <div className="col-12" >
                                                            <button className="btn btn-block btn-dark w-100 py-3" onClick={() => handleSubmitEsclate(false)}>
                                                                {loading ? <SpinLoader /> : <span>Submit</span>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className={`g_start mt-4 ${!isActive ? "d-none" : ""}`} >
                                        <div className="row g-3 justify-content-center">
                                            <div className="container-fluid">
                                                <div className=" row gx-0 justify-content-center" >
                                                    <div className="col-lg-8 col-xl-6" >
                                                        <div className="overflow-hidden pt-4 text-center ">
                                                            <img src="assets/images/check.png" alt="" width="120" className="img-fluid mx-auto mb-4" />
                                                            <h3 className="text-success" > Thank You <br /> <small><small>{ticketData?.full_name}</small></small></h3>
                                                            <p className="mb-2" > <small>Ticket raised successfully. Email confirmation will follow shortly</small> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="comp_sec" >
                        <div className="container" >
                            <div className="other_info" >
                                <h6>How to File a Complaint</h6>
                                <p>
                                    Contact our Customer Support Team via email or phone to raise your concern.
                                    If unresolved, escalate your issue to our Nodal Officer by providing a reference to your original query or ticket number.
                                </p>
                                <p>
                                    If necessary, escalate to the CEO for a final resolution.
                                    We aim to resolve all issues within the specified timelines at each level. Your satisfaction and trust are important to us, and we are here to assist you at every step.
                                </p>
                            </div>
                        </div>
                    </section>
                </section>
            </main>
            <Footer />
        </>

    )
}

export default GrievanceRedressalPrincipalOfficer